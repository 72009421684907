.service-overview-content-wrapper .service-overview-control {
    display: flex;
    align-items: flex-start;
    background-color: #f7fdff;
    padding: 15px 45px 15px 20px;
    margin: 20px 0;
}

.service-overview-content-wrapper .header-right {
    margin: auto;
}

.service-overview-content-wrapper h1 {
    @extend %page-header-title;
}

.service-overview-control .time-date-selector .st-accordion__inner.expand {
    display: block;
    cursor: default;
    border: 1px solid #dadada;
    padding: 8px;
}
