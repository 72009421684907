@keyframes halfRotation {
    0% {
        transform: rotate(135deg);
    }

    100% {
        transform: rotate(315deg);
    }
}

.circular-progress-bar {
    position: relative;
    height: 24px;
    width: 24px;
    overflow: hidden;
    color: $white;
    font-size: 1px;

    .progress-left,
    .progress-right {
        position: relative;
        display: inline-block;
        width: 50%;
        height: 100%;
        overflow: hidden;

        .circle-bar {
            position: absolute;
            top: 0;
            width: 200%;
            height: 100%;
            border: 1em solid transparent;
            border-radius: 50%;
            transform: rotate(135deg);
            animation: halfRotation linear forwards;
        }
    }

    .progress-left {
        .circle-bar {
            left: 0;
            border-top-color: currentColor;
            border-left-color: currentColor;
        }
    }

    .progress-right {
        .circle-bar {
            right: 0;
            border-bottom-color: currentColor;
            border-right-color: currentColor;
        }
    }
}
