/***********************
    Block Page
 ***********************/

.block-page-settings {
    padding-top: 25px;
}
.block-page-settings-radio-buttons {
    float: left;
}
.block-page-settings-custom {
    margin-top: 25px;
}
.block-page-settings-custom-address {
    margin-left: 10px;
    display: inline-block;
    vertical-align: bottom;
    padding-bottom: 2px;
    float: left;
}
.block-page-settings-custom-address > div {
    float: left;
}
.block-page-settings-custom-address input,
.block-page-settings-custom-address button {
    padding: 6px 5px;
}
.block-page-settings-default-example {
    padding: 4px;
    display: inline-block;
}

.block-page-settings::after {
    content: '';
    clear: both;
    display: block;
}

.block-page-settings-custom-address input {
    width: 250px;
}

.block-page-settings-custom-default-button {
    display: inline-block;
    position: relative;
    top: 4px;
}
.block-page-settings .generic-form-options::after {
    content: '';
    display: block;
    clear: both;
}
