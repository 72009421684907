.dropdown > div:first-child {
    cursor: pointer;

    a {
        padding: 6px 5px;
        display: block;
        border: 1px solid #bdbfc1;
        border-radius: 2px;
        color: var(--dropdown-input-text-color);
        line-height: 1;
    }
}

.toolbar-options .dd-label {
    float: left;
    line-height: 2;
    font-size: 0.9em;
}

.dropdown .dropdown-filter {
    padding: 10px;
    background-color: transparent;
    position: relative;
    border-bottom: 1px solid #cfd2d3;
    border-top: none;

    &:after {
        content: '';
        display: block;
        position: absolute;
        border: 5px solid transparent;
        border-bottom-color: #edeff5;
        top: -10px;
        right: 9px;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        border: 5px solid transparent;
        border-bottom-color: #cfd2d3;
        top: -11px;
        right: 9px;
    }

    input {
        background-color: $input-bg-color;
        color: var(--dropdown-input-text-color);
        width: 100% !important;
        padding: 5px 20px 5px 10px !important;

        &::-webkit-input-placeholder {
            font-size: 13px;
        }
    }

    div {
        position: relative;

        &:after {
            margin-top: 1px;
            cursor: pointer;
            content: '';
            background-image: var(--search-icon);
            right: 1px;
            top: 0;
            position: absolute;
            width: 27px;
            height: 30px;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &.filterd {
        div:after {
            display: none;
        }

        .clear-icon {
            position: absolute;
            right: 5px;
            top: 50%;
            display: block;
            transform: translate(0, -50%) rotate(45deg);
            font-size: 1.5em;
            cursor: pointer;
        }
    }

    .clear-icon {
        display: none;
    }
}

.toolbar-options .options-dropdown {
    width: 100%;
    max-width: 300px;
}

.dropdown .st-tooltip-item {
    left: 0;
    display: none;
    position: absolute;
    z-index: 5000;
    min-width: 100%;
    max-width: 100%;
    background-color: white;

    span {
        display: inline-block;
        width: 100%;
        padding: 0 !important;
    }
}

.dropdown > div > a:after,
.dropdown > div > div > a:after {
    content: ' ';
    display: block;
    position: absolute;
    right: 0;
    top: 2px;
    background: var(--dropdown-primary-arrow-icon) no-repeat center;
    width: 30px;
    height: 30px;
}

.dropdown:hover {
    & > div > a:after,
    & > div > div > a:after {
        background: var(--dropdown-hover-arrow-icon) no-repeat center;
    }
}

.dropdown.active {
    & > div > a:after,
    & > div > div > a:after {
        background: var(--dropdown-selected-arrow-icon) no-repeat center;
    }

    & > div > a:after {
        transform: rotate(180deg);
    }

    .st-tooltip-item {
        background-color: var(--dropdown-list-bg-color);
        display: block;
        border: 1px solid var(--dropdown-border-color);
    }
}

.dropdown.dashboard_toolbar_customerDD .st-tooltip-item .st-tooltip-item__content {
    max-height: 250px;
}

// override display: flex to support text ellipsis
.dropdown.dashboard_toolbar_customerDD span.st-list--data {
    display: inline-block !important;
    padding-right: 20px !important;

    &.customer.indent:before,
    &.serviceprovider:before {
        content: '';
        width: 5px;
        height: 5px;
        margin: 0 8px 2px 0;
        background: #00c5f3;
        display: inline-block;
        line-height: 2;
    }

    &.customer.indent:before {
        margin-left: 10px;
        border-radius: 50%;
        background-color: #000;
    }
}

.widget-settings .dropdown.dashboard_toolbar_customerDD .st-tooltip-item .st-tooltip-item__content {
    height: auto;
}

.widgets-repository-wrapper .dropdown.active .st-tooltip-item {
    top: calc(100% + 5px);
}

.dropdown.active .st-tooltip-item ul.st-list--table {
    list-style: none;
    width: 100%;
    padding: 0;
    max-height: 249px;

    li span,
    li a {
        cursor: pointer;
        text-decoration: none;
        @extend %text-no-overflow;
        position: relative;
        height: 100%;
        padding: 4px 25px 4px 10px;
        display: flex;
        align-items: center;
    }

    li:last-child span {
        border-bottom: none;
    }
}

.st-tooltip-item__content {
    border-bottom: 1px solid #cfd2d3;
}

.st-list--table {
    background-color: var(--dropdown-list-bg-color);

    .st-list--row {
        border-bottom: 1px solid #cfd2d3;
        font-size: 14px;
        cursor: pointer;
        min-height: 41px;
        border-top: none;
        color: var(--dropdown-item-text-color);

        &:hover:not(.st-list--selected) {
            background-color: var(--dropdown-item-hover-bg-color);

            a,
            span {
                background-color: var(--dropdown-item-hover-bg-color);
            }
        }

        &.st-list--selected {
            font-weight: inherit;
        }

        span {
            padding: 9px 40px 5px 10px !important;
            border-left: 2px solid transparent;
        }
    }
}

.dropdown .dd-done {
    display: none;
}

.dropdown-content {
    background-color: #383e3e;
    li {
        color: #f7fdff;
        font-size: 11.375px;
    }
}

.Select {
    min-width: 250px;
}

.Select-control {
    height: 28px;
    background-color: transparent !important;
    color: var(--dropdown-input-text-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 0;
    border: 0.5px solid var(--dropdown-border-color);
    border-radius: 12px;
    padding-left: 10px;
    cursor: pointer;

    &:hover {
        border: 0.5px solid var(--dropdown-hover-border-color);

        .Select-arrow {
            background: var(--dropdown-hover-arrow-icon) no-repeat center;
            background-size: 20px, 20px;
        }
    }
}

.Select-value {
    display: flex;
    align-items: center;
}

.Select-value-label {
    @extend %text-no-overflow;
}

.Select--single {
    .Select-value,
    .Select-value-label {
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 30px !important;
        color: var(--dropdown-input-text-color) !important;
    }
}

.Select.is-focused .Select-control {
    border-radius: 12px;
    border: 1px solid var(--dropdown-active-border-color);
    background-color: transparent;
}

.Select-placeholder {
    padding-left: 10px;
    font-size: 12px;
    font-weight: 300;
    color: var(--dropdown-placeholder-color);
}

.Select--multi .Select-value {
    margin-bottom: 5px;
}

.Select--multi .Select-input {
    color: var(--dropdown-input-text-color);
    margin-left: 0;
}

.Select-arrow {
    text-align: center;
    vertical-align: middle;
    line-height: unset !important;
    width: 20px;
    height: 20px;
    background: var(--dropdown-primary-arrow-icon) no-repeat center;
    background-size: 20px, 20px;
    border-style: none;
    transition: transform 0.2s;
}

.Select.is-open .Select-arrow {
    background: var(--dropdown-selected-arrow-icon) no-repeat center;
    background-size: 20px, 20px;
    transform: rotate(180deg);
}

.Select.is-open > .Select-control .Select-arrow {
    top: 0;
    right: 0;
}

.Select.is-open .Select-arrow,
.Select .Select-arrow-zone:hover > .Select-arrow {
    border-top-color: $white;
}

.Select-arrow {
    &:hover {
        transform-style: preserve-3d;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            background-color: var(--generic-icon-hover-bg-color);
            border-radius: 50%;
            transform: translateZ(-1px);
        }
    }
}

.Select-input {
    height: 28px;
    line-height: 28px;

    input {
        padding: 0;
    }
}

.Select-control .Select-input input {
    color: var(--dropdown-input-text-color);
}

.Select-control .Select-input:focus {
    border: 1px solid #cfd2d3;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
    line-height: 31px;
}

.Select-menu-outer {
    border-radius: 12px;
    border-color: var(--dropdown-item-border-color);
    max-height: 216px;
    overflow: hidden;
    background-color: var(--dropdown-list-bg-color);
    box-shadow: var(--dropdown-list-box-shadow);
    border: 1px solid var(--dropdown-list-border-color);
    margin: 1px 0 0 0;
    padding-right: 2px;
    z-index: 5000;

    @include list-scroll();
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track-piece {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--dropdown-scroller-color);
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--dropdown-scroller-color);
    }
}

.Select-menu {
    max-height: 216px;
    overflow-y: auto;
}

.Select-option {
    $option-height: 36px;
    $option-margin-right: 2px;

    width: calc(100% - #{$option-margin-right});
    height: $option-height;
    @include control-font;
    @extend %text-no-overflow;
    position: relative;
    padding: 10px;
    background-color: inherit;
    color: var(--dropdown-item-text-color);
    margin-right: $option-margin-right;

    &:not(:last-child) {
        border-bottom: 0.5px solid var(--dropdown-item-border-color);
    }

    &:hover,
    &.is-focused {
        color: var(--dropdown-item-text-color);
        background-color: var(--dropdown-item-hover-bg-color) !important;
    }
    &.is-selected,
    &:active {
        &:before {
            display: none;
        }

        background-color: var(--dropdown-item-active-bg-color) !important;
    }
}

.Select.is-disabled > .Select-control {
    background-color: var(--dropdown-disabled-bg-color) !important;
    border: 1px solid var(--dropdown-disabled-bg-color);

    .Select-arrow {
        background: var(--dropdown-disabled-arrow-icon) no-repeat center;
    }

    &:hover {
        border-color: var(--dropdown-disabled-bg-color);

        .Select-arrow {
            background: var(--dropdown-disabled-arrow-icon) no-repeat center;
        }
    }
}

.Select-option.is-selected {
    background-color: var(--dropdown-item-active-bg-color);
    color: var(--dropdown-item-text-color);

    &:before {
        content: '';
        width: 2px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.Select-clear-zone {
    display: none;
}

.custom-dropdown-wrapper {
    .custom-dropdown {
        width: 100% !important;

        .custom-dropdown-toggle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 8px 10px;
            border: 1px solid var(--dropdown-border-color);
            background-color: transparent !important;
            color: #6499b7 !important;
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            border-radius: 0;
            transition: 0.2s all;

            &:hover {
                opacity: 0.75;
            }
        }

        .custom-dropdown-menu {
            padding: 0;
            background-color: var(--dropdown-list-bg-color);
            border: 1px solid var(--dropdown-border-color);

            &.dropdown-menu-right {
                min-width: 100%;
            }

            a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                border-bottom: 1px solid var(--dropdown-border-color);
                color: var(--dropdown-item-text-color);

                &:hover,
                &:focus {
                    background-color: var(--dropdown-item-hover-bg-color);
                }

                .information-tooltip-icon-container {
                    margin-left: 10px;
                }
            }
        }
    }
}

.dropdown-label {
    display: inline-block;
    margin: 0 0 5px 10px !important;

    font-size: 12px !important;
    line-height: 14px !important;
    font-weight: 500 !important;
    color: var(--dropdown-label-color) !important;

    &.disabled {
        opacity: unset !important;

        color: var(--dropdown-disabled-label-color) !important;
    }

    .input-label {
        display: flex;
        align-items: center;
    }
}
