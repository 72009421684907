/* Extend and Mixins */

%text-no-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

%page-header-title {
    color: var(--text-color);
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}

%global-modal-buttons {
    .buttons-container {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin: 0;

        &.center {
            justify-content: center;
        }

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 156px;
            height: 36px;
            float: none;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            border: 0;
            padding: 0 16px;
            margin: 0;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.29);

            &:not(:last-child) {
                margin-left: 15px;
            }

            &[disabled] {
                opacity: 0.38 !important;
                box-shadow: none;
            }

            &:before {
                content: none;
            }

            &:focus {
                outline: none;
                border: none;
            }
        }

        .form-save {
            background: $blue-500;
            color: $white;

            &:hover {
                background: $blue-400;
            }

            &:active {
                background: $blue-300;
            }
        }

        .form-delete {
            background: #ec223a;
            color: $white;

            &:hover {
                background: $red-400;
            }
        }

        .form-discard {
            background: transparent;
            color: var(--text-color);
            box-shadow: none;

            &:hover {
                background: $gray-200;
                color: $dark-gray-300;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
            }
        }
        .form-copy {
            background: transparent;
            color: var(--text-color);
            box-shadow: none;
            border: 1px #d4d6d8;
            border-style: solid;
            &:hover {
                background: $gray-200;
                color: $dark-gray-300;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
            }
        }
    }
}

%buttons-container {
    .buttons-container {
        flex-direction: row;
        justify-content: flex-end;
        margin: 20px 0 5px 0;
        button:last-child {
            margin-left: 12px;
        }
    }
}

%select-tenant-msg,
%empty-state-msg,
%error-msg {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: var(--text-color);
}

@mixin list-scroll() {
    $thumb-color: #828282;
    $thumb-hover-color: #4f4f4f;
    $track-color: rgba($dark-gray-300, 0.25);

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-track-piece {
        background: $track-color;
        border-radius: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border-radius: 6px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $thumb-hover-color;
    }

    // Firefox
    * {
        scrollbar-width: thin;
        scrollbar-color: $thumb-color $track-color;
    }
}

@mixin control-border {
    border: 0.5px solid var(--search-primary-border-color);
    border-radius: $control-border-radius;
    transition: border 0.2s;

    &:focus,
    &:focus-within,
    &:hover {
        box-shadow: none;
        outline: none;
    }

    &:hover {
        border: 0.5px solid var(--search-hover-border-color);
    }

    &:focus,
    &:focus-within {
        border: 1px solid var(--search-active-border-color);
    }
}

@mixin control-font {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

%sortIconDesc {
    background: url(../static-assets/images/sort-icon-arrow.svg) center center;
    transform: rotate(180deg);
}

%sortIconAsc {
    background: url(../static-assets/images/sort-icon-arrow.svg) center center;
}

%sortIconDisabled {
    background: url(../static-assets/images/sort-icon-default.svg) center center;
}

@mixin sort-btns() {
    .sort-btn,
    .no-sort-btn {
        @include h6-font;

        border: none;
        background: none;
        padding: 0;
        color: var(--text-color);
        line-height: 20px;
        @extend %text-no-overflow;
        max-width: 100%;
    }
    .sort-btn {
        &.disabled {
            opacity: 1;
            &:after {
                background: none;
            }
        }

        &:after {
            content: '';
            width: 15px;
            height: 15px;
            display: inline-block;
            background-repeat: no-repeat;
            margin-left: 5px;
        }

        &.desc:after {
            @extend %sortIconDesc;
        }
        &.asc:after {
            @extend %sortIconAsc;
        }
        &.non-active:after {
            @extend %sortIconDisabled;
        }
    }
}
