.new-version-modal {
    .modal-content {
        max-width: 750px;
    }

    &.small-modal .modal-content {
        max-width: 470px;
    }

    .new-version-modal-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 600;
        font-size: 18px;

        .new-version-icon {
            background: url(../static-assets/images/new-version-icon.svg) no-repeat;
            width: 204px;
            height: 110px;
            margin-bottom: 17px;
        }
    }

    .new-version-modal-massage {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .new-version-modal-content-info {
        max-height: 230px;
        overflow-y: auto;
        border-top: 1px solid $gray-800;
        padding-top: 20px;

        .content-info-title {
            font-weight: 600;
            font-size: 18px;
        }

        .content-info-list {
            .content-item {
                position: relative;
                margin-top: 15px;
                padding-left: 15px;

                .content-item-title {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 500;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: $black;
                    }
                }

                .content-item-description {
                    margin-top: 5px;
                }
            }
        }
    }
}
