.source-blocking-show-more-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #6b7280;
    cursor: pointer;
}

.react-grid-layout {
    padding: 0;
}

.line-loader {
    height: 2px;
    margin: 0 5px;
    background-color: var(--card-separator-color);
}

.loading .line-loader:before {
    display: block;
    content: '';
    left: -200px;
    width: 200px;
    height: 2px;
    background-color: var(--card-loader-color);
    animation: line-loader 2s linear infinite;
}

.element-show {
    width: 100%;
    height: 100%;
    padding: 5px 0 10px 0;
    visibility: visible;
}

.element-hide {
    visibility: hidden;
    width: 100%;
    padding: 20px;
    height: 100%;
}

.hide-element {
    display: none;
}

.chart-area-wrapper,
.bar-chart-wrapper,
.donut-chart-wrapper,
.pie-chart-wrapper,
.chart-columns-wrapper {
    margin: auto;
    width: 100%;
    height: 90%;
}

.chart-area-wrapper {
    position: relative;
}

.chart-area-wrapper .k-stockchart:after {
    content: '';
    display: block;
    height: 3px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 90px;
    background: #edeff5;
}

.donut-chart-wrapper .donut-text {
    display: none;
}

.attack-status-widget-warpper div:first-child {
    width: 80px;
    display: inline-block;
}

.attack-status-widget-warpper {
    text-align: center;
}

.attack-status-widget-warpper > div:last-child {
    display: inline-block;
    font-size: 2.5em;
    margin-left: 25px;
}

.donut-chart-wrapper .donut-text {
    display: none;
}

.no-available-data {
    position: absolute;
    white-space: nowrap;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    @extend %empty-state-msg;
    &.show-image:before {
        content: '';
        display: block;
        background: url('../static-assets/images/no-available-data.svg') no-repeat center;
        background-size: contain;
        width: 250px;
        height: 231px;
        margin-bottom: 20px;
    }
}

.max .widget-wrapper .widget-maximize {
    background: url('../static-assets/images/widget-minimize.svg') no-repeat center transparent;
}

.max .widget-wrapper .widget-maximize:hover {
    background: url('../static-assets/images/widget-minimize_over.svg') no-repeat center transparent;
}

.widget-wrapper .widget-maximize {
    background: url('../static-assets/images/widget-maximize.svg') no-repeat center transparent;
}

.widget-wrapper .widget-minimize,
.widget-wrapper .widget-maximize {
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    cursor: pointer;
    // opacity: .7;
}

.widget-wrapper.new-widget {
    animation: blinkShadow 2s linear infinite;
}

.widget-wrapper .widget-maximize:hover {
    background-image: url('../static-assets/images/widget-maximize_over.svg');
}

.service-overview_summary_header_title {
    background-position: 15px 13px;
    background-repeat: no-repeat;
    padding: 10px 7px 7px 23px;
}

.waf-widget,
.ddos-widget {
    padding-bottom: 15px;
}

.waf-widget .widget-title:before,
.ddos-widget .widget-title:before {
    content: ' ';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 10px;
    left: 15px;
    background-repeat: no-repeat;
}

.waf-widget .widget-title:before {
    background-image: url(../static-assets/images/WAF_title_icon.svg);
}

.ddos-widget .widget-title:before {
    background-image: url(../static-assets/images/ddos-icon.svg);
}

.widget-title:before {
    content: ' ';
    position: absolute;
    left: 0px;
    right: 0px;
    height: 1px;
    width: 100%;
    top: 43px;
    background-color: #efefef;
    display: none;
}

.ddos-lst .widget-title {
    background-image: url('../static-assets/images/ddos-icon.svg');
}

.waf-lst .widget-title {
    background-image: url('../static-assets/images/WAF_title_icon.svg');
}

.react-grid-item .react-resizable-handle {
    position: absolute;
    right: 0;
    bottom: 0;
    border: 7px solid transparent;
    border-right-color: #ccc;
    border-bottom-color: #ccc;
    cursor: nw-resize;
    visibility: hidden;
}

.react-grid-item:hover .react-resizable-handle {
    visibility: visible;
}

.react-grid-item {
    transition-property: transform;
    transition: all 200ms ease;
    z-index: 10;
}

.react-grid-placeholder {
    background: #e6e7ed;
    opacity: 0.8;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-grid-item.max {
    width: auto !important;
    height: auto !important;
    left: 5px !important;
    top: 62px !important;
    right: 6px;
    position: fixed !important;
    z-index: 4000 !important;
    bottom: 35px;
    transform: translate(0, 0) !important;

    // hide 'remove widget' button while maximized
    .widget-close {
        display: none;
    }
    .widget-title .widget-title-options {
        margin-right: 0;
    }
}

.widget-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--card-bg-color);
    border-radius: var(--card-border-radius);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;

    &.settingsChangedFromDefault {
        .widget-title .widget-title-options .header-date-filter-settings-wrapper .settings-toggle {
            width: 24px;
            height: 24px;
            background: url(../static-assets/images/widget-settings_applied.svg) no-repeat center transparent;

            &:hover {
                background: url(../static-assets/images/widget-settings_applied.svg) no-repeat center transparent;
            }
        }
    }

    .widget-title {
        display: flex;
        align-items: center;
        padding: 8px 15px 8px 10px;
        flex-wrap: wrap;
        justify-content: space-between;

        .widget-title-text-wrapper {
            display: flex;
            align-items: center;
            margin-right: 5px;

            .widget-title-text {
                @extend %text-no-overflow;
                cursor: move;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
            }

            .widget-title-image {
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                margin-right: 5px;
            }
        }

        .widget-title-options {
            display: flex;
            align-items: center;
            margin-right: 15px;

            .widget-custom-options {
                margin-right: 10px;
                .toggle {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                }
                .toggle-pie {
                    background: url(../static-assets/images/pie-chart.svg) no-repeat center;
                }
                .toggle-chart {
                    background: url(../static-assets/images/area-chart.svg) no-repeat center;
                }
            }

            .header-date-filter-settings-wrapper {
                margin-right: 5px;

                .settings-toggle {
                    width: 19px;
                    height: 18px;
                    padding: 0;
                    outline: none;
                    cursor: pointer;
                    background: url(../static-assets/images/widget-settings.svg) no-repeat center transparent;

                    &:hover {
                        background: url(../static-assets/images/widget-settings_over.svg) no-repeat center transparent;
                    }
                }
            }

            .header-date-filter-settings {
                right: -45px;

                .select-with-validation {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .settings-summary {
        display: flex;
        align-items: flex-end;
        padding-top: 4px;
        margin-left: auto;
        margin-right: 5px;
        font-size: 11px;
        @extend %text-no-overflow;
        width: 100%;

        > div {
            overflow: hidden;
            text-overflow: ellipsis;

            > div {
                display: inline;
                color: var(--text-color);
                font-weight: bold;

                span {
                    color: var(--text-color);
                    font-weight: normal;
                }
            }
        }
    }

    .widget-close {
        position: absolute;
        right: 5px;
        top: 3px;
        height: 22px;
        content: ' ';
        background-image: url(../static-assets/images/widget-close.svg);
        width: 22px;
        cursor: pointer;

        &:hover {
            background-image: url(../static-assets/images/widget-close_over.svg);
        }
    }

    // .widget-close,
    // .widget-title .widget-title-options {
    //     opacity: 0;
    //     transition: 0.2s all;
    // }

    // &:hover {
    //     .widget-close,
    //     .widget-title .widget-title-options {
    //         opacity: 1;
    //     }
    // }

    .chart-content-wrapper {
        flex: 1;
        min-height: 0;
        min-width: 0;
        padding: 10px;
        .no-available-data:before {
            width: 130px;
            height: 111px;
            margin: 0 auto 15px auto;
        }
    }

    &.waf {
        .widget-title {
            .widget-title-text-wrapper {
                .widget-title-image {
                    background-image: url('../static-assets/images/widgets-waf-icon.svg');
                }
            }
        }
    }

    &.azure-ddos,
    &.ddos {
        .widget-title {
            .widget-title-text-wrapper {
                .widget-title-image {
                    background-image: url('../static-assets/images/widgets-ddos-icon.svg');
                }
            }
        }
    }

    &.bot {
        .widget-title {
            .widget-title-text-wrapper {
                .widget-title-image {
                    background-image: url('../static-assets/images/widgets-bot-icon.svg');
                }
            }
        }
    }

    &.bot-top-attacks {
        .chart-content-wrapper {
            padding: 5px 10px;
            .sub-tabs,
            .tabs {
                height: 16px;
                .tab {
                    font-size: 14px;
                    padding: 3px 10px 5px;
                }
            }
        }
    }
}

.react-grid-item.max {
    .widget-wrapper .widget-title .widget-title-options,
    .widget-wrapper .widget-close {
        opacity: 1;
    }
}

.widget-settings {
    display: inline-block;
    position: relative;
    top: 2px;
}

.widget-settings-wrapper:before {
    content: '';
    display: block;
    border: 8px solid transparent;
    position: absolute;
    top: -17px;
    right: 10px;
    border-bottom-color: #bbb9b9;
}

.widget-settings-wrapper:after {
    content: '';
    display: block;
    border: 8px solid transparent;
    position: absolute;
    top: -16px;
    right: 10px;
    border-bottom-color: $white;
}

.widget-settings-trigger {
    width: 19px;
    height: 18px;
    background-repeat: no-repeat;
    outline: none;
    cursor: pointer;
    background-image: url(../static-assets/images/widget-settings.svg);
    background-color: transparent !important;
    // opacity: 0.6;
}

.widget-settings-trigger:hover {
    background-image: url(../static-assets/images/widget-settings_over.svg);
}

// .widget-settings-trigger.filterd {
//     opacity: 1;
// }

.widget-settings-wrapper {
    z-index: 101;
}

.widget-settings-wrapper .nav.nav-pills {
    padding: 2px 0;
    white-space: nowrap;
}

.widget-settings-wrapper .widget-settings-apply {
    /*text-transform:uppercase;*/
    cursor: pointer;
    float: right;
    margin-bottom: 0 !important;
    color: #296c96;
    padding: 10px;
}

.widget-settings-wrapper .nav.nav-pills > li > a {
    background-color: transparent;
    border: 1px solid #808284;
    border-radius: 3px;
    font-size: 0.9em;
    color: $black;
    padding: 4px;
}

.widget-settings-wrapper li.time-frame-option {
    margin-left: 3px;
    float: none;
    display: inline-block;
}

.widget-settings-wrapper .nav.nav-pills > li.active > a,
.navbar-toolbar .nav.nav-pills > li.active > a:focus,
.navbar-toolbar .nav.nav-pills > li.active > a:hover {
    background-color: #6d6e70;
    color: $white;
    opacity: 1;
}

.widget-settings-wrapper .nav.nav-pills > li > a:hover {
    cursor: pointer;
    opacity: 1;
}

.widget-settings-wrapper {
    background-color: $white;
    width: auto;
    min-width: 250px;
    border: 1px solid #bbb9b9;
    font-size: 12px;
    left: 27px;
    top: 24px;
    box-shadow: 2px 2px 1px 0 rgb(221, 223, 232);
}

.widget-settings-wrapper .widget-settings-content {
    padding: 8px 10px 0;
}

.widget-settings-content .time-range-filter {
    font-size: 0.9em;
    color: #949699;
    margin-top: 0px;
    margin-left: 2px;
}

.widget-settings-wrapper li.st-accordion__item {
    margin-bottom: 5px;
}

.widget-settings-wrapper label {
    /*text-transform:uppercase; */
    font-weight: normal;
    color: #333;
    font-size: 0.9em;
    margin-bottom: 2px;
}

.widget-settings-wrapper .dropdown > div:first-child {
    background-color: #e7e7e8;
}

.widget-settings-wrapper .dropdown > div:first-child a {
    font-size: 12px;
    color: $black;
    text-decoration: none;
    display: block;
    @extend %text-no-overflow;
    border: none;
}

.widget-settings-item {
    margin-bottom: 10px;
}

.widget-settings-item .widget-settings-clear {
    background-image: url('../static-assets/images/clear-widget-settings.svg');
    background-repeat: no-repeat;
    padding-left: 23px;
    cursor: pointer;
    opacity: 0.8;
}

.widget-settings-item .widget-settings-clear:hover {
    opacity: 1;
}

.widget-settings-item .dropdown > div:first-child a {
    background-color: #edeff5;
}

.widget-settings-item.inner-options {
    padding-top: 10px;
    float: left;
    padding-left: 12px;
}

.widget-settings-bottom {
    background-color: #edeff5;
    border-top: solid 1px #c1c2c4;
}

.widget-settings-bottom:after {
    content: ' ';
    display: block;
    clear: both;
}

.widget-repository-trigger {
    cursor: pointer;
    width: 22px;
    height: 22px;
    background-image: url(../static-assets/images/widgets_repository_icon.svg);
    /* margin-top:5px; */
    opacity: 0.7;
}

.widget-repository-trigger:hover {
    opacity: 1;
}

.widget-settings-bottom .widget-settings-apply:hover {
    background-color: #296c96;
    color: $white;
    cursor: pointer;
}

.activeWidgetRepository {
    .dropdown .st-tooltip-item {
        border: none;
    }
}

.widgets-repository-wrapper {
    position: relative;

    .activeWidgetRepository {
        z-index: 999;
        left: 22px;
        top: 27px;
        border: 1px solid #cfd2d3;

        .widget-repository-trigger {
            width: 24px;
            height: 27px;
            background: url(../static-assets/images/add-new-widget.svg);
        }

        .dropdown .st-tooltip-item {
            position: static;

            ul.st-list--table {
                max-height: calc(100vh - 310px);
                min-width: 350px;
            }

            .st-list--data {
                font-size: 13px;

                &.bot,
                &.azure-ddos,
                &.ddos,
                &.waf {
                    display: flex;
                    align-items: center;
                    padding: 12px 30px 12px 10px;
                    padding-right: 20px !important;
                    &:before {
                        position: initial;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

.st-list--data.waf:before {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url('../static-assets/images/widgets-waf-icon.svg');
    opacity: 0.6;
    width: 16px;
    height: 16px;
}

.st-list--data {
    &.azure-ddos:before,
    &.ddos:before {
        content: '';
        display: inline-block;
        background-repeat: no-repeat;
        background-image: url('../static-assets/images/widgets-ddos-icon.svg');
        opacity: 0.6;
        width: 16px;
        height: 16px;
    }
}

.st-list--data.bot:before {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url('../static-assets/images/widgets-bot-icon.svg');
    opacity: 0.6;
    width: 16px;
    height: 16px;
}

.st-list--data.waf:hover:before,
.st-list--data.ddos:hover:before,
.st-list--data.azure-ddos:hover:before,
.st-list--data.bot:hover:before {
    opacity: 1;
}

.k-tooltip.k-chart-tooltip {
    background-color: #fffcde !important;
    position: relative;
    border-color: #808080 !important;
    z-index: 14001 !important;
    color: $black;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    text-align: left;
}

.k-tooltip.k-chart-tooltip.k-chart-tooltip-inverse {
    color: $black;
}

.k-tooltip .source-chart-tooltip-custom {
    padding: 10px;
}

.k-tooltip .source-chart-tooltip-custom .tooltip-header {
    margin-bottom: 10px !important;
    font-weight: bold;
    font-size: 11pt;
    color: $black;
    text-align: left;
}

.k-tooltip .source-chart-tooltip-custom .tooltip-header .value {
    float: right;
    font-size: 11pt;
    color: $black;
    display: inline-block;
    margin-left: 10px;
}

.k-tooltip .source-chart-tooltip-custom .tooltip-header:after.tooltip-series-data:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    overflow: hidden;
}

.k-tooltip .source-chart-tooltip-custom .tooltip-series-data {
    width: 100%;
}

.k-tooltip .source-chart-tooltip-custom .tooltip-series-data span.series-color-name {
    display: inline-block;
    margin-right: 20px;
    font-size: 10pt;
    color: #58585b;
}

.k-tooltip .source-chart-tooltip-custom .tooltip-series-data .series-color-name .series-name {
    display: inline-block;
    margin-right: 20px;
    font-size: 10pt;
    color: #58585b;
}

.k-tooltip .source-chart-tooltip-custom .tooltip-series-data .series-color-name span.series-color {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.k-tooltip .source-chart-tooltip-custom .tooltip-series-data span.series-color-name {
    display: inline-block;
    margin-right: 20px;
    font-size: 10pt;
    color: #58585b;
}

.k-tooltip .source-chart-tooltip-custom .tooltip-series-data td.series-color-name span.series-color {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.k-tooltip .source-chart-tooltip-custom .tooltip-series-data .value {
    float: right;
    display: inline-block;
    font-size: 10pt;
    color: #58585b;
}

.k-tooltip .source-chart-tooltip-custom .tooltip-series-data .value .pct {
    display: inline-block;
    font-size: 10pt;
    color: #58585b;
}

.k-tooltip .source-chart-tooltip-custom .tooltip-series-data .value .count {
    display: inline-block;
    font-size: 10pt;
    color: #58585b;
}

.violation-chart-tooltip-custom-tooltip-header {
    font-size: 10pt;
    color: #58585b;
}

.k-tooltip .violation-chart-tooltip-custom {
    padding: 2px;
}

.k-tooltip .violation-chart-tooltip-custom .tooltip-header {
    margin-bottom: 5px;
    font-size: 11pt;
    color: $black;
    text-align: left;
}

.k-tooltip .violation-chart-tooltip-custom .tooltip-header .series-color-name .series-name {
    font-weight: bold;
    font-size: 11pt;
    color: $black;
}

.k-tooltip .violation-chart-tooltip-custom .tooltip-header .value {
    float: right !important;
    font-size: 11pt;
    color: $black;
}

.k-tooltip .violation-chart-tooltip-custom .tooltip-header .value .pct {
    font-weight: bold;
    font-size: 11pt;
    color: $black;
}

.k-tooltip .violation-chart-tooltip-custom .tooltip-header .value .count {
    font-weight: bold;
    font-size: 11pt;
    color: $black;
}

.k-tooltip .violation-chart-tooltip-custom .tooltip-header:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    overflow: hidden;
}

.k-tooltip .violation-chart-tooltip-custom .tooltip-series-data:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    overflow: hidden;
}

.k-tooltip .violation-chart-tooltip-custom .tooltip-series-data {
    width: 100%;
}

.k-tooltip .violation-chart-tooltip-custom .tooltip-series-data .series-color-name span.series-name {
    display: inline-block;
    margin-right: 20px;
    font-size: 10pt;
    color: #58585b;
}

.k-tooltip .violation-chart-tooltip-custom .tooltip-series-data .series-color-name span.series-color {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.k-tooltip .violation-chart-tooltip-custom .tooltip-series-data .value {
    float: right !important;
    display: inline-block;
    font-size: 10pt;
    color: #58585b;
    text-align: right;
}

.k-tooltip .violation-chart-tooltip-custom .tooltip-series-data .value .pct {
    display: inline-block;
    font-size: 10pt;
    color: #58585b;
}

.k-tooltip .violation-chart-tooltip-custom .tooltip-series-data .value .count {
    display: inline-block;
    font-size: 10pt;
    color: #58585b;
}

.ServiceInstanceSummary .chart-area-wrapper {
    height: 176px;
}

.metrics h1.no-available-data {
    position: relative;
    top: inherit;
    left: inherit;
    transform: inherit;
    text-align: center;
    padding: 10px;
}

.tooltip-header {
    max-width: 500px;
    white-space: normal;
}

.widget-hover {
    z-index: 2001 !important;
}

.__react_component_tooltip.type-info {
    padding: 0;
    opacity: 1 !important;
}

.__react_component_tooltip:after {
    display: none;
}

.activities-viewer-wrapper .indicator-counter {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -7px;
    right: -7px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #212121;
    color: white;
    font-size: 0.85em;
    font-weight: 600;
    line-height: 1;
    border-radius: 50%;
    cursor: pointer;
}

.alerts-viewer-wrapper.top-to-bottom-grid-wrapper .list-wrapper {
    margin-top: 10px;
    height: calc(100% - 65px);
    max-height: 100%;
    border: none;
    overflow-y: scroll;
    position: relative;
}

.alerts-viewer-wrapper.top-to-bottom-grid-wrapper .list-wrapper .list-content {
    height: calc(100% - 33px);
    overflow: auto;
}

.alerts-viewer-wrapper.top-to-bottom-grid-wrapper .list-row .list-cell:first-child:before {
    display: none;
}

.alerts-viewer-wrapper.top-to-bottom-grid-wrapper .list-row .list-cell:first-child {
    padding-left: 10px;
    position: relative;
}

.alerts-viewer-wrapper.top-to-bottom-grid-wrapper .list-header .list-row .list-cell:after {
    display: none;
}

.top-to-bottom-grid-wrapper.alerts-viewer-wrapper .st-accordion__item.expand {
    background-color: transparent !important;
}

.top-to-bottom-grid-wrapper.alerts-viewer-wrapper .st-accordion__item:hover {
    background-color: transparent !important;
}

.top-to-bottom-grid-wrapper.alerts-viewer-wrapper .st-accordion__item .st-accordion__link {
    color: inherit;
}

.top-to-bottom-grid-wrapper.alerts-viewer-wrapper .st-accordion__item:before {
    content: '';
    display: inline-block;
    border: 3px solid transparent;
    border-left: 5px solid $black;
    transition: 0.1s;
    margin-right: 2px;
}

.top-to-bottom-grid-wrapper.alerts-viewer-wrapper .st-accordion__item.expand:before {
    transform: rotate(90deg);
}

.alerts-slider h1.title {
    position: relative;
    padding-bottom: 5px;
}

.alerts-viewer-wrapper .alerts-slider > h1.title .loader {
    display: inline-block;
    margin-left: 15px;
    width: 15px;
    height: 15px;
    margin-top: 0px;
}

/*DASHBOARD GENERAL VIEW*/
.dashboard-general-view {
    background: $white;
    padding: 10px 22px;
    transition: all 400ms ease;
    border-bottom: 1px solid #dddfe8;
    overflow: hidden;
}

.dashboard-general-view .dashboard-general-view-chart-details {
    padding-top: 20px;
    padding-left: 5px;
    color: #808285;
    font-size: 0.9em;
    line-height: 18px;
}

.dashboard-general-view.minimized {
    height: 30px;
    padding: 5px 22px;
}

.dashboard-general-view.maximized {
    height: 400px;
}

.dashboard-general-view .widget-maximize {
    background-image: url(../static-assets/images/show_icon.svg);
    width: 9px;
    height: 9px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.dashboard-general-view .widget-minimized {
    background-image: url(../static-assets/images/hide_icon.svg);
    width: 9px;
    height: 9px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.dashboard-general-view .minimize-maximize {
    line-height: 1.2;
    cursor: pointer;
    margin-right: 30px;
    opacity: 0.9;
}

.dashboard-general-view .minimize-maximize:hover {
    opacity: 1;
}

.dashboard-general-view .minimize-maximize span {
    font-size: 0.8em;
}

.dashboard-general-view .widget-maximize,
.dashboard-general-view .widget-minimized {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: 2px;
}

.dashboard-general-view .transaction-segmentation-donut-chart .pie-chart-wrapper {
    position: relative;
}

.dashboard-general-view .owasp-mapping-donut-chart .pie-chart-wrapper .pie-chart {
    background: center no-repeat url(../static-assets/images/owasp_icon.svg);
    background-size: 60px 60px;
}

.dashboard-general-view .http-traffic-area-chart {
    height: 350px;
}

.dashboard-general-view .http-traffic-area-chart .chart-area-wrapper,
.http-traffic-area-chart .pie-chart-wrapper {
    height: 100% !important;
}

.transaction-segmentation-donut-chart > .row {
    margin-bottom: 30px;
}

.dashboard-general-view .transaction-segmentation-donut-chart .pie-chart-wrapper {
    width: 90%;
    height: 90%;
    max-width: 150px;
    max-height: 150px;
    min-width: 100px;
    min-height: 100px;
}

.dashboard-general-view .owasp-mapping-donut-chart .pie-chart-wrapper {
    width: 90%;
    height: 90%;
    max-width: 150px;
    max-height: 150px;
    min-width: 100px;
    min-height: 100px;
}

.dashboard-general-view .bandwidth-chart-radial-gauge {
    width: 90%;
    height: 90%;
    max-width: 145px;
    max-height: 145px;
    min-width: 100px;
    min-height: 100px;
}

.dashboard-general-view .adaptive-learning {
    margin-top: 10px;
}

.dashboard-general-view .adaptive-learning-bar-chart {
    display: inline-block;
    width: 100px;
    height: 100px;
}

.dashboard-general-view .adaptive-learning-bar-chart .chart-generic:after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background: #bbbbc1;
    bottom: 5px;
    right: 0px;
    width: 100%;
}

.dashboard-general-view .adaptive-learning-total {
    font-size: 2em;
    color: #808285;
}

.adaptive-learning-icon {
    height: 125px;
    width: 125px;
    margin: auto;
    background: #f36523;
    border-radius: 100%;
}

.adaptive-learning-bar-chart-wrapper {
    text-align: center;
}

.dashboard-general-view-minimized {
    text-align: center;
}

.dashboard-general-view-minimized > div {
    display: inline-block;
}

.dashboard-general-view-minimized > div > span {
    margin-right: 30px;
    font-size: 0.9em;
}

.dashboard-general-view .security-events-title {
    padding: 10px 10px 10px 28px;
    text-align: left;
    color: #808285;
    font-size: 1.3em;
}

.dashboard-general-view .http-traffic-area-chart-title {
    padding-top: 10px;
    text-align: left;
    color: #808285;
    font-size: 1.3em;
}

.bandwidth-chart-wrapper {
    position: relative;
    text-align: center;
}

.bandwidth-chart-badndwidth-title {
    top: 100px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    color: #808285;
    font-size: 1.1em;
}

.dashboard-general-view .widget-wrapper {
    border: none;
}

.dashboard-general-view .traffic-utilization-wrapper .widget-title {
    border: none;
    padding: 10px 7px 7px 3px;
}

.dashboard-general-view .traffic-utilization-wrapper .widget-title-text {
    width: 100%;
    text-align: left;
    border: none;
    color: #808285;
    cursor: initial;
}

.dashboard-general-view .traffic-utilization-wrapper {
    height: 125px;
}

.transaction-segmentation-donut-chart-blocked,
.transaction-segmentation-donut-chart-reported {
    position: relative;
    text-align: center;
}

.transaction-segmentation-donut-chart-reported-details,
.transaction-segmentation-donut-chart-blocked-details {
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.transaction-segmentation-donut-chart-blocked-details-percent,
.transaction-segmentation-donut-chart-reported-details-percent {
    font-size: 1.2em;
    color: #808285;
    line-height: 0.7;
}

.transaction-segmentation-donut-chart-blocked-details-total,
.transaction-segmentation-donut-chart-reported-details-total {
    font-size: 1.5em;
    color: #808285;
}

.transaction-segmentation-donut-chart-blocked-details-blocked-title,
.transaction-segmentation-donut-chart-blocked-details-reported-title {
    font-size: 1.1em;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 25px);
    color: #808285;
}

.owasp-mapping-donut-chart-title {
    font-size: 1.1em;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 25px);
    color: #808285;
    overflow: hidden;
    white-space: nowrap;
}

/********************
    welcome screen
 ********************/
.welcome-screen-item {
    border: 2px solid #cacccd;
    background: $white;
    padding: 35px 15px;
    width: 90%;
    min-width: 300px;
    margin: auto;
}

.welcome-screen-item h2 {
    margin-top: 0;
}

.welcome-screen-item a {
    cursor: pointer;
    border-bottom: 1px solid #337ab7;
    padding-bottom: 2px;
}

.welcome-screen-item .item-icon {
    height: 130px;
    width: 130px;
    background-size: cover;
    margin: 35px auto 0px;
    opacity: 0.7;
    background-repeat: no-repeat;
}

.welcome-screen {
    width: 1200px;
    max-width: 80%;
    color: #58595b;
    position: absolute;
    left: 50%;
    top: 80px;
    transform: translate(-50%, 0);
}

.welcome-screen h1 {
    font-size: 4.5em;
    margin-bottom: 70px;
}

.welcome-screen h1 small {
    display: block;
    font-size: 50%;
}

.certificates-icon {
    background-image: url(../static-assets/images/ssl_ic.svg);
}

.applications-icon {
    background-image: url(../static-assets/images/applications_icon.svg);
}

.users-icon {
    background-image: url(../static-assets/images/user_title_icon.svg);
}

.accounts-icon {
    background: url(../static-assets/images/icon-accounts.svg);
    background-size: 120px !important;
}

.dashboard-content {
    .select-tenant {
        height: 100%;
    }
    > .container {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .welcome-screen-items {
        display: flex;
        justify-content: space-between;
        margin: auto;
        flex-wrap: wrap;
        .welcome-text {
            margin-right: 50px;
            flex: 0.6;
            .little-text {
                color: #be7c85;
                font-size: 40px;
                font-weight: 300;
            }
            .main-text {
                color: #9e2e41;
                font-size: 46.8px;
                font-weight: 300;
                border-bottom: 1px solid #f7fdff;
                @media (max-width: 1290px) {
                    font-size: 39px;
                }
            }
            .callToAction-text {
                color: #383e3e;
                font-size: 30px;
                font-weight: 300;
                margin-top: 50px;
            }
        }
        .welcome-screen-help {
            background: url(../static-assets/images/olh_icon.svg) 0px 0px/22px no-repeat;
            padding: 2px 0 2px 30px;
            width: 100%;
        }
        .action-items-container {
            margin-left: 50px;
            flex: 0.4;
            .action-item {
                color: #575d61;
                font-size: 30px;
                font-weight: 300;
                position: relative;
                padding: 25px 0 25px 160px;
                min-width: 480px;
                min-height: 132px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                border: 1px solid transparent;
                cursor: pointer;
                &:hover {
                    border: 1px solid #f7fdff;
                    color: #446c86;
                    background-color: #d5dee2;
                    &:after {
                        content: url('../static-assets/images/add-item-welcome_hover.svg');
                    }
                }
                &:before,
                &:after {
                    position: absolute;
                    left: 45px;
                }
                &:after {
                    content: url('../static-assets/images/add-item-welcome.svg');
                    left: 105px;
                    top: 58px;
                }
                &.certificates {
                    &:before {
                        content: url('../static-assets/images/certificate_icon-welcome.svg');
                    }
                }
                &.applications,
                &.accounts {
                    &:before {
                        content: url('../static-assets/images/cloud-welcome.svg');
                    }
                }
                &.users {
                    &:before {
                        content: url('../static-assets/images/users-welcome.svg');
                    }
                }
            }
        }
    }
}

/*********************
    list
 *********************/

.st-list--row.st-list--selected,
.st-list--row.st-list--selected:hover {
    position: relative;
    background-color: var(--dropdown-item-active-bg-color);
}

.multiSelect .st-list--row.st-list--selected:before,
.multiSelect .st-list--row.st-list--selected:after {
    content: '';
    display: block;
    width: 3px;
    background-color: #6499b7;
    position: absolute;
}

.st-list--row.st-list--selected:after {
    height: 11px;
    right: 10px;
    top: 15px;
    transform: rotate(45deg);
}

.st-list--row.st-list--selected:before {
    height: 7px;
    right: 15px;
    top: 19px;
    transform: rotate(-45deg);
}

.widgets-repository-wrapper .st-list--row.st-list--selected:before {
    top: 16px;
}

.widgets-repository-wrapper .st-list--row.st-list--selected:after {
    top: 12px;
}

.columns-manager .st-list--row.st-list--selected:before,
.columns-manager .st-list--row.st-list--selected:after {
    content: '';
    display: none;
}

.addWidgets:hover:before,
.removeAllWidgets:hover:before,
.removeAllWidgetsConfirm:hover:before,
.removeAllWidgetsCancel:hover:before {
    filter: brightness(100);
}

.widgets-repository-wrapper .dropdown.active .st-tooltip-item ul.st-list--table {
    box-shadow: none;
}

.widgets-repository-wrapper .dropdown.active .st-tooltip-item ul.st-list--table li:last-child span {
    border-bottom: none;
}

.country-events-wrapper .widget-settings-item .st-tooltip-item__content .st-list--table {
    height: 95px;
}

.activity-log-item-header-escalate button:active {
    background: #f2f2f2;
}

.country-events-wrapper {
    overflow: hidden;
}

.geo-map-wrapper {
    width: 100%;
    height: 100%;
}

.datamaps-hover {
    min-width: 150px;
    padding: $tooltip-padding;
    background-color: var(--tooltip-bg-color);
    position: absolute;
    font-size: 11.7px;
    border-radius: $tooltip-border-radius;
    z-index: 14001;
    color: var(--text-secondary-color);
    transform: translate(-50%, calc(-100% - 40px));
    filter: $tooltip-filter;
}

.chart-content-wrapper {
    position: relative;
}

.full .chart-content-wrapper {
    height: 62px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.full .details.chart-content-wrapper {
    height: 100px;
}

.datamaps-hoverover {
    font-family: var(--font-family) !important;
}

.dashboard-content {
    .header-date-filter-control {
        margin: $page-padding $page-padding 0 $page-padding;
        .header-date-filter-settings-wrapper {
            margin-right: 25px;
        }
    }
}

.chart-content-wrapper .geo-map-wrapper.clickable .datamap path:not(.datamaps-arc) {
    cursor: pointer !important;
}

.widget-wrapper.waf-summary {
    &.settingsChangedFromDefault .waf-summary-widget .summary-block .summary-change {
        margin-top: 0;
    }

    &.waf {
        .summary-block {
            &:hover {
                cursor: pointer;
                border-radius: $item-border-radius;
                box-shadow: 0px 2px 4px rgba(90, 91, 106, 0.24), 0px 1px 2px rgba(58, 58, 68, 0.24);
                .summary-value {
                    font-weight: 500;
                }
                .summary-label {
                    font-weight: 600;
                }
            }
        }
    }

    &.bot .waf-summary-widget .summary-block {
        min-width: 120px;
    }

    .chart-content-wrapper {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .waf-summary-widget {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        align-content: space-around;
        justify-content: space-around;

        .summary-block {
            flex: 1;
            min-width: 158px;
            padding-left: 15px;
            padding-bottom: 4px;
            margin: 0 4px;
            .summary-value {
                font-size: 33.15px;
                color: #778899;
                margin-bottom: 2px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .summary-label {
                font-size: 14.3px;
                font-weight: 500;
                white-space: pre-line;
            }
            .summary-change {
                font-size: 13px;
                margin-top: 5px;
                .arrow {
                    font-family: monospace;
                    font-size: 14px;
                }
                .up {
                    color: #ea0000;
                }
                .down {
                    color: #50af4c;
                }
                .none {
                    color: #8399dd;
                }
            }
            .summary-value,
            .summary-label {
                line-height: 100%;
            }
        }
    }
}

.modal .new-widgets-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .new-widget-icon {
        background: url(../static-assets/images/new-widget-icon.svg) no-repeat;
        width: 204px;
        height: 108px;
        margin-top: 12px;
    }

    .new-widgets-modal-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: $dark-gray-300;
        margin-top: 18px;
        margin-bottom: 5px;
    }

    .new-widgets-modal-message {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: rgba(75, 75, 75, 0.7);
    }
}

.pci-dss-widget {
    .chart-content-wrapper {
        display: flex;
    }
    .pci-dss-widget-chart-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 0;
        min-width: 0;
        .chart-wrapper {
            flex: 1;
            min-height: 0;
            min-width: 0;
        }
    }

    .chart-title {
        font-size: 14px;
        line-height: 20px;
    }
    .footer-note {
        font-size: 12px;
        line-height: 20px;
    }
}
