.applicationEvents .waterMark:before,
.ApplicationEvents .waterMark:before {
    background-image: url(../static-assets/images/security-events-icon.svg);
}

.applicationEvents .list-content .waterMark:before,
.ApplicationEvents .list-content .waterMark:before {
    background-image: none;
}

.ddosEvents .list-content .waterMark:before,
.DdosEvents .list-content .waterMark:before {
    background-image: none;
}

.ddosEvents .waterMark:before,
.DdosEvents .waterMark:before {
    background-image: url(../static-assets/images/security-events-icon.svg);
}

.sslCertificateManagement .waterMark:before,
.SslCertificateManagement .waterMark:before {
    background-image: url(../static-assets/images/ssl_ic-icon.svg);
}

.reports .waterMark:before,
.NewReports .waterMark:before {
    background-image: url(../static-assets/images/reports-title-icon-watermark.svg);
}

.alerts .waterMark:before,
.Alerts .waterMark:before {
    background-image: url(../static-assets/images/alerts-indicator-watermark.svg);
}

.appSettings .waterMark:before,
.AppSettings .waterMark:before {
    background-image: url(../static-assets/images/applications_icon-watermark.svg);
}

.users .waterMark:before {
    background-image: url(../static-assets/images/applications_icon-watermark.svg);
}

.customers .waterMark:before {
    background-image: url(../static-assets/images/customer_watermark.svg);
}

.newReports .waterMark:before,
.NewReports .waterMark:before {
    background-image: url(../static-assets/images/reports-title-icon-watermark.svg);
}
