@import '~@blueprintjs/datetime/lib/css/blueprint-datetime-modern';

.date-range-new,
.bp3-overlay {
    @import '~@blueprintjs/core/lib/css/blueprint';
    .bp3-transition-container {
        z-index: 1100;
    }
}

.date-range-new {
    &.bp3-popover-wrapper {
        display: block;
        .bp3-popover-target {
            width: 100%;

            .bp3-control-group {
                margin-top: 5px;
                display: flex;
                width: 100%;
                border-bottom: 1px solid #888;
                border-radius: 2px;
                align-items: center;
                justify-content: space-around;
                padding: 2px;

                &:after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 18px;
                    height: 11px;
                    background: url('../static-assets/images/date-time-arrow-icon.png') no-repeat center;
                }

                &:focus-within {
                    border-bottom: 1px solid #6da7c8;
                }

                .bp3-input-group {
                    text-align: center;

                    input {
                        all: unset;
                        display: block;
                        height: 100%;
                        width: 120px;
                        background-color: transparent;
                        &:focus {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    &.bp3-daterangepicker {
        .DayPicker-Caption {
            .bp3-datepicker-caption {
                margin-left: 15px;
                margin-right: 15px;
            }
            .bp3-icon-double-caret-vertical {
                display: none;
            }
        }
    }

    .bp3-daterangepicker-timepickers {
        position: relative;
        border-top: 1px solid rgba(16, 22, 26, 0.25);
        gap: 20px;
        justify-content: center;
        align-items: center;
        padding: 5px 0;

        .bp3-timepicker {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            gap: 2px;
            margin: 0;

            &:nth-child(1) {
                &:before {
                    content: 'From';
                }
            }

            &:nth-child(2) {
                &:before {
                    content: 'To';
                }
            }
        }
        .timepicker-apply {
            position: absolute;
            right: 5px;
        }
    }
}
.bp3-transition-container.bp3-popover-enter-done {
    z-index: 5000;
}
