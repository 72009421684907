%name_user {
    width: 35%;
}
%email {
    width: 35%;
}
%usertype {
    width: 10%;
}
%status {
    width: 20%;
}

%name_isNotCustomer {
    width: 25%;
}
%email_isNotCustomer {
    width: 25%;
}
%usertype_isNotCustomer {
    width: 13%;
}
%status_isNotCustomer {
    width: 17%;
}
%tenantName_isNotCustomer {
    width: 20%;
}

.users {
    .list-container {
        .list-headers {
            .header-field {
                &.name {
                    @extend %name_user;
                }
                &.email {
                    @extend %email;
                }
                &.userType {
                    @extend %usertype;
                }
                &.status {
                    @extend %status;
                }
            }
        }
        .list-items {
            .item {
                .item-list-item {
                    padding: 0 20px;
                }
                .fields {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    .data-field {
                        display: inline-block;
                        &.name {
                            width: 30% !important;
                        }
                        &.email {
                            @extend %email;
                        }
                        &.userType {
                            @extend %usertype;
                        }
                        &.status {
                            @extend %status;
                        }
                        &.userImage {
                            width: 5%;
                            padding: 3px 15px;
                            &.userChanged {
                                animation: fade 0.5s;
                            }
                        }
                        @keyframes fade {
                            0% {
                                opacity: 0;
                            }
                            100% {
                                opacity: 1;
                            }
                        }
                    }
                }
                .avatar-photo {
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }

    .isNotCustomer {
        .list-container {
            .list-headers {
                .header-field {
                    &.name {
                        @extend %name_isNotCustomer;
                    }
                    &.email {
                        @extend %email_isNotCustomer;
                    }
                    &.userType {
                        @extend %usertype_isNotCustomer;
                    }
                    &.status {
                        @extend %status_isNotCustomer;
                    }
                    &.tenantName {
                        @extend %tenantName_isNotCustomer;
                    }
                }
            }
            .list-items {
                .item {
                    .fields {
                        .data-field {
                            @extend %text-no-overflow;
                            display: inline-block;
                            &.name {
                                width: 20% !important;
                            }
                            &.email {
                                @extend %email_isNotCustomer;
                            }
                            &.userType {
                                @extend %usertype_isNotCustomer;
                            }
                            &.status {
                                @extend %status_isNotCustomer;
                            }
                            &.tenantName {
                                @extend %tenantName_isNotCustomer;
                            }
                        }
                    }
                }
            }
        }
    }

    .list-headers .header-field .sort-btn {
        cursor: default;
    }

    .item-list-item.new-app-item .fields .data-field.warning {
        color: #be1e2d;
    }

    .card-item-options > li {
        cursor: pointer;
    }

    .isLoggedInUser {
        border: 1px solid #7f7f7f;
        border-radius: $item-border-radius;
    }

    .items-header-new .management-and-refresh {
        .create-card-item {
            margin-left: auto;
        }
    }
}
