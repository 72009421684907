.try-unified-portal {
    position: relative;
    z-index: 1;
    border: none;
    border-radius: 20px;
    padding: 6px 10px;
    color: $white;
    font-size: 16px;
    line-height: 19px;
    transition: width 0.3s, box-shadow 0.3s;
    margin-right: 20px;
    background: linear-gradient(0deg, rgba(189, 189, 189, 0.1), rgba(189, 189, 189, 0.1)),
        linear-gradient(274.8deg, #1713c2 6.85%, rgba(21, 75, 212, 0.92) 22.32%, #2b6df2 45.69%, #00c2b7 99.59%);

    &:hover,
    &:focus {
        box-shadow: var(--try-unified-portal-btn-box-shadow);

        &::before {
            opacity: 1;
        }

        .btn-content .expanded-wrapper {
            width: 71px;
        }
    }

    &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(189, 189, 189, 0.1), rgba(189, 189, 189, 0.1)),
            linear-gradient(95.77deg, #1713c2 1.97%, rgba(21, 75, 212, 0.92) 63.59%, #2b6df2 127.35%);
        z-index: -1;
        transition: opacity 0.3s linear;
        opacity: 0;
        border-radius: 20px;
    }

    .btn-content {
        display: flex;
        align-items: center;
        gap: 5px;

        .expanded-wrapper {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            width: 17px;
            gap: 5px;
            overflow: hidden;
            transition: width 0.3s;

            .btn-icon {
                min-width: 17px;
                height: 17px;
                background: url('../static-assets/images/new-design/unified.svg') no-repeat;
            }

            .on-hover-text {
                white-space: nowrap;
                font-weight: 300;
            }
        }

        .primary-text {
            font-weight: 500;
            white-space: nowrap;
        }

        .beta-badge {
            border-radius: 10px;
        }
    }
}

.try-unified-portal-tooltip-text-new {
    color: var(--try-unified-portal-tooltip-new-word-color);
}

.try-unified-portal-modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close-icon {
        width: 30px;
        height: 30px;
        background-image: url(../static-assets/images/widget-close.svg);
        background-size: 23px 23px;
        cursor: pointer;
    }
}

.try-unified-portal-modal {
    display: flex;
    flex-direction: column;
    gap: 31px;

    .body-description-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .description {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
        }
    }

    .go-to-unified-btn {
        align-self: flex-end;
        margin-right: 0;
        padding: 8px 10px;

        &:hover,
        &:focus {
            &::before {
                opacity: 0;
            }
        }

        .btn-content .expanded-wrapper {
            width: 61px;
        }

        .expanded-wrapper .on-hover-text {
            font-weight: 500;
        }
    }
}
