.newAppCfg.configuration-page-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 24px 16px 24px;
    background-color: var(--page-bg-color);

    .configuration-page-header {
        position: sticky;
        top: 0;
        padding-top: 24px;
        background-color: var(--page-bg-color);
        z-index: 9997;

        .sections-screen-back-to-items-container {
            position: initial;
            width: auto;
            margin: 0;
            padding: 0;
            margin-bottom: 5px;

            &:hover .sections-screen-back-to-items-button {
                font-weight: 500;
            }

            .sections-screen-back-to-items-button {
                padding: 0;
                color: var(--text-color);
                font-weight: normal;
                font-size: 14px;

                &:before {
                    content: url(../static-assets/images/arrow-icon.svg);
                }
            }
        }

        .sticky-item-header-top {
            display: none;
        }

        .section.sticky-item-header#Applications_UpdateForm_Details_Tab {
            margin: 0;
            padding: 0;
            background-color: transparent;
            border: none;

            .section-header {
                padding-bottom: 8px;

                .app-badge-wrapper {
                    width: auto;
                    margin-left: 10px;
                }

                .cwaf-bypass-badge-wrapper {
                    margin-left: 10px;
                }

                div {
                    display: flex;
                    align-items: center;

                    * {
                        position: initial;
                        margin-top: 0;
                        float: unset;
                    }

                    .section-header-title {
                        font-size: 26px;
                        line-height: 32px;
                        font-weight: 600;
                        padding: 0;
                    }

                    .section-header-details {
                        color: var(--text-color);
                        margin-left: 20px;
                        padding-top: 6px;
                    }

                    .custom_tag {
                        display: block;
                        border-radius: 24px;
                        background: $blue-500;
                        padding: 4px 10px;
                        color: $white;
                        font-size: 13px;
                        line-height: 16px;
                        font-weight: 500;
                        text-transform: lowercase;

                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }

    .application-tabs {
        height: 0;
        flex-grow: 1;
        .tab {
            padding: 10px 15px;
            min-width: auto;
        }
        .section {
            margin: 0;
            padding: 0;
            max-width: 1030px;

            &#Applications_UpdateForm_Details_Tab {
                max-width: unset;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .section-header,
            .accordion-wrapper-header {
                display: none;
            }

            .section-operator-controls {
                margin-top: 0;
            }

            .sslApplicationManagement-details {
                padding: 0;
            }

            .accordion-category-header-status {
                .popover {
                    max-width: none;
                    width: 385px;
                    border-radius: initial;
                    top: unset;
                    bottom: 0;
                    transform: translate(0%, calc(100% + 5px)) !important;
                    color: var(--tooltip-text-color);
                    background-color: var(--tooltip-bg-color);
                    border-radius: var(--card-border-radius);
                }
            }
        }
    }
}
