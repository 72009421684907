.confirmation-modal-body {
    .confirmation-modal-body-message {
        display: inline-block;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
    }

    .second-confirm-container {
        display: flex;
        align-items: center;

        .second-confirm-checkbox {
            display: none;
        }
    }
}
