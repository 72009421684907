.editable-label {
    border: 1px solid transparent;
    padding: 0 38px 0 3px;
    cursor: text;

    * {
        display: inline-block;
    }

    &:hover {
        border: 1px dashed #cfd2d3;
        background-image: url('../static-assets/images/icon-edit_hover.svg');
        background-size: 16px auto;
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) center;
    }

    input[type='text'] {
        border: none;
        padding: 0;
        width: auto;
        font-size: inherit !important;
    }

    &.isEditing {
        border: 1px solid #cfd2d3;
        padding-right: 0;
        * {
            display: inline-block;
        }
        .saveBtn,
        .cancelBtn {
            width: 20px;
            height: 20px;
            margin: 0 5px;
            background-position: center;
            background-size: 20px;
            background-repeat: no-repeat;
            cursor: pointer;
        }

        .saveBtn {
            background-image: url('../static-assets/images/form-filter-submit-hover.svg');
        }

        .cancelBtn {
            background-image: url('../static-assets/images/search_close.svg');
        }

        .buttonsContainer {
            float: right;
        }
    }

    &.isEditing:hover {
        border: 1px solid gray;
        background-image: none;
    }

    &.editableContent {
    }
}
