$equal-toggle-border-radius: 9px;

.form-switch-toggle-button-wrapper > .label-on {
    font-size: 0.85em;
}

.form-switch-toggle-button-wrapper > .label-off {
    float: left;
    font-size: 0.85em;
}

.form-switch-toggle-button-wrapper > .form-switch-btn-wrapper {
    float: left;
}

.form-switch-btn-wrapper > div:first-child {
    float: left;
}

.form-switch-btn-wrapper > div:last-child {
    float: right;
}

.form-switch-btn-wrapper .form-switch-btn {
    margin: 0 5px;
    float: left;
    width: 28px;
    height: 14px;
    background: #a8aaad;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s;
    border: 1px solid #3f3f41;
    outline: none;
    line-height: 20px;
}

.form-switch-btn-wrapper .form-switch-btn:hover {
    opacity: 1;
}

.form-switch-btn-wrapper .form-switch-btn .form-switch-btn-state {
    width: 14px;
    height: 14px;
    background: $white;
    border-radius: 10px;
    position: relative;
    top: -1px;
    transition-property: right, left;
    transition-duration: 0.2s;
    -webkit-transition-property: right, left;
    -webkit-transition-duration: 0.2s;
    left: -1px;
    border: 1px solid #3f3f41;
}

.form-switch-btn-wrapper .form-switch-btn.on {
    background-color: #006736;
    position: relative;
    border: none;
}

.form-switch-btn-wrapper .form-switch-btn.on:after,
.form-switch-btn-wrapper .form-switch-btn.on:before {
    content: '';
    width: 1px;
    background-color: $white;
    position: absolute;
    display: none;
}

.form-switch-btn-wrapper .form-switch-btn.on:after {
    height: 8px;
    right: 19px;
    top: 2px;
    transform: rotate(45deg);
}

.form-switch-btn-wrapper .form-switch-btn.on:before {
    height: 4px;
    right: 23px;
    top: 6px;
    transform: rotate(-45deg);
}

.form-switch-btn-wrapper .form-switch-btn.on .form-switch-btn-state {
    left: 15px;
    border: none;
    top: 1px;
    width: 12px;
    height: 12px;
}

.form-switch-btn-wrapper .form-switch-btn .form-switch-btn-state.disabled {
    cursor: default;
}

.form-switch-btn-wrapper .form-switch-btn.disabled {
    opacity: 0.38;
}

.form-switch-btn-wrapper:after {
    content: ' ';
    display: block;
    float: none;
    clear: both;
}

.new-design .form-switch-btn-wrapper .form-switch-btn {
    border: 1px solid $error-color;
    border-radius: 0;
    background: transparent;
    width: 41px;
    height: 20px;
}

.new-design .form-switch-btn-wrapper.toggle-with-text .form-switch-btn {
    height: 22px;
}

.new-design .form-switch-btn-wrapper .form-switch-btn.on {
    border: 1px solid #00b168;
    background: transparent;
}

.new-design .form-switch-btn-wrapper .form-switch-btn .form-switch-btn-state {
    border-radius: 0;
    border: none;
    width: 20px;
    height: 19px;
    top: -1px;
}

.new-design .form-switch-btn-wrapper .form-switch-btn.off .form-switch-btn-state {
    background-color: $error-color;
}

.new-design .form-switch-btn-wrapper.toggle-with-text .form-switch-btn.off .form-switch-btn-state,
.new-design .form-switch-btn-wrapper.toggle-with-text .form-switch-btn.on .form-switch-btn-state {
    background-color: $white;
    width: 24px;
    height: 24px;
    top: -2px;
    left: 0px;
}

.new-design .form-switch-btn-wrapper.toggle-with-text .form-switch-btn.off .form-switch-btn-state {
    border: 1px solid #ef4c4c;
}

.new-design .form-switch-btn-wrapper.toggle-with-text .form-switch-btn.on .form-switch-btn-state {
    border: 1px solid #89d5a2;
}

.new-design .form-switch-btn-wrapper .form-switch-btn.on .form-switch-btn-state {
    background-color: #00b168;
    right: 0;
    left: 50%;
}

.new-design .form-switch-btn-wrapper .form-switch-btn.on .form-switch-btn-state:before {
    content: '';
    background-image: url(../static-assets/images/check.svg);
    position: absolute;
    right: 3px;
    top: 3px;
    width: 14px;
    height: 12px;
}

.new-design .form-switch-btn-wrapper .form-switch-btn.off .form-switch-btn-state:before {
    content: 'x';
    position: absolute;
    right: 7px;
    top: -1px;
    color: $white;
    font-weight: bold;
    font-size: 14px;
}

.new-design .form-switch-btn-wrapper.toggle-with-text .form-switch-btn {
    min-width: 77px;
    position: relative;
    color: #f7fdff;
    font-size: 12px;
}

.new-design .form-switch-btn-wrapper.toggle-with-text .form-switch-btn.on {
    background: #6ec089;
    border: 1px solid #6ec089;
}

.new-design .form-switch-btn-wrapper.toggle-with-text .form-switch-btn.off {
    background: #ef4c4c;
    border: 1px solid #ef4c4c;
}

.new-design .form-switch-btn-wrapper.toggle-with-text .form-switch-btn .form-switch-btn-state:before {
    content: '';
}

.new-design .form-switch-btn-wrapper.toggle-with-text .form-switch-btn::before {
    position: absolute;
}

.new-design .form-switch-btn-wrapper.toggle-with-text .form-switch-btn.off:before {
    content: 'Block';
    right: 10px;
    font-size: 12px;
    top: 1px;
}

.new-design .form-switch-btn-wrapper.toggle-with-text .form-switch-btn.on:before {
    content: 'Permit';
    display: block;
    left: 5px;
    transform: rotate(0);
    top: 1px;
    font-size: 12px;
    background-color: #6ec089;
}

.new-design .form-switch-btn-wrapper.toggle-with-text .form-switch-btn.on .form-switch-btn-state {
    left: 50px;
    /* float: right; */
    /* right: auto; */
    right: inherit;
}

// .react-switch{
//    .react-switch-bg{
//      border:1px solid #ef4c4c;
//      border-radius: 0 !important;
//      &.on{
//         border-color: #6ec089;
//     }
//    }
//    .react-switch-handle{
//         border-radius: 0 !important;
//     }
// }

.toggle-button-and-element-wrapper {
    display: flex;
    margin-bottom: 5px;
    > .select-with-validation {
        width: 100%;
    }

    input {
        z-index: 0;
    }
}

.new-design .form-switch-btn-wrapper {
    &.equal-toggle-button.vertical {
        .form-switch-btn {
            border: 1px solid var(--control-border-color);
            border-radius: $equal-toggle-border-radius;
            width: 20px;
            height: 30px;
            margin: 0;
            opacity: 1;
            .form-switch-btn-state {
                height: 50%;
                width: 100%;
                background-color: var(--security-events-filter-control-bg-color);
                border-radius: $equal-toggle-border-radius;
                left: 0;
                top: 0;
            }
            .form-switch-btn-state:before {
                font-size: 18px;
                font-weight: normal;
                right: 4px;
                color: #6499b7;
                top: -3px;
            }
            &.disabled {
                .form-switch-btn-state {
                    opacity: 0.38;
                }
            }
            &.off {
                .form-switch-btn-state {
                    transition: all 0.2s;
                    border-bottom: 1px solid var(--control-border-color);
                    &:before {
                        content: '\003D';
                    }
                }
            }
            &.on {
                .form-switch-btn-state {
                    transition: all 0.2s;
                    top: 13px;
                    border-top: 1px solid var(--control-border-color);
                    &:before {
                        content: '\2260';
                        background-image: none;
                        position: absolute;
                        right: 0;
                    }
                }
            }
        }
    }
}
