.application-origin-servers-tooltip {
    .application-origin-servers-list {
        width: 430px;

        .application-origin-servers-list-headers {
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--table-row-border-color);
            font-weight: 600;
        }

        .application-origin-servers-list-items {
            .list-item {
                display: flex;
                align-items: center;
                font-weight: normal;
                &:not(:last-child) {
                    border-bottom: 1px solid var(--table-row-border-color);
                }

                .origin-server-status-color {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background-color: $off-color;

                    &.active {
                        background-color: $active-color;
                    }
                }
            }
            .info-item-section {
                width: 300px;
                padding: 5px 0px;
                .info-list-item {
                    display: flex;
                    align-items: center;
                    &:not(:last-child) {
                        .server-name,
                        .status-column,
                        .latency-column {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }

        .list-column {
            flex: 1;
            width: 0;

            &:not(:last-child) {
                padding-right: 10px;
            }

            &.status-column {
                display: flex;
                justify-content: center;
                min-width: 70px;
            }
            .latency-column {
                @extend %text-no-overflow;
                min-width: 70px;
            }

            &.server-column {
                @extend %text-no-overflow;
                min-width: 130px;
                font-weight: 600;
            }
            &.server-name {
                @extend %text-no-overflow;
                min-width: 130px;
                line-height: 18px;
            }
        }
    }
}

.diversion-status-tooltip {
    width: 240px;

    .diversion-status-tooltip-bold-text {
        font-weight: 600;
        overflow-wrap: break-word;
    }
}
