.new-design .section-header-title {
    color: var(--text-color);
    font-size: 20px;
    font-weight: 500;
    border-bottom: none;
    line-height: 1.5;
    margin: 0;
    display: inline;
    float: left;
}

.new-design .section .section-header-title:before {
    padding-right: 6px;
    float: left;
}

.new-design .sections-screen {
    top: 0;
    border-top: none;
    background-color: var(--page-bg-color);
    margin-top: 0;
}

.new-design .spy-scroll-list-item a {
    display: flex;
    align-items: center;
    color: var(--text-color);
    font-size: 14px;
    font-weight: normal;
    padding: 1px 15px;
    border-radius: 15px;
    width: 100%;

    &:hover {
        font-weight: 600;
    }
}

.new-design li.spy-scroll-list-item {
    display: block;

    &:not(:first-child) {
        margin-top: 20px;
    }
}

.new-design .spy-scroll-list-item a:before {
    height: 30px;
}

.new-design .spy-scroll-list-item a.active {
    background-color: $spy-scroll-active-item-bg-color;
    font-weight: normal;
    border-radius: 15px;
    color: $white;
}

.new-design .spy-scroll-list-item a span {
    margin-left: 14px;
}

.new-design .section#Applications_UpdateForm_Details_Tab,
.new-design .section#Details_Section {
    .custom_tag {
        float: left;
        position: relative;
        right: unset;
        left: 10px;
        top: 4px;
        margin-left: 10px;
    }
    .diversion-status {
        float: left;
        margin-left: 20px;
        cursor: pointer;
    }
    .section-header-title {
        color: var(--text-color);
        font-size: 26px;
        font-weight: 700;
        float: left;
        clear: both;
    }
    &.isSticky {
        .custom_tag {
            top: 13px;
        }

        .diversion-status {
            margin-top: 10px;
        }

        .section-header-title,
        .section-header-details {
            clear: none;
        }
        .section-header-details {
            margin-top: 10px;
            margin-left: 10px;
        }
        .deployment-state {
            float: right;
            > * {
                display: inline-block;
            }
            .deployment-state-icon {
                margin-right: 5px;
            }
        }
    }
}

.new-design .section#Applications_UpdateForm_Details_Tab {
    .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:after {
            display: none;
        }

        .deployment-state {
            > * {
                display: inline-block;
            }

            .deployment-state-icon {
                margin-right: 5px;
            }
        }
    }
}

.sticky-details {
    float: left;
    margin-top: 12px;
    margin-left: 10px;
    color: var(--text-color);
    font-size: 14px;

    &.user-sticky-details {
        margin-top: 31px;
    }
}

.new-design .section .section-header-title small {
    display: block;
    font-size: 13px;
}

.new-design .card-items-container .item-list-item.card-item.disabled,
.new-design .card-items-container .item-list-item.card-item.deleted,
.new-design .card-items-container .grid-items-item.deleted {
    opacity: 0.38;
    //background: rgba(255,255,255,0.6);
    //color: rgba(0,0,0,0.6);
    cursor: auto;
    pointer-events: auto;
}

.new-design .application-protection-item-name {
    color: $dark-gray-100;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3;
    max-width: calc(100% - 265px);
}

.new-design .application-operator-dns-records-wrapper {
    max-width: 350px;
}

// .new-design .Collapsible {
//     margin-bottom: 33px;
// }

.sticky-item-header {
    position: sticky;
    top: 0;
    border-bottom: 1px solid #527b84;
    padding-bottom: 5px;
    padding-top: 10px;
    background-color: $white;
    z-index: 9997;
}

.userContainer {
    .sticky-item-header {
        padding-bottom: 15px;
        padding-top: 13px;
    }
}

.sticky-item-header-top {
    height: 1px;
}

.new-design .accordion-category-header-name,
.new-design .application-states-title {
    color: var(--text-color);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.new-design .application-states-title {
    border-top: none;
    margin-bottom: 15px;
    margin-top: 0;
    text-transform: none;
    float: left;
}

.new-design .olh-trigger {
    background-image: none;
    font-weight: 300;
    float: left;
    width: auto;
    color: #687077;
    text-decoration: underline;
}

.new-design .item-modes-radio-wrapper,
.new-design .Collapsible__trigger .dropdown {
    float: right;
}

.new-design .application-states-wrapper {
    margin: 0;
    float: right;
    clear: none;
    width: auto;
}

.new-design .application-states-wrapper:after {
    content: '';
    display: block;
    clear: both;
}

.new-design .Collapsible__trigger {
    border: 1px solid transparent;
}

.new-design .Collapsible__trigger.is-open {
    background: var(--section-header-active-bg-color);
    color: inherit;
    border-color: #e6e6e6;
    border-bottom: none;
}

.new-design .Collapsible__contentInner {
    border-bottom: none;
    background: var(--section-bg-color);
}

.new-design .Collapsible__contentInner:after {
    content: '';
    display: block;
    clear: both;
}

.new-design .accordion-category-header-description {
    color: var(--section-header-desc-text-color);
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;
}

.new-design .accordion-category-header-summary {
    display: inline-block;
    width: 100%;
}

.new-design .accordion-category-header-summary-item {
    color: var(--section-header-desc-text-color);
    font-weight: normal;

    &:not(:last-child) {
        margin-bottom: 3px;
    }
}

.new-design .accordion-category-header-summary-item-name {
    display: inline-block;
    margin-right: 5px;
}

.new-design .accordion-category-header-summary-item-value {
    width: 100%;
    .align-right {
        float: right;
        margin-right: 10px;
    }
}

.new-design .Collapsible__trigger {
    transition: all 0.3s;
    border-bottom: 1px solid transparent;
    /*    padding: 18px 35px 13px;*/
}

.new-design .Collapsible__trigger:hover {
    background-color: var(--section-header-hover-bg-color);
}

.new-design .Collapsible__contentOuter {
    border: none;
    position: relative;
}

.new-design article.section {
    border-bottom: 1px solid #527b84;
    padding-bottom: 0;
    padding-top: 10px;
    margin-top: 70px;
}

.new-design article.section:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 450px;
}

.new-design article.section#Applications_UpdateForm_Details_Tab {
    padding-top: 0;
    margin-top: 0;
}

.new-design article.section#Details_Section {
    padding-top: 27px;
    margin-top: 0;
}

.new-design article.section#Applications_UpdateForm_Details_Tab header:after,
.new-design article.section#Details_Section header:after {
    content: '';
    display: block;
    clear: both;
}

.new-design article.section#Applications_UpdateForm_Details_Tab header,
.new-design article.section#Details_Section header {
    border-bottom: 1px solid #527b84;
    padding-bottom: 0;
    margin-bottom: 18px;
}

.spContainer,
.userContainer {
    #Details_Section {
        .section-header:first-child {
            display: none;
        }
    }

    #Details_Section.sticky-item-header {
        .section-header:first-child {
            display: block;
        }
    }
}
.new-design .dropdown.disabled > div:first-child a {
    background-color: var(--dropdown-disabled-bg-color) !important;
    color: var(--dropdown-disabled-text-color);
}

.new-design .dropdown > div:first-child a {
    background-color: transparent;
    color: var(--dropdown-item-text-color);
    font-size: 14px;
    font-weight: 300;
    padding: 7px 40px 7px 10px;
    @extend %text-no-overflow;
    border: 1px solid #cfd2d3;
    border-radius: 0;

    &:hover::after {
        background: var(--dropdown-selected-arrow-icon) no-repeat center;
    }
}

.new-design .dropdown > div > a:after,
.new-design .dropdown > div > div > a:after {
    content: '';
    background: var(--dropdown-primary-arrow-icon) no-repeat center;
    padding-top: 11px;
    top: 2px;
    width: 30px;
    height: 30px;
    right: 2px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
}

.new-design .dropdown.active > div > a:after,
.new-design .dropdown.active > div > div > a:after {
    background: var(--dropdown-selected-arrow-icon) no-repeat center;
}

.new-design .details-table-item td:first-child,
.section#Details_Section .section-body-details-item-title,
.section#Details_Section li.title {
    color: var(--text-color);
    font-size: 13px;
    font-weight: 600;
}
.section#Details_Section .section-body-details-item-value.warning,
.section#Details_Section .section-body-details-item-title.warning {
    color: #be1e2d;
}

.section#Details_Section li.title {
    font-size: 14px;
    margin-bottom: 10px;
}

.section#Details_Section,
.section#Applications_UpdateForm_Details_Tab {
    .section-body {
        display: flex;
        padding-bottom: 35px;
        .infoAndButtons {
            margin-left: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .section-body-details-info {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .info-item {
                    display: flex;
                    margin: 0 15px 10px 0;
                    flex-direction: column;
                    .info-value {
                        color: #00c0ef;
                        font-size: 32.5px;
                        margin-right: 10px;
                    }
                    .info-title {
                        color: var(--text-color);
                        line-height: 1.2;
                    }
                }
            }
            .detailsSectionButtons {
                background-color: var(--button-bg-color);
                color: #6499b7;
                border: none;
                padding: 6px 25px;
                margin-right: 15px;
                box-shadow: 0 0 0 1px #e6e6e6;
            }
        }
    }
}

.trend-chart-no-data-placeholder {
    min-height: 160px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e6e6e6;
    margin-bottom: 10px;
}

.section#Applications_UpdateForm_Details_Tab,
.account_details_parent {
    .section-body .infoAndButtons {
        margin-left: 0;
        button {
            max-width: 250px;
        }
    }
    .trend-chart-container {
        margin-bottom: 20px;
    }

    .infoAndButtons.buttonsContainer {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
}

.new-design {
    .account-plans-form,
    .provider-white-label {
        .checkbox-wrapper.checkbox-with-label {
            label:before {
                left: 15px;
            }

            input[type='checkbox']:checked + label:after {
                left: 17px;
            }
        }
    }
    .licence-img-wrapper {
        .extraClass {
            padding: 0px !important;
        }
        .licence-img {
            background-image: var(--licence-image);
            width: 796px;
            height: 565px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    .provider-white-label .row {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

.container-full {
    margin: 0 auto;
    width: 100%;
}

.new-design .details-table-item {
    margin-bottom: 10px;
    float: left;
    clear: both;
}

.new-design .details-table-item > td > div {
    display: inline-block;
}

.new-design .details-table-item td {
    vertical-align: baseline;
    font-weight: 300;
    white-space: nowrap;
}

.new-design .application-dnsrecords-table.details-table .details-table-item td {
    white-space: normal;
}

.new-design .details-table-item td.provising:before {
    content: url(../static-assets/images/provisioning-icon.svg);
}

.new-design .sections-container,
.new-design .create-new-item-container {
    width: 76%;
    background-color: var(--section-bg-color);
    border-radius: var(--section-border-radius);
    padding: 30px;
    margin: $page-padding auto;
}

.new-design .sections-container {
    width: calc(100% - 230px);
    margin: 0 0 0 230px;
    border-left: 1px solid var(--section-border-color);
    border-radius: 0;
}

.new-design .sslApplicationManagement-details > * {
    display: inline-block;
}

.new-design .sslApplicationManagement-details-applicationDetailsGrid {
    margin-top: 10px;
}

.new-design .sslApplicationManagement-details-applicationDetailsGrid h4 {
    color: $dark-gray-100;
    font-size: 13px;
    font-weight: 700;
    border-bottom: none;
}

.new-design .section-body {
    margin-bottom: 0;
}

.new-design .details-table-item.status td {
    padding-right: 0;
}

.new-design .details-table-item.status td > * {
    display: inline-block;
    margin-right: 5px;
}

.new-design .Collapsible__trigger.is-open {
    background-color: var(--section-header-active-bg-color);
}

.new-design .Collapsible__trigger.is-closed.is-disabled {
    cursor: auto;
}

.new-design .accordion-category-header .accordion-category-header-message {
    position: absolute;
    right: -34px;
    top: -37px;
}

.new-design .accordion-category-header {
    position: relative;
    padding: 13px 35px;

    .component_wrapper {
        float: right;
        min-width: 250px;
        button {
            float: right;
        }
        .multi-control {
            display: flex;
        }
    }
}

.new-design .accordion-category-header-body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3px 35px 5px 35px;
}

.new-design .accordion-category-header .component_wrapper.include_configuration_icon {
    position: relative;
    right: 50px;
}

.new-design .accordion-category-header:after {
    content: '';
    display: block;
    clear: both;
}

.new-design .accordion-category-header__text {
    max-width: calc(100% - 320px);
    float: left;
}

.new-design .section-body .disable-wrapper {
    .accordion-category-header__text {
        opacity: 0.6;
    }
}
.new-design .accordion-category-header__icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    margin-right: 20px;
    pointer-events: none;

    .accordion-category-header__icon-arrow-icon {
        background-image: var(--dropdown-arrow-icon);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        transform: rotate(90deg);
        transition: transform 0.4s ease;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
}

.new-design .form-content-wrapper.protocol-form,
.new-design .form-content-wrapper.ip-source-form {
    width: 100%;
    border: none;
}

.new-design .form-content-wrapper.protocol-form:before {
    display: none;
}

.new-design input[type='text'],
.new-design input[type='password'],
.new-design input[type='number'],
.new-design textarea,
.new-design textarea[type='text'] {
    padding: 8px 10px;
    line-height: 14px;
}

input::placeholder,
textarea::placeholder {
    color: var(--control-placeholder-color);
}

.new-design .protocol-ports input[type='text'] {
    width: 75px;
}

.new-design .add-element {
    bottom: 15px;
}

.new-design .form-save:hover {
    background-color: #5987a1;
}

.new-design .generic-form-options.overflow-hidden {
    overflow: unset;
    top: auto;
    position: relative;
    right: auto;
}

.createCertificate .generic-form-options .form-discard,
.modal .form-discard,
.new-design .generic-form-options > .form-discard,
.new-design .Collapsible .form-discard {
    background-image: none;
    height: auto;
    background-color: var(--form-button-cancel-bg-color);
    float: right;
    color: #6499b7;
    font-size: 19px;
    font-weight: 400;
    padding: 10px 15px;
    line-height: unset;
    position: relative;
    border: none;
}

.new-design .generic-form-options > .form-discard:hover {
    background-color: var(--form-button-cancel-hover-bg-color);
}

.new-design .generic-form-options.inner,
.new-design .generic-form-options.overflow-hidden {
    top: auto;
    float: left;
    clear: both;
    width: 100%;
}

.new-design .inner .form-discard,
.new-design .Collapsible .form-discard {
    color: #6499b7;
    width: auto;
}

.createCertificate .generic-form-options .form-save,
.modal .form-save,
.new-design .generic-form-options > .form-save,
.policyDistributionCont .form-save,
.new-design .Collapsible .form-save {
    background-image: none;
    background-color: #6499b7;
    color: #f7fdff;
    font-size: 19px;
    border-radius: 0;
    position: relative;
    float: right;
    right: auto;
    top: auto;
    padding: 10px 15px;
    line-height: 1.5;

    &.form-retry {
        background-color: var(--form-button-retry-bg-color);
        &:hover {
            background-color: var(--form-button-retry-hover-bg-color);
        }
    }
}

.modal .form-save:before,
.modal .form-discard:before,
.new-design .generic-form-options > .form-save:before,
.new-design .generic-form-options > .form-discard:before,
.new-design .Collapsible .form-save:before,
.new-design .Collapsible .form-discard:before,
.policyDistributionCont .form-save:before,
.createCertificate .form-save:before,
.createCertificate .form-discard:before {
    content: '';
    border: 1px solid $white;
    top: 1px;
    bottom: 1px;
    right: 1px;
    left: 1px;
    position: absolute;
}

.new-design .generic-form-options > .form-save:hover,
.new-design .Collapsible .form-save:hover {
    background-color: #537d95;
}

.new-design .Collapsible__contentInner,
.new-design .application-operator-dns-records-wrapper {
    position: unset;
    border: 3px solid #e6e6e6;
}

.new-design .Collapsible__contentInner .application-operator-dns-records-wrapper {
    width: calc(100% - 250px);
}

.new-design .dropdown {
    width: 250px;
    outline: none;

    .dropdown-filter {
        margin-bottom: 0;

        &:before,
        &:after {
            display: none;
        }
    }
}

.new-design .Collapsible__trigger.is-open + .Collapsible__contentOuter {
    overflow: visible !important;
}

.new-design .generic-form-options .deleteConfirmationForm,
.new-design .generic-form-options .updateConfirmationForm {
    position: relative;
    left: 0;
}

.new-design .application-operator-dns-records-wrapper {
    position: unset;
}

.new-design .section-header-details {
    float: left;
    clear: both;
}

.new-design .section-header .olh-trigger {
    float: left;
}

.new-design .section-header:after {
    content: '';
    display: block;
    clear: both;
}

.new-design .section-header-details {
    padding-left: 36px;
    margin-top: -10px;
}

.new-design .section#Applications_UpdateForm_Details_Tab .section-header-details {
    padding-left: 0;
    margin-top: inherit;
    color: $black;
    font-size: 14px;
}

.new-design .sslApplicationManagement-details > .row {
    /* display: none; */
}

.new-design .note_container {
    border-top: 1px solid #527b84;
    padding: 20px 0;
}

.new-design .disabled .dropdown,
.new-design .dropdown.disabled,
.new-design .disabled input,
.new-design .disabled,
.new-design .form-disabled,
.new-design :not(input):not(textarea)[disabled] {
    opacity: 0.38;
    cursor: not-allowed;
    pointer-events: none;

    * {
        cursor: not-allowed !important;
        opacity: unset !important;
    }
}

.new-design .Collapsible__contentInner .application-protection-mode {
    margin: 10px 0 20px;
}

.new-design .Collapsible__contentInner .features-availability-whitelist .form-switch-btn-wrapper {
    margin-bottom: 10px;
}

.new-design .application-operator-app-protection-modes .form-protection-modes > div {
    float: left;
}

.new-design .application-operator-app-protection-modes .form-protection-modes .technology-select {
    margin-left: 10px;
}

.ellipsis-icon {
    width: 20px;
    height: 25px;
    position: absolute;
    right: 3px;
    cursor: pointer;
}

.ellipsis-icon > div {
    width: 3px;
    height: 3px;
    background: #a0a0a0;
    border-radius: 100%;
    margin: auto auto 2px auto;
}

.card-item-options {
    opacity: 0;
    width: 97px;
    background: #989a9b;
    position: absolute;
    right: -90px;
    top: -2px;
    /* z-index: 1000; */
    border-radius: 11px;
    overflow: hidden;
    transition: 0.5s;
    visibility: hidden;
}

.ellipsis-icon:hover .card-item-options {
    opacity: 1;
}

.grid-items-item:hover .card-item-options {
    opacity: 1;
    visibility: visible;
}

.card-item-options > li {
    text-align: center;
    border-bottom: 1px solid grey;
    padding: 5px;
}

.organization-login {
    float: right;
}

.forgot-password {
    float: left;
}

/* All Aplications screen */

.new-design .card-items-management-head h1 {
    @extend %page-header-title;
    float: left;
    clear: both;
    background-image: none;
    padding: 20px 0 13px;
}

.new-design .card-items-management-head .head-options-container {
    float: left;
    width: calc(100% + 30px);
    margin: 0 -15px;
    padding-bottom: 0;
    position: relative;
}

.new-design .card-items-management-head .head-options-container .top-part {
    padding-bottom: 17px;
    padding-top: 12px;
}

.new-design .card-items-management-head .head-options-container .sort-search:after {
    content: '';
    display: block;
    clear: both;
}

.new-design .card-items-management-head-details > li:first-child {
    margin-bottom: 5px;
}

.new-design .card-items-management-head-details > li {
    font-size: 17.94px;
    color: var(--text-color);
    font-weight: 300;
    display: block;
}

.new-design .card-items-management-head-details > li span {
    color: var(--text-color);
}

.new-design .card-items-management-head-details > li span.warning {
    color: #be1e2d;
    font-weight: bold;
}

.new-design .card-items-management-head-details > li span.softWarning {
    color: #ffc107;
    font-weight: bold;
}

.new-design .card-items-management-head-details > li:not(:first-child) {
    display: block;
    float: left;
    font-size: 13px;
}

.new-design .card-items-management-head-details > li:first-child {
    font-size: 18px;
}

.new-design .head-options-total-item-type {
    font-weight: 500;
}

.new-design .head-options-total-item.provisioning:before,
.new-design .deployment-state-icon.provisioning:before {
    background-image: url(../static-assets/images/provisioning-icon.svg);
}

.new-design .head-options-total-item.learning:before,
.new-design .deployment-state-icon.learning:before {
    background-image: url(../static-assets/images/learning-icon.svg);
}

.new-design .head-options-total-item.protecting:before,
.new-design .deployment-state-icon.protecting:before {
    background-image: url(../static-assets/images/protecting-icon.svg);
}

.new-design .head-options-total-item.deleting:before,
.new-design .deployment-state-icon.deleting:before {
    background-image: url(../static-assets/images/deleted-app-icon.svg);
}

.new-design .deployment-state-icon:before {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

.security-hardening {
    background: var(--security-hardening-icon) no-repeat center;
    width: 24px;
    height: 24px;
    margin-left: 10px;
}

.new-design .head-options-total-item {
    margin-right: 23px;
}

.new-design .card-items-management-head-sorting-list li {
    color: #687077;
    font-size: 14px;
    font-weight: 400;
    border-left: 1px solid #eef1f8;
    margin-left: 20px;
    padding-left: 20px;
}

.new-design .card-items-management-head-sorting-list li:first-child {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
}

.new-design .card-items-management-head-sorting-list > li.active {
    color: #6499b7;
}

.new-design .create-new-card-item {
    position: relative;
    text-align: center;
    height: 100%;
    color: #687077;
    font-size: 17px;
    font-weight: 300;
}

.new-design .create-new-card-item:before {
    content: '';
    background: url(../static-assets/images/add_new_app_icon.svg) no-repeat center;
    position: absolute;
    bottom: 40px;
    right: 0;
    left: 0;
    margin: auto;
    transition: all 0.3s;
    width: 137px;
    height: 78px;
}

.new-design .create-new-card-item .create-new-card-item-text {
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
    /* transition: all 0.3s; */
    font-size: 15.6px;
}

.new-design .card-items-container > .container {
    width: 1210px;
    position: relative;
}

.new-design .card-items-container .grid-items {
    height: calc(100vh - 245px);
}

.new-design .card-items-container .partial.grid-items-item {
    min-height: 200px;
    margin: 0;
}

.new-design .card-items-container .grid-items-item > div {
    height: 100%;
}

.new-design .card-items-container .grid-items-item.col-md-3 {
    width: 24%;
}

.new-design .card-items-container .grid-items-item .item-list-item {
    top: auto;
    height: 100%;
}

.new-design .card-items-container .grid-items-item .create-card-item,
.grid-items-item .grid-items-item-new {
    border: 2px solid #f7fdff;
    background-color: #ededed;
}

.new-design .card-items-container .grid-items-item .create-card-item,
.grid-items-item .grid-items-item-new:hover {
    cursor: pointer;
}

.new-design .card-items-container .grid-items-item .create-card-item:hover .create-new-card-item:before,
.grid-items-item .grid-items-item-new:hover .grid-items-item-new-title:before {
    transform: scale(1.15);
}

.new-design .card-items-container .grid-items-item .item-list-item:before,
.app_item_icon_under_title:before {
    position: absolute;
    left: -15px;
    top: -15px;
    width: 47px;
    height: 31px;
    content: '';
}

.new-design .card-items-container .grid-items-item .item-list-item.provisioning:before,
.app_item_icon_under_title.provisioning:before,
.deployment-state-icon.provisioning:before {
    background: url(../static-assets/images/provisioning-icon.svg) no-repeat center;
    content: '';
    width: 46px;
    height: 33px;
}

.new-design .card-items-container .grid-items-item .item-list-item.protecting:before,
.app_item_icon_under_title.protecting:before,
.deployment-state-icon.protecting:before {
    background: url(../static-assets/images/protecting-icon.svg) no-repeat center;
    content: '';
    width: 46px;
    height: 33px;
}

.new-design .card-items-container .grid-items-item .item-list-item.learning:before,
.app_item_icon_under_title.learning:before,
.deployment-state-icon.learning:before {
    background: url(../static-assets/images/learning-icon.svg) no-repeat center;
    content: '';
    width: 47px;
    height: 32px;
}

.new-design .card-items-container .grid-items-item .item-list-item.deleted:before,
.app_item_icon_under_title.deleted:before,
.deployment-state-icon.deleted:before {
    background: url(../static-assets/images/deleted-app-icon.svg) no-repeat center;
    content: '';
    width: 42px;
    height: 34px;
}

.new-design .grid-items .item-list-item {
    padding: 25px 25px 25px;
}

.card-item-header-name {
    color: var(--text-color);
    font-size: 18px;
    height: 60px;
    margin-bottom: 0;
    line-height: 1.2;
    overflow: hidden;
}

.full .card-item-header-name {
    height: 25px;
}

.new-design .card-items-container .grid-items-item:hover .card-item-header-name {
    text-decoration: underline;
}

.new-design .card-items-container .grid-items-item:hover .deleted .card-item-header-name {
    text-decoration: none;
}

.new-design .item-list-item-header .main-domain,
.new-design .item-list-item-header .created-date {
    color: var(--text-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 12px;
}

.new-design .item-list-item-header .main-domain {
    margin-bottom: 3px;
    @extend %text-no-overflow;
}

.new-design .item-list-item-header .main-domain:before {
    content: url('../static-assets/images/icon-domain.svg');
    margin-right: 5px;
}

.new-design .item-list-item-header .created-date:before {
    content: url('../static-assets/images/icon-date.svg');
    margin-right: 5px;
}

.new-design .grid-items-item .item-list-item {
    border-radius: 0;
}

.new-design .ellipsis-icon {
    top: 12px;
}

.new-design .ellipsis-icon > div {
    width: 4px;
    height: 4px;
    margin-bottom: 4px;
}

.applications .new-design .grid-items-item > div:not(.deleted):hover {
    cursor: pointer;
}

.grid-items-item:hover .elipsis-icon > div,
.new-design .card-items-container .grid-items-item:hover .ellipsis-icon > div {
    background-color: #6499b7;
}

.new-design .card-item-options {
    background-color: var(--tooltip-bg-color);
}

.new-design .card-item-options .edit_icon {
    background-image: url(../static-assets/images/icon-edit.svg);
    margin-bottom: 8px;
    width: 22px;
    height: 22px;
}

.new-design .card-item-options .edit_icon:hover {
    background-image: url(../static-assets/images/icon-edit_hover.svg);
}

.new-design .card-item-options .delete_icon {
    background-image: url(../static-assets/images/icon-delete.svg);
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.new-design .card-item-options .delete_icon.disabled {
    cursor: default;
    pointer-events: unset;
    &:hover {
        background-image: url(../static-assets/images/icon-delete.svg);
    }
}

.new-design .card-item-options .delete_icon:hover {
    background-image: url(../static-assets/images/icon-delete_hover.svg);
    background-repeat: no-repeat;
    background-position: 3px;
}

.new-design .card-item-options > li {
    padding: 0;
    border: none;
    background-repeat: no-repeat;
    background-position-x: center;
}

.new-design .card-item-options {
    right: -35px;
    top: 0;
    padding: 10px 5px;
    width: auto;
    border-radius: 0;
    overflow: visible;
}

.new-design .card-item-options:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid var(--tooltip-bg-color);
    left: -5px;
}

.new-design .card-item-bottom {
    color: $black;
    font-size: 12px;
    line-height: 1.2;
    margin-top: 10px;
}

.new-design .card-item-bottom .details {
    float: left;
}

.new-design .card-item-bottom .details > div {
    float: left;
}

.new-design .card-item-bottom .details .type {
    margin-top: 5px;
    margin-left: 3px;
}

.new-design .card-item-bottom .details .time {
    clear: both;
    margin-top: 2px;
}

.new-design .card-item-bottom .details .title {
    font-size: 18px;
    font-weight: 400;
}

.new-design .card-item-bottom .details .type,
.new-design .card-item-bottom .details .time {
    font-weight: 300;
}

.new-design .card-item-bottom .chart {
    float: right;
    /* max-width: calc(100% - 86px); */
    position: absolute;
    right: 0;
    bottom: -40px;
    height: 100px;
    left: -6px;
}

.new-design .custom_tag {
    color: #f7fdff;
    font-size: 9px;
    font-weight: 400;
    background-color: #6499b7;
    float: right;
    padding: 1px 7px;
    position: absolute;
    right: 20px;
    top: 7px;
}

.new-design .item-list-search-wrapper.search-option-close {
    width: 250px;
    position: relative;
    float: right;
}

.new-design .item-list-search-wrapper.search-option-close .search-options {
    margin: 2px 0 0 5px;
}

.new-design .item-list-search-wrapper.search-option-open input,
.dropdown .dropdown-filter input {
    position: relative;
    right: 0;
    outline: 0;
}

.dropdown .dropdown-filter input {
    width: 100%;
    padding-right: 40px;
    padding-left: 8px;
    min-height: 32px;
    border: 1px solid #cfd2d3;
    border-radius: 0;

    &:focus {
        border-color: $blue-500;
    }
}

.new-design .generic-form-options > div,
.new-design .generic-form-options .deleteConfirmationForm > div,
.new-design .generic-form-options .updateConfirmationForm > div {
    opacity: 1;
}

.new-design .item-list-search-wrapper > input,
.new-design .item-list-search-wrapper > input::-webkit-input-placeholder {
    padding-left: 0px;
    background-image: none;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
}

.new-design .item-list-search-wrapper > input::-webkit-input-placeholder {
    opacity: 0.4;
}

.new-design .item-list-search-wrapper {
    position: relative;
}

.new-design .item-list-search-wrapper {
    position: relative;
}

.new-design .item-list-search-wrapper .search-triggerButton,
.search-input-wrapper .search-triggerButton,
.new-design .item-list-search-wrapper .Filter-clear,
.search-input-wrapper .Filter-clear {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px, 20px;
    cursor: pointer;
}

.new-design .item-list-search-wrapper .search-triggerButton,
.search-input-wrapper .search-triggerButton {
    background-image: var(--search-icon);
}

.new-design .item-list-search-wrapper .Filter-clear,
.search-input-wrapper .Filter-clear {
    background-image: var(--search-close-icon);
}

.new-design .card-items-management-head-details {
    float: left;
    .action-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .action-group-item {
            margin: 0px 8px;
            label {
                cursor: pointer;
                span {
                    margin-left: 0;
                }
            }
        }
    }
}

.new-design .refresh-button {
    float: right;
    padding-top: 8px;
}

.new-design .refresh-button,
.create-new-card-item-button {
    background-color: var(--button-bg-color);
    color: #6499b7;
    font-size: 13px;
    font-weight: 400;
    line-height: 5.265px;
    padding: 6px 14px;
    cursor: pointer;
    transition: all 0.2s;
    margin-top: 8px;
    margin-right: 10px;
    border: 1px solid var(--button-bg-color);
    position: relative;
}

.new-design .refresh-button:after,
.create-new-card-item-button:after {
    content: ' ';
    position: absolute;
    z-index: 1;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    border: 1px solid $white;
}

.new-design .refresh-button:hover,
.create-new-card-item-button:hover {
    background-color: #dfe4f1;
}

.new-design .refresh-button:before,
.create-new-card-item-button::before {
    content: var(--refresh-icon-primary);
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
}

.create-new-card-item-button {
    float: left;
    padding: 10px 15px;
    line-height: 1.5;
}

.create-new-card-item-button:before {
    content: '';
    width: 30px;
    height: 18px;
    display: inline-block;
    float: left;
    background: url(../static-assets/images/add_new_app_icon.svg) left center no-repeat;
    background-size: contain;
}

.new-design .create-new-item-container h1 {
    color: #6499b7;
    font-size: 26px;
    font-weight: 700;
    background-image: none;
    padding: 0;
    margin-bottom: 5px;
}

.app-content .createApplication {
    padding: 0;
}

.new-design .form-header {
    border-bottom: none;
}

.new-design .create-new-item-container .create-new-item-container-inner {
    width: 714px;
    margin: auto;
}

.new-design .item-create-sub-title {
    color: #687077;
    font-size: 13px;
    font-weight: 300;
    float: left;
    clear: both;
}

.new-design .item-create-form {
    width: 100%;
}

.new-design .applications-create-options.form-header {
    border-bottom: 1px solid #6499b7;
}

.new-design input::-webkit-input-placeholder,
.new-design input::-moz-placeholder,
.new-design input::-ms-input-placeholder,
.new-design input::-moz-placeholder {
    color: var(--control-placeholder-color);
    @include h4-font;
}

.new-design .item-create-form .input-label span,
.new-design span.input-label,
.form-content-wrapper label,
.form-content-wrapper label > span,
.form-content-wrapper .input-label > span {
    color: var(--text-color);
    @include h3-font;
}

.new-design .item-create-form .input-label .help-tooltip span {
    color: unset;
    font-size: unset;
    font-weight: unset;
}

.new-design .form-content-wrapper .input-with-names .dropdown,
.new-design .dropdown .st-tooltip-item {
    width: 250px;
    outline: none;
}

.is-invalid-input {
    border: solid 1px $off-color !important;
    box-shadow: 0 0 0 1px $off-color !important;
    color: $off-color;

    & + span {
        color: $off-color;
        border-left: 2px solid $off-color;
        padding-left: 5px;
        line-height: 1;
        margin-bottom: 10px;
        display: inline-block;
        min-width: 150px;
    }
}

.new-design .is-invalid-input {
    background-color: inherit;
    border: 1px solid $off-color !important;
    background: url(../static-assets/images/error-icon.svg) no-repeat calc(100% - 10px) center;
}

.new-design .dropdown.isInvalidSelect > div:first-child a {
    border: 1px solid $off-color;
    box-shadow: 0 0 0 1px $off-color;
}

.new-design .is-invalid-input + span,
.new-design .item-create-form .input-label .validation-error span,
.new-design .item-create-form .input-label span.error-message,
.new-design span.invalid-input,
.application-insights .modal span.invalid-input,
.dropdown.isInvalidSelect > span {
    color: $error-color;
    border-left: none;
    float: right;
    min-width: auto;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1.3;
    right: 0;
    font-size: 13px;
    font-weight: normal;
}

.input-label + div {
    position: relative;
}

.new-design .dropdown.active .st-tooltip-item {
    border: 1px solid var(--dropdown-border-color);

    ul.st-list--table {
        color: #687077;
        padding: 0;

        li {
            display: flex;
            align-items: center;
            border: 1px solid var(--dropdown-item-border-color);
            border-top: none;

            &:hover {
                background-color: var(--dropdown-item-hover-bg-color);
            }

            &.st-list--selected {
                background-color: var(--dropdown-item-active-bg-color);
            }

            &:hover span,
            &.st-list--selected span {
                color: var(--dropdown-item-text-color);
            }
        }
    }
}

.new-design .accordion-category-header .dropdown.active .st-tooltip-item ul.st-list--table {
    background-color: var(--dropdown-list-bg-color);
    color: var(--dropdown-item-text-color);
    padding: 0;
}

.new-design .dropdown.active .st-tooltip-item ul.st-list--table li span,
.new-design .dropdown.active .st-tooltip-item ul.st-list--table li a {
    font-size: 14px;
    padding: 10px 40px 10px 20px;
    border-bottom-color: $white;
    border-left: 2px solid transparent;
    font-weight: normal;
    display: inline-block;
}

.new-design .dropdown.active .st-tooltip-item ul.st-list--table li:last-child span {
    border-bottom: none;
}

.new-design .st-list--row.st-list--selected span,
.new-design .st-list--row.st-list--selected a {
    background-color: var(--dropdown-item-active-bg-color) !important;
    font-weight: normal;
}

.new-design .item-create-form .dropdown {
    width: 100%;
}

.new-design input:disabled + label {
    cursor: default;
}

.new-design input[type='checkbox'] + label {
    text-transform: capitalize;
    outline: none;

    & + label {
        width: calc(100% - 30px);
    }
}

.new-design .checkboxes-group + #app-create-http {
    margin-top: 5px;
}

.new-design input[type='radio'] + label {
    min-height: 11px;
}

.new-design .item-create-form label.radio-button-label {
    color: #687077;
    font-size: 15px;
}

.new-design .create-new-item-container:after {
    content: '';
    display: block;
    clear: both;
}

.create-new-item-section-header {
    border-bottom: 1px solid #6499b7;
    padding-bottom: 7px;
    font-size: 16.25px;
    margin-top: 30px;
}

.new-design .create-new-item-container .generic-form-options.overflow-hidden {
    position: relative;
    border-top: 1px solid #6499b7;
    width: 100%;
    right: auto;
    padding-top: 10px;
}

.new-design .block-page-settings-custom-address-button {
    background-image: none;
    background-color: #6499b7;
    color: #f7fdff;
    font-size: 13px;
    border-radius: 0;
    border: 1px solid #6499b7;
    padding: 10px 10px;
    opacity: 0.75;
    transition: all 0.2s;
}

.new-design .block-page-settings-custom-address-button:disabled {
    opacity: 0.38;
}

.new-design .block-page-settings-custom-address-button:not(:disabled):hover {
    opacity: 1;
}

.new-design .block-page-settings-default-example {
    line-height: 2;
    margin-left: 5px;
}

.new-design .Collapsible__contentInner .application-operator-main-domain-form {
    width: 100%;

    .static-modal {
        .main-domain-modal-body {
            .main-domain-modal-notice-message {
                margin-top: 15px;
            }
        }

        // temporary fix for old modal buttons
        .modal-footer {
            .btn {
                font-weight: 600;
                font-size: 14px;
                margin-left: 15px;
                min-width: 100px;

                &[data-debug-id='Application_Customer_Main_Domain_Modal_Apply'] {
                    background-color: #ec223a;
                    color: $white;
                    font-weight: 600;
                    font-size: 14px;
                }

                &[data-debug-id='Application_Customer_Main_Domain_Modal_Cancel'] {
                    background-color: var(--button-bg-color);
                    color: var(--text-color);
                }
            }
        }
    }
}

.Collapsible__contentInner .field-wrapper {
    width: 250px;
}

.Collapsible__contentInner .hor-field-wrapper {
    width: 500px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    .input-label {
        flex-basis: 65%;
        align-self: center;
    }
    > input,
    .select-with-validation {
        flex-basis: 35%;
        width: 150px;
    }
    .invalid-input {
        flex-basis: 100%;
        text-align: right;
    }
}

.Collapsible__contentInner .fields-category {
    margin-top: 10px;
}

.sections-screen-back-to-items-container {
    display: inline-block;
    position: fixed;
    width: 200px;
    padding: 6px 14px;
}

.sections-screen-back-to-items-container button,
.back-to-items-button {
    border-radius: 3px;
    line-height: unset;
    border: none;
    color: var(--text-color);
    font-size: 14px;
    font-weight: normal;
    background-color: transparent;
    padding: 30px 0;
}

.back-to-items-button {
    padding: 0;
}

.back-to-items-button {
    margin-right: 20px;
}

.back-to-items-button {
    &:hover:before {
        margin-left: -5px;
        margin-right: 10px;
    }
}

.sections-screen-back-to-items-container button,
.back-to-items-button {
    &:before {
        content: url(../static-assets/images/arrow-all-app.svg);
        display: inline-block;
        vertical-align: middle;
        transition: all 0.2s;
        margin-right: 5px;
    }
}

.new-design button,
.new-design input,
.new-design select,
.new-design textarea {
    outline: 0;
}

.new-design .item-list-search-wrapper.search-option-open .search-options {
    display: none;
}

.toggle-button-wrapper-redirect {
    float: right;
    text-align: right;
    margin-top: 3px;
}

.new-design .protocol-ports .is-invalid-input + span {
    padding-left: 0;
    float: left;
    max-width: 96%;
    line-height: 1.2;
    left: 0;
}

.new-design .httpNonStandardTcpPorts {
    float: left;
    clear: both;
    width: 100%;
}

.placeholder:before {
    content: '' !important;
}

.split-screen-wrapper .olh-trigger {
    z-index: 1;
}

.new-design .form-content-wrapper.origin-form {
    max-width: calc(100% - 30px);
    min-width: 200px;
    width: 100%;
}

.new-design .card-item-left-side .item-list-item-header > div {
    word-wrap: break-word;
    .highlight-container {
        display: inline-block;
    }
}

.new-design .sslApplicationManagement-details .transactions-chart-details-title {
    display: none;
}

.accordion-category-header-name .information-tooltip-icon {
    background-image: url(../static-assets/images/star-unsaved.svg);
    width: 13px;
    height: 13px;
}

.accordion-category-header-name .information-tooltip-icon-container {
    display: inline-block;
    margin-left: 3px;
}

.information-tooltip-icon-container .type-info,
.item-list-item.card-item .__react_component_tooltip {
    color: var(--tooltip-text-color) !important;
    font-size: 13px !important;
    font-weight: 300 !important;
    background-color: var(--tooltip-bg-color) !important;
    padding: $tooltip-padding !important;
    border-radius: $tooltip-border-radius !important;
    max-width: 650px;
    line-height: 1.5;
    white-space: normal;
    filter: $tooltip-filter;

    .olh-trigger {
        margin: 0;
    }
}

.extraClass {
    font-size: 20px !important;
    pointer-events: auto !important;
}

.extraClass:hover {
    visibility: visible !important;
    opacity: 1 !important;
}

.item-list-disabled {
    opacity: 0.38;
    cursor: not-allowed !important;
}

.grid-items-item:hover .item-list-disabled .card-item-header-name {
    text-decoration: none !important;
}

.new-design .protocol-hc-wrapper label {
    margin-bottom: 10px;
}

.new-design .unsavedChangesContainer {
    color: #ff7100;
    right: -35px;
    top: -40px;
    font-weight: 500;
    font-size: 13px;
}

.new-design .information-tooltip-icon {
    background-image: url('../static-assets/images/i_tooltip_icon.svg');
    height: 12px;
    width: 12px;
    background-size: contain;
}

.new-design .information-tooltip-icon:hover {
    background-image: url('../static-assets/images/i_tooltip_icon_hover.svg');
    cursor: pointer;
}

.new-design .accordion-category-header .information-tooltip-icon {
    height: 25px;
    width: 12px;
    right: -10px;
    top: -23px;
    position: absolute;
}

.new-design .accordion-category-header .information-tooltip-icon.disable-icon {
    background-image: url(../static-assets/images/disable-icon.svg);
    height: 25px;
    width: 12px;
    right: -33px;
    top: -3px;
    position: absolute;
}
.new-design .accordion-category-header .information-tooltip-icon.warning-icon {
    background-image: url('../static-assets/images/warning_icon.svg');
    height: 16px;
    width: 16px;
    right: 0;
    left: -23px;
    top: 3px;
    position: absolute;
}

.new-design .help-tooltip {
    display: inline-block;
    padding-left: 5px;
}

.information-tooltip-icon-container {
    position: relative;
}

.card-items-management-head-change_width_feature {
    float: left;
}

.card-items-management-head-change_width_feature > div {
    width: 23px;
    height: 23px;
    float: left;
    margin-right: 18px;
    margin-top: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.card-items-management-head-change_width_feature .partial_width_button {
    background-image: url(../static-assets/images/partial_width_button.svg);
}

.card-items-management-head-change_width_feature .partial_width_button:hover,
.card-items-management-head-change_width_feature .partial_width_button.active {
    background-image: url(../static-assets/images/partial_width_button_hover.svg);
}

.card-items-management-head-change_width_feature .full_width_button {
    background-image: url(../static-assets/images/full_width_button.svg);
}

.card-items-management-head-change_width_feature .full_width_button:hover,
.card-items-management-head-change_width_feature .full_width_button.active {
    background-image: url(../static-assets/images/full_width_button_hover.svg);
}

.new-design .policy-distributions-header {
    background-color: var(--section-bg-color);
    border-radius: var(--section-border-radius);
    margin: $page-padding;
    padding-bottom: 20px;
}

.new-design .policy-distributions-container .partial.grid-items-item.col-md-4 {
    width: 30%;
    margin: 0 18px 18px 18px;
    padding: 0;

    .item-list-item {
        border-radius: $item-border-radius;
    }
}

.new-design .multiSelect .st-list--row.st-list--selected:after {
    top: 16px;
}

.new-design .multiSelect .st-list--row.st-list--selected:before {
    top: 20px;
}

.new-design .multiSelect .st-list--row.st-list--selected:before,
.new-design .multiSelect .st-list--row.st-list--selected:after {
    background-color: #6499b7;
}

.new-design .timer-period-selector .inputs__container__fields input[type='text']:first-child,
.new-design .timer-period-selector input[type='text'] {
    padding: 0;
    border: none;
}

.new-design .timer-period-selector .inputs__container__fields,
.new-design .inputs__container__buttons {
    border: 1px solid var(--control-border-color);
    height: 43px;
    border-radius: 0;
}

.new-design .item-list-search-wrapper.search-option-open {
    float: right;
    margin-bottom: 6px;
    z-index: 1;
}

.new-design .card-items-container .grid-items-item {
    min-height: 200px;
    margin: 0;
}

.main-applications-screen .item-list-header.card-items-management-head {
    margin-bottom: 23px;
}

.new-design .card-items-container .grid-items-item .item-list-item {
    top: auto;
    height: 100%;
}

.create-new-card-item-button {
    display: none;
}

.export-button,
.export-generic {
    float: right;
    border: none;
    margin-left: 10px;
}

.export-generic {
    width: 36px;
    height: 37px;
}

button.outlined-button {
    color: #f7fdff;
    background-color: #6da7c8;
    border: 1px solid #f7fdff;
    outline: 1px solid #6da7c8;
    padding: 0 15px;
    margin-left: 10px;
    height: 25px;

    &:hover {
        opacity: 0.75;
    }

    &.danger-btn {
        background: #f13d4a;
        outline-color: #f13d4a;
    }

    .label-with-spinner {
        display: flex;
        align-items: center;
        height: 100%;

        .sign-in-spinner {
            position: unset;
            transform: none;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.new-design .accordion-category-header button.outlined-button {
    height: 35px;
    font-size: 18px;
    font-weight: 400;
}

.BlockAndReportMessage {
    color: #687077;
    font-size: 14px;
    font-weight: 300;
    padding: 9px 40px 10px 20px;
    @extend %text-no-overflow;
    border: 1px solid #687077;
}

.searchIndication {
    color: $black;
    font-size: 15.6px;
    margin: 0 0 0 20px;
    font-weight: 500;
    position: relative;
}

.add_fields-wrapper {
    display: flex;
    flex-direction: column;
    .item_container {
        display: flex;
        margin-bottom: 10px;
        .delete-element {
            width: 16px;
            height: 16px;
            cursor: pointer;
            background: url('../static-assets/images/remove_field.svg') no-repeat;
            background-size: 16px;
            margin: 0 0 0 10px;
            align-self: center;
        }
    }
}

.progressBar {
    position: relative;
}

.progressBarConfirm {
    position: relative;
}

.partial .hideInPartialWidth,
.partial .app_item_icon_under_title {
    display: none;
}

.accordion-category-header-status {
    display: inline-block;
    position: absolute;
    right: -35px;
    font-size: 13px;
    font-weight: 500;
    color: #ff7100;

    &.header {
        display: inline-block;
        position: absolute;
        top: 5px;
        right: 5px;
    }

    &.body {
        position: absolute;
        display: inline-block;
        right: 18px;
    }
}

.accordion-category-header-status .unsavedChangesContainer {
    color: #ff7100;
    cursor: initial;
    background: url(../static-assets/images/dirty_form_indication.svg) no-repeat;
    width: 19px;
    height: 19px;
    display: inline-block;
}

.accordion-category-header-status .activity-status {
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s;
}

.accordion-category-header-status .activity-status:hover {
    font-weight: 700;
}

.accordion-category-header-status .activity-status.fail {
    background: url(../static-assets/images/failed-red-icon.svg) no-repeat;
    width: 19px;
    height: 19px;
    display: inline-block;
}

.accordion-category-header-status .activity-status.success {
    background: url(../static-assets/images/success_indication.svg) no-repeat;
    width: 19px;
    height: 19px;
    display: inline-block;
}

.accordion-category-header-status .activity-status.in-process {
    background: url(../static-assets/images/pending_indication.svg) no-repeat;
    width: 19px;
    height: 19px;
    display: inline-block;
}

.create-card-item {
    background-color: #6499b7;
    color: #f7fdff;
    font-size: 13px;
    padding: 5px 13px;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    line-height: 1.5;
    &:hover {
        transition: all 0.2s;
        opacity: 0.85;
    }
}

.sort-search {
    .create-card-item {
        float: left;
        margin-right: 35px;
        margin-top: 2px;
    }
}

.new-design .card-items-container .grid-items-item.col-md-12.full {
    padding: 0;
    min-height: 0;
    width: calc(100% - 44px);
}

.new-design .card-items-container .grid-items-item.col-md-12.full .item-list-item {
    min-height: 0;
}

.container-toggle-and-download-button {
    display: flex;
    justify-content: flex-end;

    .export-generic {
        margin-right: 10px;
        margin-top: -1px;
    }
}

/* IE11 hacks*/
_:-ms-fullscreen,
:root {
    .new-design .generic-form-options > .form-discard {
        line-height: 1.5;
    }
}

.new-design .form-content-wrapper.simple-form {
    &.dns-form-wrapper {
        padding-left: 15px;
        .field-wrapper {
            width: 325px;
            .invalid-input {
                width: 100%;
            }
        }
    }
    margin-top: 0;

    .field-wrapper {
        margin-bottom: 15px;
    }

    .enabled-checkbox .input-label {
        cursor: pointer;
    }

    .simple-form-label-with-tooltip {
        width: 350px;
        display: flex;
        align-items: center;
    }
}

.new-design .s3-config-form {
    .s3-config-form-container {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .input-label {
        padding: 0px 10px 5px 10px;
        margin: 0px !important;
        .input-label,
        .required-field {
            display: inherit;
            font-size: 12px !important;
            line-height: 14px !important;
            font-weight: 500 !important;
            color: var(--dropdown-label-color) !important;
        }
    }
    .field-wrapper {
        input[type='text'] {
            height: 28px;
            background-color: transparent !important;
            color: var(--control-text-color);
            font-size: 12px;
            font-weight: 400;
            line-height: 0;
            border: 0.5px solid var(--control-border-color);
            border-radius: 12px;
            padding-left: 10px;
            cursor: pointer;
        }
    }
}

.entities-list {
    border: 1px solid #d4d6d8;
    border-radius: 20px;
    height: 126px;
    overflow-y: auto;
    padding: 10px 12px;
    position: relative;
    li {
        margin-bottom: 10px;
        &:before {
            content: '';
            width: 10px;
            height: 10px;
            background-color: #8bb0d5;
            border-radius: 50%;
            margin-right: 5px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.appication-group-section {
    .gen-list-headers {
        font-size: 14px;
        font-weight: 500;
        .gen-list-headers-status {
            width: 50px;
            height: 16px;
            .help-tooltip {
                padding-left: 0px;
            }
        }
    }
    .gen-list-item {
        align-items: center;
        .gen-list-headers-status {
            width: 50px;
            display: flex;
            .host-status {
                width: 15px;
                height: 15px;
                margin-top: 0px;
            }
            .help-tooltip {
                height: 14px;
                .information-tooltip-icon.warning-icon {
                    color: #ff7100;
                    cursor: initial;
                    background: url(../static-assets/images/dirty_form_indication.svg) no-repeat;
                    width: 19px;
                    height: 19px;
                    display: inline-block;
                }
            }
        }
        .gen-list-item-buttons {
            justify-content: end;
        }
        .invalid-input {
            width: 300px;
            display: flex;
            align-items: center;
        }
    }
}

.api-group-section {
    width: 40%;
    padding-top: 0px;
    .gen-list-headers {
        font-size: 14px;
        font-weight: 500;
        padding-top: 0px;
    }
    .gen-list-item {
        align-items: center;
    }
    .redirect-btn {
        position: relative;
        font-weight: 500;
        margin-top: 20px;
        padding-left: 20px;
        color: #3b77ee;
        cursor: pointer;
        text-decoration: underline;
        &:after {
            content: url(../static-assets/images/new-design/arrow-blue-right.svg);
            position: absolute;
            top: 2px;
            margin-left: 10px;
        }
    }
}

.out-of-plan-label {
    background-color: var(--out-of-plan-bg-color);
    color: #fff;
    padding: 3px 10px;
    border-radius: 4px;
    margin-left: 16px;
    font-size: 12px;
}

.custom-signature-container {
    .header-section {
        margin-bottom: 20px;
        display: flex;
        .refresh-button {
            float: initial;
            margin-left: auto;
        }
    }
    .gen-form .gen-list-headers {
        font-size: 12px;
    }
    .gen-list-mgmt-header {
        margin: 0px 20px;
        .add-btn-wrapper {
            margin-left: auto;
        }
    }
    .gen-list-outer:not(:last-child) {
        border-bottom: 1px solid var(--table-row-border-color);
    }
    .gen-list-item {
        padding: 10px 20px;
    }
    .gen-list-item-buttons {
        justify-content: end;
    }
    .pattern-container {
        background-color: var(--table-expanded-row-bg-color);
        padding: 10px 15px;
        margin: 0px 15px;
        .gen-list-mgmt-header {
            margin: 0px 0px 10px 0px;
            .add-btn-wrapper {
                margin-left: auto;
                display: flex;
                .check-regex-btn {
                    margin-right: 10px;
                    padding: 6px 10px;
                    border-radius: 20px;
                    border: 1px solid var(--outlined-btn-bg-color);
                    background-color: transparent;
                    color: var(--outlined-btn-color);
                    &:hover {
                        background-color: var(--outlined-btn-bg-hover-color);
                        color: var(--outlined-btn-hover-color);
                    }
                }
            }
        }
        .gen-list-item {
            padding: 10px 20px;
            .ellipsis-text {
                flex-shrink: 1;
                @extend %text-no-overflow;
            }
            .Select {
                width: 100%;
            }
        }
        .center {
            text-align: center;
            align-items: center;
            .checkbox-wrapper {
                width: 100%;
                input[type='checkbox'] {
                    display: initial;
                }
                label {
                    display: none;
                }
            }
        }
        .gen-list-item-fields {
            align-items: center;

            .gen-list-item-input div input,
            .Select-control {
                width: 120px;
            }
            .gen-list-item-input .parameters-input input {
                width: 158px;
            }
        }
    }
    .in-progress-icon {
        background-image: url('../static-assets/images/pending_indication.svg');
        background-color: transparent;
        width: 15px;
        height: 15px;
        &:hover {
            background-image: url('../static-assets/images/pending_indication.svg');
        }
    }
}
.regex-container {
    .center {
        justify-content: right !important;
    }
}
.check-expression-model {
    .gen-list-item-input {
        margin-bottom: 20px;
        display: flex;
        input {
            width: 290px;
        }
        .invalid-input {
            float: left;
        }
    }
    .regex-wrapper {
        display: flex;
        align-items: center;
        .check-regex-btn {
            margin-right: 10px;
            padding: 6px 20px;
            border-radius: 20px;
            border: 1px solid var(--outlined-btn-bg-color);
            background-color: transparent;
            color: var(--outlined-btn-color);
            &:hover {
                background-color: var(--outlined-btn-bg-hover-color);
                color: var(--outlined-btn-hover-color);
            }
        }
        .notify-wrapper {
            width: 200px;
            height: 36px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            .notify {
                width: 21px;
                height: 21px;
                margin: 0px 10px;
            }
        }
        .match {
            border: 2px solid $green-200;
            .notify-icon {
                background: url('../static-assets/images/successicon.svg') no-repeat;
            }
        }
        .notMatch {
            border: 2px solid $red-200;
            .notify-icon {
                background: url('../static-assets/images/erroricon.svg') no-repeat;
            }
        }
    }
}
.new-design .accordion-category-header .custom-sig-tooltip {
    .information-tooltip-icon {
        height: 12px;
        width: 12px;
        top: -14px;
        right: -30px;
    }
}
