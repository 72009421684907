.toolbar-options .options-icons {
    padding-right: 0 !important;
}

.toolbar-options-child {
    padding-left: 0 !important;
}

.options-icons > div {
    float: right;
    padding: 0 5px;
    position: relative;
}

.toolbar-options {
    margin-top: 8px;
    width: calc(100% - 230px);
    position: relative;
    padding: 0;
}

.toolbar-options.disable:before {
    position: absolute;
    left: 10px;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(244, 245, 249, 0.7);
    z-index: 9999;
    display: block;
}

.toolbar-options > div:first-child .dropdown {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.toolbar-options > div:first-child .dropdown > div:first-child {
    line-height: 1;
}

.navbar.navbar-toolbar .row.toolbar-options > div:first-child {
    width: 40%;
}

.navbar-toolbar {
    background-color: rgba(237, 239, 245, 0.59);
    min-height: 39px;
    border: none;
    border-bottom: 1px solid #989a9b !important;
    z-index: 999;
}

.toolbar-options .dropdown > div:first-child a {
    color: $black;
    text-decoration: none;
    display: inline-block;
    @extend %text-no-overflow;
    border: none;
    font-size: 0.923em;
    background-color: #dddfe8;
    border-radius: 0;
    line-height: 1;
}

.navbar-default .main-nav-cont > .nav.nav-pills {
    > li:hover,
    > li.active {
        > a:before {
            content: ' ';
            display: block;
            width: 100%;
            height: 5px;
            position: absolute;
            background-color: #6499b7;
            top: 0;
            left: 0;
        }
    }
}

///////////////////////////// DropDown - navigation //////////////////////
.dropdown-content {
    padding: 10px 0 !important;
    display: inline-block;
    li {
        a:before {
            display: none !important;
        }
    }
}

ul.dropdown-sub-menu {
    display: none;
}

.navbar-toolbar .time-date-filter-timer-wrapper {
    position: relative;
    float: right;
    top: -1px;
}

.dropdown-sub.dropdown .dropdown-menu {
    display: block;
    float: right;
    position: relative;
}

.navbar-nav > li > .dropdown-menu .dropdown-sub-content li a {
    transform: scale(0.8);
    padding: 0px;
}

.dropdown-menu {
    margin: 0;
}

.dropdown-sub.dropdown .dropdown-menu {
    background-color: #383e3e;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
    background-color: #383e3e !important;
    border-color: #337ab7;
}

.navbar-toolbar .time-settings-wrapper {
    z-index: 9999;
    background-color: $white;
    width: 250px;
    border: 1px solid #3e3e3e;
    font-size: 12px;
    top: 30px;
    left: 100%;
}

.navbar-toolbar .time-settings-wrapper a {
    color: #333;
}

.navbar.navbar-toolbar .row > div {
    padding: 0px 15px 0;
}

.navbar.navbar-toolbar .row > div.options-icons-wrapper {
    margin-top: 9px;
}

.navbar-toolbar .time-frame-selector.nav.nav-pills {
    margin-top: 7px;
}

.navbar-toolbar .time-date-filter-timer-trigger {
    position: relative;
    float: right;
    cursor: default;
}

.navbar-toolbar .time-date-filter-timer-wrapper {
    position: relative;
    float: right;
}

.navbar-toolbar .date-range-picker > div {
    margin-bottom: 10px;
}

.navbar-toolbar .date-range-picker-apply {
    cursor: pointer;
    float: right;
    margin-bottom: 0 !important;
    color: #296c96;
    padding: 10px;
}

.navbar-toolbar .date-range-picker-apply.disabled {
    opacity: 0.38;
    cursor: not-allowed;
}

.navbar-toolbar .time-range-filter-refresh {
    clear: both;
    float: right;
    cursor: pointer;
}

.navbar-toolbar .time-settings-wrapper li.st-accordion__item {
    padding: 10px;
}

.time-date-filter-timer-trigger .clock {
    height: 22px;
    width: 22px;
    opacity: 0.7;
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin: 0 10px;
    cursor: pointer;
    background-image: url('../static-assets/images/time_filter_icon.svg');
}

.time-date-filter-timer-trigger .clock:hover {
    opacity: 1;
}

.main-nav-cont .activities-search-items:not(.open) .nav.nav-pills > li[class^='nav-item']:hover {
    background-color: var(--activity-log-drawer-tab-hover-bg-color);
    box-shadow: none;
}

.navbar.main-nav-cont .row > .general-switch-btn div:nth-child(2) {
    padding: 0 !important;
}

.main-nav-cont .time-settings-wrapper:before {
    content: '';
    display: block;
    border: 8px solid transparent;
    position: absolute;
    top: -17px;
    right: 10px;
    border-bottom-color: #3e3e3e;
}

.main-nav-cont .time-settings-wrapper:after {
    content: '';
    display: block;
    border: 8px solid transparent;
    position: absolute;
    top: -16px;
    right: 10px;
    border-bottom-color: $white;
}

.toolbar-options .time-date-filter-timer-toolber {
    margin-right: 0;
}

.time-date-filter-timer-toolber:after {
    content: '';
    display: block;
    clear: both;
}

.navbar-toolbar .time-frame-selector.nav.nav-pills > li > a {
    background-color: transparent;
    border: 1px solid #808284;
    border-radius: 3px;
    font-size: 0.9em;
    color: $black;
    padding: 4px;
}

.navbar-toolbar .time-frame-selector.nav.nav-pills > li.active > a,
.navbar-toolbar .nav.nav-pills > li.active > a:focus,
.navbar-toolbar .nav.nav-pills > li.active > a:hover {
    background-color: #6d6e70;
    color: $white;
}

.navbar-toolbar .time-frame-selector.nav.nav-pills > li > a:hover {
    color: $black;
    cursor: pointer;
}

.navbar-toolbar .dd-toolbar {
    margin-left: -15px;
    width: 230px;
}

.navbar-toolbar .dd-toolbar .dropdown > div > a {
    position: relative;
    background-color: #dddfe8;
    display: block;
    padding: 7px 25px 7px 7px;
    text-decoration: none;
    font-size: 1.3em;
    @extend %text-no-overflow;
    color: #333;
    border: none;
    line-height: 1.5;
    height: inherit;
}

.time-date-filter-timer-trigger > div:first-child {
    font-size: 0.9em;
    line-height: 2;
}

.navbar-toolbar .toolbar-options .time-frame-selector.nav.nav-pills {
    margin-top: 0;
}

.navbar.navbar-toolbar .row .time-date-filter-timer .general-switch-btn .pull-left:nth-child(2) {
    padding: 0 !important;
    display: block !important;
}

.time-date-filter-timer-trigger > div:first-child {
    font-size: 0.9em;
    line-height: 2;
}

.general-switch-btn .pull-left {
    padding: 0px 5px !important;
}

.general-switch-btn {
    margin-right: 5px;
    font-size: 0.9em;
}

.navbar-toolbar .time-settings-wrapper li.st-accordion__item:nth-child(odd) .date-range-picker-apply {
    background-color: #d1d1d1;
}

.nav-pills > li + li {
    margin-left: 8px;
}

.navbar-brand {
    line-height: 34px;
}

.navbar-default .nav.nav-pills li a {
    background-repeat: no-repeat;
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar-default .main-nav-cont > .nav.nav-pills > li:hover > a:before,
    .navbar-default .main-nav-cont > .nav.nav-pills > li.active > a:before {
        display: none;
    }

    .navbar-default .nav.nav-pills .dropdown-content li a:before {
        left: 0;
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@media (max-width: 767px) {
    .navbar-default .main-nav-cont > .nav.nav-pills > li:hover > a:before,
    .navbar-default .main-nav-cont > .nav.nav-pills > li.active > a:before {
        display: none;
    }
    .navbar-default .navbar-nav > .active > a,
    .navbar-default .navbar-nav > .active > a:focus,
    .navbar-default .navbar-nav li:hover > a {
        background-color: transparent !important;
        text-decoration: underline;
    }

    .dropdown-content {
        background-color: transparent;
    }

    .navbar-default .nav-pills > li .caret {
        display: inline-block;
    }
}
