.itemListActionForm,
.goToLinkButton {
    display: inline-block;
    text-align: left;
    position: relative;
}

.itemListActionForm .popover {
    z-index: 450;
}

.itemListTakeAction {
    width: 255px;
    height: 108px;
}

.itemListActionForm_box {
    box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.25);
    z-index: 35;
}

.itemListActionForm_box:before {
    content: '';
    display: block;
    border: 8px solid transparent;
    position: absolute;
    top: -17px;
    right: 38px;
    border-bottom-color: #787878;
}

.itemListActionForm_box:after {
    content: '';
    display: block;
    border: 8px solid transparent;
    position: absolute;
    top: -16px;
    right: 38px;
    border-bottom-color: #f2f2f3;
}

.itemListActionForm_trigger,
.goToLinkButton {
    color: #6d6e71;
    font-size: 12px;
    font-weight: 400;
    line-height: 8px;
    text-align: right;
    cursor: pointer;
}

.itemListActionForm_trigger span,
.goToLinkButton span {
    text-decoration: underline;
    color: var(--link-text-color);
}

.goToLinkButton {
    margin-left: 5px;
}

.itemListActionForm_id {
    white-space: nowrap;
    color: #3a3a3a;
    font-size: 12px;
    font-weight: bold;
    line-height: 8px;
    text-align: left;
    background-color: #f2f2f3;
    padding: 8px 7px 8px 7px;
}

.itemListActionForm_copy {
    color: #296d97;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    border-top: 1px solid #bbbbbb;
    padding: 4px 13px 9px 19px;
    z-index: 35;
}

.itemListActionForm_copy:hover {
    background-color: #256d98;
    color: $white;
    cursor: pointer;
}

.itemListActionForm_copy:before {
    content: ' ';
    display: inline-block;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    left: -10px;
    top: 8px;
    position: relative;
    background-image: url('../static-assets/images/copy-to-clipboard_icon.svg');
}

.itemListActionForm_copy:hover:before {
    content: ' ';
    display: inline-block;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    left: -10px;
    top: 8px;
    position: relative;
    background-image: url('../static-assets/images/copy-to-clipboard_icon_over_selected.svg');
}

.itemListActionForm_open,
.itemListActionForm_extra-details {
    color: #be1e2d;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    border-top: 1px solid #bbbbbb;
    padding: 4px 13px 9px 19px;
    z-index: 35;
}

.itemListActionForm_extra-details {
    color: #296d97;
    padding-top: 10px;
}

.itemListActionForm_open:hover {
    background-color: #bf1829;
    color: $white;
    cursor: pointer;
}

.itemListActionForm_open:before {
    content: ' ';
    display: inline-block;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    left: -10px;
    top: 8px;
    position: relative;
    background-image: url('../static-assets/images/open-ticket_icon.svg');
}

.itemListActionForm_open:hover:before {
    content: ' ';
    display: inline-block;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    left: -10px;
    top: 8px;
    position: relative;
    background-image: url('../static-assets/images/open-ticket_icon_over_selected.svg');
}

.itemListActionForm_extra-details:hover {
    background-color: #256d98;
    color: $white;
    cursor: pointer;
}
