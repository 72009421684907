/* Old Colors */
$white: #fff;
$black: #000;

$transparent-black-1: rgba(0, 0, 0, 0.22);
$transparent-black-2: rgba(0, 0, 0, 0.87);

$dark-gray-100: #1f2022;
$dark-gray-200: #1e1e1e;
$dark-gray-300: #4b4b4b;
$dark-gray-400: #212121;

$gray-100: #fdfdfd;
$gray-200: #f8f8f8;
$gray-300: #f4f4f4;
$gray-400: #efefef;
$gray-500: #e7e7eb;
$gray-600: #e6e9ef;
$gray-700: #e2e2e2;
$gray-800: #c7c6c7;
$gray-900: #999899;

$red-100: #F41414;
$red-200: #ea0000;
$red-300: #c0202b;
$red-400: #ac1d27;

$green-100: #00c349;
$green-200: #19b400;

$blue-100: #00c5f3;
$blue-200: #296c96;
$blue-300: #537c96;
$blue-400: #5a86a3;
$blue-500: #6596b6;

$yellow-100: #fff677;
$yellow-200: #ffd600;
$yellow-300: #ddba00;
$yellow-400: #ff9800;

/* Components Colors */
$spy-scroll-active-item-bg-color: $dark-gray-300;
$active-color: $green-200;
$passive-color: $yellow-200;
$passive-text-color: $yellow-300;
$disable-color: $dark-gray-300;
$off-color: $red-200;
$out-of-plan-color: $gray-900;
$error-color: $red-100;
$success-color: $green-100;
$in-progress-color: #66adb1;

/* New Colors */
$new_white: #ffffff;
$new_black: #000000;

$new_dark_gray_100: #3c4144;
$new_dark_gray_200: #3c3b3a;
$new_dark_gray_300: #364145;
$new_dark_gray_400: #303b3f;
$new_dark_gray_500: #080809;
$new_dark_gray_600: #1f2023;
$new_dark_gray_700: #475a61;
$new_dark_gray_800: #253135;
$new_dark_gray_900: #202020;
$new_dark_gray_1000: #484a4d;

$new_gray_100: #f5f6f8;
$new_gray_200: #e9f1f3;
$new_gray_300: #f8f8f8;
$new_gray_400: #f3f7f9;
$new_gray_500: #efefef;
$new_gray_600: #d0d2d3;
$new_gray_700: #666f72;
$new_gray_800: #595b5f;
$new_gray_900: #353639;
$new_gray_1000: #cbd4db;

$new_blue_100: #88c8e9;
$new_blue_200: #2980b9;
$new_blue_300: #2b6df2;
$new_blue_400: #264769;
$new_blue_500: #3d5b79;
$new_blue_600: #294a6b;
$new_blue_700: #5f7890;
$new_blue_800: #2555d1;
$new_blue_900: #355cd5;

$new_yellow_100: #ffd600;
$new_yellow_200: #ffb549;
$new_yellow_300: #ffe5bd;

$new_red_100: #ff2b44;

/*** Transparent Colors ***/
$new_transparent_white_1: rgba($new_white, 0.3);

$new_transparent_dark_gray_1: rgba($new_dark_gray_100, 0.2);

/** Icons **/
$add-icon-disabled-bg-color: #ededed;
$add-icon-disabled-bg-color-dark: #333333;
