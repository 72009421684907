.loading-bar {
    $animation-duration: 3s;
    $bar-height: 4px;
    position: absolute;
    width: 100%;
    height: $bar-height + 5px; // for visible box-shadow (glow effect)
    overflow: hidden;
    z-index: 10000;

    .subline {
        position: absolute;
        background: linear-gradient(270deg, #3288c0 40%, #69d8e7 80%);
        box-shadow: 0px 2px 5px rgba(110, 255, 246, 0.7);
        height: $bar-height;
        border-radius: 6px;
    }
    .inc {
        animation: increase $animation-duration infinite;
    }
    .dec {
        animation: decrease $animation-duration 0.5s infinite;
    }

    @keyframes increase {
        from {
            left: -5%;
            width: 5%;
        }
        to {
            left: 130%;
            width: 100%;
        }
    }
    @keyframes decrease {
        from {
            left: -80%;
            width: 80%;
        }
        to {
            left: 110%;
            width: 10%;
        }
    }
}
