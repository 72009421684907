/***************
    clear fix
****************/
%clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

/* ---- Example how to use -------
.wrap {
    @extend  %clearfix
}
*/

.u-italic {
    font-style: italic;
}
