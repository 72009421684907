.activities-expand {
    position: absolute;
    background: url('../static-assets/images/widget-maximize.svg') no-repeat center/20px transparent;
    top: 15px;
    right: 50px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.activities-viewer-wrapper.top-to-bottom-grid-wrapper .list-wrapper {
    margin-top: 10px;
    height: calc(100% - 67px);
    border: none;
    position: relative;
    max-height: 100%;
}

.activities-viewer-wrapper.top-to-bottom-grid-wrapper .list-wrapper .redux-infinite-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
}

.activities-viewer-wrapper.top-to-bottom-grid-wrapper .list-wrapper .list-content {
    height: calc(100% - 33px);
    overflow: auto;
}

.activities-viewer-wrapper.top-to-bottom-grid-wrapper .list-row .list-cell:first-child:before {
    display: none;
}

.activities-viewer-wrapper.top-to-bottom-grid-wrapper .list-row .list-cell:first-child {
    padding-left: 10px;
    position: relative;
}

.activities-viewer-wrapper.top-to-bottom-grid-wrapper .list-header .list-row .list-cell:after {
    display: none;
}

.top-to-bottom-grid-wrapper.activities-viewer-wrapper .st-accordion__item.expand {
    background-color: transparent !important;
}

.top-to-bottom-grid-wrapper.activities-viewer-wrapper .st-accordion__item:hover {
    background-color: transparent !important;
}

.top-to-bottom-grid-wrapper.activities-viewer-wrapper .st-accordion__item .st-accordion__link {
    color: inherit;
}

.top-to-bottom-grid-wrapper.activities-viewer-wrapper .st-accordion__item:before {
    content: '';
    display: block;
    border: 3px solid transparent;
    position: absolute;
    top: 4px;
    border-left: 5px solid $black;
    left: 2px;
    transition: 0.1s;
}

.top-to-bottom-grid-wrapper.activities-viewer-wrapper .st-accordion__item.expand:before {
    transform: rotate(90deg);
}

.activities-viewer-wrapper .alerts-slider > h1.title .loader {
    display: inline-block;
    margin-left: 15px;
    width: 15px;
    height: 15px;
    margin-top: 0px;
}

.activities-item {
    margin-bottom: 15px;
    padding: 5px;
    width: 340px;
    max-width: 100%;
    background-color: var(--card-bg-color);
    border-radius: 4px;
    box-shadow: var(--card-box-shadow);
    position: relative;
    color: var(--text-color);

    &:first-child {
        margin-top: 5px;
    }
}

.activities-item:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: -13px;
    width: 0;
    background-color: $white;
    left: -12px;
}

.activity-time {
    position: absolute;
    left: 0;
    top: -20px;
    padding: 0px 5px;
    font-weight: bold;
}

.activities-item .activities-item-header:before {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 4px;
    height: 17px;
    width: 17px;
    background-color: #6e6e6e;
    background-repeat: no-repeat;
}

.activities-item.IN_PROCESS .activities-item-header:before {
    background-image: url('../static-assets/images/pending_indication.svg');
    background-color: transparent !important;
}

.activities-item.SUCCESS .activities-item-header:before {
    background-image: url('../static-assets/images/success_indication.svg');
    background-color: transparent !important;
}

.activities-item.FAIL .activities-item-header:before {
    background-image: url('../static-assets/images/failed-red-icon.svg');
    background-color: transparent !important;
}

.activities-item .tracking-id {
    font-size: 1.077em;
    font-weight: bold;
    margin-left: 20px;
}

.activities-item-details {
    font-size: 0.78em;
    color: var(--text-color);
}

.activities-item-bottom-details {
    font-size: 0.85em;
}

.activities-item.IN_PROCESS {
    border-left: 3px solid #256d98;
}

.activities-item.SUCCESS {
    border-left: 3px solid #00b168;
}

.activities-item.FAIL {
    border-left: 3px solid #c94754;
}

div.activity-type > div {
    /* display:block; white-space:nowrap; overflow:hidden; text-overflow:ellipsis; cursor:pointer; */
}

.activities-item-details .idContainerBtn {
    cursor: pointer;
}

.activities-item-details .idContainer.show {
    padding-left: 10px;
}

.activities-item-details .idContainerBtn:before {
    content: '';
    display: inline-block;
    border: 3px solid transparent;
    border-left: 5px solid #333;
    float: left;
    margin-top: 3px;
}

.activities-item-details .idContainerBtn.expanded:before {
    border-left: 3px solid transparent;
    border-top: 5px solid #333;
    margin-right: 5px;
    margin-top: 4px;
}

div.activity-type.expanded:before {
    transform: rotate(90deg) translate(1px, 1px);
}

div.activity-type.expanded {
    display: block;
    white-space: normal;
}

div.activity-type.expanded .show {
    margin-left: 10px;
}

.activities-item .modal-body {
    max-height: 200px;
    overflow: auto;
}

/************************************
        ActivityLog - Header
 *************************************/

.activity-log-item {
    padding: 10px 18px;
    color: var(--tooltip-text-color);
    display: flex;
    align-items: end;
    background-color: var(--tooltip-bg-color);
    border-radius: var(--card-border-radius);
    z-index: 4431;
    cursor: initial;
}
.activity-log-item-status-container {
    display: flex;
    align-items: center;
    font-size: 11px;
}
.activity-log-item-status-icon {
    display: inline-block;
    height: 18px;
    width: 17px; /* margin-top: 4px; */
    margin-right: 10px;
}
.activity-log-item-status-icon.in_process {
    background: url('../static-assets/images/pending_indication.svg') no-repeat;
    height: 23px;
    width: 18px;
}
.activity-log-item-status-icon.success {
    background: url('../static-assets/images/success_indication.svg') no-repeat;
    width: 18px;
    height: 18px;
    margin-right: 6px;
}
.activity-log-item-status-icon.fail {
    background: url('../static-assets/images/failed-red-icon.svg') no-repeat;
}
.activity-log-item-content {
    position: relative;
    width: 100%;
}
.activity-log-item-header {
    font-size: 14.3px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}
.activity-log-item-header-escalate button {
    position: absolute;
    right: 0;
    top: -3px;
    color: #6499b7;
    background: var(--button-bg-color);
    border: 1px solid #e6e6e6;
    padding: 3px 16px;
    font-size: 12px;
}
.activity-log-item-header-escalate button:hover {
    background: #dbdbdb;
}
.activity-log-item-header-status {
    color: #fe7100;
    display: inline-block;
    font-weight: 700;
    padding-right: 5px;
    font-size: 12px;
}
.activity-log-item-header-status-date {
    padding-left: 5px;
    font-weight: 700;
    font-size: 11px;
}
.activity-log-item-header-status.in_process {
    color: $in-progress-color;
}
.activity-log-item-header-status.fail {
    color: $error-color;
}
.activity-log-item-header-status.success {
    color: $success-color;
}

.activity-log-item-user-icon {
    background: url('../static-assets/images/user-icon-small.svg') no-repeat;
    display: inline-block;
    height: 20px;
    margin-right: 10px;
    min-width: 18px;
}

.activity-type {
    display: flex;
    align-items: end;
    .link {
        cursor: pointer;
        text-decoration: underline;
        color: #2a6d96;
    }
}

.activity-description {
    flex-direction: column;
    align-items: start;
}

.activity-log-item-details .activity-type > div {
    width: 100%;
    word-wrap: break-word;
}

.activity-log-item-details {
    width: 100%;
}
.activity-log-item-details .activity-type {
    color: var(--text-secondary-color);
    padding-bottom: 10px;
    font-size: 11px;
}
.activity-log-item-details .activity-type .user-email {
    font-weight: 600;
    padding-right: 5px;
    color: var(--text-color);
}
.activity-log-item-details .id-container {
    overflow: hidden;
    white-space: nowrap;
    font-size: 11px;
}
.activity-log-item-details .id-container-label {
    font-weight: 700;
    margin-right: 14px;
    color: var(--activity-log-id-label);
    margin-left: 3px;
}

$light-blue: #f7fdff;
.activity-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $page-padding $page-padding 0 $page-padding;

    .header {
        display: flex;
        align-items: baseline;

        > .title,
        .sub-title {
            @extend %page-header-title;
            flex-shrink: 0;
        }

        .sub-title {
            font-size: 19.5px;
            margin-left: 10px;
            margin-right: 10px;
            @extend %text-no-overflow;
        }

        .filter-wrapper {
            position: relative;
            margin-left: auto;
            display: flex;

            .filter-header {
                display: flex;
                align-items: center;
                cursor: pointer;

                .information {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-end;
                    border-bottom: 1px solid #6da7c8;

                    .summary,
                    .application,
                    .connector,
                    .date-time {
                        font-size: 16px;
                        line-height: 19px;
                    }

                    .summary,
                    .application,
                    .date-time {
                        margin: 0 5px;
                        font-weight: bold;
                    }

                    .connector {
                        margin: 0 3px;
                        font-weight: 400;
                    }
                }

                .arrow {
                    margin-left: 5px;
                    width: 16px;
                    height: 11px;
                    border: none;

                    &.down {
                        background: url(../static-assets/images/arrow-down.png) center center;
                        background-size: cover;
                    }

                    &.up {
                        background: url(../static-assets/images/arrow-up.png) center center;
                        background-size: cover;
                    }
                }
            }

            .filter-body {
                position: absolute;
                right: -12px;
                top: 25px;
                z-index: 1000;
                width: 400px;
                background-color: var(--card-bg-color);
                box-shadow: $filters-box-shadow;
                border-radius: var(--card-border-radius);
                padding: 20px 20px 10px 20px;

                // margin-left: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                > div {
                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .action-group {
                    display: flex;
                    margin: auto;
                }

                .time-date-control-wrapper {
                    margin-top: 10px;
                    .rdt {
                        width: 170px;
                    }
                }

                .Select {
                    &.application,
                    &.country {
                        width: 360px;
                    }

                    .Select-menu-outer {
                        position: relative;
                    }
                }

                .filter-footer {
                    width: 100%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    padding: 10px 0;

                    button {
                        border: none;
                        width: 70px;
                        height: 25px;
                    }

                    .discard,
                    .clear {
                        background-color: inherit;
                        color: #6da7c8;
                    }

                    .clear {
                        width: auto;
                        margin-right: auto;
                        align-self: flex-start;
                    }
                    .apply {
                        color: $light-blue;
                        background-color: #6da7c8;
                        border: 1px solid $light-blue;
                        outline: 1px solid #6da7c8;
                        margin-left: 10px;
                    }
                }

                .input-wrapper {
                    margin-bottom: 5px;
                    width: 100%;
                    input {
                        width: 100%;
                    }
                }

                .action-group {
                    margin-bottom: 5px;
                }
            }
        }
    }

    .activity-page-chart {
        padding-top: 10px;
        height: 150px;
    }
}

.activity-list-wrapper {
    height: calc(100% - 134px);
    background-color: var(--table-bg-color);
    border-radius: var(--table-border-radius);
    padding-left: var(--table-side-gap);
    padding-right: var(--table-side-gap);
    overflow: hidden;

    .sort-header {
        display: flex;
        padding-left: 20px;
        padding-right: 16px;
        border-bottom: 1px solid var(--card-separator-color);

        .property {
            color: var(--text-color);
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            padding: 10px 0;

            &.sortable {
                display: flex;
                cursor: pointer;

                .sort-icon {
                    content: url(../static-assets/images/sort-icon-default.svg);
                    display: inline-block;
                }

                &.active.asc {
                    .sort-icon {
                        content: url(../static-assets/images/sort-icon-arrow.svg);
                    }
                }

                &.active.desc {
                    .sort-icon {
                        content: url(../static-assets/images/sort-icon-arrow.svg);
                        transform: rotate(180deg);
                    }
                }
            }
            &.trackingId {
                width: calc(15% + 34px); // extra pixels is because of trackingIdCopy
            }
        }
    }

    .activity-list {
        height: calc(100% - 43px);
        overflow-y: auto;

        .ReactVirtualized__Grid.ReactVirtualized__List {
            overflow: hidden !important;
            overflow-y: auto !important;
            will-change: auto !important;
        }

        .property-with-flag {
            display: flex;
            flex: 1.5;
            max-width: calc(60%);
        }

        .activity {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;

            .property-value {
                margin-right: 10px;
                @extend %text-no-overflow;
                min-width: 100px;
                @media (max-width: 1300px) {
                    min-width: 70px;
                }
            }

            .property.withLabel {
                display: flex;
                justify-content: space-between;
                .property-value {
                    margin-right: 0;
                    padding-right: 0;
                }
            }

            .property-flag {
                min-width: 40px;
                flex: 1.5;
            }

            .source-info {
                .source-info-icon {
                    width: 16px;
                    height: 16px;
                    background: url('../static-assets/images/i_tooltip_icon.svg') no-repeat center center;
                    background-size: cover;
                    margin: 0 15px;
                }
            }

            .more-icon {
                width: 20px;
                height: 20px;
                background: url('../static-assets/images/more.svg');
                transition: all 0.2s;
            }

            &.open {
                box-shadow: 0 0 13px 5px rgba(42, 42, 42, 0.26);

                .more-icon {
                    transform: rotate(180deg);
                }
            }

            .details-wrapper {
                background-color: var(--table-expanded-row-bg-color);
                display: flex;
                flex-direction: column;

                .details-body {
                    display: flex;
                    flex-wrap: wrap;

                    .details {
                        flex: 1;
                        min-width: 390px;
                        @media (max-width: 1650px) {
                            min-width: 300px;
                        }
                        @media (max-width: 1300px) {
                            min-width: 200px;
                        }
                        display: flex;
                        flex-direction: column;
                        background-color: var(--card-bg-color);
                        border-radius: var(--card-border-radius);
                        padding: 15px 20px 10px 20px;
                        margin: 5px;

                        .details-header {
                            display: flex;
                            padding: 5px 25px;
                            font-weight: 500;
                            color: var(--text-color);

                            .details-header-parameter {
                                flex: 1.5;
                            }

                            .details-header-right {
                                display: flex;
                                flex: 2;
                                margin-left: auto;

                                .details-header-total {
                                    margin-left: auto;
                                    display: flex;

                                    .details-header-total-label {
                                        color: var(--text-color);
                                        font-size: 14px;
                                        font-weight: 400;
                                    }

                                    .details-header-total-count {
                                        color: #00c0ef;
                                        font-size: 20px;
                                        font-weight: 500;
                                        margin-left: 5px;
                                        line-height: 0.9;
                                    }
                                }
                            }
                        }

                        .details-list {
                            .details-item {
                                display: flex;
                                padding: 0 25px;

                                margin-bottom: 5px;
                                height: 45px;
                                align-items: center;
                                background-color: var(--card-inner-bg-color);
                                border: 1px solid var(--card-separator-color);

                                &.other {
                                    padding-left: 65px;
                                }

                                .dropdown {
                                    margin-right: 10px;

                                    .dropdown-toggle {
                                        padding: 3px 6px;
                                        background-color: var(--dropdown-toggle-bg-color);

                                        .hamburger {
                                            .slice {
                                                height: 4px;
                                                width: 16px;
                                                border-radius: 2px;
                                                margin: 2px 0;
                                                background-color: var(--dropdown-toggle-hamburger-slice-bg-color);
                                            }
                                        }
                                    }
                                    .dropdown-menu {
                                        border-radius: 12px;
                                        border: 1px solid var(--dropdown-border-color);
                                        background-color: var(--dropdown-list-bg-color);
                                        overflow: hidden;

                                        .divider {
                                            display: none;
                                        }

                                        li {
                                            &:not(:last-child) {
                                                border-bottom: 1px solid var(--dropdown-item-border-color);
                                            }

                                            a {
                                                color: var(--dropdown-item-text-color);

                                                &:hover,
                                                &:focus {
                                                    background-color: var(--dropdown-item-hover-bg-color);
                                                }
                                            }
                                        }
                                    }
                                }

                                .events-bar {
                                    min-width: 1%;
                                    .header {
                                        display: flex;

                                        .percent {
                                            margin-left: auto;
                                        }
                                    }
                                }

                                .events-bar .header .events,
                                .details-item-parameter,
                                .details-header-parameter {
                                    font-weight: 500;
                                }

                                .details-item-parameter {
                                    min-width: 1%;
                                    flex: 1.5;
                                }

                                .details-item-events {
                                    min-width: 1%;
                                    flex: 2;
                                }
                            }
                        }
                    }
                    .details.wide {
                        flex: 100%;
                    }
                    .half-width {
                        flex: 50%;
                        max-width: calc(50% - 10px);
                        display: flex;
                    }
                }

                .show-events,
                button.notify-user,
                .http-request-sample {
                    margin: 10px 25px 10px auto;
                    padding: 5px 10px;
                    background-color: #6da7c8;
                    border: none;
                    color: $white;
                }

                .buttons-container {
                    padding: 20px 0 10px 0;
                    display: flex;
                    flex: 100%;
                    margin-left: auto;
                }

                .details-box {
                    padding: 10px 20px;
                    background-color: inherit;
                    display: flex;
                    margin: 5px;
                    .item {
                        display: flex;
                        margin-right: 20px;
                        &:last-child {
                            margin-right: 0;
                        }
                        .title {
                            font-weight: 700;
                            color: var(--text-color);
                        }
                        .content {
                            margin-left: 5px;
                            ul {
                                display: flex;
                                li {
                                    margin-right: 10px;
                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .activity-header {
            background-color: var(--table-bg-color);
            height: $list-small-item-height;
            padding-left: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .property {
                min-width: 0;
            }

            .events-bar {
                width: 70%;

                .header {
                    display: flex;

                    .events {
                        font-weight: 500;
                    }

                    .percent {
                        margin-left: auto;
                    }
                }
            }
        }
    }

    .more-col {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5%;
    }
}

.filter-body {
    .action-group {
        .action-group-item {
            margin-right: 15px;
            margin-top: 17px;
            label {
                margin-bottom: 0;
                font-size: 13px;
            }
            &:first-child label {
                color: #ff675d;
            }
            &:last-child label {
                color: #6dc3c8;
            }
        }
    }
}

.activity-logs {
    &-escalate-modal .modal-content {
        max-width: 450px;
    }

    .header .filter-wrapper {
        align-items: center;

        .refresh-icon {
            margin-right: 10px;
        }
    }

    .filter-body {
        .action-group-item {
            label {
                //padding-left: 10px;
                //background: transparent no-repeat 20px 20px/20px;
                //background-image: url('../static-assets/images/pending_indication.svg');
            }
            label[for='IN_PROCESS'] {
                color: $in-progress-color;
            }
            label[for='SUCCESS'] {
                color: $success-color;
            }
            label[for='FAIL'] {
                color: $error-color;
            }
        }
    }

    .total-results {
        margin-right: 10px;
    }

    .refresh,
    .export-button {
        margin: 0 10px 0 0;
    }
    .refresh {
        background: transparent url('../static-assets/images/refresh_icon.png') no-repeat center;
        border: none;
    }

    .activity-list-wrapper {
        height: calc(100% - 70px - 130px) !important;

        .activity-log-details {
            width: 100%;
            font-size: 13px;
            padding: 15px 20px;

            .details-item {
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                .details-item-label {
                    font-weight: 600;
                    margin-right: 5px;
                }

                .country-flag {
                    display: flex;
                    align-items: center;
                    margin-left: 5px;

                    .country-flag-icon {
                        margin-right: 10px;
                    }
                }
                &.diff-summary {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }

        .status {
            width: 6%;
            max-width: 70px;
            margin-right: 2%;
            min-height: 24px !important;
            min-width: 24px !important;
            background: transparent no-repeat 25%/24px;
            &.IN_PROCESS {
                background-image: url('../static-assets/images/pending_indication.svg');
            }
            &.SUCCESS {
                background-image: url('../static-assets/images/success_indication.svg');
            }
            &.FAIL {
                background-image: url('../static-assets/images/failed-red-icon.svg');
                cursor: pointer;
            }
        }
        .startDate {
            width: 10%;
        }
        .userEmail {
            width: 15%;
        }
        .processTypeText {
            width: 33%;
        }
        .referenceId {
            width: 15%;
            &.SECURITY_EVENT {
                background: url('../static-assets/images/security-category-icon.svg') no-repeat left center/20px;
                padding-left: 25px;
            }
            &.APPLICATION,
            .APPLICATION {
                background: url('../static-assets/images/destination-category-icon.svg') no-repeat left center/22px;
                padding-left: 26px;
            }
            .ACCOUNT {
                background: url('../static-assets/images/icon-accounts.svg') no-repeat left center/18px;
                padding-left: 26px;
            }
            .SP {
                background: url('../static-assets/images/icon-service-providers.svg') no-repeat left center/18px;
                padding-left: 26px;
            }
        }
        .trackingId {
            width: 15%;
        }
        .trackingIdCopy .property-value {
            min-width: auto;
        }
        .trackingId,
        .trackingIdCopy {
            .property-value {
                margin-right: 0;
                padding-right: 0;
            }
        }
        .link {
            cursor: pointer;
            text-decoration: underline;
            color: #2a6d96;
        }

        .no-available-data {
            margin-top: 100px;
        }
    }
}
