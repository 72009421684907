.items-header-new .management-and-refresh,
.security-events .security-events-header .header-right {
    .pagination-wrapper {
        display: flex;
        flex-wrap: wrap;

        .per-page-wrapper,
        .page-select-wrapper {
            margin: 0 8px;

            .Select {
                min-width: unset;
                width: 150px;
                margin: 0 5px;
            }
        }

        .page-select-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            .first {
                background: url(../static-assets/images/pagination-last.svg) no-repeat center center;
                transform: rotate(180deg);
                width: 14px;
                height: 11px;
                border: none;
            }

            .previous {
                background: url(../static-assets/images/pagination-next.svg) no-repeat center center;
                transform: rotate(180deg);
                width: 10px;
                height: 11px;
                border: none;
            }

            .last {
                background: url(../static-assets/images/pagination-last.svg) no-repeat center center;
                width: 14px;
                height: 11px;
                border: none;
            }

            .next {
                background: url(../static-assets/images/pagination-next.svg) no-repeat center center;
                width: 10px;
                height: 11px;
                border: none;
            }
        }
    }
}

@media (max-width: 1366px) {
    .items-header-new .management-and-refresh {
        .pagination-wrapper {
            .per-page-wrapper,
            .page-select-wrapper {
                margin: 0 5px;
            }
            .page-select-wrapper .Select {
                min-width: unset;
                max-width: 120px;
            }
        }
        .total_results {
            margin: 5px 10px;
        }
    }
}
