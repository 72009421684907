.copy-event-tooltip {
    button {
        display: block;
        width: 100%;
        padding: 3px 10px;
        font-size: 13px;
        text-align: left;
        color: var(--text-color);
        background-color: unset;
        border: none;

        &:hover {
            background-color: var(--tooltip-button-hover-bg-color);
            border-radius: 4px;
        }

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}
