$allow_padding_item: 5px 65px 5px 20px;
$allow_padding_item_uri_min_width: 226px;
%allowed_file_item_button {
    width: 16px;
    height: 16px;
    color: $white;
    border: none;
}

.allowed-file-ext {
    .gen-list-mgmt-header {
        .refresh-button {
            margin: 0 10px;
        }
        .gen-list-mgmt-add-item {
            margin-left: 0;
        }
    }

    /***********************
        list-headers
    ***********************/
    .gen-list-headers {
        display: flex;
        padding: $allow_padding_item;

        .gen-list-headers-regular {
            flex: 1;
        }
    }
}
