.new-design .spy-scroll-list-item.details a {
    &:before {
        content: url(../static-assets/images/icon-details.svg);
    }

    &:hover:before,
    &.active::before {
        content: url(../static-assets/images/icon-details-over.svg);
    }
}

.new-design .spy-scroll-list-item.general a:before {
    content: url(../static-assets/images/icon-general.svg);
}

.new-design .spy-scroll-list-item.general a:hover:before,
.new-design .spy-scroll-list-item.general a.active::before,
.new-design .section#Applications_UpdateForm_General_Tab .section-header-title:before {
    content: url(../static-assets/images/icon-general-over.svg);
}

.new-design .spy-scroll-list-item.networks a:before {
    content: url(../static-assets/images/icon-networks.svg);
}

.new-design .spy-scroll-list-item.networks a:hover:before,
.new-design .spy-scroll-list-item.networks a.active::before,
.new-design .section#Applications_UpdateForm_Network_Tab .section-header-title:before {
    content: url(../static-assets/images/icon-networks-over.svg);
}

.new-design .spy-scroll-list-item.cdn a:before {
    content: url(../static-assets/images/icon-cdn.svg);
}

.new-design .spy-scroll-list-item.cdn a:hover:before,
.new-design .spy-scroll-list-item.cdn a.active::before,
.new-design .section#Applications_UpdateForm_CDN_Tab .section-header-title:before {
    content: url(../static-assets/images/icon-cdn-over.svg);
}

.new-design .spy-scroll-list-item.alerts a:before {
    content: url(../static-assets/images/icon-alerts.svg);
}

.new-design .spy-scroll-list-item.alerts a:hover:before,
.new-design .spy-scroll-list-item.alerts a.active::before,
.new-design .section#Alerts_Section .section-header-title:before {
    content: url(../static-assets/images/icon-alerts-over.svg);
}

.new-design .spy-scroll-list-item.security a:before {
    content: url(../static-assets/images/icon-security.svg);
}

.new-design .spy-scroll-list-item.security a:hover:before,
.new-design .spy-scroll-list-item.security a.active::before,
.new-design .section#Security_Section .section-header-title:before,
.new-design .section#Applications_UpdateForm_Protections_Tab .section-header-title:before {
    content: url(../static-assets/images/icon-security-over.svg);
}

.new-design .spy-scroll-list-item.api-protection a:before {
    content: url(../static-assets/images/icon-api-protection.svg);
}

.new-design .section .section-header-title.api-protection:before,
.new-design .spy-scroll-list-item.api-protection a:hover:before,
.new-design .spy-scroll-list-item.api-protection a.active::before {
    content: url(../static-assets/images/icon-api-protection-over.svg);
}

.new-design .spy-scroll-list-item.access-control a:before {
    content: url(../static-assets/images/icon-access.svg);
}

.new-design .spy-scroll-list-item.access-control a:hover:before,
.new-design .spy-scroll-list-item.access-control a.active::before,
.new-design .section .section-header-title.access-control:before {
    content: url(../static-assets/images/icon-access-over.svg);
}

.new-design .spy-scroll-list-item.advanced-rules a:before {
    content: url(../static-assets/images/icon-rules.svg);
}

.new-design .spy-scroll-list-item.advanced-rules a:hover:before,
.new-design .spy-scroll-list-item.advanced-rules a.active::before,
.new-design .section .section-header-title.advanced-rules:before {
    content: url(../static-assets/images/icon-rules-over.svg);
}

.new-design .spy-scroll-list-item.operator a:before {
    content: url(../static-assets/images/icon-operator.svg);
}

.new-design .spy-scroll-list-item.operator a:hover:before,
.new-design .spy-scroll-list-item.operator a.active::before,
.new-design .section#Applications_UpdateForm_Operator_Tab .section-header-title:before,
.new-design .section .section-header-title.operator:before {
    content: url(../static-assets/images/icon-operator-over.svg);
}

.new-design .spy-scroll-list-item.settings a:before {
    content: url(../static-assets/images/icon-settings.svg);
}

.new-design .spy-scroll-list-item.settings a:hover:before,
.new-design .spy-scroll-list-item.settings a.active::before,
.new-design .section#Settings_Section .section-header-title:before {
    content: url(../static-assets/images/icon-settings-over.svg);
}

.new-design .spy-scroll-list-item.contact a:before {
    content: url(../static-assets/images/icon-contact.svg);
}

.new-design .spy-scroll-list-item.contact a:hover:before,
.new-design .spy-scroll-list-item.contact a.active::before,
.new-design .section#Contact_Section .section-header-title:before {
    content: url(../static-assets/images/icon-contact-over.svg);
}

.new-design .spy-scroll-list-item.bot-manager a:before {
    content: url(../static-assets/images/icon-bot-manager.svg);
}

.new-design .spy-scroll-list-item.bot-manager a:hover:before,
.new-design .spy-scroll-list-item.bot-manager a.active::before,
.new-design .section#BotSecurity_Section .section-header-title:before {
    content: url(../static-assets/images/icon-bot-manager-over.svg);
}

.new-design .spy-scroll-list-item.plans a:before {
    content: url(../static-assets/images/icon-plans.svg);
}

.new-design .spy-scroll-list-item.plans a:hover:before,
.new-design .spy-scroll-list-item.plans a.active::before,
.new-design .section .section-header-title.plans:before {
    content: url(../static-assets/images/icon-plans-over.svg);
}

.new-design .spy-scroll-list-item.service a:before {
    content: url(../static-assets/images/icon-plans.svg);
}

.new-design .spy-scroll-list-item.service a:hover:before,
.new-design .spy-scroll-list-item.service a.active::before,
.new-design .section .section-header-title.service:before {
    content: url(../static-assets/images/icon-plans-over.svg);
}

/* Configuration Pages */
.configuration-page-container {
    .sections-container {
        background-color: var(--page-bg-color);
        .sticky-item-header {
            /* that way we make sure that section's box-shadow isn't visible near the sticky header */
            margin: 0 -10px;
            padding: 10px 10px 5px 10px;
            border-bottom: none;
            background-color: var(--page-bg-color);
            &:after {
                content: '';
                display: block;
                padding-top: 10px;
                border-bottom: 1px solid #527b84;
            }
        }
    }
    .section {
        .accordion-wrapper {
            .Collapsible {
                border: none;
                border-radius: var(--section-border-radius);

                .Collapsible__trigger {
                    position: relative;
                    background-color: var(--section-bg-color);
                    border: none;
                    border-top-left-radius: var(--section-border-radius);
                    border-top-right-radius: var(--section-border-radius);
                    transition: none;

                    &:hover {
                        transition: none;

                        .accordion-category-header__icon-arrow-icon {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            background-color: var(--section-icon-hover-bg-color);
                        }
                    }

                    &.is-open {
                        border-bottom-color: var(--section-border-color);
                        background-color: var(--section-header-active-bg-color);
                        transition: none;

                        .accordion-category-header__icon-arrow-icon {
                            transform: rotate(270deg);
                        }
                    }

                    &.is-closed {
                        border-radius: var(--section-border-radius);
                        transition: border-radius 0.2s ease;
                        transition-delay: 0.2s; // wait for collapsible transition to finish

                        &:hover:not(.is-disabled) {
                            background-color: var(--section-header-hover-bg-color);
                        }

                        .accordion-category-header-body {
                            border-bottom-left-radius: var(--section-border-radius);
                            border-bottom-right-radius: var(--section-border-radius);
                        }
                    }

                    &.is-disabled {
                        box-shadow: none;
                    }
                }

                .Collapsible__contentOuter {
                    border: none;

                    .Collapsible__contentInner {
                        background-color: var(--section-bg-color);
                        border: none;
                        border-bottom-left-radius: var(--section-border-radius);
                        border-bottom-right-radius: var(--section-border-radius);
                    }
                }
            }
        }
    }
}
