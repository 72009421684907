@keyframes slideIn {
    to {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0px);
        opacity: 1;
        height: 56px;
    }

    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes minimize {
    from {
        padding: 0;
    }

    to {
        padding: 0;
        margin: 0;
        height: 0;
    }
}

.notifications {
    position: absolute;
    z-index: 9999;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 0 26px 21px 26px;
    overflow: hidden;
    pointer-events: none;

    .notification-item {
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding: 12px 16px;
        max-width: 100%;
        min-width: 540px;
        height: 56px;
        background-color: $blue-500;
        color: $white;
        border-radius: 6px;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
        pointer-events: auto;
        overflow: hidden;
        opacity: 0;
        transform: translateX(-100%); // hidden on init, then animation reveals it

        &.notification-item-success {
            background-color: $green-200;

            .notification-item-icon {
                background: url('../static-assets/images/success2.svg') no-repeat;
            }
        }

        &.notification-item-error {
            background-color: $red-200;

            .notification-item-icon {
                background: url('../static-assets/images/warning2.svg') no-repeat;
            }
        }

        &.notification-item-warning {
            background-color: $yellow-400;

            .notification-item-icon {
                background: url('../static-assets/images/warning2.svg') no-repeat;
            }
        }

        &.notification-session-expired [data-debug-id='Notifications_Cancel'] {
            display: none;
        }

        &.slide-in {
            animation: slideIn 500ms forwards;
        }

        &.slide-out {
            animation: slideOut 500ms forwards, minimize 500ms forwards;
            animation-delay: 0s, 500ms;
        }

        .notification-item-icon {
            content: '';
            background: url('../static-assets/images/info2.svg') no-repeat;
            min-width: 24px;
            width: 24px;
            height: 24px;
            color: inherit;
            margin-right: 10px;
        }

        .notification-item-description {
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.3px;
            @extend %text-no-overflow;
        }

        .notification-item-counter {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            margin-left: 10px;
            font-size: 12px;
            line-height: 11px;
            font-weight: 500;
            background-color: $dark-gray-400;
            border-radius: 50%;
        }

        .action-box {
            margin-left: auto;
            padding-left: 40px;

            .notification-item-close-btn {
                position: relative;
                border: none;
                background: url('../static-assets/images/close.svg') no-repeat;
                width: 24px;
                height: 24px;
                color: inherit;

                .close-progress-bar {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    height: 32px;
                    width: 32px;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}
