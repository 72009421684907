.pov-banner-container {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 991px;
    height: 115px;
    margin: auto;
    padding: 10px;
    background-color: white;
    background: linear-gradient(#fff, #fff) padding-box, linear-gradient(to bottom, #718f9d, #7a9cff) border-box;
    border: 3px solid transparent;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.25));
    z-index: 1000;

    .banner-icon {
        background-image: var(--pov-banner-icon);
        width: 199px;
        height: 58px;
        background-size: 199px 58px;
        margin: 0 50px 0 15px;
    }

    .pov-banner-content {
        width: 412px;
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
        margin-right: 50px;
        color: #264769;
    }

    .sign-up-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 175px;
        height: 46px;
        margin-right: 33px;
        background: linear-gradient(0deg, rgba(189, 189, 189, 0.1), rgba(189, 189, 189, 0.1)),
            linear-gradient(170.92deg, #211dd9 -14.01%, rgba(21, 75, 212, 0.92) 90.12%, #2b6df2 224.2%);
        border-radius: 30px;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #ffffff;

        &:hover,
        &:active,
        &:visited {
            color: #ffffff;
        }

        &:hover {
            box-shadow: 0px 0px 4px #4199c5;
        }
    }

    .close-icon {
        width: 30px;
        height: 30px;
        align-self: flex-start;
        background-image: var(--pov-banner-close-icon);
        background-size: 14px 14px;
        cursor: pointer;
    }
}
