.sign-in-wrapper {
    // FIXME - no point of using absolute...
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: -moz-radial-gradient(ellipse at center, #4e545a 0%, #494e54 25%, #181d22 100%);
    background: -o-radial-gradient(ellipse at center, #4e545a 0%, #494e54 25%, #181d22 100%);
    background: -webkit-radial-gradient(ellipse at center, #4e545a 0%, #494e54 25%, #181d22 100%);
    background: radial-gradient(ellipse at center, #4e545a 0%, #494e54 25%, #181d22 100%);
    background-size: cover;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e6181d22', endColorstr='#e6181d22', GradientType=1);

    .navbar-default {
        background-color: #023747;
    }
}

.sign-in-content-wrapper {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    padding: 0 110px;
}

.sign-in-content {
    min-height: 412px;
    background-color: $white;
    padding: 25px 35px 50px;
    width: 35%;
    float: right;
    position: relative;
    -webkit-animation: fadein 2s;
    -moz-animation: fadein 2s;
    -ms-animation: fadein 2s;
    -o-animation: fadein 2s;
    animation: fadein 2s;
    /* font-size: 0.923em; */
}

.sign-in-content-wrapper.extended .sign-in-content,
.sign-in-content-wrapper.extended .sign-in-instructions {
    width: 50%;
}

.sign-in-content h1 {
    font-size: 2.769em;
    font-weight: 500;
    margin-bottom: 20px;
    color: #414042;
}

.sign-in-content h1 small {
    display: block;
    font-size: 40%;
    margin-top: 10px;
    padding-bottom: 10px;
    color: $black;
}

.sign-in-instructions {
    width: 65%;
    float: right;
    padding: 0 30px;
    color: #939598;
    font-size: 1.846em;
}

.sign-in-instructions-content {
    padding-bottom: 20px;
    border-bottom: 2px solid #939598;
}

.sign-in-footer {
    position: absolute;
    bottom: 0px;
    left: 25px;
    right: 25px;
    padding: 10px 0 20px;
    border-top: 1px solid silver;
}

.sign-in-error {
    color: #bf0909;
    border-left: 5px solid #bf0909;
    padding-left: 5px;
    line-height: 1;
}

.login-verify img {
    height: 100px;
    top: 0;
}

.login-verify {
    text-align: center;
}

.sign-in-content .login-verify .form-options-verify {
    text-align: center;
    width: 100%;
    padding: 10px 36px 15px 36px;
    margin-bottom: 20px;
    margin-top: 10px;
    overflow: hidden;
}

.sign-in-content .login-verify .form-options-instructions {
    font-size: 14.95px;
}

.sign-in-content .login-verify .google-play a {
    margin-left: -35px;
}

.sign-in-content .login-verify .apple-store a {
    margin-right: -35px;
}

.sign-in-content .login-verify .form-options-verify img {
    margin: 10px auto;
}

.sign-in-content .login-verify form button {
    margin-top: 0px;
}

.btn-primary,
.sign-in-content form input[type='text'],
.sign-in-content form input[type='password'] {
    width: 100%;
    margin-bottom: 7px;
}

.sign-in-content .form-options-bottom {
    width: calc(100% - 60px);
    position: absolute;
    bottom: 65px;
    left: 25px;
}

.sign-in-content .dropdown {
    margin-bottom: 7px;
}

.sign-in-content .form-options-bottom a {
    cursor: pointer;
}

.btn-primary {
    background-color: #be1e2d !important;
    border: none;
    color: $white;
    margin-top: 10px;
    padding: 23px;
    border-radius: 0;
    font-size: 1.077em;
    /*text-transform: uppercase;*/
}

.btn-primary:hover {
    background-color: #860101 !important;
}

.sign-in-content iframe {
    visibility: hidden;
}

.sign-in-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
    /* opacity: 0.4; */
}

.sign-in-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sign-in-spinner > div {
    width: 25px;
    height: 25px;
    background-color: #00843f;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.7s infinite ease-in-out both;
    animation: sk-bouncedelay 1.7s infinite ease-in-out both;
    margin: 3px;
}

.sign-in-spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
    background-color: #b4121b;
}

.sign-in-spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    background-color: #faaf3f;
}

.sign-in-instructions .icon {
    width: 100px;
    height: 100px;
    background-size: cover;
}

.sign-in-instructions .icon.loading-icon,
.sign-in-instructions .icon.login-icon {
    background-image: url('../static-assets/images/radware_security_cloud.svg');
}

.sign-in-instructions .icon.expired-icon {
    background-image: url('../static-assets/images/session_link_expired.svg');
}

.sign-in-instructions .icon.reset-icon {
    background-image: url('../static-assets/images/re-set_pwd.svg');
}

.sign-in-instructions .icon.question-icon {
    background-image: url('../static-assets/images/okta_security_question.svg');
}

.sign-in-instructions .icon.mfa-icon {
    background-image: url('../static-assets/images/google_authenticator_embedded.svg');
}

.sign-in-instructions .icon.forgot-icon {
    background-image: url('../static-assets/images/forgot_pwd.svg');
}

.reset-password > div:last-child {
    font-size: 0.923em;
    border-left: 1px solid #949699;
}

.reset-password ul {
    padding-top: 10px;
}

.reset-password li {
    position: relative;
    padding-left: 20px;
    padding-bottom: 7px;
}

.reset-password li:before {
    content: '';
    width: 15px;
    height: 15px;
    background-image: url('../static-assets/images/green_bullett_icon.svg');
    display: inline-block;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
}

.sign-in-content input {
    padding-left: 25px;
}

.sign-in-content .form-input--wrapper {
    position: relative;
}

.sign-in-content .form-input--wrapper:before {
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    left: 7px;
    top: 9px;
}

.login-verify .form-input--wrapper:before,
.reset-password .form-input--wrapper:before {
    background-image: url('../static-assets/images/password_icon.svg');
    z-index: 1;
}

.login-form form > .form-input--wrapper:first-child:before {
    background-image: url('../static-assets/images/user_icon.svg');
}

.login-form form > .form-input--wrapper:nth-child(2):before {
    background-image: url('../static-assets/images/password_icon.svg');
    z-index: 1;
}

.login-forgot .form-input--wrapper:before {
    background-image: url('../static-assets/images/email_icon.svg');
}

.sign-in-content .dropdown input,
.login-security-question input,
.login-answer input {
    padding-left: 5px;
}

.sign-in-content .gotoLogInLink {
    float: right;
    position: absolute;
    right: 25px;
    bottom: 20px;
    cursor: pointer;
    z-index: 1;
}

.warningMessage {
    margin-top: 10px;
    > p {
        background-image: url('../static-assets/images/alert-icon.svg');
        padding-left: 20px;
        background-size: 17px;
        background-repeat: no-repeat;
        line-height: 1.5;
    }
}

.login-verify {
    .form-input--wrapper {
        span {
            float: left;
            text-align: left;
        }
    }
}

/* Responsive */
@media (max-width: 1575px) {
    .sign-in-content-wrapper.extended .sign-in-content {
        width: 65%;
    }
    .sign-in-content-wrapper.extended .sign-in-instructions {
        width: 35%;
    }
}

@media (max-width: 1275px) {
    .sign-in-content-wrapper.extended .sign-in-content {
        width: 70%;
    }
    .sign-in-content-wrapper.extended .sign-in-instructions {
        width: 30%;
    }
}
