%name_sp {
    width: 25%;
}
%created {
    width: 15%;
}
%accounts {
    width: 15%;
}
%customersAmount {
    width: 15%;
}
%appsProvisioned {
    width: 15%;
}
%appsPurchased {
    width: 15%;
}

.managed-service-provider {
    .card-items-container .container {
        position: initial;
    }
    .management-and-refresh {
        .card-items-management-head-details {
            align-items: baseline;
            > li:last-of-type {
                .head-options-total-item-type span {
                    margin-left: 0;
                }
            }
        }
    }
    .list-container {
        .list-headers {
            .header-field {
                &.name {
                    @extend %name_sp;
                }
                &.created {
                    @extend %created;
                }
                &.accounts {
                    @extend %accounts;
                }
                &.customersAmount {
                    @extend %customersAmount;
                }
                &.appsProvisioned {
                    @extend %appsProvisioned;
                }
                &.appsPurchased {
                    @extend %appsPurchased;
                }
            }
        }
        .list-items {
            .item {
                .item-list-item {
                    padding: 0 20px;
                }
                .fields {
                    display: flex;
                    width: 100%;
                    .data-field {
                        display: inline-block;
                        &.name {
                            @extend %name_sp;
                        }
                        &.created {
                            @extend %created;
                        }
                        &.accounts {
                            @extend %accounts;
                        }
                        &.customersAmount {
                            @extend %customersAmount;
                        }
                        &.appsProvisioned {
                            @extend %appsProvisioned;
                        }
                        &.appsPurchased {
                            @extend %appsPurchased;
                        }
                        &.warning {
                            color: #be1e2d;
                        }
                    }
                }
            }
        }
    }
    .card-item-options {
        position: absolute;
        top: 6px;
        right: 15px;
        width: 32px;
        visibility: visible;
        opacity: 1;
        padding: 7px 5px;
        box-shadow: none;
    }
}

.spContainer {
    .default-account-plan {
        .form-content-wrapper {
            margin-top: 0;
            > div:not(:last-child) {
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;
            }
            input,
            .Select,
            .invalid-input {
                width: $input-width + 30px; // same as calendar width
            }
            .enabled-checkbox .input-label {
                width: fit-content;
                cursor: pointer;
            }
            .invalid-input {
                text-align: end;
            }
            .generic-form-options {
                margin-bottom: 0;
            }

            .checkbox-wrapper.checkbox-with-label {
                flex-direction: row;
            }
        }
    }
}
