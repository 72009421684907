.generic-icon {
    position: relative;
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    background-color: transparent;
    background-size: 30px, 30px;
    background-position: center;
    background-repeat: no-repeat;
    transform-style: preserve-3d;
    cursor: pointer;

    &:hover,
    &:active {
        &:not(:disabled):not(.disabled):before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            background-color: var(--generic-icon-hover-bg-color);
            border-radius: 50%;
            transform: translateZ(-1px);
        }
    }
}

.generic-small-icon {
    width: 20px;
    height: 20px;
    background-size: 20px;

    &:hover,
    &:active {
        &:not(:disabled):not(.disabled):before {
            width: 20px;
            height: 20px;
        }
    }
}

.csv-download-icon {
    background-image: var(--csv-icon-primary);

    &:hover:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled) {
        background-image: var(--csv-icon-hover);
    }
}

.download-icon {
    background-image: var(--download-icon-primary);

    &:hover:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled) {
        background-image: var(--download-icon-hover);
    }
}

.refresh-icon {
    background-image: var(--refresh-icon-primary);

    &:hover:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled) {
        background-image: var(--refresh-icon-hover);
    }
}

.add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 0;
    background: #0e0ada;
    border-radius: 30px;
    border: none;
    cursor: pointer;

    &:not(.disabled):not(:disabled) {
        &:hover {
            opacity: unset;
            box-shadow: var(--add-button-box-shdow-hover);
        }

        &:active {
            box-shadow: none;
        }
    }

    &.disabled,
    &:disabled {
        opacity: unset !important;
        background-color: var(--add-button-bg-color-disabled);

        .plus-icon {
            background-image: var(--plus-icon-disabled);
        }
    }

    .plus-icon {
        display: inline-block;
        background-image: url('../static-assets/images/new-design/plus-icon.svg');
        background-position: center;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
    }
}

.delete-icon {
    background-image: url('../static-assets/images/new-design/delete-icon.svg');

    &:hover,
    &:active {
        &:not(:disabled):not(.disabled) {
            &:before {
                background-color: rgba(255, 43, 68, 0.3) !important;
            }
        }
    }
}

.discard-delete-icon {
    background-image: var(--discard-delete-icon-primary);

    &:hover,
    &:active {
        &:not(:disabled):not(.disabled) {
            background-image: var(--discard-delete-icon-hover);
        }
    }
}
