.search-item-wrapper {
    position: absolute;
    right: -100%;
    background: hsl(199, 30%, 75%);
    z-index: 99;
    width: 100%;
    top: 0;
    bottom: 0;
    opacity: 1;

    &.search-input-wrapper.open .cancel {
        display: block;
    }
}

.search-item-wrapper .dropdown > div:first-child a {
    background-color: $white;
    padding: 5px;
}

.search-close {
    background-image: var(--search-close-icon);
    position: absolute !important;
    right: 6px;
    top: 50%;
    width: 22px;
    height: 22px;
    z-index: 99999999;
    cursor: pointer;
    transform: translate(0, -50%);
}

.search-item-wrapper.open .dropdown {
    width: calc(100% - 35px);
    margin: 5px;
}

.search-item-wrapper.close {
    opacity: 0 !important;
    transition: 0.5s;
}

.search-item-wrapper.open {
    display: block;
    opacity: 1;
    z-index: 9999;
}
