$tiny-buttons: 60px;
$drag-handles: 30px;

.item-list .generic-item > div:first-child {
    padding: 8px 16px;
    cursor: pointer;
    min-height: 75px;
    background-color: var(--card-bg-color);
    position: relative;
}

.item-list .generic-item > div.deleted {
    background-color: $white !important;
    cursor: default !important;
    opacity: 0.8;
}

.item-list .generic-item > div:first-child:after {
    content: '';
    display: block;
    clear: both;
}

.item-list .generic-item {
    margin-bottom: 8px;
    position: relative;
}

.item-list .generic-item > div:first-child:hover {
    background-color: var(--card-hover-bg-color);
}

.item-list .generic-item.selected > div:first-child {
    background-color: var(--card-hover-bg-color);
}

.item-list .generic-item .item-list-item-header-name {
    word-wrap: break-word;
}

.item-list-item-header .card-item-header-name {
    font-weight: bold;
}

.item-list-wrapper {
    height: calc(100% - 15px);
}

.item-list-wrapper.loading,
.users-add.loading {
    pointer-events: none;
    opacity: 0.75;
}

.item-list {
    overflow-y: auto;
    height: calc(100% - 25px);
}

.item-list > ul {
    /* direction: ltr; */
}

.item-list-item-details {
    color: var(--text-color);
    font-size: 0.846em;
}

.item-list-item-header {
    color: var(--text-color);
    font-size: 1.077em;
    /* font-weight: bold; */
}

.item-list-item-header-last-run {
    @extend %text-no-overflow;
}

.item-list-new {
    padding: 8px 16px 22px 17px;
    background-color: #ffd67c;
    color: #231f20;
    font-size: 1.077em;
}

.item-list-header {
    margin-bottom: 24px;
    position: relative;
}

.item-list-header:after {
    content: '';
    display: block;
    clear: both;
}

.item-list-header h1 {
    background-repeat: no-repeat;
    padding-left: 30px;
}

.item-list .generic-item:hover {
    /* border-top:none; */
    /* border-bottom:none; */
    /* box-shadow:10px 0px 0px 0px #888888, -10px 0px 0px 0px #888888; */
    /* background-color: #dddfe8; */
}

/***********************
        list-item
 ***********************/

.list-item-row-wrapper {
    .list-item-row {
        height: 100%;
        border-bottom: 1px solid var(--table-row-border-color);

        .item-list-item:hover {
            background-color: var(--table-row-hover-bg-color);
        }

        .card-item-options {
            background-color: transparent;
            li {
                background-color: transparent;
            }
            &:before {
                display: none;
            }
        }
    }
}

.gen-list-item {
    display: flex;
    padding: 4px 19px;
    min-height: 50px;
    position: relative;

    &:not(:last-child) {
        border-bottom: 1px solid var(--table-row-border-color);
    }

    &.read-only {
        padding-right: 60px;
    }

    &.deleted {
        opacity: 0.6;
    }

    .gen-list-item-select .is-disabled {
        color: $black;
        .Select-value-label {
            background-color: #d3e7ff;
        }
        /*        .GET .Select-value-label { background-color: #d3e7ff; }
        .POST .Select-value-label { background-color: #fff7c8; }
        .HEAD .Select-value-label { background-color: #ffdfc8;}
        .PATCH .Select-value-label { background-color: #dde3ff; }
        .DELETE .Select-value-label { background-color: #ffddd7;}
        .OPTIONS .Select-value-label { background-color: #cfffe6;}
        .TRACE .Select-value-label { background-color: #eadbff;}
        .PUT .Select-value-label { background-color: #d6feff; }*/
    }

    .Select {
        min-width: unset;
        width: 222px;

        &.is-disabled .Select-arrow-zone {
            display: none;
        }
    }

    .gen-list-item-input {
        display: flex;
        flex: 1;
        min-width: 0;
        align-items: center;

        input[type='checkbox'] + label {
            color: unset;
            font-weight: unset;
            font-size: unset;
        }
    }

    .gen-list-item-select > div {
        display: flex;
        flex-wrap: wrap;
        min-width: 0;
    }

    .gen-list-item-input > div {
        margin-right: 10px;

        @extend %text-no-overflow;
    }

    .gen-list-item-select {
        display: flex;
        flex: 1;
        align-items: center;
        min-width: 0;

        .readonly-value {
            display: flex;
            align-items: center;
            margin: 5px;
            color: #3c3b3a; //TODO use theme colors
            background-color: #d3e7ff;
            padding: 3px 8px;
            height: 30px;
            border: 1px solid #cfd2d3;
            border-radius: 2px;
        }
    }

    .Select-value-label {
        font-size: 11px;
        font-weight: lighter;
    }

    &.allowed-uri-wrapper {
        align-items: flex-start;

        .path-access-prot-uri-wrapper {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.new-design .gen-list-item-input span.invalid-input {
    margin-right: 10px;
    align-self: flex-end;
}

.gen-list-items.scrollable-list {
    max-height: 350px;
    overflow: auto;
    overflow: overlay;

    .gen-list-item:nth-child(n + 6):nth-last-child(-n + 2) .Select-menu-outer {
        top: auto;
        bottom: 100%;
        border-top-color: #ccc;
        border-bottom-color: #e6e6e6;
    }
}

.gen-list-container {
    color: var(--text-color);
    background-color: var(--table-bg-color);
    padding-top: 5px;
    font-weight: 400;
}

.gen-list-headers > li {
    display: inline-block;
}

/***************************
        list-item-buttons
 ****************************/
.gen-list-item-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    text-align: right;

    &.tiny-buttons {
        width: $tiny-buttons;
    }
    .gen-list-item-edit {
        @extend %allowed_file_item_button;
        height: 11px;
        margin-left: 9px;
        margin-right: 5px;

        &.closed {
            background: url(../static-assets/images/arrow-down.png) center center;
            background-repeat: no-repeat;
        }

        &.inline-edit {
            background: url(../static-assets/images/icon-edit.svg) center center;
            background-repeat: no-repeat;
            background-size: 14px;
        }

        &.open {
            background: url(../static-assets/images/arrow-up.png) center center;
            background-repeat: no-repeat;
        }
    }

    .gen-list-item-delete {
        &.delete {
            @extend %allowed_file_item_button;
            background: url(../static-assets/images/delete-icon.svg) center center;
        }

        &.discard {
            @extend %allowed_file_item_button;
            background: url(../static-assets/images/cancel-delete.svg) center center;
        }
    }
}

.gen-list-headers-sortable {
    flex: 1;

    button {
        border: none;
        background: none;
        display: inline-block;
        padding: 0;
    }
    button:after {
        content: '';
        background: url(../static-assets/images/sort-icon-default.svg) no-repeat center center;
        width: 12px;
        height: 12px;
        display: inline-block;
        margin-left: 3px;
    }

    &.desc button:after {
        background: url(../static-assets/images/sort-asc.svg) no-repeat center center;
        transform: rotate(180deg);
    }

    &.asc button:after {
        background: url(../static-assets/images/sort-asc.svg) no-repeat center center;
    }
}

.gen-list-headers {
    padding: 5px 20px;
    border-bottom: 1px solid var(--table-row-border-color);

    &.tiny-buttons {
        display: flex;
        padding-right: calc(#{$tiny-buttons} + 20px);
        padding-left: calc(#{$drag-handles} + 20px);
    }
    .gen-list-headers-sortable,
    .gen-list-headers-regular {
        width: calc((100% - 300px) / 2);
    }
}

.gen-list-mgmt-footer {
    padding-top: 15px;
}

.gen-list-item {
    > form {
        width: 100%;
        display: flex;
        justify-content: space-between;

        button {
            margin-left: 10px;
            border: none;

            &.form-save,
            &.form-discard {
                font-size: 15.6px !important;
                line-height: 1.5;
                transition: 0.2s all;

                &:hover {
                    opacity: 0.75;
                }
            }

            &.form-discard.gen-list-item-delete {
                background-color: red;
                color: $white;
            }
        }

        .gen-list-headers-regular,
        .gen-list-item-input {
            width: calc((100% - 300px) / 2);

            input,
            textarea {
                max-width: calc(100% - 10px);
                padding-right: 4px;
            }
        }

        .is-invalid-input + span,
        .dropdown.isInvalidSelect > span {
            float: left;
            text-align: right;
            width: 250px;
        }
    }

    textarea {
        height: 43px;
    }

    .gen-list-item-order {
        margin: 10px;
    }
    .drag-handler {
        width: 18px;
        background: url(../static-assets/images/drag-handler.svg);
        height: 19px;
        margin: 10px 0 0 -15px;
        cursor: all-scroll;
    }
}

.gen-list-mgmt-header {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin-bottom: 10px;

    .gen-list-mgmt-add-item {
        margin-left: auto;
    }
    .gen-list-mgmt-csv {
        margin-left: 10px;
    }
    .total_results {
        font-size: 14px;
        color: var(--text-color);
        min-width: 190px;
        margin: 5px 15px;
        span {
            font-weight: 500;
        }
    }
}

.gen-list-mgmt-add-item {
    padding: 8px 10px;
    border: 1px solid #e6e6e6;
    background-color: #f1f8fa;

    color: #6499b7;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    transition: 0.2s all;

    &:hover {
        opacity: 0.75;
    }
}

/***************************
       dual-list
****************************/
.dual-list {
    box-sizing: border-box;
    min-width: 600px;
    min-height: 270px;
    &.withLabel {
        min-height: 290px;
    }
    .available-list,
    .selected-list {
        float: left;
        width: 44%;
        max-height: initial; // override default

        .Select-control,
        .Select-menu-outer {
            .Select-option {
                &:last-child {
                    border-radius: 0;
                }

                &.is-focused {
                    color: var(--text-color);
                }

                &.is-selected {
                    font-weight: 500;
                }
            }
        }
    }
    .controls {
        float: left;
        width: calc(12% - 25px);
        margin: 70px 10px 0;
        button {
            width: 100%;
            height: 30px;
            margin: 5px 0;
        }
        .separator {
            margin: 15px 0;
        }
    }
    .Select-control {
        height: 30px;
    }
    .Select-placeholder {
        line-height: 34px;
    }
    .Select-arrow-zone {
        display: none;
    }
    .Select-menu-outer {
        height: 220px;
    }
    .Select-menu {
        height: 218px;
        max-height: initial;
    }
    .Select-option {
        font-size: unset;
        padding: 6px 10px;
        &.is-selected {
            background-color: #f5faff;
        }
        .optionImage {
            float: right;
            width: 30px;
            max-height: 20px;
        }
    }
    .action-button {
        min-width: unset;
    }
}

/***************************
   Item Status Indication
****************************/

@mixin item-status($bgColor, $textColor) {
    background-color: $bgColor;
    border-radius: 50%;
    > div {
        color: $textColor;
    }

    &:hover > div {
        background-color: $bgColor;

        &:after {
            border-right: 6px solid $bgColor;
        }
    }
}

.list-item-indication {
    position: absolute;
    left: -15px;
    top: 20px;
    width: 8px;
    height: 8px;
    background-size: cover;
    transition: none;
    line-height: 16px;
    cursor: pointer;
    > div {
        transition: 0.2s;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        right: 0;
        transform: translate(calc(100% + 7px), -50%);
        z-index: 2;
        top: 50%;
        padding: 5px 10px;
        border-radius: 7px;
        white-space: nowrap;
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(calc(-100% + 1px), -50%);
            border: 7px solid transparent;
            transition: 0.2s;
        }
    }

    &:hover > div {
        visibility: visible;
        opacity: 1;
    }

    &.new {
        @include item-status(#b6e0ff, #0c7aca);
    }
    &.modified {
        @include item-status(#ffca97, #cc6a0c);
    }
    &.deleted {
        @include item-status(#dddddd, #6b6f85);
    }
    &.error {
        @include item-status($error-color, $white);
        background: url('../static-assets/images/error-icon.svg') center center;
        border-radius: unset;
        left: -15px;
        top: 18px;
        width: 14px;
        height: 12px;
    }
}

.copy-to-clipboard-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    padding-left: 18px;
    margin: 0 5px;
    opacity: 0.6;
    background: url('../static-assets/images/copy-to-clipboard_icon.svg') center no-repeat;
    background-size: contain;
    cursor: pointer;
    border: none;
    &:hover {
        opacity: 1;
    }
}

/***************************
   Form list
****************************/

.gen-form {
    .gen-list-mgmt {
        .gen-list-mgmt-header {
            display: flex;
            align-items: center;

            .gen-list-mgmt-header-left-controls,
            .gen-list-mgmt-header-right-controls {
                display: flex;
                align-items: center;
            }

            .gen-list-mgmt-header-left-controls {
                margin-right: 20px;
            }

            .gen-list-mgmt-header-right-controls {
                margin-left: auto;
            }
        }
    }

    .gen-list-title {
        margin-bottom: 10px;
    }

    .gen-list-headers {
        @include h6-font;
        display: flex;
        padding: 5px #{$tiny-buttons + 20px} 5px 20px;

        .gen-list-headers-regular {
            flex: 1;
        }
    }

    .gen-list-items {
        .gen-list-items-no-data .no-available-data {
            opacity: 0.7;
        }

        .gen-list-item {
            &.dynamic-item {
                position: relative;

                .gen-list-item-values,
                .gen-list-item-fields {
                    padding-right: $tiny-buttons;
                }

                .gen-list-item-buttons {
                    position: absolute;
                    right: 19px;
                    top: 4px;
                }
            }

            .gen-list-item-values,
            .gen-list-item-fields {
                display: flex;
                flex: 1;
                min-width: 0;

                .gen-list-item-field-value {
                    @extend %text-no-overflow;
                    padding-right: 10px;
                }
            }

            .gen-list-item-fields-container {
                display: flex;
                flex-direction: column;
                flex: 1;
                min-width: 0;

                .gen-list-item-fields {
                    width: 100%;
                    flex: none;
                }
            }

            .invalid-input {
                margin: 0;
            }

            .gen-list-item-fields {
                .gen-list-item-input {
                    flex-direction: column;
                    align-items: flex-start;

                    & > div {
                        overflow: unset;
                    }

                    .Select-input > input {
                        height: unset;
                        border: unset;
                        background-color: unset;
                    }
                }

                .gen-list-item-select {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .Select {
                    white-space: normal;
                }
            }
        }
    }
}
