.bot-captcha-wrapper {
    .editor-wrapper {
        margin-bottom: 15px;

        .toggle-edit-mode,
        .reset-html {
            margin: 10px 20px 15px 0px;
            padding: 5px 15px;
            font-size: 14px;
            font-weight: 500;
            color: #6d9fbb;
            background-color: #f1f8fa;
            border: 1px solid #e6e6e6;
            &:hover {
                opacity: 0.75;
            }
        }

        .quill-editor {
            .ql-toolbar {
                background-color: #f1f8fa;
            }

            .ql-container {
                height: 280px;
                min-height: 280px;
                resize: vertical;
                overflow-y: auto;
            }

            /* set dropdown font-families */
            .ql-font.ql-picker {
                width: 150px;

                span[data-value='initial']::before {
                    content: 'Default';
                    font-family: initial;
                }

                span[data-value='Arial']::before {
                    content: 'Arial';
                    font-family: Arial, Helvetica, sans-serif;
                }

                span[data-value='Verdana']::before {
                    content: 'Verdana';
                    font-family: Verdana, Geneva, sans-serif;
                }

                span[data-value='Times New Roman']::before {
                    content: 'Times New Roman';
                    font-family: 'Times New Roman', Times, serif;
                }

                span[data-value='Courier New']::before {
                    content: 'Courier New';
                    font-family: 'Courier New', Courier, monospace;
                }

                span[data-value='Georgia']::before {
                    content: 'Georgia';
                    font-family: Georgia, serif;
                }

                span[data-value='Lucida Console']::before {
                    content: 'Monospace';
                    font-family: 'Lucida Console', Monaco, monospace;
                }
            }
        }

        .raw-editor {
            width: 100%;
            height: 322.19px;
            min-height: 322.19px;
            resize: vertical;
            overflow-y: auto;
        }
    }

    .validation-error {
        color: $error-color;
        font-size: 15px;
        margin: 10px 0px;
    }
}
