%type_ma {
    width: 10%;
}
%name_ma {
    width: 15%;
}
%created__ma {
    width: 10%;
}
%bandwidth_ma {
    width: 10%;
}
%accounts_ma {
    width: 10%;
}
%appsProvisioned_ma {
    width: 15%;
}
%appsPurchased_ma {
    width: 15%;
}
%parent_ma {
    width: 15%;
}
%expires_ma {
    width: 10%;
}

.managed-accounts {
    .card-items-container .container {
        position: initial;
    }
    .management-and-refresh {
        align-items: flex-start;
        .card-items-management-head-details {
            align-items: baseline;
            > li:last-of-type {
                .head-options-total-item-type span {
                    margin-left: 0;
                }
            }
        }
    }
    .list-container {
        .list-headers {
            .header-field {
                &.name {
                    @extend %name_ma;
                }
                &.type {
                    @extend %type_ma;
                }
                &.created {
                    @extend %created__ma;
                }
                &.bandwidth {
                    @extend %bandwidth_ma;
                }
                &.parent {
                    @extend %parent_ma;
                }
                &.accounts {
                    @extend %accounts_ma;
                }
                &.appsProvisioned {
                    @extend %appsProvisioned_ma;
                }
                &.appsPurchased {
                    @extend %appsPurchased_ma;
                }
                &.expires {
                    @extend %expires_ma;
                }
            }
        }
        .list-items {
            .item {
                .item-list-item {
                    padding: 0 20px;
                }
                .fields {
                    display: flex;
                    width: 100%;
                    .data-field {
                        display: inline-block;
                        &.name {
                            @extend %name_ma;
                        }
                        &.type {
                            @extend %type_ma;
                        }
                        &.created {
                            @extend %created__ma;
                        }
                        &.bandwidth {
                            @extend %bandwidth_ma;
                        }
                        &.parent {
                            @extend %parent_ma;
                        }
                        &.accounts {
                            @extend %accounts_ma;
                        }
                        &.appsProvisioned {
                            @extend %appsProvisioned_ma;
                        }
                        &.appsPurchased {
                            @extend %appsPurchased_ma;
                        }
                        &.expires {
                            @extend %expires_ma;
                        }
                        &.warning {
                            color: #be1e2d;
                        }
                        &.softWarning {
                            color: #ffc107;
                        }
                    }
                }
            }
        }
    }
    .accounts-summary {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-left: 10px;
    }
    .card-item-options {
        position: absolute;
        top: 6px;
        right: 15px;
        width: 32px;
        visibility: visible;
        opacity: 1;
        padding: 7px 5px;
        box-shadow: none;
    }
}
