/*.profile-configuration-head h1 { background-image:url(../static-assets/images//user_title_icon.svg)}*/
.profile-configuration-head.form-header {
    border-bottom: none;
}

.profile-configuration-head > div:first-child {
    float: left;
}

.profile-configuration-head h1 {
    line-height: 3;
    padding-left: 15px;
}

.profile-configuration-nav .selected-content .form-content-wrapper .input-label,
.form-content-wrapper .multi-line-message {
    padding-bottom: 20px;
}

.profile-configuration-nav .selected-content .form-content-wrapper .input-label label[for='contract-ddos'] {
    margin-top: 20px;
}

.profile-configuration-nav .selected-content .form-content-wrapper .input-label input[type='text'],
.profile-configuration-nav .selected-content .form-content-wrapper .input-label input[type='password'] {
    width: 100%;
}

.profile-configuration-nav .form-content-wrapper {
    width: 45%;
}

.profile-security .form-content-wrapper {
    width: 100%;
}

.profile-configuration-head .avatar-wrapper {
    cursor: pointer;
}

.profile-security {
    margin-top: 10px;
}

.profile-security .header {
    text-transform: uppercase;
    background-size: 45px;
    background-repeat: no-repeat;
    padding: 13px 50px;
    height: 45px;
}

.profile-security > div:not(:last-child) {
    height: 100%;
    border-right: 1px solid #949699;
}

.profile-security .profile-password-header {
    background-image: url('../static-assets/images/re-set_pwd.svg');
}

.profile-security .profile-sec-question-header {
    background-image: url('../static-assets/images/okta_security_question.svg');
}

.profile-security .profile-revoke-header {
    background-image: url('../static-assets/images/google_authenticator_embedded.svg');
}

.profile-security .reset-password {
    margin-top: 20px;

    & > div {
        font-size: 1.1em;
        font-weight: 600;
    }
}

.avatar-image-container {
    position: relative;
    padding-right: 10px;
    float: left;
}

.image-close {
    display: none;
    position: absolute;
    right: -5px;
    top: -10px;
    height: 22px;
    content: ' ';
    background-image: url(../static-assets/images/widget-close.svg);
    width: 22px;
    z-index: 999 /* opacity: 0.5; */;
}

.avatar-image-container:hover .image-close {
    display: block;
}

.image-close:hover {
    background-image: url(../static-assets/images/widget-close-hover.svg);
    cursor: pointer;
}

.avatar-photo img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.avatar-photo {
    position: relative;
    background-color: #666;
    height: 75px;
    width: 75px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: $avatar-photo-box-shadow;
    z-index: 3;
    float: left;
}

.camera {
    position: absolute;
    background-image: url('../static-assets/images/camera.svg');
    left: 50%;
    top: 50%;
    height: 32px;
    width: 32px;
    transform: translate(-50%, -50%);
}

.avatar-photo .avatar-edit {
    opacity: 0;
    transition: 0.5s;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.avatar-photo:hover .avatar-edit {
    opacity: 1;
}

.avatar-photo input[type='file'] {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    -moz-opacity: 0;
    filter: alpha(opacity=0);
    opacity: 0;
    z-index: 200;
}

.AvatarCropper-base canvas {
    border-radius: 50%;
}

.modal-body {
    overflow-y: auto;
    max-height: 65vh;
}

.modal-content {
    width: 35%;
    margin: auto;
    top: 50%;
    transform: translate(0, -50%);
    max-height: 90vh;
    min-height: 140px;
    max-width: 550px;
    overflow-y: auto;
    @media (max-width: 1300px) {
        width: 50%;
    }
}

.modal-dialog {
    margin: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
}

.main-content-wrapper .profile-configuration {
    height: 100%;
    width: 100%;
    padding: 15px;
}

.profile-configuration .generic-form-options {
    float: right;
}
