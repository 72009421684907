.pager-warpper:after {
    content: ' ';
    display: block;
    clear: both;
}

.pager-nav-wrapper > div {
    float: left;
    padding: 0 10px;
}

.pager-nav-wrapper > div:hover {
    cursor: pointer;
    opacity: 1;
}

.pager-warpper {
    background-color: #fafafa;
    padding: 10px;
}

.pager-warpper select {
    border: none;
    padding-right: 15px;
}

.pager-warpper .pager-table-rows-count-wrapper select {
    margin-right: 10px;
    border: none;
    padding-right: 15px;
}

.pager-nav-start {
    border: 6px solid transparent;
    width: 0;
    height: 0;
    color: transparent;
    border-right-color: #58595b;
    position: relative;
    margin-top: 2px;
    opacity: 0.7;
}

.pager-nav-prev {
    border: 6px solid transparent;
    width: 0;
    height: 0;
    color: transparent;
    border-right-color: #58595b;
    margin-top: 2px;
    opacity: 0.7;
}

.pager-nav-next {
    border: 6px solid transparent;
    width: 0;
    height: 0;
    color: transparent;
    border-left-color: #58595b;
    margin-top: 2px;
    opacity: 0.7;
}

.pager-nav-end {
    border: 6px solid transparent;
    width: 0;
    height: 0;
    color: transparent;
    border-left-color: #58595b;
    position: relative;
    margin-top: 2px;
    opacity: 0.7;
}

.pager-nav-start:after {
    content: ' ';
    display: block;
    height: 10px;
    width: 1px;
    background-color: #58595b;
    position: absolute;
    top: -5px;
    left: 18px;
    opacity: 0.7;
}

.pager-nav-end:after {
    content: ' ';
    display: block;
    height: 10px;
    width: 1px;
    background-color: #58595b;
    position: absolute;
    top: -5px;
    right: 18px;
    opacity: 0.7;
}
