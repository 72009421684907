.serviceOverview {
    padding: 0;
}

.ServiceInstanceSummaryItem {
    font-size: 1em;
    margin-bottom: 10px;
}

.ServiceInstanceSummaryItem:last-child {
    margin-bottom: 0;
}

.ServiceInstanceSummaryItemContent,
.summeryItemRow {
    padding: 3px 5px 7px 20px;
    position: relative;
    padding-left: 20px !important;

    &.medium {
        color: #ffc107;
    }

    &.high {
        color: #be1e2d;
    }
}

.ServiceInstanceSummaryItemContent:before,
.summeryItemRow:before {
    content: ' ';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 4px;
    left: 0;
    background-repeat: no-repeat;
}

.ServiceInstanceSummaryItemContent.period:before {
    background-image: url('../static-assets/images/sov_period_16.svg');
}

.ServiceInstanceSummaryItemContent.remaining:before,
.ServiceInstanceSummaryItemContent.expirationDate:before {
    background-image: url('../static-assets/images/sov_remaining_16.svg');
}

.ServiceInstanceSummaryItemContent.expired:before {
    background-image: url('../static-assets/images/sov_remaining_expired.svg');
}

.ServiceInstanceSummaryItemContent.protectedaccounts:before {
    background-image: url('../static-assets/images/protected-accounts.svg');
}

.ServiceInstanceSummaryItemContent.apps:before {
    background-image: url('../static-assets/images/sov_protected_16.svg');
}

.ServiceInstanceSummaryItemContent.protectedapps:before {
    background-image: url('../static-assets/images/sov_protected_16.svg');
}

.ServiceInstanceSummaryItemContent.totalapps:before {
    background-image: url('../static-assets/images/sov_bandwidth_purchased_16.svg');
}

.ServiceInstanceSummaryItemContent.ddos-protection:before {
    background-image: url(../static-assets/images/widgets-ddos-icon.svg);
}

.ServiceInstanceSummaryItemContent.waf-protection:before {
    background-image: url(../static-assets/images/waf-icon.svg);
}

.ServiceInstanceSummaryItemContent.bot-protection:before {
    background-image: url(../static-assets/images/widgets-bot-icon.svg);
}

.summeryItemRow.avg:before {
    background-image: url('../static-assets/images/sov_bandwidth_average_16.svg');
}

.summeryItemRow.purchased:before {
    background-image: url('../static-assets/images/sov_bandwidth_purchased_16.svg');
}

.summeryItemRow.peak:before {
    background-image: url('../static-assets/images/sov_bandwidth_peak_16.svg');
}

.ServiceInstanceSummaryItem table th,
.ServiceInstanceSummaryItemTitle {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 5px;
}

.ServiceInstanceSummaryItem table th {
    padding: 0 15px;
}

.ServiceInstanceSummaryItem table th:not(:first-child) {
    width: 25%;
}

.ServiceInstanceSummaryItem table td {
    padding: 3px 5px 7px 20px;
    vertical-align: baseline;
}

.service-instance-summary {
    .ServiceInstanceSummary-content {
        display: flex;
    }
    .chart-wrapper {
        margin-top: 15px;
    }
    .getTrafficContainer {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .export-traffic-data {
            text-decoration: underline;
            cursor: pointer;
            margin-right: 15px;
            margin-bottom: 15px;
            &.disabled {
                opacity: 0.38;
                cursor: not-allowed;
            }
        }
    }
}

.service-overview_summary_header_title:before {
    content: ' ';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 13px;
    left: 0;
    background-repeat: no-repeat;
}

.ddos-service-summary-instance .service-overview_summary_header_title:before {
    background-image: url(../static-assets/images/widgets-ddos-icon.svg);
}

.service-overview_summary_header_title:before {
    background-image: url(../static-assets/images/widgets-waf-icon.svg);
}

.service-overview_summary_header_title {
    position: relative;
    font-size: 1.3em;
    margin-bottom: 5px;
}

.service-overview-content-wrapper {
    padding: $page-padding $page-padding 0 $page-padding;
    display: flex;
    flex-direction: column;
    .list-wrapper {
        &:not(:last-child) {
            margin: 15px 0 8px 0;
        }
        &:last-child {
            margin: 8px 0 15px 0;
        }
    }

    .select-tenant {
        flex-grow: 1;
    }

    .service-instance-summary {
        background-color: var(--card-bg-color);
        padding: 5px 15px 15px;
        border-radius: var(--card-border-radius);

        .chart-wrapper {
            position: relative;
            height: 300px;
            padding: 0 20px;
        }
    }
}

.siem-download {
    margin-right: 10px;
}

.customer-cipher-configuration-container {
    .service-overview_summary_header_title {
        padding-left: 0;
        &:before {
            background-image: none;
        }
    }
    .checkbox-wrapper {
        margin-bottom: 10px;
    }
}

.aws-config-section {
    .aws-s3-config-tabs {
        margin-bottom: 20px;
    }
    .s3-config-form-container {
        .form-save {
            margin-left: 10px;
            border-radius: 20px;
            font-size: 12px;
            line-height: 14px;
            padding: 6px 10px;
            &::before {
                content: none;
            }
        }
        .help-tooltip {
            margin: 0px;
            .activity-status-fail {
                background: url(../static-assets/images/failed-red-icon.svg) no-repeat;
                width: 19px;
                height: 19px;
            }

            .activities-status-success {
                background: url(../static-assets/images/success_indication.svg) no-repeat;
                width: 19px;
                height: 19px;
            }
            .failed-status-text {
                color: rgba(255, 43, 68, 1);
                font-weight: 600;
            }
            .success-status-text {
                color: rgba(36, 190, 116, 1);
                font-weight: 600;
            }
        }
    }
}
