.item-create-form-protocol .checkboxes-group {
    margin-top: 11px;
}

.policyDistributionCont {
    .toggle-btn-wrapper {
        float: left;
    }
    .form-save.distribute-now {
        position: absolute;
        margin-top: 16px;
        cursor: pointer;
    }
}

.timezone_difference_message {
    margin-bottom: 10px;
    margin-top: 5px;
}

.distribute-policy-periodically-title {
    border-top: none;
    margin-bottom: 15px;
    margin-top: 0;
    text-transform: none;
    float: left;
}

.new-design .timer-period-selector .inputs__container__fields input[type='text']:first-child,
.new-design .timer-period-selector input[type='text'] {
    padding: 0;
    border: none;
    width: 18px;
    top: 5px;
}

.new-design .timer-period-selector .inputs__container__symbol {
    top: 5px;
}

.new-design {
    .inputs__container__fields__hh {
        left: 3px;
    }
    .inputs__container__fields__mm {
        right: 2px;
    }
}

.new-design .inputs__container__buttons {
    border-left: none;
}

.policyDistributions .item-list-item-header .card-item-header-name {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}

.sourceAppDetails {
    border: 2px solid #cfd2d3;
    padding: 20px;
    color: #8b8c8c;
    float: left;
    width: 100%;
    margin: 20px 0;
}

.sourceApplicationListWrapper {
    margin-bottom: 10px;
}

.sourceAppDetails .sourceAppDetails-domain {
    margin-bottom: 5px;
}
