$bar-color: #00c0ef;
$border-radius: 1px;

.comp-progress-bar {
    height: 9px;
    border: $border-radius solid var(--progress-bar-border-color);
    background-color: var(--progress-bar-bg-color);

    .bar {
        width: 0;
        background-color: $bar-color;
        float: left;
        height: 100%;
        -webkit-transition: width 0.5s ease;
        -o-transition: width 0.5s ease;
        transition: width 0.5s ease;
        outline: $border-radius solid $bar-color;
    }
}
