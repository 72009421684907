html {
    height: 100%;
    font-size: 13px;
}

body {
    margin: 0 !important;
    font-size: 13px;
    min-height: 100%;
    position: relative;
    overflow: hidden;
    font-family: var(--font-family);
    color: var(--text-color);

    * {
        transition: background-color 0.2s ease-in; // transition between themes (light/dark)
    }
}

// ReactDOM.render() target
// [data-ui-role='content']
#root {
    width: 100vw;
    height: 100vh;

    #app-root {
        width: 100%;
        height: 100%;

        .app-wrapper,
        .sign-in-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-family);
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

input[type='radio'] {
    display: none;
}

.dropdown > div:first-child a {
    padding: 6px 5px;
    display: block;
    border: 1px solid #bdbfc1;
    border-radius: 2px;
    color: #333;
    line-height: 1;
}

.disabled a {
    background: #f2f3f3 !important;
}

.disabled input[type='radio'] + label:before {
    border-color: #f2f3f3;
}

.disabled input[type='radio']:checked + label:after {
    background-color: #f2f3f3;
}

.disabled label {
    cursor: default;
}

.critical {
    color: #ff1e27;
}

input[type='radio'] + label {
    position: relative;
    margin-bottom: 10px;
    padding-left: 16px;
    text-transform: none;
    cursor: pointer;
}

input[type='radio'] + label,
input[type='radio']:not(.navigator-radio-button) + label span {
    color: var(--text-secondary-color);
    @include body-4-font;
}

input[type='radio'] + label:before {
    content: ' ';
    display: block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: $input-bg-color;
    border: 1px solid var(--radio-button-border-color);
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
}

input[type='radio']:disabled,
input[type='radio'].disabled {
    + label:before {
        border: 1px solid var(--radio-button-disabled-color);
    }

    &:checked {
        + label:after {
            background-color: var(--radio-button-disabled-color);
        }
    }
}

input[type='radio']:not(:checked):not(:disabled):not(.disabled) + label:hover:before {
    border: 1px solid var(--radio-button-border-hover-color);
}

input[type='radio']:checked:not(:disabled):not(.disabled) + label:hover:before {
    box-shadow: var(--radio-button-border-selected-hover-box-shadow);
}

input[type='radio']:checked + label:after {
    content: ' ';
    display: block;
    width: 7px;
    height: 7px;
    background-color: var(--radio-button-selected-bg-color);
    border-radius: 50%;
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
}

label {
    font-weight: normal;
}

input[type='text'],
input[type='password'],
input[type='number'] {
    height: 30px;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
    width: $input-width;
    padding: 6px 5px;
    border-radius: 12px;
    border: 1px solid var(--control-border-color);
    background-color: transparent;
    display: block;
    color: var(--control-text-color);

    &:not(.disabled):not(:disabled) {
        &:hover {
            border: 1px solid var(--control-hover-border-color);
        }

        &:focus {
            border: 1px solid var(--control-selected-border-color);
            outline: none;
        }
    }
}

input[type='text'][disabled],
input[type='password'][disabled],
textarea[disabled] {
    background-color: var(--control-disabled-bg-color);
    color: var(--control-disabled-text-color);
    border: 1px solid var(--control-disabled-bg-color);
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
    color: var(--control-placeholder-color);
}

.required-field::after {
    content: '*';
    margin-left: 2px;
    color: var(--required-control-asterisk-color);
}

a {
    text-decoration: none;
}

a:hover,
a:focus,
a:active,
a:visited {
    text-decoration: none;
}

h1 {
    margin: 0;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
}

.pos-middle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.app-content {
    display: flex;
    flex-grow: 1;
    overflow: auto;
    background-color: var(--page-bg-color);
}

.main-content-wrapper {
    position: relative;
    flex-grow: 1;
    width: 0;
    box-sizing: border-box;

    .page-wrapper {
        height: 100%;
        width: 100%;
        > div {
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}

/*
// .activeWidgetRepository {
//     z-index: 4500;
//     top: calc(100% + 6px);
//     right: -2px;
//     transform: translate(0%, 0%) !important;
// }

// .activeWidgetRepository {
//     z-index: 9999;
//     top: calc(100% + 6px);
//     right: -2px;
//     transform: translate(0%, 0%) !important;

//     .st-tooltip-item__content {
//         max-height: calc(100vh - 280px);
//         overflow: auto;
//     }
// }

*/

.repo-options {
    border: 1px solid #cfd2d3;
    border-top: none;
    border-bottom: none;
}

.addWidgets:hover {
    background-color: #296c96;
    color: $white;
    cursor: pointer;
}

.repo-options > div {
    background-color: var(--widgets-repository-bg-color);
    border-bottom: 1px solid #cfd2d3;
    position: relative;
    padding: 13px 28px 13px 40px;
    width: 50%;
    float: right;
    color: var(--dropdown-item-text-color);
    font-size: 13px;
    font-weight: 300;
    line-height: 1.1;
}

.addWidgets {
    color: #296c96;
}

.repo-options > div:before {
    content: ' ';
    display: inline-block;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    left: 11px;
    top: 16px;
    position: absolute;
}

.addWidgets:before {
    background-image: url('../static-assets/images/add-widgets.svg');
}

.removeAllWidgets:hover {
    background-color: #be1e2d;
    color: $white;
    cursor: pointer;
}

.removeAllWidgets {
    color: #be1e2d;
    padding-left: 65px !important;
    &:before {
        left: 40px !important;
    }
}

.removeAllWidgets:before {
    background-image: url('../static-assets/images/remove-all-widgets.svg');
}

.removeAllWidgetsConfirm:hover {
    background-color: #be1e2d;
    color: white;
    cursor: pointer;
}

.removeAllWidgetsConfirm {
    color: #be1e2d;
    .removeAllWidgets--data {
        max-width: 100px;
        display: inline-flex;
    }
}

.removeAllWidgetsConfirm:before {
    content: ' ';
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url('../static-assets/images/remove-all-widgets.svg');
    background-position-y: center;
    opacity: 0.6;
    width: 22px;
    height: 22px;
    left: 13px;
    top: 11px;
}

.removeAllWidgetsCancel:hover {
    background-color: #296c96;
    color: white;
    cursor: pointer;
}

.removeAllWidgetsCancel {
    color: #296c96;
    padding-top: 19px !important;
    padding-bottom: 21px !important;
    padding-left: 60px !important;
    border-left: 1px solid #cfd2d3;
}

.removeAllWidgetsCancel:before {
    content: ' ';
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url('../static-assets/images/remove-all-widgets-cancel.svg');
    background-position-y: center;
    width: 22px;
    height: 22px;
    left: 33px !important;
    top: 11px;
    transform: rotate(45deg);
}

.input-wrapper {
    margin-top: 10px;

    & > .radio-group {
        display: inline-block;
    }
}

.form-header-text {
    text-transform: uppercase;
    width: 50%;
    margin-top: 5px;
}

.radio-with-input {
    display: flex;
    .input-label {
        margin: 5px 10px 0 0;
    }
}

/*OnLineHelp*/
.alerts-viewer-wrapper.help-wrapper .alerts-slider > h1:before {
    display: none;
}

.help-wrapper .alerts-slider {
    background-color: $white;

    h1 {
        background: url(../static-assets/images/olh_icon.svg) 0px 0px/22px no-repeat;
        padding-left: 27px;
        color: $black;
    }
}

.alerts-viewer-wrapper.help-wrapper iframe {
    height: calc(100% - 12px);
    border: none;
    width: 100%;
}

iframe.refreshTokenFrame {
    display: none;
}

.olh-trigger {
    display: inline-block;
    background-image: url('../static-assets/images/help.svg');
    width: 14px;
    height: 14px;
    vertical-align: bottom;
    margin-left: 5px;
    position: relative;
    z-index: 4400;
}

.form-header .olh-trigger,
.selected-content .olh-trigger {
    float: right;
    margin: 9px 0 0 30px;
}

.reports-form-container .olh-trigger {
    float: right;
    margin: 0 0 0 30px;
    position: absolute;
    top: 10px;
    right: 5px;
}

.olh-trigger:hover {
    background-image: url('../static-assets/images/help_over.svg');
    cursor: pointer;
}

/*OnLineHelp END*/

.multi-line-message {
    white-space: pre;
}

.react-draggable-dragging {
    z-index: 3001 !important;
}

.react-grid-item.resizing {
    z-index: 3001 !important;
}

.modal {
    z-index: 99999;
}

.error-page {
    %button {
        @include h6-font;
        margin: 0 15px;
        padding: 9px 15px;
        border-radius: $item-border-radius;
        color: $white;
        text-align: center;
        cursor: pointer;
        border: none;
        min-width: 156px;
        height: 36px;
        &:hover {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
        }
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $page-padding;
    text-align: center;

    &.page-not-found {
        .image {
            background: url('../static-assets/images/page-not-found.svg') no-repeat center;
            background-size: contain;
            width: 245px;
            height: 240px;
            min-height: 100px;
            margin-bottom: 30px;
        }
        .description {
            @extend %error-msg;
            margin-bottom: 30px;
        }
        .buttons-container {
            display: flex;
            .home,
            .report {
                @extend %button;
            }
            .home {
                background-color: $dark-gray-300;
            }
            .report {
                background-color: $blue-500;
            }
        }
    }

    &.forbidden {
        .image {
            background: url('../static-assets/images/forbidden.svg') no-repeat center;
            background-size: contain;
            width: 260px;
            height: 230px;
            min-height: 100px;
            margin-bottom: 40px;
        }
        h1 {
            font-size: 26px;
            font-weight: 600;
            margin: 0 0 15px 0;
            color: var(--text-color);
        }
        span {
            @extend %error-msg;
        }
    }

    &.failed-to-load {
        height: 100%;
        background-color: var(--page-bg-color);
        .image {
            background: url('../static-assets/images/failed-to-load.svg') no-repeat center;
            background-size: contain;
            width: 250px;
            height: 217px;
            min-height: 100px;
            margin-bottom: 30px;
        }
        h1 {
            font-size: 26px;
            font-weight: 600;
            margin-bottom: 10px;
            color: var(--text-color);
        }
        .description {
            @extend %error-msg;
            margin-bottom: 30px;
        }
        .buttons-container {
            display: flex;
            .home,
            .retry {
                @extend %button;
            }
            .home {
                background-color: $dark-gray-300;
            }
            .retry {
                background-color: $blue-500;
            }
        }
    }
}

.select-tenant {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @extend %select-tenant-msg;

    .image {
        background: url('../static-assets/images/select-tenant.svg') no-repeat center center;
        background-size: contain;
        width: 300px;
        height: 248px;
        margin-bottom: 20px;
    }
}

.no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .no-results {
        background: url('../static-assets/images/no-results.svg') no-repeat center center;
        background-size: contain;
        width: 300px;
        height: 231px;
        margin-bottom: 20px;
    }

    .select-app {
        background: url('../static-assets/images/select-app.svg') no-repeat center center;
        background-size: contain;
        width: 302px;
        height: 230px;
        margin-bottom: 20px;
    }

    .icon {
        background: url('../static-assets/images/security-events-icon.svg') no-repeat center center;
        background-size: cover;
        width: 200px;
        height: 200px;
    }

    .description {
        @extend %empty-state-msg;
    }
}

.loader {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    box-shadow: 1px 0px 0px 0px #555;
    margin-top: 4px;
    margin-right: 15px;
}

.timer {
    margin-right: 15px !important;
    margin-top: 10px !important;
}

/***************************
        Unknown
 ***************************/

.inputs__container__fields {
    float: left;
    border-radius: 2px 0 0 2px;
    width: 50px;
    padding: 0 5px;

    input[type='text'] {
        position: absolute;
        width: 39%;
        height: 28px;
        margin: 0;
        line-height: 29px;
        padding: 0px;
        bottom: 0;
        top: 0;
        outline: none;
        border: none;

        &:first-child {
            padding-left: 1px;
        }
    }
}

.inputs__container__fields,
.inputs__container__buttons {
    display: inline-block;
    position: relative;
    border: 1px solid #bdbfc1;
    height: 32px;
}

.inputs__container__fields__mm {
    right: 0;
}

.inputs__container__fields__mm,
.inputs__container__fields__hh {
    border: none !important;
}

.inputs__container__symbol {
    position: absolute;
    left: 47%;
    font-size: inherit;
    line-height: 28px;
    font-weight: 700;
}

.inputs__container__buttons {
    width: 17px;
    border-radius: 0 2px 2px 0;
    border-left: none;
}

.inputs__container__buttons__plus,
.inputs__container__buttons__minus {
    position: relative;
    border: none;
    background: transparent;
    outline: none;
    display: inline-block;
    padding: 0;
}

.inputs__container__buttons__plus {
    position: relative;
    width: 100%;
    height: 50%;
}

.inputs__container__buttons__minus {
    position: relative;
    width: 100%;
    height: 50%;
    bottom: 5px;
}

.inputs__container__buttons__plus i {
    display: block;
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--text-color);
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    margin: auto;
}

.inputs__container__buttons__minus i {
    display: block;
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--text-color);
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
    margin: auto;
}

.inputs__container.focused .inputs__container__fields {
    border-color: #4d90fe;
    box-shadow: 0px 0px 2px #4d90fe;
}

//TOD: remove this after we get the loading logo from server
.loading_radware_logo {
    background-repeat: no-repeat;
    background-image: url('../static-assets/images/loading_radware_logo.svg');
    width: 380px;
    height: 200px;
    transform: scale(0.6);
}

.highlight-container {
    @extend %text-no-overflow;
    .highlight {
        background-color: rgba(59, 119, 238, 0.3);
        border: 1px solid #3b77ee;
    }
}

.i18n-wrapper {
    text-align: left;

    .i18n-icon {
        background: url(../static-assets/images/icon-domain.svg) no-repeat center/30px;
        width: 40px;
        height: 40px;
        cursor: help;
    }

    .modal-title {
        color: #3f51b5;
        background: url(../static-assets/images/icon-domain.svg) no-repeat left/27px;
        padding: 2px 32px;
    }

    .i18n-details-content {
        font-size: 14px;
        border-bottom: solid #3f51b5 1px;
        margin-bottom: 10px;
        color: #3f51b5;

        .row {
            margin-bottom: 10px;

            .key {
                font-weight: bold;
            }
        }

        .save {
            background: url(../static-assets/images/ok-message_icon.svg) no-repeat left/30px;
            cursor: pointer;
            height: 35px;
        }
    }

    .modal-content {
        width: 50%;
    }

    .export-i18n,
    .import-i18n {
        margin-right: 10px;
        font-size: 14px;
        background-color: #e6e6e6;
        background-position: 5px 9px;
        background-size: 25px;
        background-repeat: no-repeat;
        height: auto;
        color: #6499b7;
        padding: 10px 15px 10px 37px;
    }
    .export-i18n {
        background-image: url(../static-assets/images/export.svg);
    }

    .import-i18n {
        background-image: url(../static-assets/images/import.svg);
        label {
            margin: unset;
            cursor: inherit;
        }
    }
}

input::placeholder,
input {
    font-size: 12px !important;
}
