@mixin h1-font {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
}

@mixin h2-font {
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
}

@mixin h3-font {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
}

@mixin h4-font {
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
}

@mixin h5-font {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
}

@mixin h6-font {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
}

@mixin body-1-font {
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
}

@mixin body-2-font {
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
}

@mixin body-3-font {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
}

@mixin body-4-font {
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;

    @media (max-width: 1600px) {
        font-size: 11px;
        line-height: 13px;
    }
}

@mixin body-5-font {
    font-size: 10px;
    line-height: 12px;
    font-weight: normal;
}
