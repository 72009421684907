@media (min-width: 768px) {
    .navbar-default .collapsing {
        height: auto !important;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .time-date-filter-timer-trigger > div:first-child > span {
        display: none;
    }
}

@media (min-width: 1201px) and (max-width: 1400px) {
    .welcome-screen {
        width: 100%;
        position: static;
        transform: translate(0, 0);
        padding: 30px;
        max-width: none;
    }
}

@media (max-width: 1200px) {
    .sign-in-content-wrapper.extended .sign-in-content {
        width: 65%;
    }
    .sign-in-content-wrapper.extended .sign-in-instructions {
        width: 35%;
    }
    .welcome-screen {
        width: 100%;
        position: static;
        transform: translate(0, 0);
        padding: 30px;
        max-width: none;
    }
    .welcome-screen h1 {
        font-size: 4em;
    }
    .welcome-options > div[class*='col-'] > div {
        width: 100%;
        margin-bottom: 30px;
    }
}

@media (max-width: 1024px) {
    .toolbar-options .dd-label {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar-default .nav.nav-pills > li > a,
    .navbar-default .nav-pills > li.active > a,
    .navbar-default .nav-pills > li:hover > a {
        width: 0;
        white-space: nowrap;
        color: transparent !important;
        padding: 0 35px 1px;
        line-height: 2.4;
    }
    .navbar-default .nav.nav-pills li a:before {
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (max-width: 991px) {
    .sign-in-content-wrapper {
        position: static;
        transform: translate(0, 0);
        padding: 55px 100px 100px;
    }
    .sign-in-content-wrapper.extended .sign-in-content,
    .sign-in-content {
        float: none;
        width: auto;
    }
    .sign-in-content-wrapper.extended .sign-in-content {
        min-height: 500px;
    }
    .reset-password > div:last-child {
        border-left: none;
        margin-top: 10px;
    }
    .sign-in-content-wrapper.extended .sign-in-instructions,
    .sign-in-instructions {
        float: none;
        width: auto;
        margin-bottom: 20px;
        padding: 0;
    }
    .sign-in-wrapper {
        position: static;
    }
}

@media (max-width: 767px) {
    body {
        padding-bottom: 0 !important;
    }
    .navbar-default a.navbar-brand {
        width: 0 !important;
        min-width: 0 !important;
        white-space: nowrap !important;
        color: transparent !important;
        padding-right: 2px !important;
        position: absolute;
    }

    .navbar-toolbar {
        padding: 0;
        margin-bottom: 0;
        top: 41px;
        width: 100%;
        z-index: 0;
        position: absolute;
    }

    .toolbar-options .options-dropdown {
        width: 45%;
        margin-left: 10px;
    }

    .navbar-toolbar .dd-toolbar {
        margin-left: 0;
        width: 170px;
        position: absolute;
        top: -42px;
        left: 57px;
    }
    .navbar-toolbar .dd-toolbar .dropdown > div > a {
        padding-top: 9px;
        padding-bottom: 9px;
    }
    .navbar.navbar-toolbar .row > .options-icons-wrapper[class*='col-xs']:last-child {
        position: absolute;
        right: 0;
        top: 0;
        min-width: 170px;
        padding-left: 0;
    }
    .navbar.navbar-toolbar .row > div[class*='col-xs']:first-child {
        position: static !important;
    }
    .navbar.navbar-toolbar .row > div[class*='col-xs']:last-child > div.navbar-right {
        float: right;
    }

    .widget-repository-trigger {
        margin-top: 7px;
        margin-left: 15px !important;
        margin-right: 0 !important;
    }

    .widgets-repository-wrapper .dropdown.active .st-tooltip-item {
        position: fixed;
        top: 10%;
        left: 20%;
        width: 60%;
        bottom: 10%;
        border: none;
    }
    .dropdown.active .st-tooltip-item .st-tooltip-item__content {
        height: calc(100% - 31px);
        overflow: auto;
    }
    .dropdown.active.multiSelect .st-tooltip-item .st-tooltip-item__content {
        position: relative;
        padding-bottom: 30px;
    }
    .dropdown.active .st-tooltip-item ul.st-list--table {
        max-height: none;
        box-shadow: none;
        overflow: hidden;
    }

    .dropdown.active.multiSelect .dd-done {
        display: block;
        position: absolute;
        bottom: 0;
        background: #266d97;
        left: 0;
        right: 0;
        text-align: center;
        color: $white;
        padding: 5px;
    }

    .widgets-repository-wrapper .dropdown.active .st-tooltip-item:before {
        content: '';
        display: block;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.6);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
    }
    .dropdown .dropdown-filter:before,
    .dropdown .dropdown-filter:after {
        left: 7px !important;
        display: none;
    }
    .navbar.navbar-toolbar .row.toolbar-options > div:first-child {
        width: 75%;
    }
    .navbar.navbar-toolbar .toolbar-options.row > div {
        padding: 0;
    }
    .time-date-filter-timer-toolber,
    .widgets-repository-wrapper {
        display: none;
    }
    .toolbar-options {
        display: block;
        z-index: 4500;
        background-color: #e7e7e8;
        margin: 0;
        width: 100%;
        transition: 0.5s;
        padding: 11px 0 !important;
    }
    .toolbar-settings-trigger {
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        outline: none;
        -webkit-mask-image: url(../static-assets/images/widget-settings-trigger.svg);
        mask-image: url(../static-assets/images/widget-settings-trigger.svg);
        background-color: #6d6e70;
        position: absolute;
        top: 6px;
        right: 16px;
        zoom: 1.3;
        padding: 0 !important;
        cursor: pointer;
        display: none;
    }
    .navbar.navbar-toolbar .toolbar-options .options-icons {
        margin-top: -7px;
    }
    .options-icons:after {
        content: ' ';
        display: block;
        clear: both;
        float: none;
    }
    .navbar.navbar-toolbar .toolbar-options .pull-right > div {
        float: left !important;
    }
    .options-dropdown {
    }
    .toolbar-options > div:first-child .dropdown {
        display: block;
        margin-left: 0;
        min-width: 0;
        width: 100%;
        max-width: none;
    }
    .toolbar-options .dd-label {
        float: none;
    }
    .navbar-toolbar .nav.nav-pills {
        padding: 7px 7px 0;
        margin: 0;
    }
    .widget-wrapper .widget-minimize,
    .widget-wrapper .widget-maximize {
        display: none !important;
    }

    /*temp*/
    .navbar-toolbar .nav.nav-pills > li[class^='nav-item']:first-child {
        margin: 0;
    }

    .portal_userMgmt li {
        float: left;
        width: auto;
        margin-left: 10px;
    }

    .UserActions_Trigger {
        background: url(../static-assets/images/caret-down.svg) no-repeat center;
        background-size: 13px;
        width: 8px;
    }

    .UserActions {
        margin: 0;
    }

    .UserActions.dropdown li.st-list--row {
        width: 100%;
        background-color: transparent;
        border: none;
    }

    .UserActions.dropdown li.st-list--row a,
    .UserActions.dropdown li.st-list--row span {
        color: $white;
        padding: 11px;
        display: block;
        border-bottom: none;
    }

    .userActions_trigger_container .dropdown.active .st-tooltip-item ul.st-list--table li span,
    .userActions_trigger_container .dropdown.active .st-tooltip-item ul.st-list--table li a {
        padding: 10px 0 0 0;
        font-size: 14px;
        color: $white !important;
    }

    .options-icons {
        margin-top: 0;
    }
    .alerts-slider {
        box-shadow: none !important;
    }
    .list-wrapper {
        width: 100%;
        overflow: auto;
    }
    .top-to-bottom-grid-wrapper .list-wrapper .list-header,
    .top-to-bottom-grid-wrapper .list-wrapper .list-content {
        min-width: 750px;
    }
    .list-wrapper .list-content {
        height: calc(100% - 120px);
    }
    .applicationEvents .list-wrapper,
    .ddosEvents .list-wrapper {
        height: calc(100% - 75px);
    }
    .columns-manager {
        display: none;
    }
    .widget-close {
        display: none;
    }
    .service-overview-content-wrapper {
        padding: 0px;
    }
    .no-available-data {
        //transform: translate(-50%, 0%);
        white-space: normal;
    }
    .security-events-time-filter {
        margin-bottom: 20px;
    }
    .split-screen-a,
    .split-screen-side-b {
        position: static;
    }
    .split-screen-a {
        border-right: none;
        width: auto;
    }
    .split-screen-side-b {
        position: fixed;
        top: 88px;
        width: 100%;
        left: 0;
        background: var(--card-bg-color);
        border-left: 1px solid var(--card-separator-color);
    }
    .form-options .back-to:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border: 1px solid #8a8a89;
        position: absolute;
        top: 4px;
        left: 0px;
        border-right: none;
        border-bottom: none;
        transform: rotate(-45deg);
    }
    .activities-item {
        width: auto;
    }
    .ddosEvents .chart-area-wrapper,
    .applicationEvents .chart-area-wrapper {
        display: none;
    }
    .sign-in-instructions {
        font-size: 1.5em;
    }
    /*bootstrap-customization*/
    .navbar-default .nav.nav-pills {
        margin-left: -15px;
        margin-right: -15px;
    }
    .navbar-default .nav.nav-pills > li {
        float: none;
        margin: 0;
    }

    .dropdown-content li {
        padding: 0;
    }
    .dropdown-content a {
        color: $white;
    }

    .navbar-default .nav.nav-pills > li {
        margin-bottom: 10px;
    }
}

@media (max-width: 1250px) {
    .new-design .card-items-container > .container {
        width: 100%;
    }
    .new-design .card-items-container .grid-items-item {
        width: calc(33% - 50px);
    }
    .new-design .card-items-container .grid-items-item.col-md-3 {
        width: calc(33% - 50px);
        margin: 25px;
    }
    .new-design .card-items-container .grid-items-item,
    .new-design .card-items-container .grid-items-item.col-md-12 {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .new-design .card-items-container .grid-items-item .create-card-item {
        top: 84px !important;
    }

    .new-design .policy-distributions-container .partial.grid-items-item.col-md-4 .create-card-item {
        top: 53px !important;
    }
}

@media (max-width: 1100px) {
    .new-design .accordion-category-header__text {
        max-width: calc(100% - 50px);
    }
}

@media (max-width: 1300px) {
    .new-design nav.spy-scroll {
        width: 210px;

        .spy-scroll-list-item a {
            padding: 1px 10px;
        }
    }

    .new-design .sections-container {
        width: calc(100% - 210px);
        margin-left: 210px;
    }
}

@media (max-width: 991px) {
    .new-design nav.spy-scroll {
        display: none;
    }
    .new-design .sections-container,
    .new-design .create-new-item-container .create-new-item-container-inner {
        width: 100%;
        margin: 0;
    }

    .new-design .policy-distributions-container .partial.grid-items-item.col-md-4 {
        width: calc(50% - 50px);
        margin: 25px;
    }
    .new-design .head-options-container .container {
        width: 98%;
        margin: 0 1%;
    }
    .new-design .head-options-total-item-type,
    .card-new-design .items-management-head-details > li,
    .new-design .card-items-management-head-sorting-list li,
    .new-design .card-items-management-head .item-list-search-wrapper > input::-webkit-input-placeholder,
    .new-design .card-items-management-head .item-list-search-wrapper.search-option-open input {
        font-size: 13px;
    }
    .card-new-design .items-management-head-details > li.total span {
        font-size: 17.55px;
    }
    .new-design .card-items-container .grid-items-item.col-md-3 {
        width: calc(50% - 50px);
    }
}

@media (min-width: 768px) {
    .full .app_item_icon_under_title:before {
        position: relative;
        left: auto;
        top: auto;
        display: block;
        float: left;
        margin-right: 7px;
    }
    .full .app_item_icon_under_title {
        line-height: 2.5;
    }
    .full .item-list-item-header > div {
        display: inline-block;
    }
    .full .domain_and_date {
        width: 50%;
    }
    .full .title_and_state {
        width: 49%;
        margin-right: 1%;
    }
    .full .card-item-header-name {
        min-height: 0;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .full .card-item-left-side {
        width: 70%;
        float: left;
        padding: 25px 25px 20px;
        background-color: $white;
    }
    .new-design .full .card-item-bottom {
        width: 30%;
        border-left: 10px solid #edeff5;
        float: right;
        padding: 25px 25px 20px;
        background-color: $white;
        margin-top: 0;
    }
    .new-design .full .card-item-bottom .chart {
        position: relative;
        right: -25px;
        bottom: -26px;
        left: auto;
        width: calc(100% + 55px);
        height: 100%;
    }
    .new-design .card-items-container .full.grid-items-item > div {
        padding: 0;
        background-color: transparent;
    }
    .new-design .card-items-container .full.grid-items-item .item-list-item:before {
        background-image: none !important;
    }
    .new-design .full.grid-items-item > div:not(.deleted):hover {
        background: transparent;
    }
    .new-design .full .card-item-bottom .details {
        margin-bottom: -47px;
    }
    .full .two_sides_container {
        display: flex;
    }
    .full .create-new-card-item-button {
        display: block;
    }
    .full .item-list-item.card-item.create-card-item {
        display: none;
    }
    .two_sides_container .card-item-options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 767px) {
    .new-design .card-items-container .grid-items-item .item-list-item.create-card-item {
        height: 180px;
    }

    .new-design .card-items-container .grid-items-item .item-list-item {
        display: inline-block;
        width: 100%;
    }

    .new-design .card-item-bottom .chart {
        bottom: -2px;
    }

    .new-design .accordion-category-header__text {
        width: calc(100%);
        margin-bottom: 20px;
    }
    .new-design .item-modes-radio-wrapper,
    .new-design .Collapsible__trigger .dropdown,
    .new-design .application-states-wrapper {
        float: left;
    }
    div[data-debug-id='Application_Operator_Regions_Header'] .accordion-category-header__text {
        width: auto;
    }
    .new-design .Collapsible {
        margin-bottom: 0;
    }

    .new-design article.section {
        border-bottom: none;
    }
    .new-design .card-items-container .grid-items-item,
    .new-design .policy-distributions-container .partial.grid-items-item.col-md-4 {
        width: calc(100% - 50px);
    }
    .new-design .head-options-total-item {
        float: left;
        clear: both;
    }
    .new-design .head-options-total-item-type,
    .new-design .card-items-management-head-details > li {
        font-size: 13px;
        margin-bottom: 20px;
    }
    .new-design .card-items-management-head-sorting-list li {
        margin-left: 10px;
        padding-left: 10px;
    }
    .new-design .head-options-total-item.total {
        margin-bottom: 50px;
    }
    .new-design .head-options-total-item.total span {
        font-weight: bold;
    }
    .card-items-management-head .sort-search {
        float: left;
        margin-top: 0;
        width: 100%;
    }
    .new-design .card-items-management-head-sorting-list li {
        margin-bottom: 0;
    }
    .new-design .dropdown.active .st-tooltip-item .st-tooltip-item__content {
        height: auto;
    }
    .new-design .dropdown.active .st-tooltip-item {
        position: relative;
        left: auto !important;
        right: auto !important;
        bottom: auto !important;
        top: auto !important;
    }
    .new-design .card-items-management-head-details > li:not(:first-child):before {
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
    }
    .new-design .card-items-management-head-details > li:first-child {
        margin-bottom: 20px;
        margin-right: 0;
    }
    .new-design .card-items-management-head .item-list-search-wrapper.search-option-open input {
        min-width: 0;
        width: 100%;
    }
    .new-design .item-list-search-wrapper.search-option-open {
        margin-top: 0;
        margin-left: 10px;
        width: calc(100% - 300px);
    }
    .new-design .card-items-management-head .item-list-search-wrapper > input,
    .new-design .card-items-management-head .item-list-search-wrapper > input::-webkit-input-placeholder {
        font-size: 10.4px;
        min-height: 32px;
    }
    .new-design .card-items-container .grid-items-item.col-md-3 {
        width: 100%;
        margin: 25px 0;
    }
    .card-items-management-head-change_width_feature {
        display: none;
    }
    .card-items-management-head-sorting {
        border-left: none;
        padding-left: 0;
    }
    .new-design .refresh-button {
        display: none;
    }
    .searchIndication {
        margin: 0 8px 0;
        padding: 0;
    }

    .app_item_icon_under_title {
        display: none;
    }
}

@media (max-width: 576px) {
    .new-design .item-list-search-wrapper.search-option-open {
        max-width: 100%;
    }
    .card-items-management-head-sorting-list > li:last-child > button {
        padding-right: 0;
    }
    .card-items-management-head-sorting-list > li > button {
        border: none;
        background: none;
        outline: none;
    }
    .new-design .card-items-management-head .item-list-search-wrapper > input,
    .new-design .card-items-management-head .item-list-search-wrapper > input::-webkit-input-placeholder {
        font-size: inherit;
    }
    .new-design .sections-container {
        padding: 0 15px;
    }
    .new-design article.accordion-wrapper {
        margin: 0 -15px;
    }
    .new-design article.section#Applications_UpdateForm_Details_Tab {
        border-bottom: 1px solid #527b84;
    }
    .new-design .application-states-radio-wrapper,
    .new-design .item-modes-radio-wrapper,
    .new-design .region-select.dropdown {
        float: left;
        clear: left;
    }
    .new-design .application-states-title {
        margin-bottom: 10px;
    }
    .new-design .application-protecton-item-header-wrapper .dropdown {
        float: left;
        clear: left;
    }
    .new-design .application-protection-item-name {
        margin-bottom: 10px;
        max-width: 100%;
    }
    .new-design .card-items-container .grid-items-item {
        width: 100% !important;
        margin: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .new-design .item-list-search-wrapper.search-option-open {
        float: left;
        clear: both;
        margin-top: 20px;
        margin-left: 0;
        width: 100%;
    }
    .new-design .card-item-header-name {
        font-size: 13px;
    }
    .createApplication .new-design .input-with-names .dropdown + div {
        width: calc(100%);
        padding-left: 0;
        margin-top: 10px;
    }
    .createApplication .new-design .input-with-names .dropdown {
        width: 100%;
    }
    .item-create-form-protocol .item-create-form-protection > div:first-child,
    .item-create-form-protocol > div:first-child {
        width: 100%;
    }
    .item-create-form-protocol .certificate-select,
    .item-create-form-protection .technology-select {
        width: 100%;
        float: left;
    }

    .new-design .head-options-total-item-type,
    .new-design .card-items-management-head-details > li {
        margin-bottom: 20px;
    }
    .new-design .form-content-wrapper.origin-form .input-with-names .dropdown,
    .new-design .form-content-wrapper.origin-form .input-with-names .dropdown + div,
    .new-design .form-content-wrapper.origin-form .input-with-names .dropdown + div input {
        width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
        padding-left: 0;
    }
    .new-design .form-content-wrapper.origin-form .add-element {
        top: 80px;
    }
}

@media (max-width: 400px) {
    .new-design .refresh {
        float: left;
        clear: both;
    }
    .card-items-management-head-change_width_feature > div {
        margin-right: 10px;
    }
    .new-design .card-items-management-head-sorting-list li {
        margin-left: 5px;
        padding-left: 5px;
    }
    .card-items-management-head-sorting {
        padding-left: 0;
    }
    .new-design .card-items-management-head-sorting-list li {
        font-size: 11.7px;
        line-height: 1.7;
    }
}
