$fonts: '../static-assets/fonts/';

@font-face {
    font-family: 'Roboto';
    src: url('#{$fonts}Roboto-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: url('#{$fonts}Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url('#{$fonts}Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url('#{$fonts}Roboto-Medium.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Roboto';
    src: url('#{$fonts}Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
}
