.ace-editor {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    border: 1px solid $gray-900;
    box-sizing: content-box;
    z-index: 0;

    .ace-github {
        background-color: var(--editor-bg-color);
        color: var(--text-color);

        .ace_marker-layer {
            .ace_active-line {
                background-color: var(--editor-active-line);
            }

            .ace_selection {
                background-color: var(--editor-selection-color);
            }
        }

        &.ace_focus .ace_marker-layer .ace_active-line {
            background-color: var(--editor-focus-active-line);
        }
    }

    .ace_gutter {
        background-color: var(--editor-line-number-column);
    }

    .ace_print-margin {
        background-color: var(--editor-line-number-column);
    }

    &.diff-summary-editor {
        height: 350px;
        width: 1200px;
        margin-top: 10px;
    }

    &:not(.hideMarker) {
        .codeMarker {
            background: var(--editor-highlight-color);
            opacity: 0.3;
            position: absolute;
            z-index: 20;
        }
    }
}

.diff-editor-nav {
    display: flex;

    .prev-nav-btn,
    .next-nav-btn {
        margin-right: 5px;
        height: 24px;
        width: 90px;
        border: none;
        border-radius: 4px;
        font-size: 12px;
        display: flex;
        justify-content: center;

        &:hover {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
        }
    }

    .prev-nav-btn {
        background: $white;
        color: #4b4b4b;
    }

    .next-nav-btn {
        background: #6596b6;
        color: $white;
    }
}
