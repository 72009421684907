.items-header-new {
    margin-bottom: 24px;

    .title {
        > * {
            display: inline-block;
        }

        span {
            font-size: 26px;
            font-weight: bold;
            margin-left: 10px;
            line-height: 1.4;
        }
    }

    h1.card-items-management-head-title {
        margin-right: 80px;
        padding: 3px 0px;
    }

    .management-and-refresh {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 2px;
        padding-bottom: $page-padding;

        .add-button {
            margin-left: auto;
        }

        .card-items-management-head-details {
            display: flex;

            &.application-states {
                margin-left: 20px;

                label {
                    display: flex;
                }

                .checkbox-wrapper.checkbox-with-label label {
                    &:before {
                        top: 5px;
                    }

                    &:after {
                        top: 7px;
                    }
                }
            }
        }

        .create-card-item {
            margin-left: auto;
            float: none;
        }

        .item-list-search-wrapper {
            margin-bottom: 0;
        }
    }

    .genericBtns {
        border-left: 1px solid #b0b0b0;
        padding-left: 15px;
        display: flex;
        flex-wrap: wrap;
    }

    .head-options-total-item {
        display: flex !important;
        margin-right: 15px;

        span {
            font-size: 14px;
            margin-left: 5px;
            margin-top: 5px;
        }

        &.no-text:before {
            display: block;
            width: 27px;
            height: 19px;
            content: '';
            background-size: cover;
            background-repeat: no-repeat;
        }

        &.provisioning {
            span {
                color: #3fa8c9;
            }
        }
        &.learning {
            span {
                color: #ed6a00;
            }
        }
        &.protecting {
            span {
                color: #44aa6a;
            }
        }
        &.deleting {
            span {
                color: #94b8cb;
            }
        }
    }

    .create-card-item {
        margin: 0 0 0 10px;
        padding: 3px 20px;
        span {
            font-weight: 700;
            font-size: 26px;
        }
    }

    .total_results {
        font-size: 14px;
        color: var(--text-color);
        min-width: 190px;
        margin: 5px 15px;
        span {
            font-weight: 500;
        }
    }

    .refresh-button {
        margin-top: 3px;
        margin-right: 5px;
    }
}

@media (max-width: 1366px) {
    .items-header-new {
        .title {
            margin-right: 20px;
            h1.card-items-management-head-title {
                float: none;
            }
        }
        .management-and-refresh {
            margin-top: 10px;

            .head-options-total-item {
                margin-right: 10px;
            }
        }
    }
}
