.static-modal .modal {
    .modal-dialog {
        background: var(--modal-backdrop-color);

        .modal-content {
            border-radius: 12px;
            border: none;
            background-color: var(--modal-bg-color);
            box-shadow: 1px 1px 10px rgb(85 85 85 / 15%);

            .modal-header {
                border: 0;
                padding: 24px 24px 15px 24px;

                .modal-title {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: var(--text-color);
                }
            }

            .modal-body {
                font-size: 14px;
                line-height: 22px;
                color: var(--text-secondary-color);
                padding: 3px 24px 15px 24px;
            }

            .modal-footer {
                border: 0;
                padding: 15px 24px;

                @extend %global-modal-buttons;
            }
        }
    }
}
