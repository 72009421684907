/**********************************************************
    Application Configuration templates actions dropdown
**********************************************************/

.templates-dropdown-wrapper {
    width: auto !important;
    margin: 0 15px 0 auto;
    .templates-dropdown-menu {
        a:hover,
        a:focus {
            background-color: #f1f8fa;
        }
    }
}

.templates-dropdown-wrapper .templates-dropdown-toggle {
    padding: 8px 10px;
    border: 1px solid #e6e6e6;
    background-color: var(--button-bg-color);
    color: #6499b7 !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    border-radius: 0;
    transition: 0.2s all;
    &:hover {
        background-color: #dfe4f1;
    }
}

.add-from-template-wrapper {
    p.description {
        margin: 5px 10px 10px 10px;
        min-height: 18px;
    }
    @extend %global-modal-buttons;
    @extend %buttons-container;
}

.save-to-template-wrapper {
    @extend %global-modal-buttons;
    @extend %buttons-container;
    .inputs-container {
        width: $input-width;
        > div {
            margin: 5px 0 25px 0;
        }
    }
}

.static-modal {
    &.allowed-file-ext,
    &.database-protection,
    &.protection-rules {
        .modal-content {
            overflow: hidden;
        }
        .list-wrapper {
            height: 300px;
            > div {
                min-height: 100%;
                ul:last-child {
                    max-height: 260px;
                    overflow-y: auto;
                }
            }
        }
        .generic-form-options button {
            line-height: 1.5;
        }
    }
    .database-protection {
        .gen-list-headers {
            padding-right: 60px;
        }
    }
    .protection-rules {
        .list-wrapper {
            overflow: hidden;
            .rule-list-wrapper {
                .sort-header {
                    padding: 0 15px;
                    .property {
                        cursor: default;
                    }
                }
                .rule-list {
                    max-height: 260px;
                    overflow-y: auto;
                }
            }
        }
    }
}

/*********************************
    Policy Templates page/route
*********************************/
%template {
    margin-bottom: $list-item-gap;
    padding: 7.5px 20px;

    &:not(:last-child) {
        border-bottom: 1px solid var(--table-row-active-bg-color);
    }
}

@mixin templateProps($inTemplatesContainerHeader: false) {
    display: flex;
    > {
        @if $inTemplatesContainerHeader {
            * {
                font-size: 14px;
            }
        }
        :not(:last-child) {
            margin-right: 20px;
        }
    }
    .template {
        &-name {
            flex-basis: 350px;
            flex-grow: 0;
        }
        &-description {
            flex-basis: 300px;
            flex-grow: 1;
            max-width: 600px;
            margin-right: auto;
            padding-right: 20px;
        }
        &-rules,
        &-inherited {
            flex-basis: 100px;
            flex-grow: 0;
        }
        &-last-updated {
            flex-basis: 125px;
            flex-grow: 0;
        }
    }
}

.policy-templates-page {
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: $page-padding $page-padding 0 $page-padding;
        .card-items-management-head .card-items-management-head-title {
            padding: 0 0 16px 0;
        }
        .inner-navigator > .radio-group {
            margin-bottom: 10px;
            .help-tooltip {
                text-align: initial;
                cursor: initial;
            }
        }
        .templates-containers {
            display: flex;
            flex-direction: column;
            gap: 60px;
            margin-top: 30px;
            .templates-container {
                .template-options {
                    position: relative;
                    z-index: 1;
                    .toggle,
                    .delete {
                        position: absolute;
                        top: 0;
                        border: none;
                        &:not([disabled]) {
                            cursor: pointer;
                        }
                    }

                    .toggle {
                        right: 0;
                        width: 30px;
                        height: 30px;
                        &.down {
                            background: url(../static-assets/images/arrow-down.png) no-repeat center center;
                        }
                        &.up {
                            background: url(../static-assets/images/arrow-up.png) no-repeat center center;
                        }
                        &:hover {
                            opacity: 0.7;
                        }
                    }

                    .delete {
                        right: 25px;
                    }
                }

                .templates-container-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-bottom: 5px;
                    .templates-container-type {
                        margin: 5px 0;
                        span {
                            font-size: 18px;
                            color: var(--text-color);
                        }
                    }

                    .add-button {
                        margin-left: auto; // align to right when templates-container has no title
                    }
                    .ip-group-limit,
                    .form-content-wrapper {
                        display: flex;
                        align-items: center;
                        margin-top: 0px;
                        position: relative;
                        margin-bottom: 20px;
                        padding-left: 15px;
                        .ip-group-label {
                            width: 100px;
                            padding-right: 5px;
                        }
                        .field-wrapper {
                            input {
                                width: 100px;
                                height: 30px;
                            }
                            .invalid-input {
                                position: absolute;
                                left: 0;
                                top: 30px;
                            }
                        }
                        .generic-form-options {
                            margin-top: 0px;
                            button {
                                width: 90px;
                                line-height: unset;
                                padding: 5px 10px;
                                font-size: 13px;
                            }
                        }
                    }
                }
                .templates-container-body {
                    color: var(--text-color);
                    background-color: var(--table-bg-color);
                    border-radius: var(--table-border-radius);
                    padding-left: var(--table-side-gap);
                    padding-right: var(--table-side-gap);

                    .templates-container-body-headers {
                        @include templateProps(true);
                        border-bottom: 1px solid var(--table-row-active-bg-color);
                        padding: 0 60px 0 20px;
                    }
                    .template {
                        @extend %template;

                        .template-header {
                            @include templateProps();
                            padding-right: 40px;
                            .template-name,
                            .template-description {
                                margin-top: 5px;
                                margin-bottom: 5px;
                                padding: 5px 0;
                                @extend %text-no-overflow;
                            }
                        }
                        .template-body {
                            margin: 5px 0;
                            .generic-form-options {
                                float: none;
                            }
                            .template-fields {
                                @include templateProps();
                                position: relative;
                                margin-bottom: 10px;
                                padding-bottom: 10px;
                                padding-right: 20px;
                                border-bottom: 1px solid #d9d9d9;
                                input {
                                    padding: 8px;
                                    width: 100%;
                                }
                                > .invalid-input {
                                    position: absolute;
                                    right: 0;
                                    bottom: 5px;
                                    margin-right: 0;
                                }
                            }
                            .gen-list-mgmt-header .gen-list-mgmt-add-item {
                                margin-left: auto;
                            }
                            /* causes issues with Select height
                            ul.gen-list-items {
                                // list-item-indication isn't visible because of overflow
                                max-height: 250px;
                                overflow-y: auto;
                            }
                            */
                        }
                    }
                    .templates-container-no-data {
                        @extend %template;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-height: 48px; //$list-small-item-height;
                        .no-available-data {
                            position: static;
                            transform: none;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
    }

    .ips-group {
        .gen-list-item-input input {
            width: 330px;
        }
    }

    .source-blocking-settings-container {
        margin-top: 20px;
        padding-bottom: 20px;
        background-color: var(--card-bg-color);
        border-radius: var(--card-border-radius);

        .label-with-info-container {
            display: flex;
            justify-content: center;
        }

        .source-blocking-title {
            padding: 20px 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--text-color);
        }

        .source-blocking-sub-title {
            display: inline-block;
            margin-top: 30px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: var(--text-color);
        }

        .source-blocking-settings-form {
            padding: 0 5px;

            .excluded-applications-dual-list {
                margin-top: 16px;
                min-height: 275px;

                .controls {
                    width: 68px;
                    margin: 80px 55px 0 55px;

                    button {
                        border: 1px solid #cbd4db;
                        border-radius: 20px;
                        background-color: transparent;

                        &:not(:disabled) {
                            background-color: #cbd4db;
                        }
                    }
                }

                .available-list,
                .selected-list {
                    width: 300px;
                }

                .separator {
                    display: none;
                }

                .action-button {
                    color: #211dd9;
                    font-size: 18px;
                    font-weight: 400;
                }
            }

            .sensitivity-level-container {
                display: flex;
                align-items: center;
                gap: 10px;

                .sensitivity-level-field {
                    width: 148px;
                    margin-top: 5px;

                    .Select {
                        min-width: unset;
                    }
                }

                .source-blocking-threshold {
                    position: relative;

                    input {
                        width: 100px;
                    }
                }
            }

            .blocking-time-label-container {
                display: flex;
                align-items: center;
                margin-top: 32px;

                .help-tooltip {
                    padding-left: 10px;
                }

                .source-blocking-sub-title {
                    margin-top: 0;
                }
            }

            .blocking-time-container {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-top: 8px;

                .blocking-time-amount {
                    position: relative;

                    input {
                        width: 50px;
                    }
                }

                .blocking-time {
                    width: 100px;

                    .Select {
                        min-width: unset;
                    }
                }
            }

            .app-list {
                width: 150px;
                height: 250px;

                .Select-arrow {
                    display: none;
                }

                .Select-menu-outer {
                    min-height: 216px;
                }
            }

            .templates-policies-list {
                width: 547px;
                margin-top: 16px;
            }

            .invalid-input {
                position: absolute;
                width: 300px;
                bottom: -20px;
                left: 0;
            }

            .generic-form-options {
                margin-top: 160px;
            }
        }
    }
}
