.grid-items-item {
    display: inline-block;
    min-height: 100px;
}

.grid-items-item .item-list-item {
    background: var(--card-bg-color);
    border-radius: 10px;
    padding: 14px;
    position: relative;
    left: 0;
    right: 0;
    top: 0px;
    // margin: 5px;
    bottom: 0;
}

.ReactVirtualized__Grid.ReactVirtualized__List {
    &:focus {
        outline: none;
    }
}
