.main-content-wrapper.cyberIntl {
    padding: 0px;
}
.cyberIntl iframe {
    border: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
