.rate-limiting {
    .client-identifier-field {
        margin-bottom: 16px;
        width: 150px;
    }
    .rate-limiting-site-wide {
        margin-bottom: 5px;

        .gen-list-item-input .input-label {
            width: fit-content;
            cursor: pointer;
        }

        .rate-limiting-site-wide-config {
            margin-top: 15px;

            .rate-limiting-site-wide-config-row {
                display: flex;
                align-items: stretch;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                .rate-limiting-site-wide-field {
                    &:not(:last-child) {
                        margin-right: 10px;
                    }

                    .Select,
                    input {
                        width: 150px;
                    }
                }

                .block-time-field-info {
                    padding-top: 30px;
                }
            }
        }
    }

    .rate-limit-whitelist {
        .rate-limit-whitelist-ip-col {
            max-width: 260px;
        }
    }

    .rate-limit-rules {
        margin-bottom: 15px;
        .gen-list-item-input,
        .gen-list-headers-regular {
            &.rate-limit-rules-path-col {
                min-width: 260px;
            }

            &.small-column {
                min-width: 100px;
                max-width: 10%;
            }

            &:not(.rate-limit-rules-path-col) {
                div.Select {
                    min-width: unset;
                    width: 140px;
                }

                input {
                    width: 90px;
                }
            }
        }

        .rate-limit-rule-recursive-toggle {
            margin-top: 5px;
            margin-left: 22px;
        }
    }
}
