@keyframes progressBarAnimation {
    100% {
        width: 100%;
    }
}

.progress-bar-timer {
    position: relative;
    width: 100%;
    height: 20px;
    margin-top: 5px;
    border-radius: 10px;
    background-color: #ebecf0;
    overflow: hidden;

    .progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        animation: progressBarAnimation linear;
        animation-fill-mode: forwards; // keep animation changes on animation end
        background-color: $red-200;
        border-radius: 10px;
    }

    .time-left-text {
        position: relative;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        color: $dark-gray-400;
        margin: 0 auto;
        text-align: center;
        user-select: none;
    }
}
