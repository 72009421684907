.database-protection {
    .gen-list-items {
        .gen-list-item-fields {
            margin-top: 10px;

            .checkbox-field {
                margin: 10px 0;

                label {
                    font-size: 13px;
                }
            }
        }
    }

    .gen-list-mgmt-header {
        .gen-list-mgmt-add-item {
            margin-left: 0;
        }
    }
}
