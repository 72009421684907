.new-design .Collapsible {
    color: var(--text-color);
}

.new-design .Collapsible__contentInner {
    position: relative;
    padding: 20px;
}

.new-design .Collapsible__trigger {
    display: block;
    background: var(--card-bg-color);
    color: var(--text-color);
    border: 1px solid var(--card-separator-color);
    cursor: pointer;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

.new-design .Collapsible__contentInner {
    border-bottom: 1px solid var(--section-border-color);
}

.new-design .Collapsible__trigger:hover {
    transition: all 0.2s ease;
}

.new-design .Collapsible__contentOuter {
    border: 1px solid var(--card-separator-color);
}

.new-design .Collapsible__contentInner .application-operator-dns-records-wrapper {
    width: 30%;
}

.new-design .Collapsible__contentInner .application-operator-main-domain-form {
    position: relative;
}

.new-design .Collapsible {
    .dropDownItemWrapper {
        max-width: 350px;
        margin-bottom: 10px;
    }
}
