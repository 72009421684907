// TODO - most of the styles here are not related to certificates. refactor is needed

.search-option-open .search-options {
    background-image: url(../static-assets/images/search_close.svg);
    margin: 2px 0 0 5px;
}

.search-options {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.head-options:after {
    content: '';
    display: block;
    clear: both;
}

.item-list-search-wrapper {
    &:after {
        content: '';
        display: block;
        clear: both;
    }

    > * {
        float: right;
    }

    > input {
        width: 0px;
        padding: 0px;
        opacity: 0;
        // transition: 0.5s;
        border-radius: 5px;

        &::placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder {
            padding-left: 50px;
        }

        &::-webkit-input-placeholder {
            background-size: 12px 12px;
            background-repeat: no-repeat;
            opacity: 0.7;
        }
    }
    &.search-option-open input {
        padding: 5px;
        opacity: 1;
        position: absolute;
        right: 55px;
        width: calc(100% - 55px);
        z-index: 1005;
    }
}

.list-options-add,
.certificates-management-add {
    width: 22px;
    height: 22px;
    background-image: url(../static-assets/images/reports-add.svg);
    background-repeat: no-repeat;
    cursor: pointer;
    float: right;
    margin: 1px 0 0 5px;
}

table.details-table {
    font-size: 0.923em;
    color: #59595c;
    margin-top: 10px;
    td:first-child {
        padding-right: 8px;
    }
}

#Applications_UpdateForm_Network_Tab .certificate-select .dropdown {
    width: 320px;
}

.new-design .card-items-container .grid-items-item .item-list-item {
    display: inline-block;
    top: auto;
    min-height: 200px;
    height: 100%;
    width: 100%;
    cursor: pointer;
}
