@keyframes line-loader {
    from {
        left: -200px;
        width: 30%;
    }
    50% {
        width: 30%;
    }
    70% {
        width: 70%;
    }
    80% {
        left: 50%;
    }
    95% {
        left: 120%;
    }
    to {
        left: 100%;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes spin {
    0% {
        -ms-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -webkit-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
    50% {
        -ms-transform: rotate(5deg);
        -moz-transform: rotate(5deg);
        -webkit-transform: rotate(5deg);
        -o-transform: rotate(5deg);
        transform: rotate(5deg);
    }
    100% {
        -ms-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -webkit-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
}

@keyframes alertRotating {
    0% {
        -ms-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -webkit-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
    50% {
        -ms-transform: rotate(5deg);
        -moz-transform: rotate(5deg);
        -webkit-transform: rotate(5deg);
        -o-transform: rotate(5deg);
        transform: rotate(5deg);
    }
    100% {
        -ms-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -webkit-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rcSliderTooltipZoomDownIn {
    0% {
        opacity: 0;
        transform-origin: 50% 100%;
        transform: scale(0, 0);
    }
    100% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
    }
}

@keyframes rcSliderTooltipZoomDownOut {
    0% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
    }
    100% {
        opacity: 0;
        transform-origin: 50% 100%;
        transform: scale(0, 0);
    }
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes flickerAnimation {
    50% {
        opacity: 0.3;
    }
}

@keyframes blinkShadow {
    50% {
        box-shadow: 0px 0px 10px rgba(255, 247, 56, 0.8);
    }
}
