.back-to-top-btn {
    position: fixed;
    bottom: 65px;
    right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: #6596b6;
    border-radius: 50%;
    opacity: 0.6;
    z-index: 5000;
    cursor: pointer;

    &.hidden {
        display: none;
    }

    &:hover {
        opacity: 1;
    }

    .arrow-up-icon {
        background-image: url(../static-assets/images/icon-arrow-up.svg);
        width: 20px;
        height: 11px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
