.userContainer {
    .session_timeout_note {
        margin-left: 10px;
    }
}

.new-design .userContainer .sticky-item-header .section-header-title {
    clear: none !important;
    margin: 20px 0 0 20px;
}

.new-design .userContainer,
.new-design .create-new-item-container {
    .radio-group {
        display: flex;

        > div:not(:last-child) {
            margin-right: 20px;
        }
    }

    .inputs-row {
        display: flex;
        margin-bottom: 20px;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        > div {
            width: 50%;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }

    .accounts-dual-list {
        margin-top: 5px;
    }

    .accounts-select,
    .accounts-dual-list {
        .select-all-customers-option,
        .customer-account-option,
        .parent-account-option {
            display: flex;
            align-items: center;

            &:before {
                content: '';
                width: 5px;
                height: 5px;
                margin-right: 8px;
                background-color: $blue-100;
                display: inline-block;
                line-height: 2;
            }
        }

        .customer-account-option {
            margin-left: 10px;

            &:before {
                border-radius: 50%;
                background-color: $black;
            }
        }

        .select-all-customers-option {
            margin-left: 10px;
            font-style: italic;

            &:before {
                background-color: transparent;
            }
        }
    }
}
