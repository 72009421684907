.access-rules-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.access-rules-footer {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.access-rules-default-action {
    display: flex;
    align-items: center;
    width: 300px;

    &-text {
        width: 150px;
    }
}

.access-rules-add-item {
    margin-left: auto;
    display: flex;
    align-items: center;

    &-button {
        color: #6d9fbb;
        background: #f1f8fa;
        border: 1px solid #e6e6e6;
        height: 32px;
        width: 150px;
        font-size: 14px;
    }

    &-error {
        margin-right: 20px;
        color: $error-color;
        font-size: 13px;
    }
}

.access-rules-list {
    /* cannot use because of the Select
        max-height: 400px;
        overflow: auto;
    */
    padding-left: 20px;
    margin-left: -15px;
}

.access-rules-item {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--table-row-border-color);
    background-color: var(--table-bg-color);
    width: 100%;
    position: relative;
    padding: 10px 15px 10px 40px;
    /* padding-left: 44px; */

    &:first-child {
        border-top: 1px solid var(--table-row-border-color);
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    &.delete {
        opacity: 0.6;
    }
}

.access-rules-item-content {
    display: flex;
    align-items: center;
    justify-content: center;

    > textarea {
        background-color: #f7fdff;
        border: 1px solid #e6e6e6;
        padding: 4px;
        margin-top: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        display: inline-block;
    }

    &-toggle {
        display: inline-block;
        font-size: 13px;
        font-weight: 500;
        width: 80px;
        > div {
            margin-top: 3px;
        }
        &.block {
            color: #eb5353;
        }
        &.permit {
            color: #6ec089;
        }
    }

    &-ip {
        width: 350px;
        padding: 0 10px;
        font-size: 13px;
        font-weight: 500;
        .Select {
            font-weight: initial;
        }
    }

    &-description {
        width: calc(100% - 550px);
        min-width: 250px;
        font-size: 13px;
        padding-right: 10px;
        @extend %text-no-overflow;
        textarea[type='text'] {
            width: 100%;
            padding: 5px 10px;
            border: 1px solid #e6e6e6;
            resize: none;
            font-size: 12px;
        }
    }
}

.access-rules-item-order {
    width: 30px;
    font-size: 13px;
}

.access-rules-item-toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: auto;
    > button {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
    &-arrow {
        width: 16px;
        height: 11px;
        color: $white;
        border: none;

        &.open {
            background: url(../static-assets/images/arrow-down.png) center center;
            background-size: cover;
        }
        &.close {
            background: url(../static-assets/images/arrow-up.png) center center;
            background-size: cover;
            opacity: inherit;
        }
    }
}

.access-rules-item-handler {
    width: 20px;
    background: url(../static-assets/images/drag-handler.svg);
    /* height: 15px; */
    position: absolute;
    top: 11px;
    bottom: 11px;
    left: 10px;
    cursor: all-scroll;
}

.access-rules-item-action-button {
    width: 16px;
    height: 16px;
    color: $white;
    border: none;

    &.delete {
        background: url(../static-assets/images/delete-icon.svg) center center;
    }
    &.discard-update {
        background: url(../static-assets/images/cancel-update.svg) center center;
    }
    &.discard-delete {
        background: url(../static-assets/images/cancel-delete.svg) center center;
    }
}

.new-design .access-rules-form .form-switch-btn-wrapper.toggle-with-text .form-switch-btn {
    &.on:before {
        content: 'Permit';
    }
    &.off:before {
        content: 'Block';
    }
}
