.sign-in-wrapper .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4500;
}

.sign-in-wrapper .footer,
.app-footer .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $global-footer-height;
    background-color: var(--footer-bg-color);
    padding: 0 15px;
    font-size: 11px;
    line-height: normal;

    a {
        color: var(--footer-text-color);
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }

        &:not(:first-child) {
            margin-left: 20px;
        }

        &.feedback {
            padding: 2px 10px;
            color: var(--footer-bolded-text-color);
            background-color: var(--footer-bolded-text-bg-color);
            border-radius: 4px;
            opacity: 1;
            font-weight: 500;
            &:hover {
                box-shadow: 0px 0px 6px var(--footer-bolded-text-hover-bs-color);
            }
        }
    }
}
