.spy-scroll {
    display: inline-block;
    position: fixed;
    top: 170px;
    width: 230px;
}

.spy-scroll-list {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
}

.sections-screen {
    background: #f4f6f9;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid grey;
    overflow: auto;
}

.spy-scroll-list-item {
    cursor: pointer;
}

.spy-scroll-list-item a {
    font-size: 1.2em;
    padding: 20px;
    display: block;
    color: black;
    user-select: none;
}

.spy-scroll-list-item a.active {
    background: #d1effe;
    border-radius: 10px;
    font-weight: 600;
}
