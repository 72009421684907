$small-checkbox-size: 10px;
$large-checkbox-size: 15px;

$small-checked-el-size: 6px;
$large-checked-el-size: 9px;

.checkbox-wrapper {
    position: relative;

    input[type='checkbox'] {
        display: none;

        &:checked + label:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: $large-checked-el-size;
            height: $large-checked-el-size;
            border-radius: 1px;
            background: var(--checkbox-selected-color);
            transform: translate(35%, 55%);
        }

        &:hover,
        &:checked {
            & + label:before {
                border-color: var(--checkbox-selected-color);
            }
        }

        &:disabled {
            + label:before {
                opacity: unset;
                border-color: var(--checkbox-border-disabled-color);
            }

            + label:after {
                background: var(--checkbox-border-disabled-color);
            }
        }
    }

    label {
        &:before {
            content: '';
            position: absolute;
            top: 2px;
            left: 0;
            width: $large-checkbox-size !important;
            height: $large-checkbox-size;
            margin: unset;
            border: 1px solid #bdbfc7;
            border-radius: 4px;
            outline: none;
            background: $input-bg-color;
        }
    }
}

.checkbox-wrapper.checkbox-with-label {
    display: flex;
    align-items: center;

    input[type='checkbox'] {
        &:checked + label:after {
            width: $small-checked-el-size;
            height: $small-checked-el-size;
        }

        &:checked + label:after {
            top: 4px;
            left: 2px;
            transform: unset;
        }

        &:checked {
            & + label:before {
                border-width: 0.666667px;
                border-radius: 2.66667px;
            }
        }
    }

    label {
        margin: 0;
        padding-left: 15px;

        &:before {
            width: $small-checkbox-size !important;
            height: $small-checkbox-size;
            border-radius: 2px;
        }
    }

    label,
    label > span {
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 14px !important;
        color: var(--checkbox-label-color) !important;
    }
}

.item-list-search-wrapper.search-input-wrapper,
.search-input-wrapper {
    display: flex;
    min-width: 300px;
    @include control-border;
    border-radius: 24px;
    background-color: transparent;
    height: 30px;
    padding: 0 5px 0 11px;

    &.disabled {
        pointer-events: none;
        opacity: 0.38;

        & > * {
            color: #959595;
        }
    }

    input {
        flex-grow: 1;
        border: none !important;
        border-radius: 8px;
        @include control-font;
        outline: 0;
        padding: 8px 0;
        width: 100%;
        min-width: unset;
        height: 100%;
        background-color: var(--search-bg-color);
        color: var(--search-input-text-color);

        &::placeholder,
        &::-webkit-input-placeholder {
            font-family: 'Roboto';
            font-style: italic !important;
            @include control-font;
            font-weight: 300;
            padding-left: 0;
            color: var(--search-input-placeholder-color);
            opacity: unset;
        }

        &:focus {
            outline: none;
            border: none;
            box-shadow: none;
        }
    }

    .search-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 25px;
    }

    .search {
        color: var(--search-icon-color);
    }

    .cancel {
        display: none;
        color: var(--search-active-border-color);
    }

    &:focus-within,
    &:focus {
        .cancel {
            display: inherit;
        }

        .search {
            display: none;
        }
    }
}
