#chartjs-tooltip {
    position: absolute;
    background-color: var(--tooltip-bg-color);
    padding: $tooltip-padding !important;
    z-index: 14001;
    color: var(--tooltip-text-color);
    border-radius: $tooltip-border-radius;
    filter: $tooltip-filter;
}

.tooltip-header {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 13px;
    color: var(--tooltip-text-color);
}

.tooltip-header-value {
    padding-left: 15px;
    text-align: right;
}

.tooltip-body-row {
    font-size: 10pt;
    color: var(--tooltip-text-color);
}

.tooltip-body-row-value {
    padding-left: 15px;
    text-align: right;
}

/*
.information-tooltip-icon {background-image: url('../static-assets/images//i_tooltip_icon.svg'); height: 12px; width: 12px; background-size: contain; }
.information-tooltip-icon:hover { background-image: url('../static-assets/images//i_tooltip_icon_over.svg'); cursor: pointer; }
.information-tooltip-icon + div > div{background-color:#fffbde;border:1px solid #b0b0b2; border-radius:0;padding: 4px 8px;color: #333;font-size:0.932em}
*/
.help-tooltip {
    display: inline-block;
    padding-left: 5px;
}

.help-tooltip,
.information-tooltip-icon-container {
    .__react_component_tooltip {
        z-index: 9999;
    }
}

.disableFromCss {
    pointer-events: all;
    background-color: inherit;
}

.info-tooltip {
    display: inline-block;
    z-index: 9999;

    .rc-tooltip-content .rc-tooltip-inner {
        padding: $tooltip-padding;
        max-width: 650px;
        background: var(--tooltip-bg-color);
        color: var(--tooltip-text-color);
        border-radius: $tooltip-border-radius;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.6;
        filter: $tooltip-filter;
    }
}

.dropdown-tooltip-text {
    max-width: 300px;
    font-size: inherit;
}
