.app-sidebar {
    $toggle-icon-size: 26px;
    $icons-size: 20px;
    $transition-duration: 0.4s;
    $left-margin: $app-left-gap;
    $opened-sidebar-border-radius: 18px;
    $item-height: 40px;

    position: relative;
    display: flex;
    flex-direction: column;
    width: $closed-sidebar-width;
    overflow: hidden;
    margin: 0 0 $content-bottom-gap $left-margin;
    padding-top: 2px;
    font-size: 14px;
    color: $new_white;
    background-color: var(--sidebar-bg-color);
    transition: width $transition-duration ease-in-out;
    box-shadow: 0px 0px 4px var(--sidebar-shadow-color);
    border-radius: calc(#{$closed-sidebar-width} / 2);

    *:focus {
        outline: none;
    }

    &.opened {
        border-radius: $opened-sidebar-border-radius;
    }

    @media (min-width: 1366px) {
        &.opened {
            width: $opened-sidebar-width;

            .menu-wrapper .menu-item {
                &.active {
                    .menu-item-trigger {
                        background-color: var(--sidebar-item-hover-bg-color);
                    }
                }

                &.opened {
                    background-color: var(--sidebar-item-bg-color);

                    .menu-item-trigger {
                        opacity: 1;
                    }

                    .menu-item-collapse-icon {
                        transform: rotate(-90deg);
                    }
                }

                .menu-item-trigger:hover {
                    background-color: var(--sidebar-item-hover-bg-color);
                }
            }

            .menu-sub-items .menu-sub-item {
                &.active,
                &:hover {
                    opacity: 1;
                    background-color: var(--sidebar-item-hover-bg-color) !important;
                }
            }

            .toggle-sidebar-btn {
                left: calc(#{$opened-sidebar-width} - (#{$toggle-icon-size} / 2) + #{$left-margin});

                .toggle-sidebar-icon {
                    transform: scaleX(-1);
                }
            }
        }
    }

    @mixin menu-item() {
        .menu-wrapper .menu-item:not(.menu-sub-item) {
            // behave like a tooltip
            .menu-sub-items {
                position: fixed; // not absolute because sidebar's overflow is hidden
                left: $closed-sidebar-width + $left-margin + 7px;
                min-width: 168px;
                z-index: 10000;
                margin-top: -50px;
                padding: 5px 0;
                overflow: initial;
                opacity: 0;
                visibility: hidden;
                background-color: var(--sidebar-tooltip-bg-color);
                border-radius: 12px;
                box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.2);
                font-size: 12px;
                transition: opacity 0.25s ease-in-out /*, visibility 0s ease-in-out */;

                // tooltip's triangle
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 22px;
                    left: -7px;
                    border-top: 8px solid transparent;
                    border-bottom: 8px solid transparent;
                    border-right: 7px solid var(--sidebar-tooltip-bg-color);
                }

                // improves hovering behavior and area
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 8px;
                    left: -7px;
                    width: 7px;
                    height: 100%;
                }

                .menu-sub-item {
                    padding-left: 17px;
                }
            }

            &:hover .menu-sub-items {
                max-height: 330px;
                opacity: 1;
                visibility: initial;
                transition: opacity 0.25s ease-in-out /*, visibility 0.25s ease-in-out */;
            }
        }
    }

    &:not(.opened) {
        @include menu-item();
    }

    @media (max-width: 1366px) {
        @include menu-item();

        .toggle-sidebar-btn {
            display: none !important;
        }
    }

    .menu-wrapper {
        position: relative;
        .menu-item {
            display: block;
            margin: 5px 0;
            color: inherit;
            text-decoration: inherit;
            user-select: none;

            &:not(.menu-sub-item) {
                margin: 20px 0;
            }

            &.active {
                .menu-item-trigger {
                    opacity: 1;
                    background-color: var(--sidebar-tooltip-item-hover-bg-color);

                    // red marker of active category
                    &:before {
                        content: '';
                        position: absolute;
                        background-color: var(--sidebar-item-active-indicator-bg-color);
                        height: inherit;
                        width: 4px;
                        left: 0;
                        border-radius: 0px 10px 10px 0px;
                    }
                }
            }

            &:hover {
                position: relative;
                cursor: pointer;

                .menu-item-trigger {
                    opacity: 1;
                }
            }

            .menu-item-trigger {
                display: flex;
                align-items: center;
                height: $item-height;
                padding-left: 14px;
                padding-right: 5px;
                opacity: 0.7;
                transition: background-color 0.2s ease-in, box-shadow 0.2s ease-in;

                &:hover {
                    opacity: 1;
                    background-color: var(--sidebar-tooltip-item-hover-bg-color);
                    box-shadow: 0px 0px 5px 2px rgba($new_white, 0.15);
                }

                .menu-item-collapse-icon {
                    margin: 0 0 0 auto;
                    transition: transform $transition-duration;
                    background: url(../static-assets/images/new-design/angleRight3.svg) no-repeat center;
                    width: 30px;
                    height: 30px;
                    transform: rotate(90deg);
                }
            }

            .menu-item-icon {
                width: $icons-size;
                height: $icons-size;
                margin-right: 18px; // is needed?
                padding-right: $icons-size;

                &.Dashboard {
                    background: url(../static-assets/images/dashboard.svg) no-repeat center;
                }
                &.Monitor {
                    background: url(../static-assets/images/shield.svg) no-repeat center;
                }
                &.Assets {
                    background: url(../static-assets/images/briefcase.svg) no-repeat center;
                }
                &.Account {
                    background: url(../static-assets/images/account.svg) no-repeat center;
                }
                &.help {
                    background: url(../static-assets/images/help2.svg) no-repeat center;
                }
            }
        }

        .menu-sub-items {
            max-height: 0;
            overflow: hidden;
            transition: max-height $transition-duration; // fallback
            transition: max-height $transition-duration cubic-bezier(0, 1, 0.65, 1);

            &.opened {
                max-height: 330px; // based on actual max list height + extra
                transition: max-height $transition-duration; // fallback
                transition: max-height $transition-duration cubic-bezier(0.4, 0, 1, 1);
            }

            .menu-sub-item {
                display: flex;
                align-items: center;
                height: $item-height;
                padding-left: 55px;
                padding-right: 18px;
                opacity: 0.7;
                transition: background-color 0.2s ease-in, box-shadow 0.2s ease-in;

                &:hover:not(.active) {
                    box-shadow: 0px 0px 5px 2px rgba($new_white, 0.15);
                }

                &:hover,
                &.active {
                    opacity: 1;
                    background-color: var(--sidebar-tooltip-item-hover-bg-color);
                }
            }
        }
    }

    .portal-theme-toggle {
        display: flex;
        align-items: center;
        margin-top: auto;
        margin-bottom: calc(#{$global-footer-height} + 30px + 40px);
        width: $opened-sidebar-width;

        .theme-toggle {
            margin-left: 4px;
            margin-right: 6px;
            transform: rotate(90deg);
        }

        .theme-toggle-label {
            position: relative;
            top: 11px;
        }
    }

    .toggle-sidebar-btn {
        position: fixed;
        left: calc(#{$closed-sidebar-width} - (#{$toggle-icon-size} / 2) + #{$left-margin});
        bottom: calc(#{$global-footer-height} + #{$content-bottom-gap} + 30px);
        display: flex;
        padding: 0;
        border-radius: 50%;
        background-color: $new_red_100;
        border: none;
        transition: left $transition-duration ease-in-out;
        z-index: 1;
        cursor: pointer;

        &:hover {
            box-shadow: var(--toggle-sidebar-btn-bs);
        }

        .toggle-sidebar-icon {
            transition: transform $transition-duration ease-in-out;
            background: url(../static-assets/images/new-design/angleRight3.svg) no-repeat center;
            width: $toggle-icon-size;
            height: $toggle-icon-size;
        }
    }
}
