.introduction-dialog-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(24, 22, 22, 0.7);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.4s, z-index 0.4s;

    &.visible {
        opacity: initial;
        z-index: 10000;
    }

    .introduction-dialog {
        position: fixed;
        display: flex;
        flex-direction: column;
        width: 470px;
        height: 164px;
        padding: 15px 24px;
        border-radius: 4px;
        background-color: $white;
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.2);

        // triangle
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -20px;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-right: 20px solid $white;
        }

        h4 {
            font-weight: 600;
            color: var(--text-color);
        }

        p {
            margin-top: 10px;
            font-size: 14px;
            color: #212121;
        }

        button {
            margin-top: 15px;
            padding: 8px 30px;
            align-self: center;
            font-size: 14px;
            font-weight: 500;
            border: none;
            border-radius: 4px;
            background-color: $blue-500;
            color: $white;
            &:hover {
                background-color: #5d88a4;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
            }
        }
    }
}
