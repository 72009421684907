.st-accordion__inner .reports-form-container {
    background-color: $white;
    box-shadow: none;
    padding: 0;
}

.create-alerts-wrapper {
    position: relative;
    clear: both;
    background-color: $white;
    padding: 15px;
    box-shadow: none;
    border: 1px solid #e7e7e8;
}

.tokens-field-container-wrapper.invalid {
    border: 1px solid #be1e2d;
}

.form-content {
    padding: 10px 16px 10px 16px;
    border-bottom: none;
}

.reports .widget-close {
    top: 8px;
    right: 5px;
    display: none;
}

.st-accordion__inner .reports-form-container > .row > div[class^='col-'] {
    position: relative;
}

.st-accordion__inner .reports-form-container > .row > div[class^='col-']:before {
    content: ' ';
    display: block;
    position: absolute;
    height: 1px;
    background-color: #585858;
    left: 15px;
    right: 15px;
    top: -8px;
}

.reports-header h1 {
    background-image: url(../static-assets/images/reports-title-icon.svg);
}

.form-options {
    padding: 10px 16px 0;
    margin-top: 12px;
    overflow: hidden;
}

.form-options > div:first-child {
    padding-left: 30px;
}

.form-options .pull-left {
    margin-right: 40px;
    background-repeat: no-repeat;
    position: relative;
    opacity: 0.8;
}

.reports-form-container .form-options .pull-left {
    padding-left: 0;
    margin-left: 25px;
}

.reports-form-container .generic-form-options {
    position: absolute;
    right: 45px;
    top: 0;
}

/*Put space for the OLH*/

.reports-form-container__radio-buttons {
    margin-right: 12px;
    display: inline-block;
}

.form-options .pull-left:hover {
    opacity: 1;
    cursor: pointer;
}

.form-options .pull-left:last-child {
    margin-right: 0;
}

.form-options .pull-left:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: -3px;
    height: 22px;
    width: 22px;
    background-repeat: no-repeat;
}

.form-send-now {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
}

.form-send-now:before {
    background-image: url(../static-assets/images/form-send-now.svg);
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: -3px;
    height: 22px;
    width: 22px;
    background-repeat: no-repeat;
}

.form-options .pull-left.form-discard:before {
    background-image: url(../static-assets/images/form-discard.svg);
}

.form-options .pull-left.form-save:before {
    background-image: url(../static-assets/images/form-save.svg);
}

.form-options .pull-left.form-delete:before {
    background-image: url(../static-assets/images/new-design/delete-icon.svg);
}

.deleteConfirmationForm:first-child {
    position: absolute;
    transform: translate(-200%, 0);
    white-space: nowrap;
    background-color: $white;
    z-index: 100;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.deleteConfirmation:last-child {
    transform: translate(0, 0);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.deleteOption:last-child {
    transform: translate(-250%, 0);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.deleteConfirmationForm.open:first-child {
    transform: translate(-25px, -3px);
    padding-top: 3px;
}

.deleteConfirmationForm:first-child > div {
    display: inline-block;
    position: relative;
    padding-left: 25px;
}

.deleteConfirmationForm:first-child > div:nth-child(1) {
    margin-right: 15px;
    color: #6884a7;
}

.deleteConfirmationForm:first-child > div:nth-child(2) {
    color: #af3f37;
}

.deleteConfirmationForm:first-child > div:nth-child(1):before {
    content: ' ';
    display: inline-block;
    background-image: url('../static-assets/images/report-delete-no.png');
    width: 19px;
    height: 20px;
    position: absolute;
    top: -3px;
    left: 0;
}

.deleteConfirmationForm:first-child > div:nth-child(2):before {
    content: ' ';
    display: inline-block;
    background-image: url('../static-assets/images/report-delete-yes.png');
    width: 19px;
    height: 22px;
    position: absolute;
    top: -3px;
    left: 0;
}

.reports-form-container .form-switch-btn-wrapper {
    font-size: 0.85em;
}

.reports-add {
    width: 22px;
    height: 22px;
    background-image: url(../static-assets/images/reports-add.svg);
    cursor: pointer;
}

.reports-list {
    height: 100%;

    .item-list {
        height: calc(100% - 70px);
    }
}

.reports-list .disabled {
    opacity: 0.38;
    cursor: not-allowed;
}

.reports-head {
    margin-bottom: 7px;

    .search-options {
        margin-top: 2px;
        margin-right: 2px;
    }

    h1 {
        margin-bottom: 13px;
    }

    .pull-left {
        float: unset !important;
    }

    .search-input-wrapper {
        float: right;
        margin-right: 15px;

        input {
            opacity: 1;
        }
    }
}

.reports-head:after {
    content: ' ';
    display: block;
    clear: both;
}

.reports-head > h1:before {
    content: ' ';
    display: inline-block;
    background-image: url(../static-assets/images/reports-title-icon.svg);
    height: 22px;
    width: 22px;
    margin-right: 10px;
}

.reports-head {
    margin-bottom: 10px;
    position: relative;
    border-bottom: none;
}

.tokens-field-container-wrapper {
    background-color: $input-bg-color;
    width: 100%;
    height: 100px;
    border: 1px solid var(--control-border-color);
    border-radius: 2px;
    color: var(--control-text-color);
    padding: 5px;
    font-size: 11px;
    margin-top: 2px;
    overflow: auto;
    margin-bottom: 10px;
    max-width: 500px;
}

.tokens-field-container-wrapper > div > div {
    display: inline-block;
    margin-right: 10px;
}

.tokens-field-container-wrapper .token {
    margin-bottom: 5px;
    color: #264769;
}

.tokens-field-container-wrapper .token.invalid {
    color: #990202;
}

.tokens-field-container-wrapper .token span,
.tokens-field-container-wrapper .token a {
    display: inline-block;
    background-color: #ededed;
    border: 1px solid #d9d9d9;
    padding: 2px 5px;
}

.tokens-field-container-wrapper .token span {
    border-radius: 2px 0 0 2px;
    border-right: none;
}

.tokens-field-container-wrapper .token a {
    border-radius: 0 2px 2px 0;
    border-left: none;
}

.tokens-field-container-wrapper .token.invalid a {
    color: #990202;
}

.tokens-field-container-wrapper .token.invalid span,
.tokens-field-container-wrapper .token.invalid a {
    background-color: #f6abba;
}

.tokens-field-container-wrapper .token.selected {
    border: 1px solid #52a8ec;
}

.tokens-field-container-wrapper .token input + a {
    background-color: transparent;
    border: none;
}

.tokens-field-container-wrapper input {
    font-size: 11px;
    background: 0 0;
    border: 0;
    height: 20px;
    padding: 0;
    margin-bottom: 6px;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    color: var(--control-text-color);
}

.tokens-field-container-wrapper .token-remove {
    cursor: pointer;
}

.tokens-field-container-wrapper .tokens-field-container {
    float: left;
}

.tokens-field-container-wrapper .tokens-field-click-area {
    height: 100%;
}

.tokens-field-container-wrapper input::-ms-clear {
    display: none;
}

.report-recipients:before,
.report-period:before,
.report-periodic-mails:before {
    content: ' ';
    display: inline-block;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: -1px;
}

.report-period:before {
    background-image: url('../static-assets/images/report-calender-icon.svg');
}

.report-periodic-mails:before {
    background-image: url('../static-assets/images/report-time-icon.svg');
}

.report-recipients:before {
    background-image: url('../static-assets/images/report-email-icon.svg');
}
