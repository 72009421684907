.users-head.item-list-header h1 {
    background-image: url(../static-assets/images/user_title_icon.svg);
    background-position: left, center;
}

/*.users-list-item {background-image: url(../static-assets/images/user_item_icon.svg); background-position: left, center; background-repeat: no-repeat;}*/

.users-list-item.pending {
    background-image: url(../static-assets/images/user_pending_icon.svg);
    background-position: left, center;
    background-repeat: no-repeat;
}

.users-list-item .inetation-state {
    color: #be1e2d;
}

.user-details .dropdown {
    width: 281px;
}

.user-details .input-label input {
    width: 281px;
    /* margin-bottom: 10px; */
}

.user-details-form {
    border-bottom: 1px solid #bdbfc1;
    padding-bottom: 10px;
}

.user-details-form h1 {
    background-image: url(../static-assets/images/user_title_icon.svg);
    background-position: left, center;
}

.users-add {
    width: 22px;
    height: 22px;
    background-image: url(../static-assets/images/reports-add.svg);
    margin: 1px 0 0 5px;
    cursor: pointer;
}

.users .dep-msg {
    position: absolute;
    left: 50%;
    top: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    border-bottom: solid 1px;
    border-top: solid 1px;
    padding: 5px 70px 5px 25px;
    background: url(../static-assets/images/info_icon.svg) 0/16px no-repeat;
}

.users .card-items-container .container {
    position: initial;
}

.item-list .generic-item > div:first-child.users-list-item.me {
    background-color: #d2d4d5;
    cursor: default;
}

.user-security_button-wrapper {
    margin-top: 10px;
}

.user-security_button-wrapper label {
    display: block;
    font-weight: 500;
}

.action-button,
.inner .form-save {
    font-size: 12px;
    background-color: #6c6e70;
    color: $white;
    border: none;
    padding: 3px 8px;
    border-radius: 3px;
    outline: none;
    min-width: 60px;
    /* opacity:1; */
    text-align: center;
    line-height: unset;
}

.action-button.set-of-buttons {
    margin-right: 5px;
}

.action-button:hover {
    background-color: #2c3b42;
}

.action-button.disabled,
.action-button.disabled:hover {
    background-color: #e9e9e9;
    cursor: default;
}

.user-management-title.form-header {
    border-bottom: none;
}

.user-management-title.form-header {
    padding-bottom: 0;
}

.user-management-title.form-header h1 {
    line-height: 2;
}

.me-user-list-item {
    position: absolute;
    bottom: 0;
    cursor: pointer;
}

.item-list-header .disabled {
    opacity: 0.38;
    cursor: not-allowed;
    pointer-events: inherit;
}

.user-management.loading {
    position: relative;
}

.user-management.loading:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    display: block;
    z-index: 10000;
    background: rgba(244, 245, 249, 0.4);
}

.data-loader {
    font-size: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #a5a9ac;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #a5a9ac 10%, rgba(255, 255, 255, 0) 42%);
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999999;
}

.data-loader:before {
    width: 50%;
    height: 50%;
    background: #a5a9ac;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.data-loader:after {
    background: rgb(249, 250, 252);
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/***************
    UserInfo
 ***************/

.UserActions.dropdown > div:first-child {
    background-color: inherit;
}

.UserActions {
    margin: 0 0 0 10px;
    outline: none;
}

.UserActions_Trigger {
    color: white;
    width: 40px;
    height: 42px;
    padding: 10px 0 0 0;
    cursor: pointer;
    background: url(../static-assets/images/v.svg) no-repeat center;
}

.UserActions_Trigger:hover {
    background-color: #be1e2d;
}

.UserActions .st-tooltip-item {
    width: auto !important;
    right: 0;
    left: inherit !important;
}

.UserActions li.st-list--row {
    position: relative;
    cursor: pointer;
}

.UserActions li.st-list--row:hover {
    border-left-color: #c11e14;
}

.UserActions .st-list--row.st-list--selected,
.UserActions .st-list--row.st-list--selected a,
.UserActions .st-list--row.st-list--selected span {
    box-shadow: none;
}

.userActions_trigger_container .dropdown.active {
    .st-tooltip-item {
        background-color: #383e3e;
        border: none;
        top: 46px;
        min-width: 200px;
        padding: 10px 0;
        ul.st-list--table {
            list-style: none;
            width: 100%;
            background-color: transparent;
            padding: 0;
            li span,
            li a {
                padding: 10px 20px;
                color: #f7fdff !important;
                font-weight: light;
                font-size: 14px;
                background-color: #383e3e;
                border: none;
                &:hover {
                    font-weight: normal;
                }
            }
        }
    }
}

.UserActions .st-list--row:hover {
    span,
    a {
        text-decoration: underline !important;
    }
}

.UserActions .st-list--row:hover span,
.UserActions .st-list--row.st-list--selected,
.UserActions .st-list--row.st-list--selected a,
.UserActions .st-list--row.st-list--selected span {
    font-weight: normal;
}

.UserActions .st-list--row {
    span,
    a {
        &:before {
            margin-right: 10px;
            float: left;
        }
    }
}

@media (max-width: 767px) {
    .userActions_trigger_container .dropdown.active .st-tooltip-item {
        background-color: transparent !important;
        border: none;
        top: auto;
        min-width: 0;
        padding: 10px 0;
    }

    .userActions_trigger_container .dropdown.active .st-tooltip-item ul.st-list--table li span,
    .userActions_trigger_container .dropdown.active .st-tooltip-item ul.st-list--table li a {
        padding: 0;
        background-color: transparent !important;
    }
}

.session-expiration-wrapper {
    display: flex;
    align-items: center;
}

.session-expiration-wrapper input[type='text'] {
    margin: 0 5px 0 0;
}

.profile-configuration-nav {
    .allowedIPs {
        float: left;
    }
    .userSecurityInnerWrapper {
        margin-top: 20px;
        float: left;
        position: relative;
        width: 100%;
        margin-bottom: 5px;
        label,
        > div:first-child {
            font-weight: 500;
        }
        > div {
            position: relative;
        }
        .is-invalid-input + span {
            position: relative;
        }
        .session-expiration-wrapper input {
            margin-bottom: 5px;
            margin-right: 5px;
        }
    }
}

.user-management.form-header {
    padding-bottom: 20px;
}

.userContainer form.allowedIPs {
    input[type='text'] {
        padding-right: 5px;
        width: 300px;
    }
    & + .action-button {
        margin: 15px 0 0 0;
    }
}
