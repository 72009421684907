.inner-navigator {
    > .radio-group {
        display: flex;
        flex-wrap: wrap;
        align-self: flex-start;
        margin-bottom: 24px;

        input[type='radio'] {
            + label {
                font-size: 16px;
                line-height: 1.4;
                margin: 0;
                padding: 8px 15px 3px 15px;
                min-width: 100px;
                text-align: center;
                opacity: 0.7;
                color: var(--text-color);
                font-weight: 400;
                cursor: pointer;
                &:before,
                &:after {
                    display: none;
                }
                &:hover {
                    opacity: 1;
                    font-weight: 500;
                }
            }

            &:checked + label {
                opacity: 1;
                font-weight: 600;
                &:after {
                    content: '';
                    position: absolute;
                    top: initial;
                    bottom: -1px;
                    left: 0;
                    right: 0;
                    display: block;
                    height: 3px;
                    width: 100%;
                    background-color: var(--tab-active-line);
                    border-radius: 6px;
                }
            }
        }
    }
}
