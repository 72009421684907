.generic-form-options > div {
    float: left;
    padding-left: 25px;
    background-repeat: no-repeat;
    line-height: 2.1;
    margin-left: 21px;
    color: var(--text-color);
    cursor: pointer;
}
.generic-form-options > button {
    margin-left: 21px;
    border: none;
}
.generic-form-options > div,
.generic-form-options .deleteConfirmationForm > div,
.generic-form-options .updateConfirmationForm > div {
    opacity: 0.75;
    transition: all 0.2s;
}

.generic-form-options > div:hover,
.generic-form-options .deleteConfirmationForm > div:hover,
.generic-form-options .updateConfirmationForm > div:hover {
    opacity: 1;
}

.generic-form-options > div.disabled,
button[disabled] {
    opacity: 0.38 !important;
    cursor: not-allowed;
}

.generic-form-options > .form-save {
    background-image: url(../static-assets/images/form-save.svg);
}

.generic-form-options > .form-delete {
    background-image: url(../static-assets/images/form-delete.svg);
    margin-left: 145px;
}

.generic-form-options > .form-discard {
    background-image: url(../static-assets/images/form-discard.svg);
    background-repeat: no-repeat;
    height: 23px;
}

.generic-form-options {
    position: relative;
    font-size: 0.923em;
    margin-top: 5px;
}

.generic-form-options.overflow-hidden {
    overflow: hidden;
}

.generic-form-options:after {
    content: '';
    display: block;
    clear: both;
}

.generic-form-options .deleteConfirmationForm,
.generic-form-options .updateConfirmationForm {
    position: absolute;
    left: 100%;
    top: 0;
    transition: 0.4s;
    padding-left: 0;
    float: none;
    width: 100%;
    margin-left: 0;
}

.generic-form-options .deleteConfirmationForm:after,
.generic-form-options .updateConfirmationForm:after {
    content: '';
    display: block;
    clear: both;
}

.generic-form-options .deleteConfirmationForm > div,
.generic-form-options .updateConfirmationForm > div {
    float: left;
    margin-left: 25px;
    padding-left: 25px;
    font-size: 11.7px;
}

.generic-form-options .deleteConfirmationForm.open,
.generic-form-options .updateConfirmationForm.open {
    left: 0;
    background-color: $white;
    opacity: 1;
}

.generic-form-options .denyDelete {
    background-image: url('../static-assets/images/report-delete-no.png');
    background-repeat: no-repeat;
    color: #436691;
    position: relative;
}

.generic-form-options .secondDeleteConfirm {
    position: absolute;
    left: 25px;
}

.generic-form-options .secondDeleteConfirm p {
    position: absolute;
    white-space: nowrap;
    padding-left: 20px;
    top: -9px;
    right: 147px;
    cursor: default;
}

.generic-form-options .secondDeleteConfirm label {
    position: absolute;
    white-space: nowrap;
    right: 400px;
    top: 7px;
    cursor: pointer;
}

.generic-form-options .deleteConfirmationForm .form-delete {
    background-image: url('../static-assets/images/report-delete-yes.png');
    background-repeat: no-repeat;
    color: #9c1006;
}

.generic-form-options .updateConfirmationForm .form-update {
    background-image: url(../static-assets/images/form-save.svg);
    background-repeat: no-repeat;
    color: #9c1006;
}

.form-delete.disabled {
    opacity: 0.38;
    cursor: not-allowed;
}

.form-header {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #bdbfc1;
    position: relative;
}

.form-header h1 {
    float: left;
    max-width: 60%;
    @extend %text-no-overflow;
    background-repeat: no-repeat;
    padding-left: 30px;
}

.form-header:after {
    content: '';
    display: block;
    clear: both;
}

.form-switch-toggle-button-wrapper > .label-on {
    font-size: 0.85em;
}

.form-switch-toggle-button-wrapper > .label-off {
    float: left;
    font-size: 0.85em;
}

.form-switch-toggle-button-wrapper > .form-switch-btn-wrapper {
    float: left;
}

.generic-form-options.inner {
    position: absolute;
    z-index: 1;
    right: 15px;
}

.input-password_container {
    position: relative;
    .capsLockMessage {
        right: 10px;
        position: absolute;
        top: 7px;
        background-image: url('../static-assets/images/alert-icon.svg');
        padding-left: 20px;
        background-size: 17px;
        background-repeat: no-repeat;
        line-height: 1.5;
    }

    input[type='password'] {
        padding-right: 130px;
    }
}
