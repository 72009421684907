.control-panel {
    .time-date-selector {
        .st-accordion {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .st-accordion__item {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .st-accordion__item:first-child {
            margin-right: 20px;
        }

        .st-accordion__item label {
            margin-right: 10px;
        }

        .date-range-picker-apply {
            cursor: pointer;
            color: #f7fdff;
            background-color: #6da7c8;
            border: 1px solid #f7fdff;
            margin-left: 10px;
            line-height: 2.5;
            text-align: center;
            padding: 0 10px;
        }

        .date-range-picker {
            display: flex;
        }

        .st-accordion__item > a {
            color: #010101;
            width: 100px;
        }

        .st-accordion__item .time-frame-option {
            margin: 0;
            margin-right: 12px;
            border: 1px solid #e6e6e6;
        }

        .st-accordion__item .time-frame-option.active {
            border: 1px solid #6499b7;
        }

        .st-accordion__item .time-frame-option:last-child {
            margin: 0;
        }

        .st-accordion__item .time-frame-option a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            width: 26px;
            height: 26px;
            font-size: 10px;
            border: 1px solid $white;
            border-radius: 0;
            background-color: #e6e6e6;
            color: #8a8f95;
        }

        .st-accordion__item .time-frame-option.active a:focus,
        .st-accordion__item .time-frame-option.active a:hover {
            background-color: #e6e6e6;
            color: #8a8f95;
        }

        .date-range-picker input[type='text'] {
            width: 150px;
            margin: 0;
            border-radius: 0;
            font-size: 12px;
            border: 1px solid #cfd2d3;
            background-color: #f7fdff;
            font-weight: 400;
            color: #687077;
            text-align: center;
        }

        .date-range-picker .date-from {
            margin-right: 10px;
        }
    }
}
