.modal {
    .modal-body {
        .loading_image {
            right: calc((100% - 100px) / 2);
            top: calc((100% - 100px) / 2);
            position: absolute;
        }
    }
    .buttons-container {
        text-align: right;
    }
    span.invalid-input,
    .dropdown.isInvalidSelect > span {
        color: $error-color;
        border-left: none;
        float: right;
        min-width: auto;
        margin-top: 5px;
        margin-bottom: 0;
        line-height: 1.3;
        right: 0;
    }

    .label-with-checkbox-container {
        font-weight: normal;
        > * {
            float: left;
            cursor: pointer;
        }
        > p {
            max-width: calc(100% - 30px);
        }
    }
}
