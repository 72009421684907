/* Variables */
$global-header-height: 50px;
$global-footer-height: 28px;

$page-padding: 20px;

$app-left-gap: 10px;
$content-top-gap: 13px;
$content-bottom-gap: 10px;
$content-side-gap: 20px;

$opened-sidebar-width: 240px;
$closed-sidebar-width: 48px;

$input-width: 250px;
$input-long-width: 350px;
$input-bg-color: transparent;

$item-border-radius: 6px;

$list-small-item-height: 65px; // LIST_SMALL_ITEM_HEIGHT(=65) minus the gap($list-item-gap)
$list-large-item-height: 65px; // LIST_LARGE_ITEM_HEIGHT(=65) minus the gap($list-item-gap)
$list-item-gap: 0px;

$avatar-photo-box-shadow: 0 0 0 2px #fff, 0 0 0 3px #ccc;

$filters-box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.2);

$control-border-radius: 12px;

$tooltip-border-radius: 4px;
$tooltip-padding: 10px 16px;
$tooltip-filter: drop-shadow(1px 1px 5px rgba(85, 85, 85, 0.25));
