.open-dd li.st-list--row.st-list--selected {
    box-shadow: none !important;
    background-color: #fff5f5;
}

.open-dd .dropdown.active .st-tooltip-item ul.st-list--table li:hover {
    background-color: #fff5f5;
}

.open-dd .st-list--row.st-list--selected span:after {
    height: 12px;
    right: 8px;
    top: 5px;
    transform: rotate(45deg);
}

.open-dd .st-list--row.st-list--selected span:before {
    height: 6px;
    right: 13px;
    top: 9px;
    transform: rotate(-45deg);
}

.top-to-bottom-grid-wrapper .list-content .st-accordion__inner .dropdown li {
    padding: 0;
}

.open-dd .dropdown.active .st-tooltip-item {
    position: relative;
}

.open-dd .dropdown .dropdown-filter:after {
    display: none;
}

.open-dd .dropdown .dropdown-filter:before {
    display: none;
}

.form-template-wrapper {
    position: relative;
}

.dropdown > div:first-child a {
    padding: 6px 5px;
    display: block;
    border: 1px solid #bdbfc1;
    border-radius: 2px;
    line-height: 1.5;
    width: 100%;
    font-size: 0.923em;
    font-weight: 500;
}

.error-select.dropdown > div:first-child a .alerts-form-container input[type='radio'] + label {
    position: relative;
    line-height: 1.7;
    margin-bottom: 10px;
    padding-left: 23px;
    text-transform: none;
    cursor: pointer;
}

.item-text-overflow {
    @extend %text-no-overflow;
    display: inline-block;
    max-width: 100%;
}

.validatable-field-input.invalid-input,
.validatable-field-input.invalid-report-name {
    background-color: #ffd7d9;
    border: 1px solid #be1e2d;
}

.split-screen-a {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 35%;
    padding: 15px;
    left: 0;
}

.split-screen-a .active {
    border-color: #a7a7a7 !important;
}

.split-screen-a .alert-item-label {
    @extend %text-no-overflow;
    display: inline-block;
    max-width: 100%;
}

.split-screen-side-b {
    position: absolute;
    left: 35%;
    top: 0;
    bottom: 0;
    width: 65%;
    padding: 15px;
    overflow-x: hidden;
    background: var(--card-bg-color);
    border-left: 1px solid var(--card-separator-color);
}

.split-screen-side-b {
    > div,
    .selected-content,
    .content-view {
        -webkit-animation: fadein 0.4s;
        -moz-animation: fadein 0.4s;
        -ms-animation: fadein 0.4s;
        -o-animation: fadein 0.4s;
        animation: fadein 0.4s;
        font-size: 13px;
        top: 45%;
    }
    .no-available-data {
        top: 50%;
    }
}

.item-list li.list-row:last-child {
    border-bottom: none;
}

.confirm-list-wrapper {
    background-color: #ffe19f;
    position: inherit;
    width: 100%;
}

.confirm-list-content {
    padding: 10px;
}

.confirm-actions > button {
    float: left;
    margin: 0 15px 0 0;
}

.confirm-actions > .multi-line-message {
    float: left;
    margin: 4px 15px 0 0;
}

.error-list-wrapper {
    background-color: #fbd6d9;
}

.error-list-wrapper .error-description {
    background-image: url('../static-assets/images/error-message_icon.svg');
    background-repeat: no-repeat;
    padding-left: 21px;
}

.error-list-wrapper .error-actions {
    background-image: url('../static-assets/images/error_acknowledge_close-message.svg');
    background-repeat: no-repeat;
    padding-right: 10px;
    float: right;
    width: 16px;
    height: 16px;
    z-index: 9;

    &:hover {
        background-image: url('../static-assets/images/error_acknowledge_close-message_over.svg');
        cursor: pointer;
    }
}

.error-list-row {
    border-bottom: solid 1px $white;
    padding: 5px 0;
}

.error-list-row:last-child {
    border: none;
}

.success-list-wrapper .success-actions {
    background-image: url('../static-assets/images/ok_close-message.svg');
    background-repeat: no-repeat;
    padding-right: 10px;
    float: right;
    width: 16px;
    height: 16px;
    z-index: 9;

    &:hover {
        background-image: url('../static-assets/images/ok_close-message_over.svg');
        cursor: pointer;
    }
}

.success-list-row {
    border-bottom: solid 1px $white;
    padding: 5px 0;
}

.success-list-row:last-child {
    border: none;
}

.info-list-wrapper .info-actions {
    background-image: url('../static-assets/images/info_close-message.svg');
    background-repeat: no-repeat;
    padding-right: 10px;
    float: right;
    width: 16px;
    height: 16px;
    z-index: 9;

    &:hover {
        background-image: url('../static-assets/images/info_close-message_over.svg');
        cursor: pointer;
    }
}

.info-list-row {
    border-bottom: solid 1px $white;
    padding: 5px 0;
}

.info-list-row:last-child {
    border: none;
}

.warning-list-wrapper .warning-actions {
    background-image: url('../static-assets/images/warning_close-message.svg');
    background-repeat: no-repeat;
    padding-right: 10px;
    float: right;
    width: 16px;
    height: 16px;
    z-index: 9;

    &:hover {
        background-image: url('../static-assets/images/warning_close-message_over.svg');
        cursor: pointer;
    }
}

.warning-list-row {
    border-bottom: solid 1px $white;
    padding: 5px 0;
}

.warning-list-row:last-child {
    border: none;
}

.alerts-slider {
    background-color: var(--card-bg-color);
    width: 400px;
    z-index: 9999;
    top: $global-header-height;
    bottom: $global-footer-height;
    padding: 15px 15px 15px 15px;
    overflow: hidden;
    border-left: 1px solid #989a9b;
}

/*****************
    Search
 *****************/

.alerts-close {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 20px;
    height: 20px;
    background-image: url('../static-assets/images/alert-close.png');
    opacity: 0.7;

    &:hover {
        opacity: 1;
        cursor: pointer;
    }
}

.activities-search-items {
    position: absolute;
    right: 0;
    width: 100%;
    transition: 0.4s;
    top: 0;
    &.open {
        right: 100%;
        .search-item-wrapper > input[type='text'] {
            width: calc(100% - 37px);
        }
    }
}

.user-global-alerts {
    margin-top: 20px;
}
.selected-content {
    .user-alerts-checkboxes-wrapper {
        margin-bottom: 10px;
        label {
            margin-right: 10px;
            cursor: pointer;
        }
    }
}
