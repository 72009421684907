.tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .tabs-nav-list {
        display: flex;
        align-items: center;

        .tab {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--text-color);
            padding: 10px 20px;
            min-width: 100px;
            white-space: nowrap;
            cursor: pointer;

            &:not(.active):hover {
                .tab-label {
                    font-weight: 500;
                }
            }

            &.active {
                .tab-label {
                    font-weight: 600;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    background-color: var(--tab-active-line);
                    height: 3px;
                    width: 100%;
                    border-radius: 15px;
                }
            }

            .tab-label {
                position: absolute;
                text-align: center;
            }

            .tab-label-hidden {
                visibility: hidden;
                font-weight: 600;
            }
        }
    }

    .tabs-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 0;
        flex-grow: 1;
        padding-top: 24px;
        overflow-y: auto;
    }
}

.sub-tabs {
    width: auto;
    align-self: center;

    .tabs-nav-list {
        gap: 5px;
        border-bottom: unset;
        .tab {
            font-size: 14px;
            line-height: 18px;
            border-radius: 20px;
            height: 29px;
            &.active {
                background-color: var(--tab-active-bg-color);
                color: $white;
                &:after {
                    height: 0;
                }
            }
        }
    }
}
