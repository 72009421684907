.advance-refine-modal {
    .modal-content {
        max-width: none;
        width: 1243px;

        .modal-header {
            padding: 0;

            .modal-title {
                padding: 10px 0 20px 20px;
                color: var(--text-color);
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
            }
        }

        .modal-body {
            padding: 0 20px;

            .modal-footer {
                padding: 20px 0;

                .buttons-container {
                    .form-save,
                    .form-discard {
                        width: 190px;
                    }
                    .form-save {
                        background: #6ec089;
                        &:hover {
                            background-color: #6ec089;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
                        }
                    }
                }
            }
        }
    }
}
