$attacksContainerWidth: 517px; //same value as in AttackStory.js (attacksContainerWidth)

.as-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    &.show-attack-story-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;

        .show-attack-story-label {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: var(--text-color);
        }
    }

    .as-summary-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 1;
        width: 90%;
        max-width: 596px;
        padding: 2px 0;
        margin-right: 50px;

        .as-summary-items-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .as-summary-item-container {
                display: flex;
                gap: 5px;

                .as-icon-container {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;

                    &.hacker {
                        border: 1px solid var(--as-summary-hacker-border-color);
                        box-shadow: var(--as-summary-hacker-box-shadow);

                        .hacker-icon {
                            width: 14px;
                            height: 17px;
                            background-image: var(--as-hacker-icon);
                            background-size: contain;
                        }
                    }

                    &.warning {
                        &.low {
                            border: 1px solid var(--as-summary-sensitivity-low-color);
                        }

                        &.medium {
                            border: 1px solid var(--as-summary-sensitivity-medium-color);
                        }

                        &.high {
                            border: 1px solid var(--as-summary-sensitivity-high-color);
                        }

                        &.custom {
                            border: 1px solid var(--as-summary-sensitivity-custom-color);
                        }
                    }

                    .warning {
                        width: 16px;
                        height: 16px;
                        background-size: contain;

                        &.low-icon {
                            background-image: var(--as-warning-low-icon);
                        }

                        &.medium-icon {
                            background-image: var(--as-warning-medium-icon);
                        }

                        &.high-icon {
                            background-image: var(--as-warning-high-icon);
                        }

                        &.custom-icon {
                            background-image: var(--as-warning-custom-icon);
                        }
                    }

                    &.shield {
                        border: 1px solid var(--as-summary-blocked-events-color);

                        .shield-icon {
                            width: 14px;
                            height: 14px;
                            background-image: var(--as-shield-icon);
                            background-size: contain;
                        }
                    }

                    &.clock {
                        border: 1px solid var(--as-summary-blocking-time-color);

                        .clock-icon {
                            width: 16px;
                            height: 16px;
                            background-image: var(--as-clock-icon);
                            background-size: contain;
                        }
                    }
                }

                .as-summary-item-labels {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                }
            }
        }
    }

    .as-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;

        &.primary,
        &.source-ip-color {
            color: var(--text-color);
        }

        &.sensitivity-low-color {
            color: var(--as-summary-sensitivity-low-color);
        }

        &.sensitivity-medium-color {
            color: var(--as-summary-sensitivity-medium-color);
        }

        &.sensitivity-high-color {
            color: var(--as-summary-sensitivity-high-color);
        }

        &.sensitivity-custom-color {
            color: var(--as-summary-sensitivity-custom-color);
        }

        &.shield-color {
            color: var(--as-summary-blocked-events-color);
        }

        &.blocking-time-color {
            color: var(--as-summary-blocking-time-color);
        }
    }

    .as-sub-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: var(--text-secondary-color);
    }

    .attack-chain-container {
        display: flex;
        align-items: center;
        width: 100%;

        .hacker-container {
            width: 47px;
            height: 47px;
            position: relative;

            .hacker-icon-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 47px;
                height: 47px;
                border-radius: 50%;
                box-shadow: var(--as-hacker-icon-box-shadow);

                .hacker-icon {
                    width: 17px;
                    height: 21px;
                    background-image: var(--as-hacker-icon);
                    background-size: contain;
                }
            }

            &::after {
                content: '';
                position: absolute;
                width: 36px;
                height: 70px;
                top: -25%;
                left: 50%;
                border-radius: 50%;
                background-image: var(--as-attack-rays-icon);
            }

            .as-arrow {
                position: absolute;
                right: -25px;
                top: 20px;
            }
        }

        .as-arrow {
            width: 20px;
            height: 6px;
            background-image: var(--as-arrow-icon);
        }

        .as-attacks-carousel-container {
            width: 40%;
            flex: 1;
            margin: 0 4px 20px 29px;

            .as-title {
                display: inline-block;
                margin-left: 9px;
                margin-bottom: 6px;
            }

            .as-attacks-carousel {
                display: flex;
                justify-content: center;
                gap: 5px;
                width: 100%;
                max-width: 605px;
                height: 48px;
                padding: 9px;
                border: 0.5px solid var(--as-attacks-carousel-border-color);
                border-radius: 10px;

                .as-attacks-wrapper {
                    overflow: hidden;
                    flex: 1;

                    &.not-slideable {
                        width: 100%;
                    }
                }

                .as-attacks-container {
                    display: flex;
                    gap: 13px;
                    height: 100%;
                    width: 100%;
                    max-width: $attacksContainerWidth;
                    overflow: hidden;
                    scroll-behavior: smooth;
                    padding: 0 5px;

                    &.not-slideable {
                        width: 100%;
                        justify-content: space-around;
                    }
                }

                .as-slide-arrow {
                    width: 30px;
                    height: 30px;
                    border: none;
                    background-color: transparent;
                    background-image: var(--as-attacks-carousel-slide-btn);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 17px 20px;

                    &:disabled {
                        background-image: var(--as-attacks-carousel--disabled-slide-btn);
                    }

                    &.as-slide-arrow-prev {
                        margin-right: auto;
                        transform: rotate(180deg);
                    }

                    &.as-slide-arrow-next {
                        margin-left: auto;
                    }
                }

                .as-attack {
                    display: flex;
                    gap: 5px;
                    height: 100%;
                    flex: 1 0 120px;
                    max-width: 160px;

                    .as-attack-count {
                        align-self: center;
                        font-weight: 400;
                        font-size: 30px;
                        line-height: 35px;
                        letter-spacing: -1px;
                        color: var(--as-attack-count-color);
                    }

                    .as-sub-title {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }
        }

        .as-stop-icon {
            width: 47px;
            height: 47px;
            background-image: var(--as-stop-icon);
            background-size: contain;
            margin: 0 16px 0 4px;
        }

        .shield-icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 47px;
            height: 47px;
            border-radius: 50%;
            box-shadow: var(--as-shield-success-icon-box-shadow);

            .shield-icon {
                width: 25px;
                height: 20px;
                background-image: var(--as-shield-success-icon);
            }
        }
    }
}
