.protocol-ports {
    padding-left: 20px;
    padding-top: 5px;
}

.protocol-ports > div {
    width: 155px;
    float: left;
}

.protocol-ports:after {
    content: '';
    display: block;
    clear: both;
}

.protocol-ports > div {
    margin-bottom: 5px;
}

.protocol-ports input[type='text'] {
    width: 60px;
}

.protocol-ports input[type='checkbox'] + label {
    width: 85px;
}

.applications-ssl-form {
    width: 40%;
    margin-top: 10px;
}

.form-content-wrapper.applications-ssl-form > label {
    margin-bottom: 8px;
}

.card-items-management-head h1 {
    background-image: url(../static-assets/images/applications_icon.svg);
}

.card-items-management-head .item-list-search-wrapper.search-option-open input {
    width: calc(100% - 55px);
    opacity: 1;
}

.card-item .item-list-item-details .missing-state {
    color: #be1e2d;
    font-weight: 400;
}

.card-item-left-side .main-domain {
    line-height: 1;
    font-size: 0.87em;
}

.card-item-left-side .deployment-state.provisioning {
    color: #296d97;
}

.card-item-left-side .deployment-state.provisioning:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 12px;
    height: 13px;
    background-image: url(../static-assets/images/app-provisioning-small.svg);
    background-repeat: no-repeat;
    top: 2px;
    margin-right: 2px;
}

.card-item-left-side .deployment-state.protecting {
    color: #006838;
}

.card-item-left-side .deployment-state.protecting:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 12px;
    height: 13px;
    background-image: url(../static-assets/images/protected_shield_icon.svg);
    background-repeat: no-repeat;
    top: 2px;
    margin-right: 2px;
    background-size: contain;
}

.card-item-left-side .deployment-state.learning {
    color: #f26522;
}

.card-item-left-side .deployment-state.learning:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 12px;
    height: 13px;
    background-image: url(../static-assets/images/app-learning-small.svg);
    background-repeat: no-repeat;
    top: 2px;
    margin-right: 2px;
}

/* .ssl-application-item:after {content:''; display:block; position:absolute; left:0; top: 0; bottom:0; border-left-style: solid; border-left-width: 6px;} */

/* .ssl-application-item.provisioning:after {border-left-color: #296d97}
.ssl-application-item.protecting:after {border-left-color: #006838}
.ssl-application-item.learning:after {border-left-color: #f26522} */

.card-item-right-side .transactions-chart {
    position: relative;
    top: -3px;
    cursor: default;
}

.card-item-right-side .transactions-chart-details {
    position: absolute;
    right: 0;
    bottom: 20px;
    width: 140px;
}

.card-item-left-side .item-list-item-header > div {
    vertical-align: top;
}

.sslApplicationManagement-details .transactions-chart {
    margin-top: 10px;
}

.source-chart-tooltip-custom.transactions-chart {
    padding: 0;
    margin: 0;
}

.source-chart-tooltip-custom.transactions-chart .tooltip-header {
    color: grey;
    margin: 0;
    padding: 0;
    font-size: 0.9em;
}

.source-chart-tooltip-custom.transactions-chart .tooltip-series-data .value {
    font-size: 1em;
    font-weight: 600;
}

.transactions-chart .generic-chart-wrapper:after {
    content: '';
    display: block;
    clear: both;
}

.sslApplicationManagement-details .generic-chart-wrapper {
    left: -5px;
}

.sslApplicationManagement-details .transactions-chart {
    margin-bottom: 13px;
}

.card-item-right-side .transactions-chart-details {
    position: absolute;
    right: 0;
    bottom: 9px;
}

.sslApplicationManagement-details .transactions-chart-details {
    position: absolute;
    right: 0;
    bottom: -10px;
    left: 0;
}

.ssl-certificate-list .transactions-chart .chart-generic {
    float: right;
    bottom: 13px;
}

.card-item-right-side .transactions-chart-details-title {
    font-weight: 600;
    font-size: 0.9em;
}

.sslApplicationManagement-details .transactions-chart-details-title {
    font-weight: 400;
    color: #414042;
}

.transactions-chart .chart-generic:after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background: #bbbbc1;
    bottom: 13px;
    right: 6px;
}

.sslApplicationManagement-details .chart-generic:after {
    width: 97%;
    right: 9px;
}

.card-item-right-side .chart-generic:after {
    width: 96%;
    right: 4px;
}

.settings-screen-form-options.form-header h1,
.applications-create-options.form-header h1 {
    background-image: url(../static-assets/images/applications_icon.svg);
}

.settings-screen-form-options.form-header {
    border-bottom: none;
}

.settings-screen-form-options.form-header {
    padding-bottom: 0;
}

.card-items-management-head .head-options-container {
    margin: 15px 0;
}

.head-options-total-item-type {
    font-weight: bold;
    margin-right: 5px;
}

.card-items-management-head .sort-search {
    /* margin-top: 37px; */
    padding-top: 8px;
    position: relative;
    padding-bottom: 0px;
}

.card-items-management-head-sorting {
    float: left;
}

.card-items-management-head-details > li {
    display: inline-block;
    margin-right: 15px;
}

.card-items-management-head-sorting {
    font-size: 0.9em;
    margin-top: 6px;
    border-left: 1px solid #eef1f8;
    padding-left: 15px;
}

.card-items-management-head-sorting-list > li {
    display: inline-block;
    margin-left: 10px;
}

.card-items-management-head-sorting-list > li > button {
    border: none;
    background: none;
    outline: none;
}

.card-items-management-head-sorting-list > li.active {
    color: blue;
}

.card-items-management-head-sorting-list,
.card-items-management-head-sorting-title {
    display: inline-block;
}

/************** Access Control *************/

.search-container input {
    height: 24px;
    width: 100%;
}

.item-create-form {
    width: 400px;
}

.item-create-form > div,
.item-create-form .generalInfoContainer > div {
    margin-bottom: 20px;
}

.item-create-form.form-content-wrapper {
    input,
    textarea {
        width: 100%;
    }
}

.checkboxes-group {
    margin: 5px 0 6px;
}

.item-create-form-protocol > div,
.item-create-form-protection > div {
    float: left;
}

.application-operator-wrapper .item-create-form-protection > div:first-child,
.item-create-form-protocol > div:first-child {
    width: 210px;
}

.item-create-form-protocol .certificate-select,
.item-create-form-protection .technology-select {
    width: calc(100% - 210px);
    float: right;
}

.item-create-form-protection .technology-select.immediate {
    float: left;
}

.item-create-form-protection .radio-group > div {
    margin-right: 10px;
}

.item-create-form-protection .radio-group:after {
    content: '';
    display: block;
    clear: both;
}

.item-create-form label.radio-button-label {
    margin-top: 5px;
    display: inline-block;
}

.item-create-form-protection:after,
.item-create-form-protocol:after {
    content: '';
    clear: both;
    display: block;
}

.customers-management-head h1 {
    background-image: url(../static-assets/images/customer_icon.svg);
}

.customer-form-options.form-header {
    border-bottom: none;
    padding-bottom: 0;
}

.customer-form-options > h1 {
    background-image: url(../static-assets/images/customer_icon.svg);
    background-repeat: no-repeat;
    padding-left: 30px;
    text-overflow: ellipsis;
}

.checkboxes-group > div {
    float: left;
    margin-right: 15px;
}

.checkboxes-group:after {
    content: '';
    display: block;
    clear: both;
}

.application-nav .dep-msg,
.profile-configuration-nav .dep-msg {
    position: absolute;
    left: 50%;
    top: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    border-bottom: solid 1px;
    border-top: solid 1px;
    padding: 5px 70px 5px 25px;
    background: url(../static-assets/images/info_icon.svg) 0/16px no-repeat;
}

.application-create-msg {
    margin-top: 10px;
    border-bottom: solid 2px #c9cacb;
    border-top: solid 2px #c9cacb;
    padding: 5px 70px 5px 25px;
    background: url(../static-assets/images/info_icon.svg) 0/16px no-repeat;
}

.sslApplicationManagement-details .dep-msg {
    position: relative;
    left: initial;
    top: initial;
    transform: initial;
    margin: 20px 0 10px 0;
}

.protocol-content .redirect-message {
    border-bottom: solid 1px;
    border-top: solid 1px;
    padding: 5px 70px 5px 25px;
    background: url(../static-assets/images/info_icon.svg) 0/16px no-repeat;
    margin-top: 20px;
}

.form-content-wrapper {
    margin-top: 20px;
    font-size: 0.923em;
    font-weight: 400;
    .redirect-status-wrapper {
        margin-top: 45px;
        label {
            margin-bottom: 10px;
        }
        .radio-btns-wrapper {
            > div {
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
}

.form-content .row {
    margin-bottom: 25px;
}

.form-content-wrapper.protocol-form,
.form-content-wrapper.ip-source-form {
    width: 75%;
    border-top: 1px solid #818386;
}

.inner .form-discard,
.inner .form-save {
    float: right;
}

.inner .form-discard {
    color: transparent;
    width: 20px;
}

.inner .form-save {
    background-image: none;
}

.radio-options {
    margin-top: 10px;
}

.radio-options:after {
    content: '';
    display: block;
    clear: both;
}

.form-content-wrapper.protocol-form:before {
    content: '';
    display: block;
    position: absolute;
    top: 30px;
    bottom: 0;
    width: 1px;
    background-color: #949699;
    left: 50%;
    clear: both;
}

.form-content-wrapper.protocol-form:after {
    content: '';
    display: block;
    clear: both;
}

.form-content-wrapper > .protocol-content {
    float: left;
    /* max-width: 300px; */
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
}

.form-content-wrapper:after {
    content: '';
    display: block;
    clear: both;
}

.form-content-wrapper label {
    margin-bottom: 0px;
    display: block;
}

.protocol-hc-wrapper {
    margin-top: 45px;
}

.protocol-content .certificate-select {
    margin-top: 20px;
}

.protocol-content .certificate-select > *:first-child {
    margin-bottom: 5px;
}

.protocol-content .certificate-select > *:not(:first-child) {
    margin-left: 15px;
}

.protocol-hc-wrapper > div {
    padding-left: 20px;
}

.radio-group:after {
    content: '';
    display: block;
    clear: both;
}

.origin-form .radio-group > div,
.protocol-hc-wrapper .radio-group > div,
.application-states-wrapper .radio-group > div,
.application-operator-wrapper .form-protection-modes {
    float: left;
    margin-right: 10px;
}

.protocol-hc-wrapper .radio-group:after {
    content: '';
    display: block;
    clear: both;
}

.protocol-hc-wrapper input {
    width: 100%;
    margin-bottom: 10px;
}

.protocol-hc-wrapper label.Interval + input {
    width: 50px;
    display: inline-block;
}

.protocol-hc-wrapper > label {
    margin-bottom: 8px;
}

.protocol-type-wrapper {
    overflow: hidden;
}

.protocol-type-http {
    float: left;
}

.toggle-button-wrapper-redirect {
    float: right;
    text-align: right;
}

.toggle-button-wrapper-redirect > label {
    margin-top: 2px;
    display: inline-block;
    vertical-align: middle;
}

.toggle-button-wrapper-redirect > div {
    font-size: 0;
    vertical-align: middle;
    display: inline-block;
}

.toggle-button-wrapper-redirect .form-switch-btn-wrapper {
    display: inline-block;
}

.disable-add {
    display: none;
}

.disable-delete {
    display: none;
}

.form-content-wrapper.origin-form {
    width: 40%;
    .add_fields-wrapper {
        font-size: 15px;
    }
    .origin-server-input {
        width: 320px;
    }
}

.application-states-wrapper,
.application-operator-wrapper .form-protection-modes {
    width: 100%;
    margin: 20px;
}

.application-states-wrapper {
    clear: both;
}

.form-protection-modes > div {
    display: inline-block;
}

.form-protection-modes .technology-select {
    display: inline-block;
    float: right;
    min-width: 160px;
    top: 10px;
}

.application-operator-app-protection-modes:after {
    content: '';
    display: block;
    clear: both;
}

.app-protection-modes-save {
    width: 100%;
}

.app-protection-modes-save .generic-form-options {
    width: 100%;
    float: left;
}

.app-protection-modes-save .generic-form-options.open {
    overflow: initial;
    height: 115px;
}

.protocol-hc-wrapper .selected-content div.input-label:last-child > div:last-child {
    display: inline-block;
    input {
        width: 110px;
    }
}

.form-content-wrapper .input-with-names .dropdown {
    width: 100px;
    float: left;
}

.form-content-wrapper .add_fields-wrapper {
    margin-bottom: 10px;
}

.form-content-wrapper .input-with-names .dropdown + div {
    float: left;
    padding-left: 10px;
}

.form-content-wrapper .input-with-names .dropdown + div input {
    width: 100%;
}

.form-content-wrapper .input-with-names:after {
    content: '';
    display: block;
    clear: both;
}

.form-content-wrapper.origin-form > label {
    margin-bottom: 8px;
}

.form-error-is-visible {
    border-left: solid 1px #be1e2d;
    padding-left: 4px;
    color: #be1e2d;
}

.advanced-ip-source {
    margin-top: 15px;
}

.advanced-ip-source-cbx-container {
    margin-right: 10px;
}

.advanced-ip-source-cbx-container + div {
    margin-top: 12px;
}

.advanced-ip-source-input-container {
    font-size: 12px;
    padding-left: 27px;
}

.advanced-ip-source-input-container > div {
    width: 250px;
}

.noCertificatesMessage {
    font-size: 15.6px;
    padding-top: 8px;
    a {
        cursor: pointer;
    }
    &.validation-error {
        color: $error-color;
    }
}

.protocol-port_container {
    position: relative;
}

.accordion-trigger {
    color: #687077;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
    position: relative;
    padding-left: 10px;

    &:before {
        content: '';
        display: block;
        border: 4px solid transparent;
        position: absolute;
        border-left: 8px solid #687077;
        top: 7px;
        left: 0;
        transition: 0.2s all ease;
    }
}

.expand .accordion-trigger:before {
    transform: rotate(90deg);
    top: 9px;
    left: -3px;
}

.category-config .hor-field-wrapper {
    margin-left: 20px;
    .input-label span {
        font-size: 0.9em;
        font-weight: normal;
    }
}

.security-bypass {
    .gen-list-item-input .Select {
        width: 350px;
    }
}

.json-protection {
    .gen-list-item-input .Select {
        width: 350px;
    }
}

.gen-form.ports {
    .gen-list-headers .gen-list-headers-regular,
    .gen-list-item .gen-list-item-input {
        flex: 2;

        input,
        .Select {
            width: 95%;
        }

        .select-with-validation {
            width: 100%;
        }

        &.description {
            flex: 4;
        }
    }

    .gen-list-item .port-edit .gen-list-item-input {
        &.frontPort input,
        &.backPort input,
        &.type .Select {
            width: 100px;
        }
    }

    .ports-certificates,
    .ports-redirect,
    .ports-hcs {
        margin-top: 20px;
        display: flex;
        width: 400px;
        min-height: 40px;
    }
    .ports-certificates {
        flex-direction: column;
        .validTo {
            margin-left: 10px;
        }
        .warning-msg {
            color: #e66f01;
            display: flex;
            align-items: center;
            .help-tooltip {
                b {
                    color: #e66f01;
                }
            }
        }
    }
    .ports-redirect .redirectEnabled {
        flex: 4;
        min-width: 200px;

        &.checkbox-wrapper.checkbox-with-label label {
            &:before {
                top: 14px;
            }

            &:after {
                top: 16px;
            }
        }
    }
    .ports-redirect {
        width: 500px;
        .redirect,
        .responseCode {
            flex: 2;
        }
        .Select {
            min-width: unset;
            width: 200px;
        }
        .responseCode {
            margin-left: 20px;
            input {
                width: 100px;
            }
            > div {
                width: 70px;
            }
        }
    }
    .ports-hcs {
        width: unset;
        border-top: solid 1px $gray-600;
        padding-top: 20px;
        .invalid-input {
            float: unset;
        }
        .ports-hc {
            width: 420px;
            .hc-input {
                width: 250px;
                margin-bottom: 20px;
            }
        }
    }
    .radio-options {
        display: flex;
        > div {
            margin-right: 10px;
        }
    }
}
