.section-header-title {
    border-bottom: 1px solid #148fe6;
    display: inline-block;
    padding-bottom: 2px;
    font-size: 1.8em;
    color: #148fe6;
}
.section-header .olh-trigger {
    vertical-align: initial;
}

.sections-container {
    /* position: absolute; */
    /* display: inline-block; */
    /* right: 0; */
    /* left: 15%; */
    /* bottom: 0; */
    /* top:0; */
    /* overflow: auto; */
    padding: 0 25px;
}
.sections-screen {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid grey;
    overflow: auto;
}

.sections-screen-back-to-applications-container {
    display: inline-block;
    position: fixed;
    width: 200px;
    padding: 6px 14px;
}

.sections-screen-back-to-applications-container button {
    font-size: 13px;
    background-color: #a8aaac;
    color: $white;
    padding: 6px 20px;
    border-radius: 3px;
    line-height: unset;
    border: none;
}

article.section {
    border-bottom: 1px solid #e4e4e4;
}

.section {
    padding: 50px 0;
}

.sections-container {
    background: #f4f6f9;
    width: 80%;
    margin: auto;
}

.section-body {
    margin-bottom: 20px;
}

.sections-container .popover {
    max-width: none;
    width: 385px;
    border-radius: initial;
    left: initial;
    right: 0;
}
