.time-date-control-wrapper {
    .quick-range-header {
        display: flex;
        font-size: 0.923em;
        font-weight: 600;

        .icon {
            width: 15px;
            height: 15px;
            margin-right: 10px;
            background: url('../static-assets/images/quick-range-icon.png');
        }
    }

    .date-time-header {
        display: flex;
        margin-top: 10px;
        font-size: 0.923em;
        font-weight: 600;

        .icon {
            width: 16px;
            height: 16px;
            margin-right: 10px;
            background: url('../static-assets/images/date-time-select-icon.png');
        }
    }

    .quick-range-selector-wrapper {
        display: flex;
        align-items: center;
        color: var(--date-picker-text-color);

        .range-item {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            height: 30px;
            border-radius: 8px;

            &:hover {
                cursor: pointer;
                background-color: var(--date-picker-item-hover-bg-color);
            }
        }

        .range-item.selected {
            color: var(--date-picker-selected-day-text-color);
            background-color: var(--date-picker-item-hover-bg-color);
            font-weight: 700;
        }
    }

    .datetime {
        display: flex;
        align-items: center;

        .datetime-to {
            .rdt {
                .rdtPicker {
                    right: 0;
                }
            }
        }

        input[type='text'] {
            width: 100%;
            margin: 0;
            padding: 0;
            border: none;
            border-bottom: 1px solid #cfd2d3;
            background-color: inherit;
        }

        .rdt {
            .rdtPicker {
                display: none;
            }

            input[type='text'] {
                font-size: 0.923em;
                width: 100%;
                margin: 0;
                padding: 0;
                border: none;
                box-shadow: none;
                border-bottom: 2px solid #cfd2d3;
                background-color: inherit;
                text-align: center;
            }
        }

        .rdt.rdtOpen {
            .rdtPicker {
                display: block;
            }

            input[type='text'] {
                border-bottom: 2px solid var(--tab-active-line);
            }
        }

        input[type='text'] {
            width: 100%;
            height: 26px;
            margin: 0;
            padding: 0;
            border: none;
            border-bottom: 1px solid #cfd2d3;
            background-color: inherit;
        }

        .arrow {
            width: 18px;
            height: 11px;
            background: url('../static-assets/images/date-time-arrow-icon.png') no-repeat center;
        }
    }
}

.bp3-overlay .bp3-popover {
    *:focus {
        outline: none;
    }

    &,
    .bp3-popover-arrow:before {
        box-shadow: var(--date-picker-shadow);
    }

    .bp3-popover-arrow-fill {
        fill: var(--date-picker-arrow-fill-color);
    }

    .date-range-input.bp3-datepicker {
        background-color: var(--date-picker-bg-color);

        .DayPicker-wrapper {
            color: var(--date-picker-text-color);
        }

        .DayPicker-Day--isToday,
        .DayPicker-Day--today {
            color: var(--date-picker-today-text-color);
            font-weight: 700;
        }

        .DayPicker-Day {
            &.DayPicker-Day--selected-range,
            &.DayPicker-Day--hovered-range {
                background-color: var(--date-picker-selected-range-bg-color);
                color: var(--date-picker-selected-range-text-color);
            }

            &.DayPicker-Day--selected {
                &.DayPicker-Day--selected-range-start,
                &.DayPicker-Day--selected-range-end {
                    background-color: var(--date-picker-item-hover-bg-color);
                    color: var(--date-picker-selected-day-text-color);
                    font-weight: 700;
                }
            }

            &:hover,
            &:focus {
                &:not(.DayPicker-Day--today) {
                    background-color: var(--date-picker-item-hover-bg-color);
                    color: var(--date-picker-text-color);
                }
            }
        }

        .bp3-icon {
            &.bp3-icon-chevron-left,
            &.bp3-icon-chevron-right {
                color: var(--date-picker-next-prev-arrow-color);
            }
        }

        .bp3-datepicker-month-select,
        .bp3-datepicker-year-select {
            select {
                color: var(--text-color);

                option {
                    color: var(--text-color);
                    background-color: var(--date-picker-bg-color);
                }
            }
        }

        .bp3-timepicker-input-row {
            background-color: var(--date-picker-time-bg-color);

            .bp3-timepicker-input.bp3-timepicker-hour,
            .bp3-timepicker-input.bp3-timepicker-minute,
            .bp3-timepicker-divider-text {
                color: var(--date-picker-time-text-color);
            }
        }
    }
}
