.theme-toggle-wrapper {
    display: flex;
    cursor: pointer;

    .theme-toggle {
        .form-switch-btn {
            $gap: 2px;
            $btn-size: 16px;

            position: relative;
            width: 40px;
            height: 20px;
            margin: 0;
            background-color: $new_white !important;
            border: none !important;

            .form-switch-btn-state {
                width: $btn-size !important;
                height: $btn-size !important;
                background-color: var(--sidebar-bg-color) !important;
                border: none !important;
                z-index: 1;
            }

            &.off .form-switch-btn-state {
                top: $gap;
                left: $gap;
            }

            &.on .form-switch-btn-state {
                top: $gap;
                right: $gap;
                left: calc(100% - #{$btn-size} - #{$gap});
            }

            &:before,
            &:after {
                content: '';
                display: initial !important;
                position: absolute;
                top: $gap + $gap !important;
                width: 12px !important;
                height: 12px !important;
            }

            &:before {
                right: $gap + $gap !important;
                background: url(../static-assets/images/moon.svg) no-repeat;
                transform: rotate(-90deg) !important;
            }

            &:after {
                left: $gap + $gap !important;
                background: url(../static-assets/images/sun.svg) no-repeat;
            }
        }
    }

    &:hover .theme-toggle .form-switch-btn {
        box-shadow: 0px 0px 8px 2px rgba($new_white, 0.5);
    }
}
