.certificates-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $page-padding $page-padding 10px $page-padding;
    position: relative;

    .items-header-new {
        margin-bottom: 14px;
    }

    .certificates-page-wrapper-tabs {
        height: auto;
        align-self: flex-start;
        margin-bottom: 18px;
    }

    .certificate-groups,
    .key-servers {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 0;
        overflow-x: auto;

        .card-items-container {
            height: auto;
            width: 100%;
        }

        .numeric-badge {
            width: 27px;
            height: 16px;
            line-height: 16px;
            border-radius: 20px;
            color: var(--text-color);
            background-color: var(--badge-bg-color);
            font-size: 12px;
            font-weight: 500;
            text-align: center;

            &.numeric-error-badge {
                color: var(--badge-error-color);
                background-color: var(--badge-error-bg-color);
            }

            &.numeric-value {
                background-color: initial;
                font-weight: initial;
            }
        }

        .certificate-groups-list {
            position: relative;
            padding: 0;

            .items-header-new {
                margin-bottom: 0;
                padding: var(--table-side-gap);
                border-bottom: 1px solid var(--table-row-border-color);

                .management-and-refresh {
                    gap: 20px;
                    margin: 0;
                    padding: 0;

                    .add-button {
                        margin-left: initial;
                    }

                    .max-certificates-per-group {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        height: 30px;
                        padding: 5px 8px 5px 10px;
                        border: 1px solid rgba(151, 151, 151, 0.5);
                        border-radius: 15px;
                        font-size: 12px;
                        line-height: 14px;
                        color: var(--badge-text-color);
                    }
                }
            }

            .certificate-groups-table-headers,
            .certificate-groups-table-body {
                margin-left: var(--table-side-gap);
                margin-right: var(--table-side-gap);
            }

            .certificate-groups-table-body {
                flex-grow: 1;
                overflow: auto;
                overflow: overlay;
                margin-right: 0;
                padding-right: var(--table-side-gap);

                .certificate-group-row {
                    height: $list-large-item-height;

                    .isSelected {
                        background-color: var(--table-row-active-bg-color);
                    }

                    .isFaded {
                        opacity: 0.5;
                    }
                }

                .Collapsible {
                    border: none;
                    color: inherit;

                    .Collapsible__contentInner,
                    .Collapsible__contentOuter,
                    .Collapsible__trigger {
                        color: inherit;
                        border: none;
                        padding: 0;
                        font-size: inherit;
                        font-weight: inherit;
                        border-radius: inherit;
                    }

                    .Collapsible__contentInner {
                        background-color: var(--table-expanded-row-bg-color);
                    }

                    .Collapsible__trigger:hover .item {
                        background-color: var(--table-row-hover-bg-color);
                    }

                    &.open {
                        .Collapsible__trigger .item-list-item {
                            background-color: var(--table-row-active-bg-color);
                        }
                    }
                }
            }

            .certificate-groups-table-headers {
                width: auto;
                padding-right: 105px; // considering row options width and status width
            }

            .header-field,
            .data-field {
                &.groupName {
                    width: 20%;
                }
                &.associatedApp {
                    width: 25%;
                }
                &.totalCertificates {
                    width: 25%;
                }
                &.totalExpiredCertificates {
                    width: 25%;
                }
                &.status {
                    min-width: 136px;
                }

                /* Key Server --- */
                &.expand {
                    min-width: 30px;
                }
                &.name,
                &.address,
                &.certificate,
                &.description {
                    width: 21%;
                }
                &.boundCertificatesCount {
                    width: 16%;
                }
                /* --- Key Server */
            }
            .data-field {
                &.associatedApp {
                    .close-icon {
                        position: relative;
                        left: -5px;
                        width: 22px;
                        height: 22px;
                        background-image: url(../static-assets/images/widget-close.svg);
                    }
                }
                &.totalCertificates {
                    padding-left: 55px;
                }
                &.totalExpiredCertificates {
                    padding-left: 52px;
                }
                &.status {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    &.hidden-field {
                        visibility: hidden;
                    }
                    .in-progress-badge,
                    .failed-badge {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 2px;
                        padding: 3px 7px 3px 2px;
                        border-radius: 10px;
                        line-height: 16px;
                    }

                    .in-progress-badge {
                        width: 106px;
                        background-color: #ffe2c8;
                        color: #d36500;
                    }
                    .failed-badge {
                        width: 76px;
                        background-color: #ffcaca;
                        color: #d6181f;
                    }

                    .in-progress-icon,
                    .failed-icon {
                        width: 24px;
                        height: 24px;
                    }
                    .in-progress-icon {
                        background: url('../static-assets/images/new-design/refresh-orange.svg') no-repeat center;
                        background-size: contain;
                    }
                    .failed-icon {
                        background: url('../static-assets/images/open-ticket_icon.svg') no-repeat center;
                        background-size: 14px;
                    }
                }

                /* Key Server --- */
                &.expand {
                    background-image: var(--dropdown-arrow-icon);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 20px;
                    transform: rotate(90deg);
                    transition: transform 0.4s ease;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;

                    &:hover {
                        background-color: var(--section-icon-hover-bg-color);
                    }
                    &.expanded {
                        transform: rotate(270deg);
                    }
                }

                &.certificate {
                    @extend %text-no-overflow;
                    height: 24px;
                    color: var(--text-color);

                    .badge {
                        @extend %text-no-overflow;
                        display: inline-block; // for ellipsis
                        max-width: 100%; // for ellipsis
                        font-size: 12px;
                        font-weight: 400;
                        color: var(--badge-text-color);
                        background-color: var(--badge-bg-color);
                        border-radius: 4px;
                    }
                }
                /* --- Key Server */

                &.card-item-options {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    width: auto;
                    padding: 0;

                    .edit-icon {
                        width: 30px;
                        height: 30px;
                        background-size: 13px;
                        background-image: url(../static-assets/images/icon-edit.svg);
                        margin-bottom: 0;
                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .edit-icon,
                    .delete-icon {
                        &.disabled {
                            cursor: initial;
                            pointer-events: initial;
                        }
                    }
                }
            }
        }

        .certificate-groups-side-panel {
            width: 585px;
            min-width: 585px;
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            padding: 14px 20px 20px 20px;
            background-color: var(--card-bg-color);
            border-radius: var(--card-border-radius);

            .side-panel-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;

                .side-panel-title {
                    margin: 0;
                    .help-tooltip {
                        b {
                            color: #e66f01;
                        }
                    }
                }

                .close-icon {
                    left: 5px;
                    background: var(--close-icon) no-repeat center;
                }
            }

            .side-panel-form {
                display: flex;
                flex-direction: column;
                height: 100%;

                .certificate-group-name,
                .certificate-group-certificates {
                    .invalid-input {
                        position: absolute;
                    }
                }

                .certificate-group-name {
                    position: relative;
                    width: $input-width;
                }

                .certificate-group-certificates {
                    display: flex;
                    align-items: flex-end;
                    gap: 10px;
                    margin-bottom: 20px;

                    .certificate-group-certificates-select {
                        position: relative;
                        flex-grow: 1;

                        .certificate-group-certificates-select-label-wrapper {
                            display: flex;
                            align-items: center;
                            margin-right: 10px;

                            .certificate-group-certificates-label {
                                font-size: 12px;
                                line-height: 14px;
                            }

                            .certificate-group-counter-badge {
                                display: inline-block;
                                width: auto;
                                min-width: 35px;
                                margin-left: auto;
                                padding: 0 10px;
                            }
                        }

                        .Select-option.is-disabled {
                            color: var(--dropdown-disabled-text-color);
                            pointer-events: none;
                            opacity: 0.7;
                        }

                        .Select-value {
                            margin: 3.5px 10px 3.5px 0;
                        }
                    }

                    .certificate-group-certificates-add-btn {
                        height: 30px;
                        padding: 0 12px;
                        border-radius: 8px;
                        color: $new_white;
                        background: linear-gradient(0deg, rgba(189, 189, 189, 0.1), rgba(189, 189, 189, 0.1)),
                            linear-gradient(170.92deg, #211dd9 -14.01%, rgba(21, 75, 212, 0.92) 90.12%, #2b6df2 224.2%);

                        &[disabled] {
                            color: #a5abb5;
                            background: var(--add-button-bg-color-disabled);
                            opacity: initial !important;
                        }
                    }
                }

                .generic-form-options {
                    margin-top: auto;
                }

                .chosen-certificates-table {
                    height: 0;
                    flex-grow: 1;
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    font-size: 12px;

                    .chosen-certificates-table-headers {
                        display: flex;
                        margin-bottom: 5px;
                        font-weight: 500;
                        @include sort-btns();
                        .sort-btn {
                            cursor: pointer;
                        }
                    }

                    .chosen-certificates-table-rows {
                        height: 100%;
                        margin-right: -15px;
                        padding-right: 15px;
                        overflow: auto;
                        overflow: overlay;
                    }

                    .chosen-certificates-table-row {
                        position: relative;
                        display: flex;
                        height: 38px;
                        border-top: 1px solid var(--dropdown-item-border-color);
                    }

                    .header-field,
                    .data-field {
                        &.defaultCertificate {
                            width: 40px;
                        }
                        &.certificateName {
                            flex-grow: 1;
                        }
                        &.certificateExpiration {
                            width: 110px;
                        }
                        &.deleteCertificate {
                            width: 30px;
                        }
                    }

                    .header-field {
                        &.certificateExpiration {
                            position: relative;
                            overflow: visible;

                            .expired-certificate-notice {
                                top: 4px;
                                left: -20px;
                            }
                        }
                    }

                    .data-field {
                        display: flex;
                        align-items: center;

                        &.defaultCertificate {
                            .default-certificate-icon {
                                background: var(--certificate-disabled-icon) no-repeat center center;

                                &.isDefaultCertificate {
                                    background: var(--certificate-icon) no-repeat center center;
                                }

                                &:before {
                                    opacity: 0; // hide icon's background on hover
                                }
                            }
                        }

                        &.certificateExpiration {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;

                            .certificateExpiration-label {
                                padding-left: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    .key-servers {
        .download-package-badge {
            display: inline-flex;
            gap: 5px;
            padding: 8px 10px;
            color: var(--outlined-btn-color);
            font-size: 12px;
            font-weight: 400;
            border: 1px solid var(--outlined-btn-bg-color);
            border-radius: 20px;
            background-color: transparent;

            &:hover {
                background-color: var(--outlined-btn-bg-hover-color);
                color: var(--outlined-btn-hover-color);
            }

            &:not(.disabled) {
                cursor: pointer;
            }

            .download-icon {
                width: 12px;
                height: 12px;
                background-size: contain;
                background-image: var(--download-icon-new-primary);
                &:before {
                    opacity: 0; // hide icon's background on hover
                }
            }

            &:hover {
                .download-icon {
                    background-image: var(--download-icon-new-hover);
                }
            }
        }

        .refresh-icon {
            position: relative;
            left: 12px;
            margin-left: auto;
        }

        .certificate-groups-list .certificate-groups-table-headers {
            padding-right: 93px;
        }

        .key-server-input .invalid-input {
            position: absolute;
        }

        .key-server-input {
            position: relative;
            width: $input-width;

            &.key-server-certificate.certificate-group-certificates {
                margin-top: 5px;
                margin-bottom: 15px;
            }

            &.key-server-description,
            &.key-server-description input {
                width: 100%;
            }
        }

        .key-server-details {
            display: flex;
            gap: 10px;
            padding: 10px;

            .key-server-widget-wrapper {
                display: flex;
                flex-direction: column;
                gap: 10px;
                flex-basis: 33.33%;
                height: 254px;
                padding: 10px 15px;
                background-color: var(--card-bg-color);
                border-radius: var(--card-border-radius);

                .key-server-widget-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .key-server-widget-title {
                        font-size: 16px;
                        font-weight: 600;
                        color: var(--key-server-widget-title-color);
                    }
                    .key-server-widget-title-dateTime {
                        .header-date-filter-settings {
                            .time-date-control-wrapper {
                                margin-top: 0;
                            }
                            .title {
                                width: auto;
                            }
                        }
                    }
                }

                .key-server-widget {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;

                    .loading-bar {
                        top: 0;
                    }

                    .no-available-data {
                        font-size: 14px;

                        &:before {
                            width: 130px;
                            height: 111px;
                            margin: 0 auto 2px auto;
                        }
                    }
                }
            }
        }
    }
}

.boundCertificatesCount-tooltip {
    font-size: 12px;
}

.download-key-server-package-modal {
    .modal-content,
    .modal-body,
    .modal-body-content {
        overflow: initial;
    }

    .download-key-server-package-modal-content {
        @extend %global-modal-buttons;
        @extend %buttons-container;
        display: flex;
        flex-direction: column;

        .Select {
            width: $input-width;

            .Select-value {
                padding-right: 25px;
            }
        }
    }
}

.certificates-page {
    width: 100%;

    .items-header-new {
        .header-right {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: auto;

            // duplicated code from security-events.scss
            .sort-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 10px;

                .Select {
                    width: 220px;
                    margin: 0 5px;
                }

                .asc,
                .desc {
                    width: 27px;
                    height: 26px;
                    cursor: pointer;
                    background: url(../static-assets/images/sort-direction.svg) no-repeat center center;
                    &.disabled {
                        cursor: not-allowed;
                    }
                }
                .desc {
                    transform: rotate(180deg);
                }
            }

            .export-button {
                margin-right: 10px;
            }
        }
    }

    .certificates-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 30px;
        align-items: center;

        .certificate-item {
            position: relative;
            width: 330px;
            height: 420px;
            padding: 14px 0 10px 0;
            background: var(--card-bg-color);
            border-radius: var(--card-border-radius);

            .certificate-item-container {
                padding-left: 25px;
                padding-right: 25px;
                .domains-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 90%;
                }
            }

            .selected-content > div {
                height: 95px;
                padding: 15px 0 13px 0;
                font-size: 15px;
            }

            .certificate-item-header {
                margin-bottom: 12px;
                min-height: 50px;

                .certificate-item-header-fingerprint {
                    @extend %text-no-overflow;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                }

                .certificate-item-header-type {
                    display: inline-flex;
                    align-items: center;
                    gap: 5px;
                    padding: 6px 10px;
                    border-radius: 4px;
                    margin-bottom: 8px;

                    .certificate-item-header-type-icon {
                        width: 16px;
                        height: 16px;
                    }

                    .certificate-item-header-type-label {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: normal;
                    }

                    &.client-REGULAR,
                    &.client-SELF_SIGNED {
                        background-color: var(--certificate-type-client-bg-color);
                        .certificate-item-header-type-icon {
                            background: var(--certificate-type-client-icon) no-repeat center;
                        }
                        .certificate-item-header-type-label {
                            color: var(--certificate-type-client-color);
                        }
                    }
                    &.client-SECK {
                        background-color: var(--certificate-type-key-server-bg-color);
                        .certificate-item-header-type-icon {
                            background: var(--certificate-type-key-server-icon) no-repeat center;
                        }
                        .certificate-item-header-type-label {
                            color: var(--certificate-type-key-server-color);
                        }
                    }
                    &.server-HSM,
                    &.server-SELF_SIGNED_HSM {
                        background-color: var(--certificate-type-no-key-bg-color);
                        .certificate-item-header-type-icon {
                            background: var(--certificate-type-no-key-icon) no-repeat center;
                        }
                        .certificate-item-header-type-label {
                            color: var(--certificate-type-no-key-color);
                        }
                        .certificate-item-header-key-server-name {
                            color: var(--certificate-type-no-key-extra-color);
                        }
                    }
                    &.server-MANAGED,
                    &.SELF_SIGNED-MANAGED {
                        background-color: var(--certificate-type-managed-bg-color);
                        .certificate-item-header-type-icon {
                            background: var(--certificate-type-managed-icon) no-repeat center;
                        }
                        .certificate-item-header-type-label {
                            color: var(--certificate-type-managed-color);
                        }
                    }
                }

                .certificate-item-header-name {
                    // color: var(--text-color);
                    @extend %text-no-overflow;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    min-height: 30px;
                }
                .certificate-item-header-description-section {
                    color: var(--text-color);
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    padding: 20px 0px;
                    min-height: 80px;
                    .certificate-item-header-description {
                        font-weight: 400;
                        margin-top: 10px;
                        @extend %text-no-overflow;
                    }
                }
            }

            .certificate-item-signed-value {
                display: flex;
                align-items: center;
                font-size: 1.07em;
                font-weight: 600;

                span {
                    @extend %text-no-overflow;
                    padding-left: 5px;
                }
                .certificate-icon {
                    width: 24px;
                    height: 24px;

                    &.self-signed {
                        background: url('../static-assets/images/stop-sign.svg') no-repeat center;
                    }

                    &.trusted {
                        background: url('../static-assets/images/trusted-signed.svg') no-repeat center;
                    }
                }
            }

            .certificate-item-header-name,
            .certificate-item-signed-value,
            .certificate-item-details-total-apps {
                cursor: pointer;
            }

            .certificate-item-details {
                padding-top: 15px;
                padding-bottom: 20px;
                line-height: 1.2;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .certificate-item-details-key-size,
                .certificate-item-details-total-apps {
                    text-align: center;
                }

                .certificate-item-details-key-size-prefix,
                .certificate-item-details-total-apps-label {
                    font-size: 1.25em;
                }

                .certificate-item-details-key-size-total,
                .certificate-item-details-date-counter-total,
                .certificate-item-details-total-apps-value {
                    font-size: 1.46em;
                    font-weight: 500;
                }

                .certificate-item-details-date-counter-validTo {
                    font-size: 0.923em;
                    color: #8d8d8d;
                }

                .certificate-item-details-date-counter-text {
                    font-size: 1.07em;
                }

                .certificate-item-details-date-counter-total {
                    padding-right: 5px;
                }

                .certificate-item-details-date {
                    display: flex;
                    align-items: center;
                    .calendar {
                        display: inline-block;
                        position: relative;
                        width: 37px;
                        height: 38px;
                        background: url('../static-assets/images/calendar.svg') no-repeat;
                        margin-right: 8px;
                        &.critical:before {
                            content: '';
                            display: block;
                            position: absolute;
                            background: url('../static-assets/images/critical-icon.svg') no-repeat;
                            width: 14px;
                            height: 32px;
                            right: 0;
                            top: 3px;
                        }
                    }
                }
            }

            .certificate-item-sections-container {
                .certificate-item-sections {
                    li {
                        display: flex;
                        align-items: center;
                        margin-bottom: 16px;
                    }

                    &.inner-navigator .radio-group {
                        justify-content: center;
                        width: 100%;
                        border-top: 1px solid $gray-800;
                        margin-bottom: 0;
                        input[type='radio'] + label {
                            min-width: auto;
                            font-size: 14px;
                            padding: 12px 10px 3px 10px;
                        }
                    }

                    .domain-icon {
                        display: inline-block;
                        background: url('../static-assets/images/domain-icon.svg') no-repeat;
                        width: 17px;
                        height: 17px;
                        margin-right: 5px;
                    }

                    .certificate-item-sections-bound {
                        text-align: center;
                        border: none;
                        background: none;
                        font-size: 0.9em;
                        color: #acb3b7;
                        position: absolute;
                        right: 5px;
                        bottom: 5px;
                        transform: scale(0.9);
                        .certificate-item-sections-bound-icon {
                            width: 48px;
                            height: 32px;
                            display: block;
                            background: url(../static-assets/images/import-certificate-icon.svg) no-repeat;
                            border: none;
                        }
                    }

                    .certificate-item-sections-bounded-apps-item {
                        span {
                            max-width: 219px;
                            @extend %text-no-overflow;
                        }
                        .cloud-icon {
                            display: inline-block;
                            background: url('../static-assets/images/cloud-icon.svg') no-repeat;
                            width: 25px;
                            height: 15px;
                            margin-right: 5px;
                            padding-bottom: 20px;
                        }
                    }

                    li.certificate-item-sections-chain-item {
                        align-items: flex-start;
                        .chain-icon {
                            display: inline-block;
                            background: url('../static-assets/images/chain-icon.svg') no-repeat;
                            width: 10px;
                            height: 19px;
                            margin-right: 5px;
                            vertical-align: top;
                        }
                        .info-icon {
                            display: inline-block;
                            background: url('../static-assets/images/info_icon.svg') no-repeat;
                            background-size: contain;
                            width: 15px;
                            height: 15px;
                            margin-right: 5px;
                            padding: 0 8px;
                        }
                        .chain-CN {
                            font-weight: 500;
                        }
                        &.expandable {
                            cursor: pointer;
                            &:before {
                                content: '';
                                display: inline-block;
                                border: 3px solid transparent;
                                border-left: 5px solid #333;
                                margin-right: 3px;
                                margin-left: -11px;
                                margin-top: 8px;
                            }
                            &.expanded {
                                &:before {
                                    border-left: 3px solid transparent;
                                    border-top: 5px solid #333;
                                    margin-right: 5px;
                                    margin-left: -11px;
                                    margin-top: 9px;
                                }
                                .chain-info {
                                    padding-left: 15px;
                                    div {
                                        display: flex;
                                        flex-wrap: wrap;
                                        align-items: center;
                                    }
                                }
                            }
                        }
                    }

                    .certificate-item-sections-info-item {
                        div {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                        }
                        .info-icon {
                            display: inline-block;
                            background: url('../static-assets/images/info_icon.svg') no-repeat;
                            background-size: contain;
                            width: 15px;
                            height: 15px;
                            margin-right: 5px;
                            padding: 0 8px;
                        }
                    }
                }
            }

            &:hover .certificate-item-options {
                opacity: 1;
                visibility: visible;
            }

            .certificate-item-options {
                opacity: 0;
                position: absolute;
                top: 0;
                right: 0;
                visibility: hidden;
                width: auto;
                padding: 7px 5px;
                overflow: visible;
                transition: 0.5s;
                border-radius: $item-border-radius;
                background: var(--tooltip-bg-color);
                z-index: 1;

                &:before {
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-right: 5px solid var(--tooltip-bg-color);
                    left: -5px;
                }

                > li {
                    text-align: center;
                    padding: 0;
                    border: none;

                    &:hover {
                        background-color: transparent;
                    }
                    .information-tooltip-icon-container .managed-cert-delete-Tooltip {
                        max-width: 350px;
                        text-align: left;
                    }
                }

                .edit_icon {
                    background-image: url(../static-assets/images/icon-edit.svg);
                    margin-bottom: 10px;
                    width: 20px;
                    height: 19px;
                    &:hover {
                        background-image: url(../static-assets/images/icon-edit_hover.svg);
                    }
                }

                .delete_icon:hover {
                    background-image: url(../static-assets/images/icon-delete_hover.svg);
                    background-position: center;
                }
            }

            .static-modal {
                .save-button {
                    background-color: #6499b7;
                    color: $white;
                }

                .cancel-button {
                    background-color: var(--form-button-cancel-bg-color);
                    color: #6499b7;

                    &:hover {
                        background-color: var(--form-button-cancel-hover-bg-color);
                    }
                }
            }
        }
    }
}

.createCertificate {
    height: auto !important;
    width: 76%;
    overflow: visible !important; // for key server dropdown
    background-color: var(--card-bg-color);
    border-radius: var(--card-border-radius);
    padding: 30px;
    margin: $page-padding auto;

    .createCertificate-radio-btn {
        display: flex;
        gap: 13px;
        .certificate-wrapper {
            display: flex;
            align-items: center;
        }
    }

    .hidden-field {
        display: none;
    }

    .createCertificate-inner {
        width: 714px;
        margin: auto;
    }
    .form-header {
        border-bottom: 1px solid #6499b7;
        h1 {
            color: #6499b7;
            font-size: 2em;
            font-weight: 700;
            background-image: none;
            padding: 0;
            margin-bottom: 5px;
        }
    }

    .ssl-certificate-create-form {
        .ssl-certificate-description textarea {
            height: 48px;
        }
        .input-label {
            margin-top: 12px;
            color: var(--text-color);
            font-size: 1.154em;
            font-weight: 500;
        }
        input,
        textarea {
            border-radius: 0;
            padding: 11px 30px 10px 4px;
            width: 100%;
        }
        .key.invalid,
        .certificate.invalid {
            textarea {
                background-color: #ffd7d9;
                border: 1px solid #be1e2d;
            }
        }

        .certificate-key-server-select {
            .Select-control {
                border-radius: 0;
                &,
                .Select-value,
                .Select-placeholder {
                    padding-left: 4px;
                }
                .Select-input input {
                    padding: initial;
                }
            }
            .Select-menu-outer {
                border-radius: 0;
            }
            .error-message {
                float: initial;
                color: $off-color;
                border-left: 2px solid $off-color;
                padding-left: 5px;
                font-size: 13px;
                font-weight: 400;
            }
        }
        .checkbox-with-label {
            margin-top: 12px;
            .managed-cer-label {
                display: flex;
                align-items: center;
            }
        }
    }

    // TODO - this is a lock icon hidden by background none, need to check it's purpose, when is it showing?!
    //.ssl-certificate-create-options.form-header h1,
    //.ssl-certificate-update-options.form-header h1 {
    //    background-image: url(../static-assets/images/ssl_ic.svg);
    //}

    .ssl-certificate-update-form {
        margin-top: 10px;
    }

    .ssl-certificate-update-form-item > div {
        width: 40%;
    }

    .ssl-certificate-update-form-content-box {
        display: block;
        width: 40%;
        padding: 5px 5px;
        border-radius: 2px;
        border: 1px solid #bdbfc1;
    }

    .tree-item > div {
        margin-left: 10px;
    }

    .ssl-certificate-update-form-content-box,
    .ssl-certificate-create-form textarea {
        resize: none;
        height: 116px;
        overflow: auto;
    }

    .generic-form-options {
        &.overflow-hidden {
            position: relative;
            border-top: 1px solid #6499b7;
            width: 100%;
            right: auto;
            padding-top: 10px;
            margin-top: 35px;
        }
        > .form-discard:hover {
            background-color: var(--form-button-cancel-hover-bg-color);
        }
    }
}

.new-design .certificate-select .dropdown {
    width: 330px;
    &.active {
        .st-tooltip-item {
            position: relative;
        }
    }
}
