.list-pager.container-fluid {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.applicationEvents .limitReached,
.ddosEvents .limitReached {
    position: absolute;
    bottom: -17px;
    right: 0;
}

.top-to-bottom-grid-wrapper .list-wrapper {
    border: 1px solid #e6e6e6;
    position: relative;
}

.main-content-wrapper .applicationEvents {
    padding-left: 15px;
    padding-right: 15px;
}

div.applicationEvents,
div.fileScanningEvents {
    overflow-y: hidden;
}

.main-content-wrapper .applicationEvents,
.main-content-wrapper .applicationEvents > div,
.main-content-wrapper.ddosEvents,
.main-content-wrapper.ddosEvents > div,
.main-content-wrapper .fileScanningEvents,
.main-content-wrapper .fileScanningEvents > div {
    height: 100%;
}

.main-content-wrapper.Reports,
.main-content-wrapper.Reports > div,
.main-content-wrapper.Reports > div .top-to-bottom-grid-wrapper,
.main-content-wrapper.Reports > div .top-to-bottom-grid-wrapper > div,
.main-content-wrapper.Reports > div .top-to-bottom-grid-wrapper > div > div {
    height: 100%;
}

.main-content-wrapper.Reports > div .top-to-bottom-grid-wrapper > div > div > div:last-child {
    height: calc(100% - 50px);
}

.main-content-wrapper.Reports > div .top-to-bottom-grid-wrapper > div > div > div:last-child > .list-wrapper {
    height: 100%;
}

.main-content-wrapper.reports,
.main-content-wrapper.reports > div,
.main-content-wrapper.reports > div .top-to-bottom-grid-wrapper,
.main-content-wrapper.reports > div .top-to-bottom-grid-wrapper > div,
.main-content-wrapper.reports > div .top-to-bottom-grid-wrapper > div > div {
    height: 100%;
}

.main-content-wrapper.reports > div .top-to-bottom-grid-wrapper > div > div > div:last-child {
    height: calc(100% - 50px);
}

.main-content-wrapper.reports > div .top-to-bottom-grid-wrapper > div > div > div:last-child > .list-wrapper {
    height: 100%;
}

.applicationEvents .top-to-bottom-grid-wrapper,
.applicationEvents .top-to-bottom-grid-wrapper > div,
.ddosEvents .top-to-bottom-grid-wrapper,
.ddosEvents .top-to-bottom-grid-wrapper > div,
.fileScanningEvents .top-to-bottom-grid-wrapper,
.fileScanningEvents .top-to-bottom-grid-wrapper > div {
    height: 100%;
}

.applicationEvents .list-wrapper,
.ddosEvents .list-wrapper,
.fileScanningEvents .list-wrapper {
    height: calc(100% - 30px);
}

.applicationEvents .top-to-bottom-grid-wrapper .application-attack-list,
.ddosEvents .top-to-bottom-grid-wrapper > div,
.fileScanningEvents.top-to-bottom-grid-wrapper > div {
    height: calc(100% - 80px);
}

.fileScanningEvents.top-to-bottom-grid-wrapper > div {
    height: 100%;
}

.applicationEvents .list-content,
.ddosEvents .list-content,
.fileScanningEvents .list-content {
    height: calc(100% - 70px);
    overflow: auto;
}

.top-to-bottom-grid-wrapper .list-wrapper {
    border: 1px solid #e6e6e6;
    position: relative;
    clear: both;
    width: 100%;
    max-height: 74vh;
}

.top-to-bottom-grid-wrapper .list-row {
    width: 100%;
    position: relative;
}

.top-to-bottom-grid-wrapper .list-row:after {
    content: ' ';
    display: block;
    clear: both;
}

.top-to-bottom-grid-wrapper .list-row .list-cell {
    float: left;
    width: 50%;
    box-sizing: border-box;
    border-right: 1px solid $white;
    position: relative;
    font-size: 0.9em;
    color: #333;
    @extend %text-no-overflow;
}

.top-to-bottom-grid-wrapper .list-header .list-row .list-cell {
    padding: 8px 13px 8px 6px;
}

.top-to-bottom-grid-wrapper .list-content .list-row .list-cell {
    padding: 8px 8px 8px 6px;
}

.top-to-bottom-grid-wrapper .list-row .list-cell-nosort {
    float: left;
    width: 50%;
    box-sizing: border-box;
    padding: 8px 10px;
    border-right: 1px solid $white;
    position: relative;
    font-size: 0.9em;
    color: #333;
    @extend %text-no-overflow;
}

.top-to-bottom-grid-wrapper .list-row .list-cell:first-child {
    padding-left: 40px;
    position: relative;
}

.top-to-bottom-grid-wrapper .list-row .list-cell:first-child:before {
    content: '^';
    display: block;
    position: absolute;
    left: 8px;
    top: 0px;
    font-size: 20px;
    transform: rotate(180deg);
    opacity: 0.6;
}

.top-to-bottom-grid-wrapper .sslApplicationManagement-details-applicationDetailsGrid .list-row .list-cell:first-child {
    padding-left: 6px;
}

.top-to-bottom-grid-wrapper .sslApplicationManagement-details-applicationDetailsGrid .list-row .list-cell:first-child:before {
    display: none;
}

.top-to-bottom-grid-wrapper .sslApplicationManagement-details-applicationDetailsGrid .list-header .list-row .list-cell:after {
    display: none;
}

.top-to-bottom-grid-wrapper .st-accordion__item.expand .list-row .list-cell:first-child:before {
    opacity: 1;
    transform: rotate(0deg);
    top: 4px;
}

.top-to-bottom-grid-wrapper .list-header .list-row .list-cell:first-child:before {
    display: none;
}

.applicationEvents .top-to-bottom-grid-wrapper .list-row .list-cell:last-child,
.ddosEvents .top-to-bottom-grid-wrapper .list-row .list-cell:last-child {
    padding-right: 33px;
}

.top-to-bottom-grid-wrapper .list-row .list-cell:last-child {
    border-right: none;
}

.top-to-bottom-grid-wrapper .list-row .list-cell-color {
    content: ' ';
    display: block;
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.top-to-bottom-grid-wrapper .list-header .list-row {
    background-color: #acc6d2;
}

.top-to-bottom-grid-wrapper .list-header .list-row .list-cell {
    /*text-transform:uppercase; */
    font-size: 0.95em;
    font-weight: bold;
}

.top-to-bottom-grid-wrapper .list-header .list-row .list-cell-nosort {
    /*text-transform: uppercase;*/
    font-size: 0.95em;
    font-weight: bold;
}

.top-to-bottom-grid-wrapper .list-row .list-cell.low:before,
.top-to-bottom-grid-wrapper .list-row .list-cell.info:before,
.top-to-bottom-grid-wrapper .list-row .list-cell.high:before,
.top-to-bottom-grid-wrapper .list-row .list-cell.critical:before,
.top-to-bottom-grid-wrapper .list-row .list-cell.warning:before {
    content: ' ';
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 8px;
    background-repeat: no-repeat;
}

.top-to-bottom-grid-wrapper .list-row .list-cell.low:before {
    background-image: url('../static-assets/images/severity-low.svg');
}

.top-to-bottom-grid-wrapper .list-row .list-cell.info:before {
    background-image: url('../static-assets/images/severity-info.svg');
}

.top-to-bottom-grid-wrapper .list-row .list-cell.high:before {
    background-image: url('../static-assets/images/severity-high.svg');
}

.top-to-bottom-grid-wrapper .list-row .list-cell.critical:before {
    background-image: url('../static-assets/images/severity-critical.svg');
}

.top-to-bottom-grid-wrapper .list-row .list-cell.warning:before {
    background-image: url('../static-assets/images/severity-warning.svg');
}

.top-to-bottom-grid-wrapper .list-row .list-cell.low,
.top-to-bottom-grid-wrapper .list-row .list-cell.info,
.top-to-bottom-grid-wrapper .list-row .list-cell.high,
.top-to-bottom-grid-wrapper .list-row .list-cell.critical,
.top-to-bottom-grid-wrapper .list-row .list-cell.warning {
    padding-left: 30px;
}

.applicationEvents .top-to-bottom-grid-wrapper .list-row .list-cell:last-child:after,
.ddosEvents .top-to-bottom-grid-wrapper .list-row .list-cell:last-child:after {
    right: 27px !important;
}

.applicationEvents .top-to-bottom-grid-wrapper .list-row .list-cell-nosort:last-child:after,
.ddosEvents .top-to-bottom-grid-wrapper .list-row .list-cell:last-child:after {
    right: 35px !important;
}

.top-to-bottom-grid-wrapper .list-header .list-row .list-cell-nosort:after {
    display: none;
}

.top-to-bottom-grid-wrapper .list-header .list-row .list-cell:after {
    content: ' ';
    display: block;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 0;
    width: 6px;
    cursor: pointer;
    background-image: url('../static-assets/images/unsorted.svg');
    background-repeat: no-repeat;
    background-position-y: center;
}

.top-to-bottom-grid-wrapper .list-header .list-row .list-cell.sort-desc:after {
    background-image: url('../static-assets/images/sorted-desc.svg');
}

.top-to-bottom-grid-wrapper .list-header .list-row .list-cell.sort-asc:after {
    background-image: url('../static-assets/images/sorted-asc.svg');
}

.top-to-bottom-grid-wrapper .list-content .generic-list > .list-row:nth-child(even) {
    background-color: #edeff5;
}

.top-to-bottom-grid-wrapper .list-content .generic-list > .list-row:nth-child(odd) {
    background-color: white;
}

.top-to-bottom-grid-wrapper .list-content .st-accordion__inner {
    background-color: $white;
    padding: 15px;
}

.top-to-bottom-grid-wrapper .list-content .st-accordion__inner li {
    padding: 5px 0;
    font-size: 0.85em;
}

.top-to-bottom-grid-wrapper .list-content .st-accordion__inner li label {
    margin-bottom: 0;
}

.top-to-bottom-grid-wrapper .list-content .st-accordion__inner ul:after {
    content: ' ';
    display: block;
    float: none;
    clear: both;
}

.top-to-bottom-grid-wrapper .item-details .st-accordion__item:hover {
    background-color: transparent;
}

.top-to-bottom-grid-wrapper .item-details .st-accordion__item {
    background-color: transparent !important;
    position: relative;
}

.top-to-bottom-grid-wrapper .item-details .st-accordion__item:hover * {
    cursor: pointer;
}

.top-to-bottom-grid-wrapper .item-details .st-accordion__item a {
    color: #333;
    padding-left: 10px;
    display: inline-block;
}

.top-to-bottom-grid-wrapper .item-details .st-accordion__item:before {
    content: '';
    display: block;
    position: absolute;
    border: 3px solid transparent;
    border-left-color: #333;
    border-left-width: 6px;
    top: 7px;
    left: 0px;
}

.top-to-bottom-grid-wrapper .item-details .st-accordion__item.expand:before {
    transform: rotate(90deg);
    top: 10px;
    left: -2px;
}

.top-to-bottom-grid-wrapper .st-accordion__item.expand .list-row .list-cell {
    font-weight: bold;
}

.expanded-details-columns {
    padding: 0 20px;
    white-space: pre;
}

.events-details-wrapper {
    box-sizing: border-box;
    float: left;
    padding: 0 20px;
}

.item-details:after {
    content: ' ';
    display: block;
    clear: both;
}

.item-details .events-details-wrapper {
    float: left;
    padding: 0 20px;
    box-sizing: border-box;
}

.item-details .events-details-wrapper ul {
    list-style: none;
    padding: 0;
}

.item-details .events-details-wrapper ul li label {
    display: inline-block;
    padding-right: 10px;
    font-style: normal;
    font-weight: bold;
    width: 20%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.item-details .events-details-wrapper ul li span {
    white-space: pre;
    display: inline-block;
    vertical-align: top;
    width: 80%;
    max-height: 108px;
    overflow: auto;
}

.item-details .events-details-wrapper ul li span li {
    float: none;
    width: 90%;
    padding: 1px 2%;
}

.item-details .events-details-wrapper ul:after {
    content: ' ';
    clear: both;
}

.navbar-toolbar .nav.nav-pills > li[class^='nav-item'] a {
    color: #414042;
    border: none;
    background-color: transparent;
    font-size: 1.077em;
    border-radius: 0;
    padding: 0 8px;
    line-height: 2.8;
}

.navbar-toolbar .nav.nav-pills > li[class^='nav-item'] a:hover {
    background-color: transparent;
    box-shadow: inset 0 -2px 0px 0px #276e98;
    color: #276e98;
    cursor: pointer;
}

.navbar-toolbar .nav.nav-pills > li[class^='nav-item']:first-child {
    margin-left: 15px;
}

.navbar-toolbar .nav.nav-pills > li.active[class^='nav-item'] a {
    box-shadow: inset 0 -2px 0px 0px #266d97;
    color: #2a6d96;
}

.application-attack-list .columns-manager,
.ddosEvents .columns-manager {
    position: absolute;
    right: 6px;
    top: 4px;
    z-index: 4400;
}

.application-attack-list .top-to-bottom-grid-wrapper,
.ddosEvents .top-to-bottom-grid-wrapper {
    position: relative;
}

.columns-manager .dropdown > div > a:after,
.columns-manager .dropdown > div > div > a:after {
    display: none;
}

.application-attack-list .columns-manager .dropdown > div:first-child a,
.ddosEvents .columns-manager .dropdown > div:first-child a {
    color: transparent !important;
}

.column-manager-trigger > div {
    height: 4px;
    width: 100%;
    background-color: #5a6469;
    margin: 3px 0;
    border-radius: 25%;
}

.columns-manager .dropdown > div:first-child {
    background: inherit;
}

.column-manager-trigger {
    width: 15px;
}

.columns-manager .dropdown.active .st-tooltip-item {
    width: 200px;
    left: inherit;
    right: -5px;
    border: none;
    top: 27px;
}

.columns-manager .dropdown.active .st-tooltip-item ul.st-list--table li.st-list--selected span:after {
    content: '\2713';
    display: block;
    position: absolute;
    right: 14px;
    top: 8px;
    color: $black;
}

.columns-manager .dropdown.active .st-tooltip-item ul.st-list--table li span:before {
    content: '';
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    border: 1px solid #6d6f70;
    right: 10px;
    top: 7px;
    border-radius: 2px;
    background-color: #d6e2e9;
}

.columns-manager .dropdown.active .st-tooltip-item ul.st-list--table li span {
    background-color: #acc6d2;
    padding: 7px 5px;
    color: #333;
    border-bottom: none;
    box-shadow: none !important;
}

.columns-manager .dropdown.active .st-tooltip-item ul.st-list--table li {
    padding: 2px;
    box-shadow: none !important;
}

.columns-manager .dropdown.active .st-tooltip-item ul.st-list--table {
    box-shadow: none;
}

.confirmation-options {
    position: absolute;
    background-color: $white;
    left: 0;
    transition: 0.5s;
    top: 8px;
    padding: 3px;
}

.events-details-options {
    position: relative;
    background-color: $white;
    padding: 10px 10px 0;
    border-top: 1px solid #d1d1d1;
    overflow: hidden;
}

.confirmation-options > button,
.confirmation-options > div {
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    border: none;
}

.confirmation-options > button:nth-child(2) {
    background-color: #76a058;
    cursor: pointer;
}

.confirmation-options > button:nth-child(3) {
    background-color: silver;
    cursor: pointer;
}

.events-details-options .refine {
    padding: 5px 10px;
    display: inline-block;
    background-color: #76a058;
    cursor: pointer;
    border: none;
}

.events-details-options .disableRefine {
    padding: 5px 10px;
    display: inline-block;
    background-color: rgba(120, 210, 74, 0.7);
}

.confirmation-options.off {
    left: -100%;
}

/*Security Events Form*/

.list-form.list-form-close {
    display: none;
}

.security-events-form {
    position: relative;
}

.security-events-form {
    margin-bottom: 15px;
}

.security-events-form .header-options {
    float: right;
    padding: 0 10px 5px;
}

.security-events-form .header-title {
    float: left;
}

.security-events-form .header-options > div {
    float: left;
    margin-left: 10px;
    cursor: pointer;
}

.security-events-form .form-header {
    border-bottom: 1px solid #d2d2d2;
}

.security-events-form .form-content div[class^='col-']:nth-child(2) {
    border-right: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
}

.security-events-form .form-content div[class^='col-']:last-child {
    border-right: none;
}

.security-events-form .form-content {
    padding: 10px 0;
}

.security-events-form .form-content table td {
    padding: 5px 10px;
    white-space: nowrap;
}

.security-events-form .form-content table {
    width: 100%;
}

.security-events-form .form-content table td:nth-child(2) {
    width: 80px;
}

.security-events-form .form-content div[class^='col-']:nth-child(1) table td:nth-child(2) {
    width: auto;
}

.security-events-form .form-content div[class^='col-']:not(:nth-child(1)) table td:nth-child(1) {
    width: 130px;
}

.security-events-form .form-content div[class^='col-']:not(:nth-child(1)) table td:nth-child(2) {
    max-width: 80px;
}

.security-events-form .form-content div[class^='col-']:not(:nth-child(1)) table td:nth-child(2) .dropdown {
    width: 70px;
}

.security-events-form .form-content .dropdown a {
    color: #414042;
}

.security-events-form {
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.25);
    background-color: #f3f3f4;
    padding: 10px;
}

.security-events-form .form-content table th {
    font-weight: normal;
    padding: 0 10px;
    text-transform: uppercase;
}

.security-events-form .form-content input[type='text'] {
    width: 100%;
    max-width: none;
}

.security-events-form .form-header > div:after {
    display: block;
    content: ' ';
    clear: both;
}

.security-events-form .header-options-close {
    position: absolute;
    background-image: url('../static-assets/images/form-filter-close.svg');
    width: 22px;
    height: 22px;
    top: -15px;
    right: -15px;
    cursor: pointer;
    color: transparent;
}

.security-events-form .header-options-close:hover {
    background-image: url('../static-assets/images/form-filter-close-hover.svg');
}

.security-events-form .header-options-submit {
    background-image: url('../static-assets/images/form-filter-submit.svg');
    width: 26px;
    height: 22px;
    color: transparent;
    background-repeat: no-repeat;
}

.security-events-form .header-options-clear {
    background-image: url('../static-assets/images/form-filter-clear.svg');
    width: 22px;
    height: 22px;
    color: transparent;
    background-repeat: no-repeat;
}

.security-events-form .header-options-submit:hover {
    background-image: url('../static-assets/images/form-filter-submit-hover.svg');
}

.security-events-form .header-options-clear:hover {
    background-image: url('../static-assets/images/form-filter-clear-hover.svg');
}

.security-events-form .header-title {
    background-image: url(../static-assets/images/form-filter-open.svg);
    background-repeat: no-repeat;
    padding-left: 32px !important;
    white-space: nowrap;
    opacity: 0.6;
}

.security-events-form .rdt .form-control {
    padding: 5px !important;
}

.security-events-options {
    display: flex;
}

.security-events-options .security-events-toolbar {
    //     border-right: 2px solid silver;
    padding: 0 15px;
}

.security-events-options .security-events-toolbar:last-child {
    border: none;
    padding-right: 0;
}

.security-events-options span.last-updated-value {
    display: inline-block;
    margin-right: 8px;
    line-height: 1.8;
}

.list-toolbar {
    padding-bottom: 5px;
}

.security-events-toolbar div {
    cursor: pointer;
}

.security-events-options .toggle-btn {
    background-image: url(../static-assets/images/form-filter-open.svg);
    width: 22px;
    height: 22px;
    color: transparent;
    background-repeat: no-repeat;
    opacity: 0.5;
}

.security-events-options .toggle-btn:hover {
    opacity: 1;
}

.security-events-options .update-btn {
    background-image: url(../static-assets/images/form-filter-refresh.svg);
    width: 22px;
    height: 22px;
    color: transparent;
    background-repeat: no-repeat;
    opacity: 0.65;
}

.security-events-options .update-btn:hover {
    opacity: 1;
}

/*Hide oredering in file scanning events*/
.fileScanningEvents .list-row .list-cell:after {
    display: none !important;
}

.security-events-options #dateTimeFilter {
    position: relative;
}

.security-events-options .time-settings-wrapper {
    z-index: 4500;
    background-color: $white;
    width: 250px;
    border: 1px solid #3e3e3e;
    font-size: 12px;
    top: 8px;
    right: -248px;
}

.security-events-options .time-settings-wrapper .time-frame-selector .time-frame-option {
    margin: 0;
}

.security-events-options .time-settings-wrapper .time-frame-selector .time-frame-option a {
    padding: 5px;
}

.security-events-options .time-settings-wrapper .date-range-picker-apply {
    padding: 10px;
    float: right;
    background-color: #dddfe8;
}

.security-events-options .time-settings-wrapper .date-range-picker-apply:hover {
    background-color: #2a6d96;
}

.security-events-options .time-settings-wrapper a {
    color: #333;
}

.time-date-selector ul.time-frame-selector {
    font-size: 11.7px;
}

.security-events-options .time-settings-wrapper li.st-accordion__item {
    padding: 10px;
}

.security-events-options .time-settings-wrapper:before {
    content: '';
    display: block;
    border: 8px solid transparent;
    position: absolute;
    top: -17px;
    right: 10px;
    border-bottom-color: #3e3e3e;
}

.security-events-options .time-settings-wrapper:after {
    content: '';
    display: block;
    border: 8px solid transparent;
    position: absolute;
    top: -16px;
    right: 10px;
    border-bottom-color: $white;
}

.security-events-options .time-settings-wrapper li.st-accordion__item:nth-child(odd) .date-range-picker-apply {
    background-color: #d1d1d1;
}
