.navbar-default {
    border: none;
    min-height: 40px;
    background-color: #252928;
    margin-bottom: 0;
    border-radius: 0;
    position: left top;
    background-repeat: no-repeat;
}

.sign-in-wrapper {
    .navbar-default {
        background-image: url(../static-assets/images/bgImage.jpg);
        background-color: #00394a;

        .navbar-brand {
            min-width: 250px;
            padding: 11px 15px 11px 15px;
            height: auto;
            opacity: 0.9;
            color: $white;
            font-size: 18px;
            font-weight: 400;
            line-height: 33.59px;

            img {
                display: inline-block;
                padding-right: 20px;
            }
        }
    }
}

.navbar-brand > img {
    margin-left: 5px;
}

.navbar-default nav > .navbar-nav > li {
    margin-left: 5px;
}

.navbar-default .main-nav-cont > .nav.nav-pills > li > a {
    border-radius: 0;
    padding: 18px 40px;
    color: #f7fdff;
    font-weight: lighter;
    font-size: 16px;
    background-color: #252928;
}

.navbar-default .navbar-nav li:hover > a {
    color: #f7fdff;
    background-color: #383e3e !important;
}

.navbar-default .navbar-nav li.st-accordion__item:hover > a {
    background-color: transparent !important;
}

.navbar-default .nav-pills > li.active > a,
.navbar-default .nav-pills > li:hover > a,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
    background-color: #383e3e;
    color: #f7fdff;
    font-size: 16px;
    font-weight: 400;
}

.navbar-default .nav-pills > li .caret {
    display: none;
}

.navbar-nav > li > .dropdown-menu {
    padding: 0;
    border: 0;
    min-width: 200px;
    box-shadow: none;
    background-color: transparent;
    li {
        display: block;
        width: 100%;
        float: left;
        a {
            font-weight: lighter;
            font-size: 14px;
            padding: 20px 40px 20px 30px;
            float: left;
            color: $white;
            @extend %text-no-overflow;
            background-position: left center;
            line-height: 1.5;
            margin-left: 20px;
        }
    }
}

.navbar-nav li .dropdown-menu a:before {
    display: inline-block !important;
}

/***********************
    activities-search
 ***********************/

.activities-search {
    position: relative;

    .hide-tab-title span {
        visibility: hidden;
    }
}

.activities-search:after {
    content: '';
    display: block;
    clear: both;
}

.activities-search > div:last-child {
    font-size: 1.3em;
    color: #6e6e70;
    float: left;
    line-height: 2.2;
}

.activities-search-tabs .navbar-nav > li {
    white-space: nowrap;
}

.activities-search-tabs {
    float: right;
    transition: 0.4s;
    background-color: transparent;
}

.main-nav-cont .activities-search .nav.nav-pills > li.active[class^='nav-item'],
.main-nav-cont .activities-search .nav.nav-pills > li {
    top: 0 !important;
    -webkit-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
}

.main-nav-cont .activities-search .nav.nav-pills > li > a {
    margin-top: 0;
}

.activities-search-tabs:after {
    content: '';
    display: block;
    clear: both;
}

.main-nav-cont .activities-search .nav.nav-pills > li.active[class^='nav-item'] {
    background-color: var(--activity-log-drawer-tab-hover-bg-color);;
    box-shadow: none;
    padding: 0;
}

.main-nav-cont .activities-search .nav.nav-pills > li[class^='nav-item'] a {
    color: transparent !important;
    box-shadow: none !important;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    background-color: transparent !important;
}

.main-nav-cont .activities-search .nav.nav-pills > li[class^='nav-item'] {
    color: transparent !important;
    width: 45px;
    height: 37px;
    box-shadow: none !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    top: 50%;
    transform: translate(-50%, -50%);
}

.main-nav-cont .activities-search .nav.nav-pills > li[class^='nav-item']:hover {
    background-color: var(--activity-log-drawer-tab-hover-bg-color);;
    box-shadow: none;
}

.main-nav-cont .activities-search .nav.nav-pills > li[class^='nav-item'] {
    margin-left: 0 !important;
    position: relative;
    cursor: pointer;
}

/*******************************
    nav-item 
 *******************************/

.nav-item-open-search a:before {
    display: block;
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border: 2px solid #6e6e70;
    border-radius: 50%;
    right: 20px;
    top: 12px;
}

.nav-item-open-search a:after {
    display: block;
    content: '';
    position: absolute;
    width: 2px;
    height: 7px;
    background-color: #6e6e70;
    right: 19px;
    top: 21px;
    transform: rotate(-45deg);
}

.nav-item-AllActivities a {
    background-image: url('../static-assets/images/activity-all.svg');
    background-color: transparent !important;
    width: 16px;
    height: 16px;
    display: block !important;
    transform: translate(-50%, -50%);
    position: absolute !important;
    top: 50%;
    left: 50%;
    overflow: hidden;
    padding: 0 !important;
}

.nav-item-InProgress a {
    background-image: url('../static-assets/images/in_process.svg');
    background-color: transparent !important;
    width: 16px;
    height: 16px;
    display: block !important;
    transform: translate(-50%, -50%);
    position: absolute !important;
    top: 50%;
    left: 50%;
    padding: 0 !important;
}

.nav-item-Success a {
    background-image: url('../static-assets/images/succeeded.svg');
    background-color: transparent !important;
    width: 16px;
    height: 16px;
    display: block !important;
    transform: translate(-50%, -50%);
    position: absolute !important;
    top: 50%;
    left: 50%;
    padding: 0 !important;
}

.nav-item-Fail a {
    background-image: url('../static-assets/images/failed.svg');
    background-color: transparent !important;
    width: 16px;
    height: 16px;
    display: block !important;
    transform: translate(-50%, -50%);
    position: absolute !important;
    top: 50%;
    left: 50%;
    padding: 0 !important;
}

.nav-item-AllActivities a:hover,
.nav-item-InProcess a:hover,
.nav-item-Success a:hover,
.nav-item-Fail a:hover,
.nav-item-AllActivities.active a,
.nav-item-InProcess .active a,
.nav-item-Success.active a,
.nav-item-Fail.active a {
    background-color: transparent !important;
}

.dropdown-content li a:before {
    float: left;
    padding: 0 0 0 10px;
    width: 40px;
}

.dropdown-content li a:hover,
.dropdown-content li.active a {
    text-decoration: underline #6da7c8;
    font-weight: bold;
    text-underline-position: under;
}

.dropdown-content li.disabled a:hover {
    text-decoration: none;
}
.dropdown-content li.disabled a {
    background: none !important;
    cursor: default;
}

a:focus {
    outline: none;
}

.portal_userMgmt {
    margin-top: 10px;
    position: relative;
}

/* Responsive */
@media (max-width: 1350px) {
    .navbar-default .main-nav-cont > .nav.nav-pills > li > a {
        padding-right: 8px;
        padding-left: 8px;
        font-size: 14px;
        .nav-pills > li + li {
            margin-left: 4px;
        }
    }
}
