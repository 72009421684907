.application-protecton-item-header-wrapper {
    background-color: #f2f3f3;
    /* overflow: auto; */
    padding: 3px 10px;
    margin-bottom: 15px;
    /* line-height: 3; */
}

.application-protecton-item-header-wrapper.solo {
    background-color: initial;
    margin: 0;
    padding: 0;
}

.application-protecton-item-header-wrapper .dropdown {
    float: right;
}

.application-protecton-item-header-wrapper:after {
    content: '';
    display: block;
    clear: both;
}

.application-protection-item-wrapper {
    width: 90%;
    margin-top: 20px;
}

.application-protection-item-protection-modes span:before {
    content: '';
    display: inline-block;
}

.application-protection-read-only-mode {
    float: right;
}

.application-protection-item-name {
    float: left;
    /* padding: 4px 0; */
    line-height: 2.5;
}

.application-protections-protection-mode-BLOCK,
.application-protections-protection-mode-REPORT {
    float: right;
}

.application-protection-item-protection-modes input[type='radio'] + label {
    margin-bottom: 0;
}

.application-protections-protection-mode-BLOCK:before {
    background: url('../static-assets/images/protections_block.svg') no-repeat;
    width: 22px;
    height: 22px;
}

.application-protections-protection-mode-REPORT:before {
    background: url('../static-assets/images/protections_report_only.svg') no-repeat;
    width: 22px;
    height: 22px;
}

.application-protection-item-list-header {
    margin: 0 0 5px 10px;
}

.application-protecton-against-list-wrapper {
    margin-bottom: 10px;
    margin-left: 15px;
}

.application-protecton-against-list-wrapper li:before {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: black;
    display: inline-block;
    margin-right: 5px;
}

.application-protection-item-protection-modes > span {
    margin-left: 12px;
}

.application-protection-read-only-mode .application-protection-item-protection-modes > span {
    margin: 0 120px 0 0;
    position: absolute;
    top: -10px;
}

.application-protection-events {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.ip-source-header-name-input {
    margin-left: 10px;
    display: inline-block;
    vertical-align: bottom;
    padding-bottom: 2px;
}

.protection-rules {
    .protection-rules-header {
        display: flex;
        .add-button {
            margin-left: 10px;
        }
        .export-icon {
            margin-left: 10px;
        }
        .refresh-button {
            margin: 0 0 0 10px;
        }
        .templates-dropdown-wrapper {
            margin: 0 0 0 auto;
        }
    }

    .sort-header {
        display: flex;
        padding: 0 15px;

        .property {
            color: var(--text-color);
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            padding: 10px 0;
            cursor: pointer;

            &.sortable {
                display: flex;
                align-items: center;

                .sort-icon {
                    content: url(../static-assets/images/sort-icon-default.svg);
                    margin-left: 3px;
                    display: inline-block;
                }

                &.active.asc {
                    .sort-icon {
                        content: url(../static-assets/images/sort-icon-arrow.svg);
                    }
                }

                &.active.desc {
                    .sort-icon {
                        content: url(../static-assets/images/sort-icon-arrow.svg);
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .filter-list-form {
        .rule-list-wrapper {
            max-height: 400px;
            overflow: auto;

            .rule-list {
                .rule {
                    display: flex;
                    align-items: center;
                    padding: 10px 15px;
                    border-bottom: 1px solid var(--table-row-border-color);
                    background-color: var(--table-bg-color);
                    font-size: 13px;
                    .rule-item {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        .rule-item-dropdown {
                            flex: 0;
                        }
                    }
                    .property {
                        .property-value {
                            @extend %text-no-overflow;
                        }

                        .property-tooltip {
                            color: #687077;
                            font-size: 13px;
                            font-weight: 300;
                            border: 1px solid #c0bfbf;
                            background-color: $white;
                            padding: 10px;
                            font-style: italic;
                            border-radius: 0;
                            max-width: 650px;
                            line-height: 1.5;
                            white-space: normal;
                        }
                    }

                    input[type='text'] {
                        width: 100%;
                        padding: 5px 10px;
                        margin: 0;
                    }

                    .rule-action-box {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-left: auto;

                        .rule-action-button {
                            width: 30px;
                            height: 30px;
                            color: $white;
                            border: none;
                            padding: 0;
                            margin-left: 10px;

                            &.delete {
                                background: url(../static-assets/images/delete-icon.svg) center center;
                            }

                            &.discard-delete {
                                background: url(../static-assets/images/cancel-delete.svg) center center;
                            }

                            &.discard-update {
                                background: url(../static-assets/images/cancel-update.svg) center center;
                            }

                            &.open {
                                height: 11px;
                                background: url(../static-assets/images/arrow-down.png) center center;
                                background-size: cover;
                            }

                            &.close {
                                height: 11px;
                                background: url(../static-assets/images/arrow-up.png) center center;
                                background-size: cover;
                            }
                        }
                    }
                }
            }
        }

        .generic-form-options {
            margin-top: 15px;
        }
    }

    .sort-header {
        padding-right: 105px;
    }

    .sort-header,
    .rule-list-wrapper .rule-list .rule {
        .property {
            flex: 1;
            min-width: 0;
            padding-right: 15px;

            &.rule-id {
                flex: 0.3;
            }

            &.param-name {
                flex: 0.5;
            }

            &.url {
                flex: 1;
            }

            &.description {
                flex: 1;
            }
        }
    }

    .rule-action-box {
        width: 80px;
    }
}
