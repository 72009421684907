$light-blue: #f7fdff;
$hits-color: #fc6b6b;

.property-tooltip {
    display: inline-block;

    .rc-tooltip-content .rc-tooltip-inner {
        color: var(--tooltip-text-color);
        font-size: 13px;
        font-weight: 300;
        background-color: var(--tooltip-bg-color);
        padding: $tooltip-padding;
        border-radius: $tooltip-border-radius;
        max-width: 650px;
        line-height: 1.5;
        white-space: normal;
        filter: $tooltip-filter;
    }
}

.httpRequestSample {
    .modal-body-content {
        white-space: pre-wrap;
        min-height: 190px;
        word-wrap: break-word;
        max-width: 100%;
    }
}

.source-tooltip {
    display: inline-block;

    .rc-tooltip-content .rc-tooltip-inner {
        font-size: 13px;
        font-weight: 300;
        width: 300px;
        line-height: 1.5;
        white-space: normal;

        .header {
            font-size: 14.3px;
            font-weight: 500;
        }

        .category-header {
            font-size: 13px;
            font-style: bold;
            font-weight: 500;
            margin-top: 8px;
            margin-bottom: 2px;
        }

        ul li {
            display: flex;

            .source-details-label {
                margin-right: 10px;
            }

            .source-details-value {
                margin-left: auto;
            }
        }

        .source-reputation {
            margin-top: 5px;
        }
    }
}

.reputation-value {
    &.high {
        color: rgb(255, 0, 0);
    }

    &.medium {
        color: rgb(255, 153, 0);
    }

    &.low {
        color: rgb(255, 204, 0);
    }

    &.suspected {
        color: rgb(153, 204, 255);
    }

    &.none {
        color: rgb(153, 204, 0);
    }
}

.ip-analytics,
.bot-analytics,
.application-insights,
.activity-logs {
    height: 100%;

    .analytics-chart-wrapper {
        display: flex;
        padding-top: 20px;
        margin-bottom: 24px;
        height: 150px;

        .analytics-statistics {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            flex: 0 0 230px;

            padding: 20px;

            .statistic {
                display: flex;
                justify-content: space-between;

                .stat-value {
                    @extend %text-no-overflow;
                    width: 70px;
                    text-align: right;
                }
            }
        }

        .analytics-chart {
            flex: 1;
            min-width: 0;
        }
    }

    .module-type-selector-wrapper {
        display: flex;
        align-items: baseline;

        .inner-navigator .radio-group {
            margin-top: 10px;
        }

        .summary {
            display: flex;
            background-color: var(--card-bg-color);
            border-radius: var(--card-border-radius);
            margin-left: auto;
            padding: 0 8px;

            .summary-block {
                padding: 2px 10px;
                text-align: center;

                .title {
                    color: var(--text-color);
                    font-size: 18px;
                    > * {
                        display: inline-block;
                    }

                    .number {
                        color: #00c0ef;
                        font-size: 26px;
                        font-weight: 500;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .activity-list-wrapper {
        height: calc(100% - 110px - 150px);
        display: flex;
        flex-direction: column;
        .activity-list {
            .activity {
                .activity-header {
                    height: $list-small-item-height;

                    &:hover {
                        background-color: var(--table-row-hover-bg-color);
                    }
                }
                &.open {
                    .activity-header {
                        box-shadow: none;
                        background-color: var(--table-row-active-bg-color);
                    }
                    .details-wrapper {
                        box-shadow: none;
                    }
                }

                .unblock-icon {
                    background: url(../static-assets/images/icon-unlock.svg) center no-repeat;
                    width: 24px;
                    height: 24px;
                }

                .unblock-icon[disabled] {
                    background-image: url(../static-assets/images/icon-unlock-disabled.svg);
                    cursor: default;
                }

                &.allow-list {
                    .allow-list-url,
                    .allow-list-method {
                        font-weight: 500;
                        font-size: 14.3px;
                    }
                }

                &.vulnerabilities {
                    .vulnerabilities-pattern-id,
                    .vulnerabilities-pattern {
                        font-weight: 500;
                        font-size: 14.3px;
                    }
                }
            }
        }

        .allow-list-url {
            width: 20%;
        }
        .allow-list-method {
            width: 20%;
        }
        .allow-list-events {
            width: 20%;
        }
        .allow-list-first-seen {
            width: 12%;
        }
        .allow-list-sources {
            width: 8%;
        }
        .allow-list-trend {
            height: 100%;
            width: 15%;
        }
        .vulnerabilities-pattern-id {
            width: 8%;
        }
        .vulnerabilities-pattern {
            width: 12%;
        }
        .vulnerabilities-details {
            width: 25%;
        }
        .vulnerabilities-events {
            width: 15%;
        }
        .vulnerabilities-first-seen {
            width: 12%;
        }
        .vulnerabilities-sources {
            width: 8%;
        }
        .vulnerabilities-trend {
            height: 100%;
            width: 15%;
        }

        .database-parameter {
            width: 12%;
        }
        .database-pattern-id {
            width: 8%;
        }
        .database-description {
            width: 25%;
        }
        .database-events {
            width: 15%;
        }
        .database-first-seen {
            width: 12%;
        }
        .database-sources {
            width: 8%;
        }
        .database-trend {
            height: 100%;
            width: 15%;
        }
        .more-col,
        .allow-list-more,
        .vulnerabilities-more,
        .database-more {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 5%;
            margin-left: auto;
        }
        .trend-chart-container {
            height: 100%;
        }

        .bad-bot-ip {
            width: 15%;
        }
        .bad-bot-isp {
            width: 20%;
        }
        .bad-bot-country {
            width: 20%;
        }
        .bad-bot-count {
            width: 10%;
            .bad-bot-count-value {
                display: inline-block;
                padding: 6px 10px;
                background-color: $hits-color;
                color: #ffffff;
                border-radius: 10px;
                height: 30px;
                cursor: pointer;
            }
        }
        .bot-name {
            width: 20%;
        }
        .bot-category {
            width: 20%;
        }
        .bot-count {
            width: 15%;
        }
        .legit-bot-action {
            width: 10%;
        }
    }
}

.application-insights {
    .sub-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 25px;

        .inner-navigator .radio-group {
            margin-bottom: 0;
            border: none;
        }

        .summary {
            display: flex;
            font-size: 18px;
            .activities {
                margin-left: 20px;
            }
            .events,
            .activities {
                .value {
                    font-size: 24px;
                    font-weight: 600;
                    line-height: normal;
                    color: #00c0ef;
                    margin-right: 5px;
                }
            }
        }
    }
    .http-request-sample-modal .modal-body {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .no-data-action {
        border: none;
        outline: none;
        color: var(--text-color);
        font-size: 16px;
        background: none;
        margin-top: 10px;
        text-decoration: underline;
    }
}

.property-value {
    @extend %text-no-overflow;
    padding-right: 15px;
}

.bot-analytics {
    .module-type-selector-wrapper {
        margin: 10px 0 20px 0;
    }
    .header .module-type-selector-wrapper {
        margin-top: 0;
        &:before {
            content: '';
            margin: 0px 24px;
            height: 24px;
            width: 1px;
            background-color: var(--tab-active-line);
            align-self: center;
        }
    }
    .sub-tabs {
        margin-right: 20px;
    }
    .item-list-summary {
        display: flex;
        margin-left: auto;
        .export-button {
            align-content: flex-end;
        }
        .total-hits {
            display: flex;
            position: relative;
            padding: 6px 10px;
            font-size: 12px;
            margin-right: 20px;
            background-color: $hits-color;
            color: #ffffff;
            border-radius: 10px;
            height: 30px;

            .total-hits-value {
                font-size: 12px;
                font-weight: 500;
                margin-left: 10px;
            }
            .sign-in-spinner {
                left: calc(100% + 14px);
                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
    .activity-list-wrapper {
        height: calc(100% - 120px);

        .ReactVirtualized__Grid__innerScrollContainer {
            overflow: unset !important;
        }

        .allow-list-unblock {
            margin-left: auto;
            & + .allow-list-more {
                margin-left: 0;
            }
        }
    }
    .item-list-header {
        display: flex;
        position: relative;
        margin-bottom: 10px;
        .item-list-search-wrapper {
            margin-bottom: 0;
            input {
                min-width: 250px;
            }
        }
        .select-with-validation {
            width: 300px;
            &.bot-search-type {
                width: 150px;
                margin-right: 10px;

                .Select {
                    min-width: unset;
                }
            }
        }
    }
}

.ip-analytics {
    .activity-list-wrapper {
        height: calc(100% - 110px - 110px);
        .ip-address-col {
            width: 15%;
            font-weight: 600;
        }
        .country-col {
            width: 10%;
        }
        .apps-count-col {
            width: 8%;
            font-weight: 600;
        }
        .first-seen-col {
            width: 15%;
        }
        .risk-col {
            width: 8%;
            font-weight: 600;
        }
        .events-count-col {
            width: 20%;
        }
        .trend-chart-col {
            height: 100%;
            width: 20%;
        }
        .more-col {
            width: 4%;
        }
    }
}

/*Special IP Analytics style*/
.ip-analytics {
    .analytics-chart-wrapper {
        padding-top: 10px;
        height: 150px;
    }
    .activity-list-wrapper .activity-list .activity .details-wrapper .details-body .details {
        flex: 2;
        @media (max-width: 1300px) {
            flex: 3;
        }
    }
    .activity-list-wrapper .activity-list .activity .details-wrapper .details-body {
        .top-items-donut {
            flex: 1;
            height: 315px;
            width: 300px;
            @media (max-width: 1300px) {
                flex: 2;
                width: 250px;
            }
        }
        .owasp {
            padding: 15px 30px 35px 20px;
            width: 290px;
        }
    }

    .module-type-selector-wrapper .summary {
        flex-direction: column;
        min-width: 210px;
        margin-left: 20px;
        .summary-block {
            text-align: left;
        }
    }
}

.ip-analytics,
.application-insights {
    .filter-body {
        .input-wrapper {
            position: relative;
            .clear-ip-btn {
                position: absolute;
                right: 7px;
                top: 8px;
                font-size: 18px;
                font-weight: 300;
                line-height: 1;
                color: #999;
                cursor: pointer;
            }
        }
    }
}
