.application-states-wrapper input[type='radio'] + label:before,
.application-protection-item-protection-modes input[type='radio'] + label:before {
    border: none;
    background: transparent;
}
.application-states-wrapper input[type='radio']:checked + label:after,
.application-protection-item-protection-modes input[type='radio']:checked + label:after {
    display: none;
}
.application-states-wrapper input[type='radio'] + label,
.application-protection-item-protection-modes input[type='radio'] + label {
    padding-left: 26px;
}
.application-operator-wrapper .applications-create-form-protection input[type='radio'] + label,
.application-operator-wrapper .applications-create-form-protection input[type='radio'] + label {
    padding-left: 26px;
}

.application-states-wrapper input[disabled] + label,
.application-states-wrapper input[disabled] + label:hover {
    opacity: 0.4;
}

.application-states-wrapper input[type='radio'] + label:before,
.application-protection-item-protection-modes input[type='radio'] + label:before {
    border: none;
    background: transparent;
}

.application-states-wrapper input[type='radio']:checked + label:after,
.application-protection-item-protection-modes input[type='radio']:checked + label:after {
    display: none;
}

.application-states-wrapper input[type='radio'] + label,
.application-protection-item-protection-modes input[type='radio'] + label {
    padding-left: 26px;
}

.application-operator-wrapper .item-create-form-protection input[type='radio'] + label,
.application-operator-wrapper .item-create-form-protection input[type='radio'] + label {
    padding-left: 26px;
}

.application-states-wrapper input[disabled] + label,
.application-states-wrapper input[disabled] + label:hover {
    opacity: 0.4;
}

.application-states-wrapper input[type='radio'] + label.provisioning-state:before {
    display: block;
    background-image: url('../static-assets/images/app-provisioning-small.svg');
    width: 22px;
    height: 22px;
    opacity: 0.4;
}

.application-states-wrapper input[type='radio']:checked + label.provisioning-state:before,
.application-states-wrapper input[type='radio']:hover + label.provisioning-state:before {
    opacity: 1;
}

.application-states-wrapper input[type='radio'] + label.learning-state:before {
    display: block;
    background-image: url('../static-assets/images/app-learning-small.svg');
    width: 22px;
    height: 22px;
    opacity: 0.4;
}

.application-states-wrapper input[type='radio']:checked + label.learning-state:before,
.application-states-wrapper input[type='radio']:hover + label.learning-state:before {
    opacity: 1;
}

.application-states-wrapper input[type='radio'] + label.protecting-state:before {
    display: block;
    background-image: url('../static-assets/images/protected_shield_icon.svg');
    width: 22px;
    height: 22px;
    opacity: 0.4;
    background-size: contain;
    border-radius: 0;
}

.application-states-wrapper input[type='radio']:checked + label.protecting-state:before,
.application-states-wrapper input[type='radio']:hover + label.protecting-state:before {
    opacity: 1;
}

.application-protection-item-protection-modes input[type='radio']:hover + label.protection-block-mode:before {
    opacity: 1;
}

.application-protection-item-protection-modes input[type='radio']:hover + label.protection-report-mode:before {
    opacity: 1;
}

.application-operator-wrapper .form-content-wrapper {
    padding-left: 26px;
    padding-top: 0;
    font-size: 0.923em;
    font-weight: 400;
}

.application-operator-wrapper .form-content-wrapper .application-operator-alias-form input[type='text'],
.application-operator-wrapper .form-content-wrapper .application-operator-main-domain-form input[type='text'] {
    width: 100%;
    margin-bottom: 10px;
}

.form-content-wrapper .application-operator-alias-form .add_fields-wrapper {
    margin-bottom: 20px;
}

.application-operator-wrapper .features-availability {
    margin: 20px;
}

.application-protection-mode .radio-group div {
    display: inline-block;
    padding: 10px 10px 10px 0;
}

.application-protection-mode {
    white-space: nowrap;
}

.application-main-domain-title,
.application-operator-aliases-title,
.application-operator-dns-records-title,
.application-states-title,
.features-availability-title,
.application-operator-wrapper .applications-create-form-protection-title,
.region-title {
    margin: 8px 0;
    border-top: 2px solid #efeff0;
    color: #58595b;
    font-size: 11px;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
}

.app-protection-modes-save {
    float: right;
}

.application-operator-wrapper .regions-container {
    float: left;
    width: 100%;
    clear: both;
    margin: 20px;
}
